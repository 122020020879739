import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { store as useStore } from "../../../../components/VerticalLayout/store";
import { capitalize } from "../../../../helpers/general";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

export function ConfirmationModalActiveApplication({
    isConfirmationModalActive,
    setIsConfirmationModalActive,
    setIsDataUpdated,
    selectedRows,
    setIsAddApplication,
}: {
    isConfirmationModalActive: boolean;
    setIsConfirmationModalActive: Function;
    setIsDataUpdated: Function;
    selectedRows: any;
    setIsAddApplication: Function;
}) {
    const { t } = useTranslation();
    const { shopID, oneShop, userID, franchiseID, operatorID } = useSnapshot(
        useStore
    );
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");

    async function activeApplication() {
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/orb`;
            const appId: string = "ORB";
            let data = {
                userId: userID,
                shopId: oneShop ? shopID : selectedRows[0]?.shopId,
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                type: "Désarchivage",
                data: {
                    [appId]: {
                        id_application: appId,
                        data_app: {
                            [selectedRows[0]?.appId]: {
                                id_application_children: selectedRows[0]?.appId,
                                printer: selectedRows[0].printer,
                                designation: selectedRows[0][t("Application")],
                                ip: selectedRows[0][t("Address IP")],
                                note: selectedRows[0][t("Note")],
                                isArchived: false,
                                template: selectedRows[0].template,
                            },
                        },
                    },
                },
            };
            try {
                fetch(apiUrlAdd, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(data),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "This application was successfully unarchived"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsConfirmationModalActive(
                            !isConfirmationModalActive
                        );
                        setIsAddApplication(false);
                        setIsDataUpdated!(true);
                        setIsValidateButtonDisabled(false);
                    });
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    async function activedApplications() {
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);

            const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/orb`;
            let localData: any = [];
            const appId: string = "ORB";
            selectedRows.forEach((row: any) => {
                if (row.appId !== undefined) {
                    let data = {
                        userId: userID,
                        shopId: oneShop ? shopID : row.shopId,
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        type: "Désarchivage",
                        data: {
                            [appId]: {
                                id_application: appId,
                                data_app: {
                                    [row?.appId]: {
                                        id_application_children: row?.appId,
                                        printer: row.printer,
                                        designation: row[t("Application")],
                                        ip: row[t("Address IP")],
                                        note: row[t("Note")],
                                        isArchived: false,
                                        template: row.template,
                                    },
                                },
                            },
                        },
                    };
                    localData.push(
                        fetch(apiUrl, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(data),
                        }).then((response) => response.json())
                    );
                }
            });
            try {
                mutate(
                    apiUrl,
                    await Promise.all(localData).then((result: any) => {
                        let errors: boolean[] = result.map(
                            (el: any) => el.error
                        );
                        if (!errors.includes(true)) {
                            toast.success(
                                `${t(
                                    "This application was successfully unarchived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsConfirmationModalActive(
                                !isConfirmationModalActive
                            );
                            setIsAddApplication(false);
                            setIsDataUpdated(true);
                            setIsValidateButtonDisabled(false);
                        } else {
                            throw Error(result.message);
                        }
                    })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    return (
        <StyledModal toggle={true} isOpen={isConfirmationModalActive!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsConfirmationModalActive(!isConfirmationModalActive);
                    setIsDataUpdated(true);
                }}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "Do you want to confirm the unarchiving of the selected application?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsConfirmationModalActive(
                            !isConfirmationModalActive
                        );
                        setIsDataUpdated(true);
                    }}
                    className="cmn_btn_cancelAlert"
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2 cmn_btn_validateAlert"
                    variant="primary"
                    onClick={() => {
                        selectedRows.length > 1
                            ? activedApplications()
                            : activeApplication();
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
