import React from "react";
import {
    SettingsIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { Tooltip } from "@mui/material";
import classnames from "classnames";
import ErrorToast from "@components/Common/ErrorTost";
import { t } from "i18next";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";

import store, {
    setIsSettingModalOpened,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setIsSettingFromPrinter,
    setIsLoading,
} from "./store";
import { store as userStore } from "@components/VerticalLayout/store";

import {
    verifyIfPeripheralAssociate,
    verifyIfPeripheralAssociateApplicationKds,
} from "../../../helpers/peripheralsHelpers";

import { setShopRowData } from "@pages/SettingGeneral/Language/store";

export function CustomIconsComponent({
    e,
    setShopId,
    handleTestPrinterButtonOnClickEvent,
    setEditedData,
    setIsModalOpened,
    setPrinterId,
    isModalOpened,
    isModalDeleteAssociatePeripheralOpened,
    setIsModalDeleteAssociatePeripheralOpened,
    setInputIp,
    setIsConfirmationEditRunning,
    typeDisplay,
    setIsModalOpenedHistory,
    isModalOpenedHistory,
    setSelectedShopId,
    setIsConsult,
    setIsAddPrinterClicked,
    isAddPrinterClicked,
}: any) {
    const {
        isUpdateOperation,
        isActionsClicked,
        isEdited,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { userID, franchiseID } = useSnapshot(userStore);
    const appId = "KIOSK";
    const idApp = "KDS";
    let ifPeripheralAssociateObject: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    let ifPeripheralAssociateApplicationKds: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    return (
        <React.Fragment>
            {e.selectedRow.subRows === undefined ? (
                <div className="d-flex justify-content-around ">
                    {(modify === false || typeDisplay === "archived") && (
                        <Tooltip title={t("To consult").toString()}>
                            <div className="p-1">
                                <ConsultSvgIcon
                                    className="cmn_icn_toConsult"
                                    height={35}
                                    width={35}
                                    onClick={() => {
                                        setEditedData(e.selectedRow);
                                        setIsConsult(true);
                                        setShopId(e.selectedRow.shopId);
                                        setInputIp(false);
                                        setIsAddPrinterClicked(true);
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "5px",
                                    }}
                                />
                            </div>
                        </Tooltip>
                    )}
                    {modify === true ? (
                        <>
                            {typeDisplay === "notArchived" ? (
                                <Tooltip title={t("Configure").toString()}>
                                    <div>
                                        <SettingsIcon
                                            className={
                                                "pointer__clz cmn_icn_printSettings"
                                            }
                                            onClick={() => {
                                                setIsSettingFromPrinter(true);
                                                setShopRowData(e.selectedRow);
                                                setIsSettingModalOpened(true);
                                            }}
                                            height={22}
                                            width={22}
                                            style={{
                                                position: "relative",
                                                top: "7px",
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            ) : null}
                            <div className="p-1">
                                {typeDisplay === "notArchived" ? (
                                    <Tooltip title={t("Edit").toString()}>
                                        <div>
                                            <PencilIcon
                                                className={` ${classnames(
                                                    "cmn_icn_FRedit",
                                                    {
                                                        "not-allowed-icon__clz":
                                                            typeDisplay ===
                                                            "archived",
                                                    }
                                                )}`}
                                                height={25}
                                                width={25}
                                                onClick={() => {
                                                    if (isEdited === true) {
                                                        setIsActionsClicked(
                                                            !isActionsClicked
                                                        );
                                                        setActions(() => {
                                                            setIsEdited(false);
                                                            setEditedData(
                                                                e.selectedRow
                                                            );
                                                            setShopId(
                                                                e.selectedRow
                                                                    .shopId
                                                            );
                                                            setInputIp(false);

                                                            setIsAddPrinterClicked(
                                                                true
                                                            );
                                                        });
                                                    } else {
                                                        setEditedData(
                                                            e.selectedRow
                                                        );
                                                        setShopId(
                                                            e.selectedRow.shopId
                                                        );
                                                        setInputIp(false);

                                                        setIsAddPrinterClicked(
                                                            true
                                                        );
                                                    }
                                                }}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </div>
                                    </Tooltip>
                                ) : null}
                            </div>
                            <div className="p-1">
                                {typeDisplay === "notArchived" ? (
                                    <Tooltip title={t("Archive").toString()}>
                                        <div>
                                            <TrashIcon
                                                className={`${classnames(
                                                    "cmn_icn_FRarchived",
                                                    {
                                                        "not-allowed-icon__clz": isLoading,
                                                    }
                                                )}`}
                                                onClick={async () => {
                                                    setIsLoading(true);
                                                    if (isEdited === true) {
                                                        setIsLoading(false);
                                                        setIsActionsClicked(
                                                            !isActionsClicked
                                                        );
                                                        setActions(async () => {
                                                            setIsEdited(false);
                                                            setIsAddPrinterClicked(
                                                                false
                                                            );
                                                            if (
                                                                isUpdateOperation ===
                                                                    true &&
                                                                isAddPrinterClicked ===
                                                                    true
                                                            ) {
                                                                setIsConfirmationEditRunning(
                                                                    true
                                                                );
                                                            } else {
                                                                setShopId(
                                                                    e
                                                                        .selectedRow
                                                                        .shopId
                                                                );
                                                                ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                                    e
                                                                        .selectedRow
                                                                        .Id,
                                                                    e
                                                                        .selectedRow
                                                                        .shopId,
                                                                    franchiseID,
                                                                    userID,
                                                                    appId
                                                                );
                                                                ifPeripheralAssociateApplicationKds = await verifyIfPeripheralAssociateApplicationKds(
                                                                    e
                                                                        .selectedRow
                                                                        .Id,
                                                                    e
                                                                        .selectedRow
                                                                        .shopId,
                                                                    franchiseID,
                                                                    userID,
                                                                    idApp
                                                                );

                                                                if (
                                                                    ifPeripheralAssociateObject.isPeripheralAssociate ||
                                                                    ifPeripheralAssociateApplicationKds.isPeripheralAssociate
                                                                ) {
                                                                    ErrorToast(
                                                                        t(
                                                                            "Please note that you cannot archive a device associated with an application."
                                                                        )
                                                                    );
                                                                } else {
                                                                    setIsModalOpened(
                                                                        !isModalOpened
                                                                    );
                                                                }

                                                                setPrinterId(
                                                                    e
                                                                        .selectedRow
                                                                        .Id
                                                                );
                                                            }
                                                        });
                                                    } else {
                                                        if (
                                                            isUpdateOperation ===
                                                                true &&
                                                            isAddPrinterClicked ===
                                                                true
                                                        ) {
                                                            setIsConfirmationEditRunning(
                                                                true
                                                            );
                                                            setIsLoading(false);
                                                        } else {
                                                            setShopId(
                                                                e.selectedRow
                                                                    .shopId
                                                            );
                                                            ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                                e.selectedRow
                                                                    .Id,
                                                                e.selectedRow
                                                                    .shopId,
                                                                franchiseID,
                                                                userID,
                                                                appId
                                                            );
                                                            ifPeripheralAssociateApplicationKds = await verifyIfPeripheralAssociateApplicationKds(
                                                                e.selectedRow
                                                                    .Id,
                                                                e.selectedRow
                                                                    .shopId,
                                                                franchiseID,
                                                                userID,
                                                                idApp
                                                            );
                                                            if (
                                                                ifPeripheralAssociateObject.isPeripheralAssociate ||
                                                                ifPeripheralAssociateApplicationKds.isPeripheralAssociate
                                                            ) {
                                                                ErrorToast(
                                                                    t(
                                                                        "Please note that you cannot archive a device associated with an application."
                                                                    )
                                                                );
                                                                setIsLoading(
                                                                    false
                                                                );
                                                            } else {
                                                                setIsModalOpened(
                                                                    !isModalOpened
                                                                );
                                                                setIsLoading(
                                                                    false
                                                                );
                                                            }

                                                            setPrinterId(
                                                                e.selectedRow.Id
                                                            );
                                                        }
                                                    }
                                                }}
                                                style={{ cursor: "pointer" }}
                                                height={25}
                                                width={25}
                                                fill="red"
                                            />
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={t("Unarchive").toString()}>
                                        <div>
                                            <EyeIcon
                                                className={`${classnames(
                                                    "cmn_icn_unarchive",
                                                    {
                                                        "not-allowed-icon__clz": isLoading,
                                                    }
                                                )}`}
                                                onClick={async () => {
                                                    setIsLoading(true);
                                                    if (
                                                        isUpdateOperation ===
                                                            true &&
                                                        isAddPrinterClicked ===
                                                            true
                                                    ) {
                                                        setIsConfirmationEditRunning(
                                                            true
                                                        );
                                                        setIsLoading(false);
                                                    } else {
                                                        setShopId(
                                                            e.selectedRow.shopId
                                                        );
                                                        ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                            e.selectedRow.Id,
                                                            e.selectedRow
                                                                .shopId,
                                                            franchiseID,
                                                            userID,
                                                            appId
                                                        );

                                                        if (
                                                            ifPeripheralAssociateObject.isPeripheralAssociate
                                                        ) {
                                                            setIsModalDeleteAssociatePeripheralOpened(
                                                                !isModalDeleteAssociatePeripheralOpened
                                                            );
                                                            setIsLoading(false);
                                                        } else {
                                                            setIsModalOpened(
                                                                !isModalOpened
                                                            );
                                                            setIsLoading(false);
                                                        }
                                                        setPrinterId(
                                                            e.selectedRow.Id
                                                        );
                                                    }
                                                }}
                                                style={{ cursor: "pointer" }}
                                                height={25}
                                                width={25}
                                                fill="black"
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>

                            <div className="p-1">
                                <Tooltip
                                    title={t("Consult history").toString()}
                                >
                                    <div>
                                        <TimePastSvgIcon
                                            height={25}
                                            width={25}
                                            fill="black"
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(async () => {
                                                        setIsEdited(false);
                                                        setIsAddPrinterClicked(
                                                            false
                                                        );
                                                        setPrinterId(
                                                            e.selectedRow.Id
                                                        );
                                                        setSelectedShopId(
                                                            e.selectedRow.shopId
                                                        );
                                                        setIsModalOpenedHistory(
                                                            !isModalOpenedHistory
                                                        );
                                                    });
                                                } else {
                                                    setPrinterId(
                                                        e.selectedRow.Id
                                                    );
                                                    setSelectedShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setIsModalOpenedHistory(
                                                        !isModalOpenedHistory
                                                    );
                                                }
                                            }}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_BThistory cmn_icn_history",
                                                {}
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="p-1">
                                <Tooltip
                                    title={t("Consult history").toString()}
                                >
                                    <div>
                                        <TimePastSvgIcon
                                            height={25}
                                            width={25}
                                            fill="black"
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(async () => {
                                                        setIsEdited(false);
                                                        setIsAddPrinterClicked(
                                                            false
                                                        );
                                                        setPrinterId(
                                                            e.selectedRow.Id
                                                        );
                                                        setSelectedShopId(
                                                            e.selectedRow.shopId
                                                        );
                                                        setIsModalOpenedHistory(
                                                            !isModalOpenedHistory
                                                        );
                                                    });
                                                } else {
                                                    setPrinterId(
                                                        e.selectedRow.Id
                                                    );
                                                    setSelectedShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setIsModalOpenedHistory(
                                                        !isModalOpenedHistory
                                                    );
                                                }
                                            }}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_BThistory cmn_icn_history",
                                                {}
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        </>
                    )}
                </div>
            ) : null}
        </React.Fragment>
    );
}
