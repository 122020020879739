import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CheckboxIcon,
    StyledH2,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { AvForm, AvGroup } from "availity-reactstrap-validation";

import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import {
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import classnames from "classnames";
import { mutate } from "swr";
import { toast } from "react-toastify";

export default function ModalPass({
    isModalPassClicked,
    setIsModalPassClicked,
    cosutmerName,
    uid,
    setDataIsUpdated,
}: any) {
    const { t } = useTranslation();
    const { franchiseID } = useSnapshot(store);
    const [passinitial, setPassinitial] = useState<string>("");
    const [newpass, setNewpass] = useState<string>("");
    const [confirmpass, setConfirmpass] = useState<string>("");
    const [inputpassinitial, setInputpassinitial] = React.useState<boolean>(
        false
    );
    const [inputnewpass, setInputnewpass] = React.useState<boolean>(false);
    const [inputconfirmpass, setInputconfirmpass] = React.useState<boolean>(
        false
    );
    const [checkinputconform, setCheckinputconform] = React.useState<boolean>(
        false
    );

    async function handleStateChange() {
        if (
            /^\d*$/.test(passinitial) &&
            /^\d*$/.test(newpass) &&
            /^\d*$/.test(confirmpass)
        ) {
            let data = {
                uid: uid,
                passinitial: passinitial,
                newpasscode: newpass,
            };

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/updatepasscode`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            console.log(data);
                            if (data === true) {
                                setIsModalPassClicked(false);
                                toast.success(
                                    `${t("Pass code modifié avec succès")}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        theme: "colored",
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                            } else {
                                toast.error(`${t("Passcode incorrect")!}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                            }
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } else {
            toast.error(`${t("Passcode doit être numérique ")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <AvForm>
            <Modal size="l" centered isOpen={isModalPassClicked}>
                <ModalHeader
                    className="text-capitalize"
                    toggle={() => {
                        setIsModalPassClicked(!isModalPassClicked);
                    }}
                >
                    <StyledH2 className="text-uppercase">
                        {t("MODIFICATION PASSCODE")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <CustomCardBody>
                        <AvGroup>
                            <StyledLabel
                                htmlFor="name"
                                className="required__clz mt-3"
                            >
                                {t("Veuillez saisi le passcode initiale")}
                            </StyledLabel>
                            <StyledTextInput
                                autocomplete="off"
                                maxlength="4"
                                id="passcodeInitiale"
                                name="passcodeInitiale"
                                placeholder={t("Write")}
                                type="password"
                                onChange={(e: any) => {
                                    const value = e.target.value;

                                    // Only allow numeric input
                                    setPassinitial(e.target.value);
                                    setInputpassinitial(false);
                                }}
                            />
                            {inputpassinitial ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please enter a passcode")}
                                </div>
                            ) : null}
                        </AvGroup>
                        <AvGroup>
                            <StyledLabel
                                htmlFor="name"
                                className="required__clz mt-3"
                            >
                                {t("Veuillez saisi le nouveau passcode")}
                            </StyledLabel>
                            <StyledTextInput
                                autocomplete="off"
                                id="newpasscode"
                                name="newpasscode"
                                inputMode="numeric"
                                placeholder={t("Write")}
                                type="password"
                                maxlength="4"
                                onChange={(e: any) => {
                                    setNewpass(e.target.value);
                                    setInputnewpass(false);
                                }}
                            />

                            {inputnewpass ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please enter a passcode")}
                                </div>
                            ) : null}
                        </AvGroup>
                        <AvGroup>
                            <StyledLabel
                                htmlFor="name"
                                className="required__clz mt-3"
                            >
                                {t("Veuillez confirmer le nouveau passcode")}
                            </StyledLabel>
                            <StyledTextInput
                                autocomplete="off"
                                id="confirmpasscode"
                                name="confirmpasscode"
                                maxlength="4"
                                placeholder={t("Write")}
                                type="password"
                                onChange={(e: any) => {
                                    setConfirmpass(e.target.value);
                                    setInputconfirmpass(false);

                                    setCheckinputconform(false);
                                }}
                            />
                            {inputconfirmpass ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please enter a passcode")}
                                </div>
                            ) : null}

                            {checkinputconform ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Passcode incorrect")}
                                </div>
                            ) : null}
                        </AvGroup>
                    </CustomCardBody>
                    <CustomCardFooter className="pt-1 pb-2">
                        <CustomSecondaryColorButton
                            outline
                            rounded
                            variant="light"
                            className="mr-2 afa_btn_FRcancel  cmn_btn_cancel"
                            onClick={() => {}}
                        >
                            <span> {t("Cancel")}</span>
                        </CustomSecondaryColorButton>
                        <CustomMainColorButton
                            rounded
                            variant="primary"
                            onClick={() => {
                                if (passinitial === "") {
                                    setInputpassinitial(true);
                                }
                                if (newpass === "") {
                                    setInputnewpass(true);
                                }
                                if (confirmpass === "") {
                                    setInputconfirmpass(true);
                                }
                                if (newpass != confirmpass) {
                                    setCheckinputconform(true);
                                } else if (
                                    !inputconfirmpass &&
                                    !inputnewpass &&
                                    !inputpassinitial
                                ) {
                                    setCheckinputconform(false);
                                    handleStateChange();
                                }
                            }}
                            className={classnames(
                                "afa_btn_FRvalidate cmn_btn_validate"
                            )}
                        >
                            {t("Validate")}
                        </CustomMainColorButton>
                    </CustomCardFooter>
                </ModalBody>
            </Modal>
        </AvForm>
    );
}
