import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { diff } from "deep-diff";

import { store } from "@components/VerticalLayout/store";
import { store as orbStore } from "../store";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./index.css";

type PropsType = {
    children: React.ReactNode;
    setIsConfigurationModalOpened: Function;
    isConfigurationModalOpened: boolean;
    rowData: any;
    setIsDataUpdated: Function;
    setIsModalConfirmationConfigurationOrb: Function;
};

export function ModalWrapper({
    children,
    setIsConfigurationModalOpened,
    isConfigurationModalOpened,
    rowData,
    setIsDataUpdated,
    setIsModalConfirmationConfigurationOrb,
}: PropsType): JSX.Element {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID, oneShop } = useSnapshot(store);
    const {
        view,
        language,
        model,
        options,
        categoryAssignment,
        advertisingAnimation,
        template,
    } = useSnapshot(orbStore);
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const [
        isValidateButtonProjectDisabled,
        setIsValidateButtonProjectDisabled,
    ] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");
    async function handleUpdateProjectButtonOnClickEvent() {
        if (isValidateButtonProjectDisabled === false) {
            setIsValidateButtonProjectDisabled(true);
            let apiUrl: any;

            if (
                Object.keys(template).length !== 0 &&
                diff(template, {
                    view: view,
                    language: language,
                    model: model,
                    options: options,
                    categoryAssignment: categoryAssignment,
                    advertisingAnimation: advertisingAnimation,
                }) === undefined
            ) {
                setIsConfigurationModalOpened(!isConfigurationModalOpened);
                setIsValidateButtonProjectDisabled(false);
            } else {
                apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/configuration/orb`;
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            userId: userID,
                            franchiseId: franchiseID,
                            project_id: "PROJECT",
                            projectId: rowData.projectId,
                            shopId:
                                oneShop &&
                                Object.keys(rowData.template).length === 0
                                    ? "0"
                                    : rowData.shopId,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            data: {
                                template: {
                                    view: view,
                                    language: language,
                                    model: model,
                                    options: options,
                                    categoryAssignment: categoryAssignment,
                                    advertisingAnimation: advertisingAnimation,
                                },
                            },
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.error) {
                                throw Error(result.message);
                            }
                            !oneShop &&
                                toast.success(
                                    `${t(
                                        "ORB configuration saved successfully"
                                    )}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );

                            if (
                                rowData !== undefined &&
                                Object.keys(rowData).length > 0 &&
                                Object.keys(rowData?.template).length !== 0 &&
                                rowData?.subRows.length > 0 &&
                                rowData?.subRows.some(
                                    (el: any) =>
                                        Object.keys(el.template).length !== 0
                                ) &&
                                diff(template, {
                                    view: view,
                                    language: language,
                                    model: model,
                                    options: options,
                                    categoryAssignment: categoryAssignment,
                                    advertisingAnimation: advertisingAnimation,
                                }) !== undefined
                            ) {
                                setIsModalConfirmationConfigurationOrb(true);
                            } else {
                                setIsDataUpdated(true);
                            }
                            setIsValidateButtonProjectDisabled(false);
                            setIsConfigurationModalOpened(
                                !isConfigurationModalOpened
                            );
                        })
                        .catch((error) => {
                            toast.error(`${t("There's an error")!}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });
                        })
                );
            }
        }
    }
    async function handleUpdateAssociateShopButtonOnClickEvent(
        shopId: any,
        idProject: any
    ) {
        let apiUrl: any;
        let savedData = {
            userId: userID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            franchiseId: franchiseID,
            shopId: shopId,
            idProject: idProject,
            template: {
                view: view,
                language: language,
                model: model,
                options: options,
                categoryAssignment: categoryAssignment,
                advertisingAnimation: advertisingAnimation,
            },
        };
        apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/associated/orb`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t("ORB configuration saved successfully")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }
    async function handleValidate() {
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            let localData: any = [];
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/configuration/orb`;
            if (rowData.subRows.length !== 0) {
                for (let el of rowData.subRows) {
                    let savedData: any = {
                        userId: userID,
                        franchiseId: franchiseID,
                        project_id: "PROJECT",
                        projectId: el.projectId,
                        shopId: el.id_boutique,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        data: {
                            template: {
                                view: view,
                                language: language,
                                model: model,
                                options: options,
                                categoryAssignment: categoryAssignment,
                                advertisingAnimation: advertisingAnimation,
                            },
                        },
                    };

                    localData.push(
                        fetch(apiUrl, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                        }).then((response) => response.json())
                    );
                    setIsValidateButtonDisabled(false);
                }
            }
        }
    }

    async function handleUpdateShopButtonOnClickEvent() {
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            let apiUrlPlan = `${process.env.REACT_APP_API_V2_URL}/settings/project/configuration/orb`;
            toast.dismiss();
            let localData: any = [];
            let arrayOfShops: any =
                rowData.associates !== undefined &&
                Object.keys(rowData.associates).length !== 0
                    ? Object.keys(rowData.associates)
                    : [rowData.id_boutique];
            if (
                Object.keys(template).length !== 0 &&
                diff(template, {
                    view: view,
                    language: language,
                    model: model,
                    options: options,
                    categoryAssignment: categoryAssignment,
                    advertisingAnimation: advertisingAnimation,
                }) === undefined
            ) {
                setIsConfigurationModalOpened(!isConfigurationModalOpened);
                setIsValidateButtonDisabled(false);
            } else {
                arrayOfShops.forEach((el: any) => {
                    localData.push(
                        fetch(apiUrlPlan, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                userId: userID,
                                franchiseId: franchiseID,
                                project_id: "PROJECT",
                                projectId: rowData.projectId,
                                shopId: el,
                                operatorId: operatorID,
                                uuidUser: uuidUser,
                                data: {
                                    template: {
                                        view: view,
                                        language: language,
                                        model: model,
                                        options: options,
                                        categoryAssignment: categoryAssignment,
                                        advertisingAnimation: advertisingAnimation,
                                    },
                                },
                            }),
                        }).then((response) => response.json())
                    );
                });
                try {
                    mutate(
                        apiUrlPlan,
                        await Promise.all(localData).then(
                            async (result: any) => {
                                let errors: boolean[] = result.map(
                                    (el: any) => el.error
                                );
                                if (!errors.includes(true)) {
                                    if (
                                        rowData !== undefined &&
                                        Object.keys(rowData).length > 0 &&
                                        Object.keys(rowData?.template)
                                            .length !== 0 &&
                                        rowData?.subRows.length > 0 &&
                                        rowData?.subRows.some(
                                            (el: any) =>
                                                Object.keys(el.template)
                                                    .length !== 0
                                        ) &&
                                        diff(template, {
                                            view: view,
                                            language: language,
                                            model: model,
                                            options: options,
                                            categoryAssignment: categoryAssignment,
                                            advertisingAnimation: advertisingAnimation,
                                        }) !== undefined
                                    ) {
                                        setIsModalConfirmationConfigurationOrb(
                                            true
                                        );
                                    } else {
                                        setIsDataUpdated(true);
                                    }
                                    handleUpdateAssociateShopButtonOnClickEvent(
                                        rowData.id_boutique,
                                        rowData.projectId
                                    );
                                    setIsConfigurationModalOpened(
                                        !isConfigurationModalOpened
                                    );
                                    setIsValidateButtonDisabled(false);
                                } else {
                                    throw Error(result.message);
                                }
                            }
                        )
                    );
                } catch (e: any) {
                    toast.error(`${t("There's an error")}!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }

    async function handleApplicationValidateButtonClickEvent() {
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/orb/template`;
            if (
                Object.keys(template).length !== 0 &&
                diff(template, {
                    view: view,
                    language: language,
                    model: model,
                    options: options,
                    categoryAssignment: categoryAssignment,
                    advertisingAnimation: advertisingAnimation,
                }) === undefined
            ) {
                setIsConfigurationModalOpened(!isConfigurationModalOpened);
                setIsValidateButtonDisabled(false);
            } else {
                let savedData = {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    franchiseId: franchiseID,
                    shopId: rowData.shopId,
                    appId: "ORB",
                    appId_children: rowData.appId,
                    template: {
                        [oneShop ? rowData.id_project : rowData.projectId]: {
                            view: view,
                            language: language,
                            model: model,
                            options: options,
                            categoryAssignment: categoryAssignment,
                            advertisingAnimation: advertisingAnimation,
                        },
                    },
                };
                try {
                    mutate(
                        apiUrlAdd,
                        await fetch(apiUrlAdd, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                            method: "POST",
                        })
                            .then((response) => response.json())
                            .then(async (data) => {
                                if (data.error) {
                                    throw Error(
                                        "Error while saving selection!"
                                    );
                                }

                                toast.success(
                                    `${t(
                                        "ORB configuration saved successfully"
                                    )}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                                setIsConfigurationModalOpened(
                                    !isConfigurationModalOpened
                                );
                                setIsDataUpdated(true);
                                setIsValidateButtonDisabled(false);
                            })
                    );
                } catch (e: any) {
                    toast.error(`${t("Mistake ! Please try again")}!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }
    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                isOpen={isConfigurationModalOpened}
                className="modal-wrapper__clz"
            >
                <ModalHeader
                    toggle={() => {
                        setIsConfigurationModalOpened(
                            !isConfigurationModalOpened
                        );
                        setIsDataUpdated(true);
                    }}
                    className="text-uppercase"
                >
                    {t("ORB Setup")}
                </ModalHeader>
                <ModalBody className="p-0">{children}</ModalBody>
                <ModalFooter>
                    <CustomMainColorButton
                        className="w-20 orb_btn_validateAlert"
                        rounded
                        variant="primary"
                        onClick={() => {
                            if (oneShop) {
                                if (rowData.key_project !== undefined) {
                                    if (rowData[t("Status")] !== "Publié") {
                                        handleUpdateShopButtonOnClickEvent();
                                        if (
                                            Object.keys(rowData.template)
                                                .length === 0
                                        ) {
                                            handleUpdateProjectButtonOnClickEvent();
                                        }
                                    } else {
                                        setIsConfigurationModalOpened(
                                            !isConfigurationModalOpened
                                        );
                                        setIsDataUpdated(true);
                                    }
                                } else {
                                    handleApplicationValidateButtonClickEvent();
                                }
                            } else {
                                if (rowData.key_project !== undefined) {
                                    if (rowData[t("Status")] !== "Publié") {
                                        handleUpdateProjectButtonOnClickEvent();
                                        if (
                                            Object.keys(rowData.template)
                                                .length === 0
                                        ) {
                                            handleValidate();
                                        }
                                    } else {
                                        setIsConfigurationModalOpened(
                                            !isConfigurationModalOpened
                                        );
                                        setIsDataUpdated(true);
                                    }
                                } else if (
                                    rowData.key_application === undefined
                                ) {
                                    handleUpdateShopButtonOnClickEvent();
                                } else {
                                    handleApplicationValidateButtonClickEvent();
                                }
                            }
                        }}
                    >
                        {rowData[t("Status")] === "Publié" &&
                        rowData.key_project !== undefined
                            ? t("Close")
                            : t("Save")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
