import React from "react";
import { useTranslation } from "react-i18next";

import { CustomErrorBoundary } from "@components/CustomErrorBoundary";
import { Template } from "./Template";
import { FlexboxGrid } from "@components/Common/FlexboxGrid";

export function TemplateSelection({
    setDesign,
    setView,
    setItems,
    setBeeperCall,
    setReminder,
    setDisplay,
    rowData,
}: any): JSX.Element {
    const { t } = useTranslation();

    return (
        <CustomErrorBoundary>
            <div
                className="d-grid__clz cursor__clz"
                style={{
                    gridTemplateRows: "0.15fr auto",
                    gap: "20px",
                }}
            >
                <span style={{ fontSize: "45px", marginLeft: "2%" }}>
                    {t("Template")}
                </span>
                <FlexboxGrid gap="20px" styles={{ marginLeft: "2%" }}>
                    <Template
                        setDesign={setDesign}
                        setView={setView}
                        setItems={setItems}
                        setBeeperCall={setBeeperCall}
                        setReminder={setReminder}
                        setDisplay={setDisplay}
                        rowData={rowData}
                    />
                </FlexboxGrid>
            </div>
        </CustomErrorBoundary>
    );
}
