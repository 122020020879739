import React from "react";
import classnames from "classnames";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";

import { store as useStore } from "../../../../components/VerticalLayout/store";
import {
    store,
    setIsHistoryShopsModalOpened,
    setShopDataIsUpdated,
    setFranchiseDataIsUpdated,
    setIsLoading,
    setKey,
} from "../store";

import ErrorToast from "../../../../components/Common/ErrorTost";
import { ConfirmationArchive } from "../ConfirmationArchive";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

export function CustomSideFilter({
    isShopSelected,
    selectedRows,
    isAssociateModalOpened,
    setIsAssociateModalOpened,
    setLocalFilterActive,
    isArchivedSaleSupportClicked,
    setIsArchivedSaleSupportClicked,
    setSelectedShopsRows,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(useStore);
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        isHistoryShopsModalOpened,
        listFranchiseArchived,
        modify,
        isLoading,
    } = useSnapshot(store);

    const [error, setError] = React.useState<boolean>(false);
    const [
        isArchivedValidationModalOpened,
        setIsArchivedValidationModalOpened,
    ] = React.useState<boolean>(false);

    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        false
    );
    const uuidUser = localStorage.getItem("uuidUser");
    function ArchivedLanguagesFranchise() {
        let idsAssociated: any[] = [];
        if (listFranchiseArchived !== undefined) {
            listFranchiseArchived.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];
        selectedRows.forEach(async (el: any) => {
            if (
                ids.indexOf(`${el.shopId}${el.Id}`) === -1 &&
                el[t("Shop")] === undefined
            ) {
                arrayOfRows.push(el);
                ids.push(`${el.shopId}${el.Id}`);
            }
        });
        arrayOfRows = [...new Set(arrayOfRows)];

        return arrayOfRows;
    };

    let arrayOfRows = getArrayOfRows();

    async function handleArchiveSaleSupport(value: boolean) {
        setIsLoading(true);
        setLimit(1);
        let localData: any = [];
        let isLimitToastChang: boolean = false;
        let arrayOfRows = getArrayOfRows();
        let itemsCantDelete: any = arrayOfRows.filter(
            (x: any) => x?.isFavorite === true && x?.isArchived === false
        );
        let itemsCanDelete: any = arrayOfRows.filter(
            (x: any) => x?.isFavorite === false
        );

        arrayOfRows = itemsCanDelete;
        let listOfSaleSupport;

        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;

        let saleSupportUsedData: any = [];
        if (!isArchivedSaleSupportClicked) {
            Promise.all(
                arrayOfRows.map(async (el: any) => {
                    listOfSaleSupport = encodeURIComponent(el.Id);
                    let SaleSupportURLUsed = `${process.env.REACT_APP_API_V2_URL}/settings/general/isSaleSupportUsed?userId=${userID}&shopId=${el.shopId}&franchiseId=${franchiseID}&saleSupport=${listOfSaleSupport}`;
                    await fetch(SaleSupportURLUsed, {
                        method: "GET",
                        headers: { "Content-Type": "application/json" },
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.saleSupportUsed.length > 0) {
                                saleSupportUsedData.push({
                                    saleSupportUsed: result.saleSupportUsed,
                                    shopId: el.shopId,
                                });
                            }
                        })
                        .catch((error) => {
                            toast.error(`${t("There's an error")!}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });
                        });
                    return saleSupportUsedData;
                })
            ).then(async (result: any) => {
                if (
                    (!isValidateClicked &&
                        saleSupportUsedData.length > 0 &&
                        saleSupportUsedData.length < selectedRows.length) ||
                    (saleSupportUsedData.length === 0 && !isValidateClicked)
                ) {
                    setIsArchivedValidationModalOpened(true);
                }

                if (isValidateClicked && selectedRows.length !== 0) {
                    arrayOfRows.forEach(async (el: any) => {
                        if (
                            saleSupportUsedData.length !== 0 &&
                            saleSupportUsedData
                                .filter((elem: any) => {
                                    return elem.shopId === el.shopId;
                                })
                                .map(
                                    (element: any) => element.saleSupportUsed[0]
                                )
                                .includes(el.Id)
                        ) {
                        } else {
                            let objectData = {
                                userId: userID,
                                shopId: [el.shopId],
                                franchiseId: franchiseID,
                                operatorId: operatorID,
                                uuidUser: uuidUser,
                                data: {
                                    [el.Id]: {
                                        id: el.Id,
                                        displayName: el[t("Display name")],
                                        support_de_vente: el[t("Sale support")],
                                        shopId: el.shopId,
                                        tag: el.Tag,
                                        isActive: false,
                                        isArchived: value,
                                        isFavorite: el.isFavorite,
                                        advancedDisplayName:
                                            el.advancedDisplayName,
                                    },
                                },
                            };
                            localData.push(objectData);
                        }
                    });
                }

                try {
                    if (localData.length > 0) {
                        await fetch(apiUrl, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(localData),
                        }).then((response) => response.json());
                    }
                } catch (e) {
                    setError(true);
                    setIsLoading(false);
                    toast.error(`${t("There's an error")}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
                if (saleSupportUsedData.length > 0) {
                    isLimitToastChang = true;
                    setIsLoading(false);
                    toast.error(
                        `${t("Attention, you cannot archive a used element.")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsValidateClicked(false);
                }

                if (itemsCantDelete.length > 0) {
                    setLimit(2);
                    ErrorToast(
                        t(
                            `Please note, you cannot archive a favorite sales support`
                        )
                    );
                }
                if (error === false && localData.length > 0) {
                    if (isLimitToastChang === true) setLimit(2);
                    toast.success(
                        !value
                            ? `${t("Sale support is successfully unarchived")!}`
                            : `${t("Sale Support is successfully archived")!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            autoClose: 2000,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsLoading(false);
                    setKey(uuid());
                    setShopDataIsUpdated(true);
                    setFranchiseDataIsUpdated(true);
                }
            });
        } else {
            if (selectedRows.length !== 0) {
                arrayOfRows.forEach(async (el: any) => {
                    let objectData = {
                        userId: userID,
                        shopId: [el.shopId],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        data: {
                            [el.Id]: {
                                id: el.Id,
                                displayName: el[t("Display name")],
                                support_de_vente: el[t("Sale support")],
                                shopId: el.shopId,
                                tag: el.Tag,
                                isActive: false,
                                isArchived: value,
                                isFavorite: el.isFavorite,
                                advancedDisplayName: el.advancedDisplayName,
                            },
                        },
                    };
                    localData.push(objectData);
                });
            }

            try {
                if (localData.length > 0) {
                    await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(localData),
                    }).then((response) => response.json());
                }
            } catch (e) {
                setError(true);
                setIsLoading(false);
                toast.error(`${t("There's an error")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
            if (itemsCantDelete.length > 0) {
                setLimit(2);
                ErrorToast(
                    t(
                        `Please note, you cannot archive a favorite sales support`
                    )
                );
            }
            if (error === false && localData.length > 0) {
                setShopDataIsUpdated(true);
                setFranchiseDataIsUpdated(true);
                setIsLoading(false);
                setKey(uuid());
                toast.success(
                    !value
                        ? `${t("Sale support is successfully unarchived")!}`
                        : `${t("Sale Support is successfully archived")!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            }
        }
    }

    React.useEffect(() => {
        if (isValidateClicked) {
            handleArchiveSaleSupport(true);
        }
        // eslint-disable-next-line
    }, [isValidateClicked]);

    return (
        <div className="d-flex justify-content-start align-items-center">
            {modify === true ? (
                <>
                    <CustomMainColorButton
                        className="w-100 ml-2 sdv_btn_BTassignSalesSupport"
                        rounded
                        disabled={
                            isShopSelected && !isArchivedSaleSupportClicked
                                ? false
                                : true
                        }
                        variant="primary"
                        onClick={() => {
                            setIsAssociateModalOpened(!isAssociateModalOpened);
                            setLocalFilterActive!(false);
                            setSelectedShopsRows(selectedRows);
                        }}
                    >
                        {t("Assign sale support")}
                    </CustomMainColorButton>
                </>
            ) : null}
            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
                className="cmn_drp_BTmenu"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_BTlistActivated "
                    )}
                    onClick={() => {
                        setIsArchivedSaleSupportClicked(false);
                    }}
                >
                    {t("Enabled sales support")} ({numberActiveMultiShop})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_BTlistArchived"
                    )}
                    onClick={() => {
                        setIsArchivedSaleSupportClicked(true);
                    }}
                >
                    {t("Archived sales Support")} ({numberArchiveMultiShop})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item pl-1 cmn_btn_BThistory",
                        {
                            "not-allowed-icon__clz":
                                selectedRows.length === 1 ||
                                selectedRows.every(
                                    (elt: any) => elt[t("Shop")] !== undefined
                                ),
                        }
                    )}`}
                    onClick={() => {
                        setIsHistoryShopsModalOpened(
                            !isHistoryShopsModalOpened
                        );
                    }}
                >
                    {t("Historical")}
                </div>
                {modify === true ? (
                    <>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "dropdown-item pl-1 cmn_btn_BTarchived cmn_btn_BTunarchive",
                                {
                                    "not-allowed-icon__clz":
                                        isLoading ||
                                        selectedRows.length === 0 ||
                                        arrayOfRows.length === 0 ||
                                        (isArchivedSaleSupportClicked &&
                                            getArrayOfRows().some(
                                                (element: any) =>
                                                    ArchivedLanguagesFranchise().includes(
                                                        element.Id
                                                    )
                                            )) ||
                                        selectedRows.every(
                                            (elt: any) =>
                                                elt[t("Shop")] !== undefined
                                        ),
                                }
                            )}`}
                            onClick={async () => {
                                if (
                                    selectedRows.length === 1 &&
                                    (selectedRows[0].subRows === undefined ||
                                        selectedRows[0].subRows.length < 2)
                                ) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else {
                                    if (isArchivedSaleSupportClicked) {
                                        await handleArchiveSaleSupport(false);
                                    } else {
                                        setIsArchivedValidationModalOpened(
                                            true
                                        );
                                    }
                                }
                            }}
                        >
                            <span>
                                {isArchivedSaleSupportClicked
                                    ? t("Unarchive")
                                    : t("Archive")}
                            </span>
                        </div>
                    </>
                ) : null}
            </div>
            {isArchivedValidationModalOpened ? (
                <ConfirmationArchive
                    isArchiveSVModalOpened={isArchivedValidationModalOpened}
                    setIsArchiveSVModalOpened={
                        setIsArchivedValidationModalOpened
                    }
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
        </div>
    );
}
