import React from "react";
import CustomReactTable from "@components/Common/CustomReactTable";
import { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import "../index.css";

export default function Categories({
    data,
    globalFilter,
    setGlobalFilter,
    rowSelection,
    setRowSelection,
}: any) {
    const { t } = useTranslation();

    const Columns: ColumnDef<any>[] = React.useMemo(() => {
        return [
            {
                accessorKey: "title",
                header: () => (
                    <span style={{ width: "327px" }}>
                        {t("List of categories")}
                    </span>
                ),
            },
        ];
    }, [t]);

    return (
        <React.Fragment>
            <div
                style={{
                    background:
                        "rgb(255, 255, 255) none no-repeat scroll 0% 0% padding-box",
                    border: "1px solid rgb(230, 230, 230)",
                    color: "#212529 ",
                    marginLeft: "2%",
                    height: "600px",
                }}
            >
                <CustomReactTable
                    tableColumns={Columns}
                    data={data}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    customRowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    isRowSelectionEnabled
                />
            </div>
        </React.Fragment>
    );
}
