import React from "react";
import { useTranslation } from "react-i18next";
import { StyledLabel, StyledTextInput } from "@aureskonnect/react-ui";
import { AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { Input } from "antd";

import HidePasswordSvgIcon from "@components/Common/SvgIcons/HidePasswordSvgIcon";
import ShowPasswordSvgIcon from "@components/Common/SvgIcons/ShowPasswordSvgIcon";

import "./index.css";

type FidelitySynapsyType = {
    password: string;
    setPassword: Function;
    login: string;
    setLogin: Function;
    inputLogin: boolean;
    setInputLogin: Function;
    inputPassword: boolean;
    setInputPassword: Function;
    setIsEdited: Function;
    isConsult?: boolean;
};
export default function FidelitySynapsy({
    password,
    setPassword,
    login,
    setLogin,
    inputLogin,
    setInputLogin,
    inputPassword,
    setInputPassword,
    setIsEdited,
    isConsult,
}: FidelitySynapsyType) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <AvGroup className={` ${isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel className="required__clz mt-3" htmlFor="name">
                    {t("Login")}
                </StyledLabel>
                <StyledTextInput
                    autocomplete="off"
                    id="login"
                    name="login"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        if (/^\s/.test(e.target.value)) {
                            setLogin(e.target.value.trim());
                        } else {
                            setLogin(e.target.value);
                        }

                        setInputLogin(false);
                        setIsEdited(true);
                    }}
                    className={classnames("fed_inp_FRlogin", {
                        input__clz: inputLogin,
                    })}
                    value={login}
                />
                {inputLogin ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a login")}
                    </div>
                ) : null}
            </AvGroup>
            <AvGroup className={` ${isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel className="required__clz mt-3" htmlFor="name">
                    {t("Password")}
                </StyledLabel>
                <Input.Password
                    placeholder={t("Write")}
                    iconRender={(visible) =>
                        visible ? (
                            <ShowPasswordSvgIcon />
                        ) : (
                            <HidePasswordSvgIcon />
                        )
                    }
                    onChange={(e: any) => {
                        if (/^\s/.test(e.target.value)) {
                            setPassword(e.target.value.trim());
                        } else {
                            setPassword(e.target.value);
                        }
                        setInputPassword(false);
                        setIsEdited(true);
                    }}
                    value={password}
                    className={classnames("fed_inp_FRpassword", {
                        input__clz: inputPassword,
                    })}
                    style={{
                        height: "38px",
                        borderRadius: "6px",
                        color: "#2b2828",
                        fontSize: "16px",
                        fontFamily: "Segoe UI",
                    }}
                />
                {inputPassword ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter an password")}
                    </div>
                ) : null}
            </AvGroup>
        </React.Fragment>
    );
}
