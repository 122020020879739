import { proxy } from "valtio";

type StoreType = {
    isAddChoiceClicked: boolean;
    countryKeyValue: string;
    isActive: boolean;
    numberActive: number;
    numberArchived: number;
    numberMultiShopActive: number;
    numberMultiShopArchived: number;
    isAssociateModalOpened: boolean;
    isAssociateModalClosed: boolean;
    localShop: any[];
    isLanguesAssociatedModifiedClosed: boolean;
    idsLocalShop: string[];
    isAssociatedLangues: boolean;
    selectedRows: any[];
    ids: number;
    isLanguesAssociatedArchivedClosed: boolean;
    isModified: boolean;
    isActivation: boolean;
    isLanguesAssociatedModified: boolean;
    shopsLanguagesData: any[];
    languagesData: any[];
    shopRowData: any[];
    archivedLanguageFranchise: any[];
    associatedLanguages: any[];
    activeLanguages: any[];
    message: string;
    languagesFranchise: any[];
    globalDataIsUpdated: boolean;
    dataArchive: any[];
    archivedLanguageOfFranchise: any[];
    isArchived: boolean;
    isPlusIconClicked: boolean;
    editedDataLanguage: any;
    isValidateArchiveOpened: boolean;
    isValidateShopArchiveOpened: boolean;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    modify: boolean;
    languageFavored: any;
    isAssociateModified: boolean;
    key: any;
    isLoading: boolean;
    
  
};

export const store = proxy<StoreType>({
    isAddChoiceClicked: false,
    countryKeyValue: "",
    isActive: false,
    numberActive: 0,
    numberArchived: 0,
    numberMultiShopActive: 0,
    numberMultiShopArchived: 0,
    isAssociateModalOpened: false,
    localShop: [],
    isAssociateModalClosed: false,
    isLanguesAssociatedModifiedClosed: false,
    idsLocalShop: [],
    isAssociatedLangues: false,
    selectedRows: [],
    ids: 0,
    isLanguesAssociatedArchivedClosed: false,
    isModified: false,
    isActivation: false,
    isLanguesAssociatedModified: false,
    shopsLanguagesData: [],
    languagesData: [],
    shopRowData: [],
    archivedLanguageFranchise: [],
    associatedLanguages: [],
    activeLanguages: [],
    message: "",
    languagesFranchise: [],
    globalDataIsUpdated: false,
    dataArchive: [],
    archivedLanguageOfFranchise: [],
    isArchived: false,
    isPlusIconClicked: false,
    editedDataLanguage: {},
    isValidateArchiveOpened: false,
    isValidateShopArchiveOpened: false,
    isActionsClicked: false,
    isEdited: false,
    actions: () => { },
    validationAction: {},
    modify: false,
    languageFavored: [],
    isAssociateModified: false,
    key: 0,
    isLoading: false,
  
    
});

export function setIsLoading(isLoading: boolean): void {
    store.isLoading = isLoading;
}


export function setModify(value: boolean): void {
    store.modify = value;
}
export function setIsAddChoiceClicked(isAddChoiceClicked: boolean): void {
    store.isAddChoiceClicked = isAddChoiceClicked;
}

export function setCountryKeyValue(countryKeyvalue: string): void {
    store.countryKeyValue = countryKeyvalue;
}

export function setIsActive(isActive: boolean): void {
    store.isActive = isActive;
}
export function setNumberActive(number: number): void {
    store.numberActive = number;
}
export function setNumberArchived(number: number): void {
    store.numberArchived = number;
}
export function setNumberMultiShopActive(number: number): void {
    store.numberMultiShopActive = number;
}
export function setNumberMultiShopArchived(number: number): void {
    store.numberMultiShopArchived = number;
}
export function setIsAssociateModalOpened(isModalOpened: boolean): void {
    store.isAssociateModalOpened = isModalOpened;
}
export function setIsAssociateModalClosed(
    isAssociateModalClosed: boolean
): void {
    store.isAssociateModalClosed = isAssociateModalClosed;
}
export function setLocalShop(shops: any[]): void {
    store.localShop = shops;
}
export function setIsLanguesAssociatedModifiedClosed(
    isLanguesAssociatedModifiedClosed: boolean
): void {
    store.isLanguesAssociatedModifiedClosed = isLanguesAssociatedModifiedClosed;
}
export function setIdsLocalShop(ids: any[]): void {
    store.idsLocalShop = ids;
}

export function setIsAssociatedLangues(isAssociatedLangues: boolean): void {
    store.isAssociatedLangues = isAssociatedLangues;
}
export function setSelectedRow(selectedRows: any[]): void {
    store.selectedRows = selectedRows;
}
export function setIds(id: number): void {
    store.ids = id;
}

export function setIsLanguesAssociatedArchivedClosed(
    isLanguesAssociatedArchivedClosed: boolean
): void {
    store.isLanguesAssociatedArchivedClosed = isLanguesAssociatedArchivedClosed;
}
export function setIsModified(isModified: boolean): void {
    store.isModified = isModified;
}
export function setIsActivation(isActivation: boolean): void {
    store.isActivation = isActivation;
}
export function setIsLanguesAssociatedModified(
    isLanguesAssociatedModified: boolean
): void {
    store.isLanguesAssociatedModified = isLanguesAssociatedModified;
}
export function setShopsLanguagesData(shopsLanguagesData: any[]): void {
    store.shopsLanguagesData = shopsLanguagesData;
}
export function setLanguagesData(languagesData: any[]): void {
    store.languagesData = languagesData;
}
export function setShopRowData(shopRowData: any[]): void {
    store.shopRowData = shopRowData;
}
export function setArchivedLanguageFranchise(
    archivedLanguageFranchise: any[]
): void {
    store.archivedLanguageFranchise = archivedLanguageFranchise;
}
export function setAssociatedLanguages(associatedLanguages: any[]): void {
    store.associatedLanguages = associatedLanguages;
}
export function setActiveLanguages(activeLanguage: any): void {
    let index = store.activeLanguages.findIndex(
        (element: any) => element.Id === activeLanguage.Id
    );
    index !== -1
        ? (store.activeLanguages[index] = activeLanguage)
        : store.activeLanguages.push(activeLanguage);
}

export function setMessage(message: string): void {
    store.message = message;
}
export function setLanguagesFranchise(languages: any[]): void {
    store.languagesFranchise = languages;
}
export function setGlobalDataIsUpdated(globalDataIsUpdated: boolean): void {
    store.globalDataIsUpdated = globalDataIsUpdated;
}
export function setDataArchive(dataArchive: any[]): void {
    store.dataArchive = dataArchive;
}
export function setArchivedLanguageOfFranchise(
    archivedLanguageOfFranchise: any[]
): void {
    store.archivedLanguageOfFranchise = archivedLanguageOfFranchise;
}
export function setIsArchived(isArchived: boolean): void {
    store.isArchived = isArchived;
}
export function setIsPlusIconClicked(isPlusIconClicked: boolean): void {
    store.isPlusIconClicked = isPlusIconClicked;
}
export function setEditedDataLanguage(editedDataLanguage: any): void {
    store.editedDataLanguage = editedDataLanguage;
}
export function setIsValidateArchiveOpened(isValidateArchiveOpened: any): void {
    store.isValidateArchiveOpened = isValidateArchiveOpened;
}
export function setIsValidateShopArchiveOpened(
    isValidateShopArchiveOpened: any
): void {
    store.isValidateShopArchiveOpened = isValidateShopArchiveOpened;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}

export function setLanguageFavored(languageFavored: any): any {
    let localIndex: number = store.languageFavored.findIndex((e: any) => {
        return e.id === languageFavored.id;
    });
    if (localIndex === -1 || localIndex === undefined) {
        store.languageFavored.push(languageFavored);
    } else {
        store.languageFavored[localIndex] = languageFavored;
    }
    if (languageFavored.status === true) {
        store.languageFavored = store.languageFavored.map((el: any) =>
            el.id !== languageFavored.id ? { id: el.id, status: false } : el
        );
    }
}
export function setIsAssociateModified(isAssociateModified: boolean): any {
    store.isAssociateModified = isAssociateModified;
}
export function setKey(key: any): void {
    store.key = key;
}


export default store;
