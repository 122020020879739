import React from "react";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { IoSettingsOutline } from "react-icons/io5";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import {
    designStore,
    setIsPaymentImageActive,
    setIsPaymentMessageActive,
    setPaymentMainContent,
    setPaymentConfig,
    setIsOptionModalOpen,
    generalConfigStore,
    setTPAValinaModalContent,
    setPaymentCreditCardContent,
    setIsValinaOptionActive,
    setProjectFiles,
} from "@store";

import { truncateString } from "@helpers/general";

import { Translation } from "./Translation";
import { PaymentOptionSetting } from "./PaymentOptionSetting";
import { Uploader } from "@components/Uploader";

export function CreditCardMainContent(): JSX.Element {
    const { t } = useTranslation();

    const {
        paymentSelectedRegulationModeValue,
        paymentMainContent,
        isPaymentMessageActive,
        isPaymentImageActive,
        isOptionModalOpen,
        paymentInstructionsItem,
        isValinaOptionActive,
        selectedLanguage,
        isTPAValina,
    } = useSnapshot(designStore);

    const {
        project: { template, files },
        imageKey,
    } = useSnapshot(generalConfigStore);

    const selectedPaymentMethod = paymentMainContent.filter((item) => {
        return item.shortName === paymentSelectedRegulationModeValue;
    })[0];

    function handleResetImagesOnClickEvent() {
        const activeItemsIds =
            selectedPaymentMethod.languages[selectedLanguage]
                .paymentInstructions.id;

        const imagesArray = [...files];

        const image = imagesArray.find(
            (element) => element.id === activeItemsIds
        );
        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);

            const localContent = JSON.parse(
                JSON.stringify(image.content as ImageItemLanguagesType)
            );

            const modifiedLanguage = Object.keys(localContent).reduce(
                (prev, key) => {
                    return {
                        ...prev,
                        [key]: {
                            ...localContent[key],
                            path: localContent[key].defaultImage as string,
                        },
                    };
                },
                {}
            );
            imagesArray[imageIndex] = {
                ...image,
                content: {
                    ...modifiedLanguage,
                } as ImageItemLanguagesType,
            };
        }

        setProjectFiles(imagesArray);
    }

    function handleIsPaymentMessageSwitchOnClickEvent() {
        setIsPaymentMessageActive(!isPaymentMessageActive);

        const mainContentItem = [
            ...JSON.parse(JSON.stringify(paymentMainContent)),
        ];

        const itemPayment = mainContentItem.find(
            (item) => item.shortName === paymentSelectedRegulationModeValue
        );

        if (itemPayment !== undefined) {
            const itemIndex = mainContentItem.indexOf(itemPayment);

            const modifiedLanguage = Object.keys(itemPayment.languages).reduce(
                (prev, key) => {
                    return {
                        ...prev,
                        [key]: {
                            ...itemPayment.languages[key],
                            paymentInstructions: {
                                ...itemPayment.languages[key]
                                    .paymentInstructions,
                                isTextVisible: !isPaymentMessageActive,
                                active:
                                    !isPaymentMessageActive ===
                                        isPaymentImageActive &&
                                    !isPaymentMessageActive === false
                                        ? false
                                        : true,
                            },
                        },
                    };
                },
                {}
            );

            mainContentItem[itemIndex].languages = {
                ...modifiedLanguage,
            };

            setPaymentMainContent(mainContentItem);
            setPaymentConfig(mainContentItem);
        }
    }

    function handleIsPaymentImageSwitchOnClickEvent() {
        setIsPaymentImageActive(!isPaymentImageActive);

        const mainContentItem = [
            ...JSON.parse(JSON.stringify(paymentMainContent)),
        ];

        const itemPayment = mainContentItem.find(
            (item) => item.shortName === paymentSelectedRegulationModeValue
        );

        if (itemPayment !== undefined) {
            const itemIndex = mainContentItem.indexOf(itemPayment);

            const modifiedLanguage = Object.keys(itemPayment.languages).reduce(
                (prev, key) => {
                    return {
                        ...prev,
                        [key]: {
                            ...itemPayment.languages[key],
                            paymentInstructions: {
                                ...itemPayment.languages[key]
                                    .paymentInstructions,
                                isImageVisible: !isPaymentImageActive,
                                active:
                                    !isPaymentImageActive ===
                                        isPaymentMessageActive &&
                                    !isPaymentImageActive === false
                                        ? false
                                        : true,
                            },
                        },
                    };
                },
                {}
            );
            mainContentItem[itemIndex].languages = {
                ...modifiedLanguage,
            };
            setPaymentMainContent(mainContentItem);
            setPaymentConfig(mainContentItem);
        }
    }

    function handleIsValinaTPASwitchOnClickEvent() {
        setIsValinaOptionActive(!isValinaOptionActive);

        const mainContentItem = [
            ...JSON.parse(JSON.stringify(paymentMainContent)),
        ];

        const itemPayment = mainContentItem.find(
            (item) => item.shortName === paymentSelectedRegulationModeValue
        );

        if (itemPayment !== undefined) {
            const itemIndex = mainContentItem.indexOf(itemPayment);

            const modifiedLanguage = Object.keys(itemPayment.languages).reduce(
                (prev, key) => {
                    return {
                        ...prev,
                        [key]: {
                            ...itemPayment.languages[key],
                            paymentInstructions: {
                                ...itemPayment.languages[key]
                                    .paymentInstructions,
                                TPAValina: {
                                    ...(itemPayment.languages[key]
                                        .paymentInstructions
                                        .TPAValina as TPAValinaType),
                                    active: !isValinaOptionActive,
                                },
                            },
                        },
                    };
                },
                {}
            );
            mainContentItem[itemIndex].languages = {
                ...modifiedLanguage,
            };
            setPaymentMainContent(mainContentItem);
            setPaymentConfig(mainContentItem);
        }
    }

    function handleMoreOptionsButtonOnClickEvent() {
        setIsOptionModalOpen(!isOptionModalOpen);
        setTPAValinaModalContent(
            template.content.TPAValina as TPAValinaPageType
        );
        setPaymentCreditCardContent(
            (template.content.payment as PaymentContentItemType).items.filter(
                (regulationMode) => {
                    return regulationMode.shortName === "creditCard";
                }
            )[0]
        );
    }
    return (
        <React.Fragment>
            <div
                className="rounded border"
                style={{
                    width: "800px",
                    height: "500px",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    borderRadius: "9px",
                    opacity: 1,
                }}
            >
                <div>
                    <div
                        className="text-left px-2 d-flex justify-content-between align-items-center"
                        style={{
                            backgroundColor: "#EDEDED",
                            height: "40px",
                            fontSize: "20px",
                        }}
                    >
                        <div
                            className="mx-3"
                            style={{
                                width: "217px",
                                height: "27px",
                                textAlign: "left",
                                font: " normal normal 600 20px/27px Segoe UI",
                                letterSpacing: "0px",
                                color: "#000000",
                                opacity: 1,
                            }}
                        >
                            {t("Payment instruction")}
                        </div>
                        {isTPAValina === true &&
                        isValinaOptionActive === true ? (
                            <div
                                className="d-flex align-items-center"
                                style={{ gap: "5px" }}
                            >
                                <span
                                    style={{
                                        height: "20px",
                                        textAlign: "left",
                                        textDecoration: "underline",
                                        font:
                                            "normal normal 600 15px/20px Segoe UI",
                                        letterSpacing: "0px",
                                        color: "#000000",
                                        opacity: 1,
                                    }}
                                >
                                    {t("More Options")}
                                </span>
                                <div
                                    style={{
                                        display: "flex",
                                        cursor: "pointer",
                                        width: "30px",
                                        height: "30px",
                                        background:
                                            "#FFFFFF 0% 0% no-repeat padding-box",
                                        boxShadow: "0px 10px 20px #12263F08",
                                        border: "1px solid #CBCDD5",
                                        borderRadius: " 6px",
                                        opacity: 1,
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    onClick={
                                        handleMoreOptionsButtonOnClickEvent
                                    }
                                >
                                    <IoSettingsOutline
                                        style={{
                                            width: "18px",
                                            height: "18px",
                                        }}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>

                    <div
                        className="p-3"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "15px",
                        }}
                    >
                        <div
                            className="d-flex flex-row justify-content-between align-items-center"
                            style={{
                                top: "408px",
                                left: "569px",
                                width: "766px",
                                height: "36px",
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        top: "531px",
                                        left: "569px",
                                        width: "484px",
                                        height: "20px",
                                        textAlign: "left",
                                        font:
                                            "normal normal 600 15px/20px Segoe UI",
                                        letterSpacing: "0px",
                                        color: "#000000",
                                        opacity: 1,
                                    }}
                                >
                                    {t("Text")}
                                </div>
                                <div
                                    style={{
                                        color: "#B7B7B7",
                                        fontSize: "13px",
                                    }}
                                >
                                    {t(
                                        truncateString(
                                            paymentInstructionsItem.message,
                                            50
                                        )
                                    )}
                                </div>
                            </div>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={isPaymentMessageActive}
                                onChange={() => {
                                    handleIsPaymentMessageSwitchOnClickEvent();
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                            />
                        </div>
                        <div
                            className="d-grid__clz"
                            style={{
                                gridTemplateColumns: "300px 200px auto",
                                gap: "10px",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    top: "531px",
                                    left: "569px",
                                    width: "484px",
                                    height: "20px",
                                    textAlign: "left",
                                    font:
                                        "normal normal 600 15px/20px Segoe UI",
                                    letterSpacing: "0px",
                                    color: "#000000",
                                    opacity: 1,
                                }}
                            >
                                {t("File")}
                            </div>
                            <div className="d-flex align-items-center">
                                <StyledIconButton
                                    title={t("Reset")}
                                    className="m-0"
                                    icon="RefreshIcon"
                                    onClick={() => {
                                        handleResetImagesOnClickEvent();
                                    }}
                                    disabled={
                                        isPaymentImageActive === true
                                            ? false
                                            : true
                                    }
                                >
                                    <RefreshIcon height={15} width={15} />
                                </StyledIconButton>

                                <Uploader
                                    key={imageKey}
                                    uploadedFileTitle={
                                        selectedPaymentMethod.languages[
                                            selectedLanguage
                                        ].paymentInstructions.image as string
                                    }
                                    id={
                                        selectedPaymentMethod.languages[
                                            selectedLanguage
                                        ].paymentInstructions.id
                                    }
                                    activeLanguage={selectedLanguage}
                                    disabled={
                                        isPaymentImageActive === true
                                            ? false
                                            : true
                                    }
                                />
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                                <ReactSwitch
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={isPaymentImageActive}
                                    onChange={() => {
                                        handleIsPaymentImageSwitchOnClickEvent();
                                    }}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={50}
                                    height={20}
                                />
                            </div>
                        </div>
                        <div
                            className="d-flex flex-row justify-content-between align-items-center"
                            style={{
                                top: "408px",
                                left: "569px",
                                width: "766px",
                                height: "36px",
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        top: "531px",
                                        left: "569px",
                                        width: "484px",
                                        height: "20px",
                                        textAlign: "left",
                                        font:
                                            "normal normal 600 15px/20px Segoe UI",
                                        letterSpacing: "0px",
                                        color: "#000000",
                                        opacity: 1,
                                    }}
                                >
                                    {t(
                                        "Propose a step of payment choice (with code or without contact)"
                                    )}
                                </div>
                                <div
                                    style={{
                                        color: "#B7B7B7",
                                        fontSize: "13px",
                                    }}
                                >
                                    {t("Only valid for TPA Valina")}
                                </div>
                            </div>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor={
                                    isTPAValina === true ? "#f7b4b8" : "#a7afab"
                                }
                                offHandleColor={
                                    isTPAValina === true ? "#E30613" : "#6e7572"
                                }
                                checked={isValinaOptionActive}
                                onChange={() => {
                                    handleIsValinaTPASwitchOnClickEvent();
                                }}
                                onColor={
                                    isTPAValina === true ? "#c2eddd" : "#a7afab"
                                }
                                onHandleColor={
                                    isTPAValina === true ? "#34C38F" : "#6e7572"
                                }
                                width={50}
                                height={20}
                                disabled={isTPAValina === true ? false : true}
                            />
                        </div>
                    </div>
                </div>
                {isPaymentMessageActive === true ? <Translation /> : null}
            </div>
            {isOptionModalOpen === true ? <PaymentOptionSetting /> : null}
        </React.Fragment>
    );
}
