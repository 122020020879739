import React, { useRef } from "react";
import {
    StyledH2,
    StyledH1,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import ReactSwitch from "react-switch";
import { Tooltip } from "@mui/material";

import { setOptions } from "../../store";

import BellSvgIcon from "@components/Common/SvgIcons/BellSvgIcon";
import "../index.css";

export default function Options({ rowData }: any) {
    const { t } = useTranslation();
    const audioRef = useRef<any>(null);
    const bellRingtone1 = "/BellRingTone/Bipeur1.wav";
    const bellRingtone2 = "/BellRingTone/Bipeur2.wav";
    const bellRingtone3 = "/BellRingTone/Bipeur3.wav";
    const bellRingtone4 = "/BellRingTone/Bipeur4.wav";

    const [isCallRingtoneActive, setIsCallRingtoneActive] = React.useState(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? false
            : rowData.template.options.callingReadyOrders.isCallRingtoneActive
    );
    const [
        inputPersonalizedMessage,
        setInputPersonalizedMessage,
    ] = React.useState<string>(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? ""
            : rowData.template.options.customerMessage.inputPersonalizedMessage
    );
    const [bellName, setBellName] = React.useState<any>(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? { label: "", value: "" }
            : rowData.template.options.callingReadyOrders.bellName.label === ""
            ? { label: "", value: "" }
            : {
                  label: t("Alarm", {
                      number:
                          rowData.template.options.callingReadyOrders.bellName
                              .value,
                  }),
                  value:
                      rowData.template.options.callingReadyOrders.bellName
                          .value,
              }
    );
    const [numberDigits, setNumberDigits] = React.useState<any>(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? {
                  label: t("By default"),
                  value: "1",
              }
            : {
                  label: t(rowData.template.options.numberDigits.label),
                  value: t(rowData.template.options.numberDigits.value),
              }
    );
    const [callRingtone, setCallRingtone] = React.useState<string>(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? ""
            : rowData.template.options.callingReadyOrders.callRingtone
    );
    const [isEnableManualCall, setIsEnableManualCall] = React.useState(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? false
            : rowData.template.options.callingReadyOrders.isEnableManualCall
    );
    const [isActivateVoice, setIsActivateVoice] = React.useState(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? false
            : rowData.template.options.callingReadyOrders.isActivateVoice
    );
    const [customerMessage, setCustomerMessage] = React.useState<any>(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? "Default message"
            : rowData.template.options.customerMessage.customerMessage
    );
    const [
        animationCustomerMessage,
        setAnimationCustomerMessage,
    ] = React.useState<any>(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? "Fixed display"
            : rowData.template.options.animationCustomerMessage
    );
    const [
        animationDisplayedCommands,
        setAnimationDisplayedCommands,
    ] = React.useState<any>(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? "Fixed list"
            : rowData.template.options.animationDisplayedCommands
    );
    const [isReadCustomerName, setIsReadCustomerName] = React.useState(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? false
            : rowData.template.options.callingReadyOrders.isReadCustomerName
    );
    const [inputDefaultMessage, setInputDefaultMessage] = React.useState<
        string
    >("Please present your ticket at the cash desk");
    React.useEffect(() => {
        setOptions(
            JSON.parse(
                JSON.stringify({
                    callingReadyOrders: {
                        isCallRingtoneActive: isCallRingtoneActive,
                        callRingtone: callRingtone,
                        isEnableManualCall: isEnableManualCall,
                        isActivateVoice: isActivateVoice,
                        bellName: bellName,
                        isReadCustomerName: isReadCustomerName,
                    },
                    customerMessage: {
                        customerMessage: customerMessage,
                        inputPersonalizedMessage: inputPersonalizedMessage,
                        inputDefaultMessage: inputDefaultMessage,
                    },
                    animationCustomerMessage: animationCustomerMessage,
                    animationDisplayedCommands: animationDisplayedCommands,
                    numberDigits:
                        numberDigits.label === t("By default")
                            ? { label: "By default", value: numberDigits.value }
                            : numberDigits,
                })
            )
        );
        // eslint-disable-next-line
    }, [
        animationCustomerMessage,
        animationDisplayedCommands,
        callRingtone,
        customerMessage,
        inputPersonalizedMessage,
        isCallRingtoneActive,
        isEnableManualCall,
        numberDigits,
        isActivateVoice,
        isReadCustomerName,
        inputDefaultMessage,
    ]);

    const playRingtone = () => {
        if (audioRef.current && audioRef.current !== "") {
            audioRef.current.play();
        }
    };

    return (
        <React.Fragment>
            <StyledH1 className="mx-3">{t("Options")}</StyledH1>
            <StyledH2 className="ml-3 mt-1">
                {t("Calling ready orders")}
            </StyledH2>
            <div className="d-flex align-items-center justify-content-between">
                <div className="ml-5">
                    {t("Enable manual call of ready orders")}
                </div>
                <div className="float-right mr-5">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isEnableManualCall}
                        onChange={() => {
                            setIsEnableManualCall(!isEnableManualCall);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                        className="orb_icn_enableManualCallReadyCommands"
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between">
                <div className="ml-5">{t("Call ringtone")}</div>
                <div
                    style={{
                        width: "172px",
                        position: "absolute",
                        right: "54%",
                    }}
                >
                    <StyledSelectInput
                        isClearable
                        value={bellName.label === "" ? null : bellName}
                        onChange={(e: any) => {
                            if (
                                e === null
                                    ? ""
                                    : e.label === t("Alarm", { number: "1" })
                            ) {
                                setCallRingtone(bellRingtone1);
                            } else if (
                                e === null
                                    ? ""
                                    : e.label === t("Alarm", { number: "2" })
                            ) {
                                setCallRingtone(bellRingtone2);
                            } else if (
                                e === null
                                    ? ""
                                    : e.label === t("Alarm", { number: "3" })
                            ) {
                                setCallRingtone(bellRingtone3);
                            } else if (
                                e === null
                                    ? ""
                                    : e.label === t("Alarm", { number: "4" })
                            ) {
                                setCallRingtone(bellRingtone4);
                            }
                            setBellName(e === null ? "" : e);
                        }}
                        placeholder={t("Select")}
                        options={[
                            {
                                label: t("Alarm", { number: "1" }),
                                value: 1,
                            },
                            {
                                label: t("Alarm", { number: "2" }),
                                value: 2,
                            },
                            {
                                label: t("Alarm", { number: "3" }),
                                value: 3,
                            },
                            {
                                label: t("Alarm", { number: "4" }),
                                value: 4,
                            },
                        ]}
                        name="mark"
                        noOptionsMessage={() => t("No options")}
                        maxMenuHeight="70px"
                        className="orb_drp_selectRingtone"
                    />
                </div>
                <Tooltip title={t("listen")}>
                    <div
                        style={{
                            position: "absolute",
                            right: "48%",
                            cursor: "pointer",
                        }}
                        className={`${classnames("orb_icn_alarm", {
                            "not-allowed-icon__clz":
                                bellName.label === "" ||
                                bellName.label === undefined,
                        })}`}
                    >
                        <BellSvgIcon onClick={playRingtone} />

                        <audio ref={audioRef} src={callRingtone} />
                    </div>
                </Tooltip>
                <div className="float-right mr-5">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isCallRingtoneActive}
                        onChange={() => {
                            setIsCallRingtoneActive(!isCallRingtoneActive);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                        className="orb_icn_callRingtone"
                    />
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <div className="ml-5">
                    {t("Activate voice call ready commands")}
                </div>
                <div className="float-right mr-5">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isActivateVoice}
                        onChange={() => {
                            setIsActivateVoice(!isActivateVoice);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                        className="orb_icn_enableVoiceCallReadyOrders"
                    />
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <div
                    className={`${classnames("ml-5", {
                        "not-allowed-icon__clz": !isActivateVoice,
                    })}`}
                >
                    {t("Read customer name during voice call")}
                </div>
                <div className="float-right mr-5">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isReadCustomerName}
                        disabled={!isActivateVoice}
                        onChange={() => {
                            setIsReadCustomerName(!isReadCustomerName);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                        className="orb_icn_readingCustomerNameWhenCalling"
                    />
                </div>
            </div>

            <div className="border-footer-orb__clz" />
            <StyledH2 className="ml-3 mt-1">{t("Customer message")}</StyledH2>

            <AvForm
                model={{
                    form: customerMessage,
                    checkItOut: true,
                }}
            >
                <AvRadioGroup
                    name="form"
                    required
                    inline
                    errorMessage="Pick one!"
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="ml-5">{t("Default message")}</div>
                        <div className="float-right mr-5">
                            <AvRadio
                                id={"Default message"}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setCustomerMessage("Default message");
                                    setInputDefaultMessage(
                                        "Please present your ticket at the cash desk"
                                    );
                                }}
                                value={"Default message"}
                                disabled={false}
                                className="orb_chk_defaultClientMessage"
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            font: " normal normal normal 15px/20px Nunito Sans",
                            marginLeft: "47px",
                            color: "rgb(166, 163, 163)",
                        }}
                    >
                        {t("Please present your ticket at the cash desk")}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="ml-5">{t("Personalized message")}</div>
                        <div className="float-right mr-5">
                            <AvRadio
                                id={t("Personalized message")}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setCustomerMessage(
                                        t("Personalized message")
                                    );
                                }}
                                value={t("Personalized message")}
                                disabled={false}
                                className="orb_chk_messageClientPersonaliser"
                            />
                        </div>
                    </div>
                </AvRadioGroup>
                <StyledTextInput
                    autocomplete="off"
                    name="designation"
                    onChange={(e: any) => {
                        setInputPersonalizedMessage(e.target.value);
                    }}
                    value={inputPersonalizedMessage}
                    placeholder={t("Write")}
                    type="text"
                    disabled={customerMessage === "Default message"}
                    style={{
                        width: "33%",
                        marginLeft: "3%",
                        marginTop: "1%",
                        marginBottom: "1%",
                    }}
                    className="orb_inp_messagePersonaliser"
                />
            </AvForm>

            <div className="border-footer-orb__clz" />
            <StyledH2 className="ml-3 mt-1">
                {t("Animation of the customer message")}
            </StyledH2>

            <AvForm
                model={{
                    form: animationCustomerMessage,
                    checkItOut: true,
                }}
            >
                <AvRadioGroup
                    name="form"
                    required
                    inline
                    errorMessage="Pick one!"
                >
                    <div className="d-flex  align-items-center justify-content-between">
                        <div className="ml-5">{t("Fixed display")}</div>
                        <div className="float-right mr-5">
                            <AvRadio
                                id={"Fixed display"}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setAnimationCustomerMessage(
                                        "Fixed display"
                                    );
                                }}
                                value={"Fixed display"}
                                disabled={false}
                                className="orb_chk_fixedDisplay"
                            />
                        </div>
                    </div>
                    <div className="d-flex  align-items-center justify-content-between">
                        <div className="ml-5">{t("Rotating display")}</div>
                        <div className="float-right mr-5">
                            <AvRadio
                                id={t("Rotating display")}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setAnimationCustomerMessage(
                                        t("Rotating display")
                                    );
                                }}
                                value={t("Rotating display")}
                                disabled={false}
                                className="orb_chk_rotatingDisplay"
                            />
                        </div>
                    </div>
                </AvRadioGroup>
            </AvForm>

            <div className="border-footer-orb__clz" />
            <StyledH2 className="ml-3 mt-2">
                {t("Animation of orders displayed (Orders ready)")}
            </StyledH2>

            <AvForm
                model={{
                    form: animationDisplayedCommands,
                    checkItOut: true,
                }}
            >
                <AvRadioGroup
                    name="form"
                    required
                    inline
                    errorMessage="Pick one!"
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="ml-5">{t("Fixed list")}</div>
                        <div className="float-right mr-5">
                            <AvRadio
                                id={"Fixed list"}
                                label=""
                                name="example"
                                onChange={() => {
                                    setAnimationDisplayedCommands("Fixed list");
                                }}
                                value={"Fixed list"}
                                disabled={false}
                                className="orb_chk_fixedList"
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="ml-5">{t("Dynamic list")}</div>
                        <div className="float-right mr-5">
                            <AvRadio
                                id={t("Dynamic list")}
                                label=""
                                name="example"
                                onChange={() => {
                                    setAnimationDisplayedCommands(
                                        t("Dynamic list")
                                    );
                                }}
                                value={t("Dynamic list")}
                                disabled={false}
                                className="orb_chk_dynamicList"
                            />
                        </div>
                    </div>
                </AvRadioGroup>
            </AvForm>

            <div className="border-footer-orb__clz" />
            <StyledH2 className="ml-3 mt-1">{t("Order number")}</StyledH2>

            <div
                className="d-flex  align-items-center justify-content-between"
                style={{
                    position: "relative",
                    bottom: "10px",
                }}
            >
                <div className="ml-5">{t("Number of digits")}</div>
                <div style={{ width: "172px", marginRight: "63%" }}>
                    <StyledSelectInput
                        isClearable
                        value={numberDigits.label === "" ? null : numberDigits}
                        onChange={(e: any) => {
                            setNumberDigits(e !== null ? e : "");
                        }}
                        placeholder={t("Select")}
                        options={[
                            {
                                label: t("By default"),
                                value: "1",
                            },
                            {
                                label: "2",
                                value: "2",
                            },
                            {
                                label: "3",
                                value: "3",
                            },
                            {
                                label: "4",
                                value: "4",
                            },
                        ]}
                        name="mark"
                        noOptionsMessage={() => t("No options")}
                        maxMenuHeight="45px"
                        className="orb_drp_numberDigits"
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
