import React from "react";
import {
    StyledButton,
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import ShowPasswordSvgIcon from "@components/Common/SvgIcons/ShowPasswordSvgIcon";
import HidePasswordSvgIcon from "@components/Common/SvgIcons/HidePasswordSvgIcon";
import { Col } from "reactstrap";
import { useSnapshot } from "valtio";
import { store as userStore } from "@components/VerticalLayout/store";

import store, { setCashManagementData } from "./store";

import { ipMatchWord, numberMatchWord } from "../../../../constants";

import "react-dropzone-uploader/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { checkIpIfExist } from "@helpers/general";

type PeripheralsConfigurationsType = {
    setDataIsUpdated: Function;
    isEdited: boolean;
    setIsEdited: Function;
    selectedRows: any;
    editedData: any;
    shopIds: any;
};

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export default function CashManagementConfiguration({
    setDataIsUpdated,
    isEdited,
    setIsEdited,
    selectedRows,
    editedData,
    shopIds,
}: PeripheralsConfigurationsType) {
    const { t } = useTranslation();
    const { shopID, oneShop, resourceIp } = useSnapshot(userStore);
    const {
        mark,
        inputPort,
        ipAddress,
        inputPassword,
        inputUser,
        inputPosId,
        logDirectory,
        posId,
        password,
        portNumber,
        user,
        inputIp,
        reference,
        ipExist,
        emptyIp,
    } = useSnapshot(store);

    const [isNewPasswordDisplayed, setIsNewPasswordDisplayed] = React.useState<
        boolean
    >(false);
    const CashdroReferencesArray: {
        label: string;
        value: string;
    }[] = ["EXEMPLE 1", "EXEMPLE 2", "EXEMPLE 3", "EXEMPLE 4"].map(
        (element: string) => {
            return {
                label: element,
                value: element,
            };
        }
    );

    const GloryReferencesArray: {
        label: string;
        value: string;
    }[] = ["Monnayeur CI-5", "Monnayeur CI-10"].map((element: string) => {
        return {
            label: element,
            value: element,
        };
    });
    let arrayOfCustomColumns: CustomColumnProps[] = [];

    arrayOfCustomColumns.push({
        indexOFColumn: 1,
        columnName: t("Actions"),
        customJsx: () => (
            <StyledButton rounded variant="light">
                <span className="text-dark">{t("Select")}</span>
            </StyledButton>
        ),
    });

    const shopId = oneShop ? shopID : selectedRows?.shopId;
    React.useEffect(() => {
        setCashManagementData(
            shopIds.length > 1 || shopIds.length === 0
                ? false
                : checkIpIfExist(
                      resourceIp,
                      ipAddress,
                      editedData?.peripheralId !== undefined
                          ? editedData?.peripheralId
                          : "",
                      editedData?.peripheralId !== undefined
                          ? editedData?.shopId
                          : shopId
                  ),
            "ipExist"
        );
        if (ipAddress !== "") {
            setCashManagementData(false, "emptyIp");
            setCashManagementData(!ipAddress.match(ipMatchWord), "inputIp");
        }

        if (ipAddress === "") {
            setCashManagementData(false, "ipExist");
            setCashManagementData(false, "inputIp");
            setCashManagementData(false, "emptyIp");
        }
        if (editedData[t("IP address")] !== undefined) {
            let filteredData = JSON.parse(JSON.stringify(resourceIp))
                ?.filter(
                    (item: any) =>
                        item?.shopId === shopId &&
                        item.ip.trim() !== "" &&
                        item.ip.trim() !== editedData[t("IP address")] &&
                        item.ip.trim() !== editedData.ip
                )
                .map((y: any) => {
                    return y.ip;
                });
            filteredData = [...new Set(filteredData)];
            if (filteredData.includes(ipAddress)) {
                setCashManagementData(true, "ipExist");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData, shopId, ipAddress, shopIds]);

    return (
        <React.Fragment>
            {mark === "Cashdro" && (
                <React.Fragment>
                    <AvGroup>
                        <StyledLabel
                            className={`${
                                mark === "Cashdro" ? "" : "required__clz"
                            } mt-3`}
                            htmlFor="reference"
                        >
                            {t("Reference")}
                        </StyledLabel>
                        {mark === "Cashdro" ? (
                            <StyledTextInput
                                onChange={(e: any) => {
                                    setCashManagementData(
                                        e.target.value,
                                        "reference"
                                    );
                                    setIsEdited(true);
                                }}
                                placeholder={t("Write")}
                                value={reference}
                                name="reference"
                                className={classnames("mon_inp_reference", {})}
                            />
                        ) : (
                            <StyledSelectInput
                                onChange={(e: any) => {
                                    setCashManagementData(e.value, "reference");
                                    setIsEdited(true);
                                }}
                                value={
                                    reference === ""
                                        ? null
                                        : {
                                              label: reference,
                                              value: reference,
                                          }
                                }
                                options={
                                    mark !== "Cashdro"
                                        ? GloryReferencesArray
                                        : CashdroReferencesArray
                                }
                                name="reference"
                                className="afp_drp_reference"
                                placeholder={t("Select")}
                                noOptionsMessage={() => t("No options")}
                            />
                        )}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("IP address")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("afp_inp_ipAdresse", {
                                input__clz: inputIp || emptyIp || ipExist,
                            })}
                            autocomplete="off"
                            id="ipAddress"
                            name="ipAddress"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                if (!e.target.value.match(ipMatchWord)) {
                                    setCashManagementData(
                                        e.target.value,
                                        "ipAddress"
                                    );
                                    setDataIsUpdated(true);
                                    setIsEdited(true);
                                    setCashManagementData(true, "inputIp");
                                } else {
                                    setCashManagementData(
                                        e.target.value,
                                        "ipAddress"
                                    );
                                    setDataIsUpdated(true);
                                    setIsEdited(true);
                                    setCashManagementData(false, "inputIp");
                                }
                                if (e.target.value === "") {
                                    setCashManagementData(false, "emptyIp");
                                }
                            }}
                            value={ipAddress}
                        />
                        {inputIp || emptyIp || ipExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t(
                                    emptyIp
                                        ? "Please enter a IP address"
                                        : inputIp
                                        ? "IP address is invalid"
                                        : "The ip address is already exists"
                                )}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("Port number")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("afp_inp_portNumber", {
                                input__clz: inputPort,
                            })}
                            autocomplete="off"
                            id="portNumber"
                            name="portNumber"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setCashManagementData(
                                    e.target.value,
                                    "portNumber"
                                );
                                setIsEdited(true);
                                setDataIsUpdated(true);
                                setCashManagementData(false, "inputPort");
                            }}
                            value={portNumber}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: `${t(
                                        "Please enter a port number"
                                    )!}`,
                                },
                                pattern: {
                                    value: numberMatchWord,
                                    errorMessage: t("Port number is invalid"),
                                },
                            }}
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("User")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("afp_inp_user", {
                                input__clz: inputUser,
                            })}
                            autocomplete="off"
                            id="user"
                            name="user"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setCashManagementData(e.target.value, "user");
                                setIsEdited(true);
                                setDataIsUpdated(true);
                                setCashManagementData(false, "inputUser");
                            }}
                            value={user}
                        />
                        {inputUser ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a user")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <Col className="px-0">
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("Password")}
                        </StyledLabel>
                        <div className="new-password-toggle__clz mr-2 afp_icn_showPassword">
                            {!isNewPasswordDisplayed ? (
                                <HidePasswordSvgIcon
                                    onClick={() =>
                                        setIsNewPasswordDisplayed(
                                            (prevState: boolean) => !prevState
                                        )
                                    }
                                />
                            ) : (
                                <ShowPasswordSvgIcon
                                    onClick={() =>
                                        setIsNewPasswordDisplayed(
                                            (prevState: boolean) => !prevState
                                        )
                                    }
                                />
                            )}
                        </div>
                        <StyledTextInput
                            className={classnames("afp_inp_password", {
                                input__clz: inputPassword,
                            })}
                            autocomplete="off"
                            id="password"
                            name="password"
                            placeholder={t("Write")}
                            type={isNewPasswordDisplayed ? "text" : "password"}
                            onChange={(e: any) => {
                                setCashManagementData(
                                    e.target.value,
                                    "password"
                                );
                                setCashManagementData(false, "inputPassword");
                                setIsEdited(true);
                                setDataIsUpdated(true);
                            }}
                            value={password}
                        />
                        {inputPassword ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter an password")}
                            </div>
                        ) : null}
                    </Col>
                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("POS ID")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("afp_inp_idPos", {
                                input__clz: inputPosId,
                            })}
                            autocomplete="off"
                            id="posId"
                            name="posId"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setCashManagementData(e.target.value, "posId");
                                setCashManagementData(false, "inputPosId");
                                setIsEdited(true);
                                setDataIsUpdated(true);
                            }}
                            value={posId}
                        />
                        {inputPosId ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a POS ID")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="name">
                            {t("Log directory")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            id="logDirectory"
                            name="logDirectory"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setCashManagementData(
                                    e.target.value,
                                    "logDirectory"
                                );
                                setDataIsUpdated(true);
                            }}
                            value={logDirectory}
                            className="afp_inp_logDirectory"
                        />
                    </AvGroup>
                </React.Fragment>
            )}
            {mark === "Glory" && (
                <React.Fragment>
                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="reference"
                        >
                            {t("Reference")}
                        </StyledLabel>

                        <StyledSelectInput
                            onChange={(e: any) => {
                                setCashManagementData(e.value, "reference");
                                setIsEdited(true);
                            }}
                            value={
                                reference === ""
                                    ? null
                                    : {
                                          label: reference,
                                          value: reference,
                                      }
                            }
                            options={GloryReferencesArray}
                            name="reference"
                            className="afp_drp_reference"
                            placeholder={t("Select")}
                            noOptionsMessage={() => t("No options")}
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("IP address")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("afp_inp_ipAdresse", {
                                input__clz: inputIp || emptyIp || ipExist,
                            })}
                            autocomplete="off"
                            id="ipAddress"
                            name="ipAddress"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                if (!e.target.value.match(ipMatchWord)) {
                                    setCashManagementData(
                                        e.target.value,
                                        "ipAddress"
                                    );
                                    setCashManagementData(true, "inputPosId");

                                    setCashManagementData(true, "inputIp");
                                } else {
                                    setCashManagementData(
                                        e.target.value,
                                        "ipAddress"
                                    );
                                    setCashManagementData(false, "inputPosId");
                                    setDataIsUpdated(true);
                                    setCashManagementData(false, "inputIp");
                                }
                                setIsEdited(true);
                            }}
                            value={ipAddress}
                        />
                        {inputIp || emptyIp || ipExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t(
                                    emptyIp
                                        ? "Please enter a IP address"
                                        : inputIp
                                        ? "IP address is invalid"
                                        : "The ip address is already exists"
                                )}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("Port number")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("afp_inp_portNumber", {
                                input__clz: inputPort,
                            })}
                            autocomplete="off"
                            id="portNumber"
                            name="portNumber"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setCashManagementData(
                                    e.target.value,
                                    "portNumber"
                                );
                                setCashManagementData(false, "inputPort");
                                setDataIsUpdated(true);
                                setIsEdited(true);
                            }}
                            value={portNumber}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: `${t(
                                        "Please enter a port number"
                                    )!}`,
                                },
                                pattern: {
                                    value: numberMatchWord,
                                    errorMessage: t("Port number is invalid"),
                                },
                            }}
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="name">
                            {t("Log directory")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            id="logDirectory"
                            name="logDirectory"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setCashManagementData(
                                    e.target.value,
                                    "logDirectory"
                                );
                                setDataIsUpdated(true);
                                setIsEdited(true);
                            }}
                            value={logDirectory}
                            className="afp_inp_logDirectory"
                        />
                    </AvGroup>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
