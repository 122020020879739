import React from "react";

export default function Look(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={40}
            height={40}
            viewBox="0 0 46 46"
            {...props}
        >
            <g data-name="01 align center">
                <path d="M19 8V7A7 7 0 0 0 5 7v1H2v13a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V8ZM7 7a5 5 0 0 1 10 0v1H7Zm13 14a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V10h16Z"></path>
                <path d="M11 14h2v4h-2z"></path>
            </g>
        </svg>
    );
}
