import React from "react";
import classnames from "classnames";
import {
    DisassociateIcon,
    PencilIcon,
    SettingsIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";

import store, {
    setIdStore,
    setIsModalDissociateOpened,
    setSelectedApplicationKds,
    setTemplate,
} from "./store";
import { setIsConfigured, setIsKdsSetting } from "./ModalContentWrapper/store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

export function CustomIcons({
    e,
    setSelectedRows,
    isAddProject,
    setIsAddProject,
    isProjectArchived,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setIsModalOpened,
    dataIdStore,
}: any) {
    const { t } = useTranslation();
    const { isModalDissociateOpened, modify } = useSnapshot(store);

    return (
        <React.Fragment>
            {e.selectedRow?.subRows !== undefined &&
            e.selectedRow.key_project !== undefined ? (
                <div className="d-flex justify-content-between mt-2">
                    {modify === true && (
                        <div>
                            <PencilIcon
                                onClick={() => {
                                    setSelectedRows(e.selectedRow);
                                    if (!isAddProject) {
                                        setIsAddProject(!isAddProject);
                                    }
                                }}
                                height={25}
                                width={25}
                                style={{ cursor: "pointer" }}
                                className={`${classnames(
                                    "pointer__clz cmn_icn_FRedit",
                                    {
                                        "not-allowed-icon__clz": isProjectArchived,
                                    }
                                )}`}
                            />
                        </div>
                    )}
                    <div>
                        <TimePastSvgIcon
                            onClick={() => {
                                setSelectedApplicationKds([
                                    {
                                        [e.selectedRow.shopId]:
                                            e.selectedRow.ProjectId,
                                    },
                                ]);
                                setIsHistoryModalOpened(!isHistoryModalOpened);

                                setSelectedRows([e.selectedRow]);
                            }}
                            className="pointer__clz ml-2 cmn_icn_FRhistory"
                        />
                    </div>
                </div>
            ) : e.selectedRow[t("Designation")] !== "" &&
              e.selectedRow.id_boutique !== undefined ? (
                modify === true && (
                    <div className="mt-3">
                        <DisassociateIcon
                            height={40}
                            width={35}
                            fill="white"
                            style={{ position: "relative", bottom: "10px" }}
                            className={`${classnames("pointer__clz ml-2", {
                                "not-allowed-icon__clz": isProjectArchived,
                            })}`}
                            onClick={() => {
                                setSelectedRows([e.selectedRow]);
                                setIsModalDissociateOpened(
                                    !isModalDissociateOpened
                                );
                            }}
                        />
                    </div>
                )
            ) : (
                modify === true && (
                    <>
                        <SettingsIcon
                            className={`${classnames("pointer__clz ml-4", {
                                "not-allowed-icon__clz":
                                    e?.selectedRow.isProjectConfigured ===
                                    false,
                            })}`}
                            onClick={() => {
                                let shopData = dataIdStore.find(
                                    (el: any) =>
                                        el.uid ===
                                        e.selectedRow?.shopId
                                );
                                if (shopData !== undefined) {
                                    setIdStore(shopData.IdStore);
                                } else {
                                    setIdStore(0);
                                }
                                setSelectedRows(e.selectedRow);
                                setIsModalOpened(true);
                                setIsKdsSetting(true);
                                setTemplate(e.selectedRow.template);
                                setIsConfigured(true);
                            }}
                            height={22}
                            width={22}
                        />
                    </>
                )
            )}
        </React.Fragment>
    );
}
