import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as logoStore,
    setLocalShop,
    setIsAssociateLogoOpened,
    setIsHistoryShopsModalOpened,
    setSelectedLogo,
    setIdsLocalShop,
    setShopsLogoData,
    setShopsSelectedRow,
    setNumberArchiveMultiShop,
    setNumberActiveMultiShop,
    setLogoStoreArchived,
} from "../store";

import { removeArrayOfObjectsDuplicates } from "@helpers/general";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";

import { DisplayName } from "../LogoFranchise/DisplayName";
import { LogoAssociateModal } from "./LogoAssociateModal";
import { CustomSideFilter } from "./CustomSideFilter";
import { CustomIcons } from "./CustomIcons";
import HistoryModal from "../HistoryModal";
import Dimensions from "../LogoFranchise/Dimensions";
import { ConfirmationActivationDeactivationMessageModal } from "@components/Common/ConfirmationActivationDeactivationMessageModal";
import { store as StoreConfirmationActivationDeactivation } from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type logosShopsType = {
    isLogoArchived: boolean;
    setIsLogoArchived: Function;
    setLimit: Function;
};
export default function LogoShops({
    isLogoArchived,
    setIsLogoArchived,
    setLimit,
}: logosShopsType) {
    const { t } = useTranslation();

    const { oneShop, userID, franchiseID } = useSnapshot(store);

    const { activationDeactivationMessageModalOpened } = useSnapshot(
        StoreConfirmationActivationDeactivation
    );
    const {
        isUpdateDataActive,
        isAssociateLogoOpened,
        localShop,
        isHistoryShopsModalOpened,
        isValidateClicked,
        isDataUpdated,
        key,
    } = useSnapshot(logoStore);

    const [
        isAddNewModeOfSaleButtonClicked,
        setIsAddNewModeOfSaleButtonClicked,
    ] = useState(false);

    const [data, setData] = React.useState<any>({});
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);

    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);

    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);
    const [isUpdatedModification, setIsUpdatedModification] = React.useState<
        boolean
    >(false);

    const [logo, setLogo] = useState<any[]>([]);

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [isEdited, setIsEdited] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo?userId=${userID}&franchiseId=${franchiseID}&type=archived&uuidUser=${uuidUser}`;
    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo?userId=${userID}&franchiseId=${franchiseID}&type=activated&uuidUser=${uuidUser}`;

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: oneShop ? 6 : 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    selectedRowData={e}
                    setDataIsUpdated={setDataIsUpdated}
                    isLogoArchived={isLogoArchived}
                />
            ),
            disableFilter: false,
        },
        {
            indexOFColumn: 2,
            columnName: t("Logo number"),
            customJsx: (e: any) => <NumberLogo e={e} />,
            filterName: t("Logo number"),
            canFilter: true,
        },
        {
            indexOFColumn: 4,
            columnName: t("Dimensions"),
            customJsx: (e: any) => <Dimensions e={e} />,

            filterName: t("Dimensions"),
            canFilter: true,
        },

        {
            indexOFColumn: 4,
            columnName: t("Picture"),
            customJsx: (e: any) => <DisplayName e={e} />,
            filterName: t("Picture"),
            canFilter: true,
        },
    ];

    function NumberLogo(e: any) {
        return (
            <React.Fragment>
                {e.e.selectedRow?.subRows !== undefined && (
                    <h6 className="badge__clz lgo_icn_BTcounter">
                        {e.e.selectedRow[t("Shop")] !== ""
                            ? e.e.selectedRow.subRows?.length
                            : 0}
                    </h6>
                )}
            </React.Fragment>
        );
    }

    React.useEffect(() => {
        if (isValidateClicked) {
            setDataIsUpdated(true);
        }
    }, [isValidateClicked]);

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];

        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el.shopId);
            for (let i in el.subRows) {
                idsLocalShop.push(el.subRows[i].Id);
            }
        });

        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        setIsShopSelected(localShop.length !== 0);
    }, [selectedRows, t]);
    React.useEffect(() => {
        let array: any = [];
        selectedRows.forEach((element: any) => {
            if (element[t("Shop")] !== undefined) {
                element.subRows.forEach((el: any) => {
                    array.push({ [el.shopId]: el.Id });
                });
            } else {
                array.push({ [element.shopId]: element.Id });
            }
        });
        setShopsSelectedRow(selectedRows);

        setSelectedLogo(removeArrayOfObjectsDuplicates(array));
    }, [selectedRows, t]);

    React.useEffect(() => {
        let localShop: string[] = [];
        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el[t("Shop")]);
        });

        setIsShopSelected(localShop.length >= 1);
        let localArchived: number = 0;
        let localActive: number = 0;

        if (selectedRows.length > 0) {
            selectedRows.forEach((element: any) => {
                if (element[t("Shop")] !== undefined) {
                    localActive += element?.logoNumber.activated;
                    localArchived += element?.logoNumber.archived;
                }
            });
            if (selectedRows.filter((el: any) => el[t("Shop")]).length > 0) {
                setNumberActiveMultiShop(localActive);
                setNumberArchiveMultiShop(localArchived);
            } else {
                setNumberActiveMultiShop(
                    data.othersData !== undefined
                        ? data.othersData.activated
                        : 0
                );
                setNumberArchiveMultiShop(
                    data.othersData !== undefined ? data.othersData.archived : 0
                );
            }
        } else {
            setNumberActiveMultiShop(
                data.othersData !== undefined ? data.othersData.activated : 0
            );
            setNumberArchiveMultiShop(
                data.othersData !== undefined ? data.othersData.archived : 0
            );
        }
    }, [data, selectedRows, t]);

    useEffect(() => {
        setShopsLogoData(
            data !== undefined && data.data !== undefined ? data?.data : []
        );
    }, [data]);
    useEffect(() => {
        setLogoStoreArchived(data?.othersData?.listLogo);
    }, [data?.othersData]);

    useEffect(() => {
        if (isUpdatedModification) {
            setDataIsUpdated(true);
        }
        if (isUpdateDataActive) {
            setDataIsUpdated(true);
        }
    }, [data, isUpdatedModification, isUpdateDataActive]);
    useEffect(() => {
        if (isDataUpdated) {
            setDataIsUpdated(true);
        }
    }, [isDataUpdated]);
    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    key={key}
                    url={isLogoArchived ? urlArchive : urlActive}
                    canExpand
                    canSelect
                    setData={setData}
                    customSelect
                    setSelectedRows={setSelectedRows}
                    canMovedCheckboxLeftOnExpand
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    customJsxSideFilterButton={
                        <CustomSideFilter
                            isShopSelected={isShopSelected}
                            setLogo={setLogo}
                            selectedRows={selectedRows}
                            logo={logo}
                            isEdited={isEdited}
                            setIsInInitializationModalOpened={
                                setIsInInitializationModalOpened
                            }
                            setDataIsUpdated={setDataIsUpdated}
                            isHistoryModalOpened={isHistoryModalOpened}
                            setIsHistoryModalOpened={setIsHistoryModalOpened}
                            setLocalFilterActive={setLocalFilterActive}
                            setIsLogoArchived={setIsLogoArchived}
                            isLogoArchived={isLogoArchived}
                        />
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={ActionColumn}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="Logo"
                />
                {isAssociateLogoOpened ? (
                    <LogoAssociateModal
                        setGlobalDataUpdated={setDataIsUpdated}
                        idShops={localShop}
                        setIsAssociateLogoOpened={setIsAssociateLogoOpened}
                        selectedShopsRows={selectedRows}
                        isAssociateLogoOpened={isAssociateLogoOpened}
                        setIsUpdatedModification={setIsUpdatedModification}
                        setLimit={setLimit}
                    />
                ) : null}
            </div>

            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewModeOfSaleButtonClicked}
                    isClicked={isAddNewModeOfSaleButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isHistoryShopsModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryShopsModalOpened}
                    isModalOpened={isHistoryShopsModalOpened}
                />
            ) : null}
            {activationDeactivationMessageModalOpened && !oneShop && (
                <ConfirmationActivationDeactivationMessageModal />
            )}
        </React.Fragment>
    );
}
