import { proxy } from "valtio";

type StoreType = {
    designation: string;
    mark: string;
    reference: string;
    note: string;
    title: string;
    identifiant: string;
    nomTpe: string;
    merchantCode: string;
    paymentType: string;
    deviceCode: string;
    url: string;
    port: string;
    selectMark: string;
    isModalOpened: string;
    speed: string;
    ipAddress: string;
    kiss: boolean;
    inputIp: boolean;
    inputPort: boolean;
    InputCode: boolean;
    InputUrl: boolean;
    inputDesignation: boolean;
    isNameExist: boolean;
    dataListDesignation: boolean;
    portSelectInput: boolean;
    speedSelectInput: boolean;
    SelectInput: boolean;
    dataTable: any;
    localShop: any;
    typeTerminal: any;
    typeConfig: any;
    inputTimeout: boolean;
    inputPaymentMethod: boolean;
    timeout: any;
    paymentMethod: any;
    inputSetupType: boolean;
    inputTerminalType: boolean;
    ipExist: boolean;
    idPer: string;
    emptyIp: boolean;
    adyenIpAddress: string;
    inputAdyenIpAddress: boolean;
    mode: string;
    inputMode: boolean;
    adyenIpExist: boolean;
    isConsult: boolean;
    webhook: string;
    inputWebHook: boolean;
    inputIdIntegrator: boolean;
    idIntegrator: string;
    inputAutoValidate: boolean;
    autoValidate: string;
    inputCurrency: boolean;
    currency: any;
    adyenConfig: any
};

export const store = proxy<StoreType>({
    designation: "",
    mark: "",
    reference: "",
    note: "",
    title: "",
    identifiant: "",
    nomTpe: "",
    merchantCode: "",
    paymentType: "",
    deviceCode: "",
    url: "",
    port: "",
    selectMark: "",
    isModalOpened: "",
    speed: "",
    ipAddress: "",
    kiss: true,
    inputIp: false,
    inputPort: false,
    InputCode: false,
    InputUrl: false,
    inputDesignation: false,
    isNameExist: false,
    dataListDesignation: false,
    portSelectInput: false,
    speedSelectInput: false,
    SelectInput: false,
    dataTable: [],
    localShop: [],
    typeTerminal: "",
    typeConfig: "",
    timeout: "",
    inputTimeout: false,
    inputPaymentMethod: false,
    paymentMethod: "",
    inputSetupType: false,
    inputTerminalType: false,
    ipExist: false,
    idPer: "",
    emptyIp: false,
    adyenIpAddress: "",
    inputAdyenIpAddress: false,
    mode: "Production",
    inputMode: false,
    adyenIpExist: false,
    isConsult: false,
    webhook: "",
    inputWebHook: false,
    inputIdIntegrator: false,
    idIntegrator: "",
    inputAutoValidate: false,
    autoValidate: "",
    inputCurrency: false,
    currency: "",
    adyenConfig: {}
});

export function setLocalShop(x: any) {
    store.localShop = x;
}

export function setDataTable(x: any) {
    store.dataTable = x;
}

export function setMoneticData(data: any, type: string): void {
    switch (type) {
        case "emptyIp":
            store.emptyIp = data;
            break;
        case "timeout":
            store.timeout = data;
            break;
        case "inputSetupType":
            store.inputSetupType = data;
            break;
        case "inputTerminalType":
            store.inputTerminalType = data;
            break;
        case "paymentMethod":
            store.paymentMethod = data;
            break;
        case "inputPaymentMethod":
            store.inputPaymentMethod = data;
            break;
        case "inputTimeout":
            store.inputTimeout = data;
            break;
        case "ipExist":
            store.ipExist = data;
            break;
        case "title":
            store.title = data;
            break;
        case "typeConfig":
            store.typeConfig = data;
            break;

        case "typeTerminal":
            store.typeTerminal = data;
            break;
        case "designation":
            store.designation = data;
            break;
        case "mark":
            store.mark = data;
            break;
        case "note":
            store.note = data;
            break;
        case "speed":
            store.speed = data;
            break;
        case "ipAddress":
            store.ipAddress = data;
            break;
        case "port":
            store.port = data;
            break;
        case "identifiant":
            store.identifiant = data;
            break;
        case "nomTpe":
            store.nomTpe = data;
            break;
        case "merchantCode":
            store.merchantCode = data;
            break;
        case "isNameExist":
            store.isNameExist = data;
            break;
        case "isModalOpened":
            store.isModalOpened = data;
            break;
        case "inputDesignation":
            store.inputDesignation = data;
            break;
        case "inputIp":
            store.inputIp = data;
            break;
        case "paymentType":
            store.paymentType = data;
            break;
        case "deviceCode":
            store.deviceCode = data;
            break;
        case "url":
            store.url = data;
            break;
        case "selectMark":
            store.selectMark = data;
            break;
        case "reference":
            store.reference = data;
            break;

        case "inputPort":
            store.inputPort = data;
            break;
        case "InputCode":
            store.InputCode = data;
            break;
        case "InputUrl":
            store.InputUrl = data;
            break;
        case "dataListDesignation":
            store.dataListDesignation = data;
            break;
        case "portSelectInput":
            store.portSelectInput = data;
            break;
        case "speedSelectInput":
            store.speedSelectInput = data;
            break;
        case "SelectInput":
            store.SelectInput = data;
            break;
        case "kiss":
            store.kiss = data;
            break;
        case "adyenIpAddress":
            store.adyenIpAddress = data;
            break;
        case "inputAdyenIpAddress":
            store.inputAdyenIpAddress = data;
            break;
        case "mode":
            store.mode = data;
            break;
        case "inputMode":
            store.inputMode = data;
            break;
        case "adyenIpExist":
            store.adyenIpExist = data;
            break;
        case "isConsult":
            store.isConsult = data;
            break;
        case "webhook":
            store.webhook = data;
            break;
        case "inputWebHook":
            store.inputWebHook = data;
            break;
        case "inputIdIntegrator":
            store.inputIdIntegrator = data;
            break;
        case "idIntegrator":
            store.idIntegrator = data;
            break;
        case "inputAutoValidate":
            store.inputAutoValidate = data;
            break;
        case "autoValidate":
            store.autoValidate = data;
            break;
        case "inputCurrency":
            store.inputCurrency = data;
            break;
        case "currency":
            store.currency = data;
            break;
        case "adyenConfig":
            store.adyenConfig = data;
            break;
    }
}
export function resetMoneticData(): void {
    store.designation = "";
    store.mark = "";
    store.reference = "";
    store.note = "";
    store.title = "";
    store.typeConfig = "";
    store.identifiant = "";
    store.nomTpe = "";
    store.merchantCode = "";
    store.paymentType = "";
    store.deviceCode = "";
    store.url = "";
    store.port = "";
    store.selectMark = "";
    store.isModalOpened = "";
    store.speed = "";
    store.ipAddress = "";
    store.kiss = true;
    store.inputIp = false;
    store.inputPort = false;
    store.InputCode = false;
    store.InputUrl = false;
    store.inputDesignation = false;
    store.isNameExist = false;
    store.dataListDesignation = false;
    store.portSelectInput = false;
    store.speedSelectInput = false;
    store.SelectInput = false;
    store.inputPaymentMethod = false;
    store.paymentMethod = "";
    store.timeout = "";
    store.inputTimeout = false;
    store.adyenIpAddress = "";
    store.inputAdyenIpAddress = false;
    store.mode = "";
    store.inputMode = false;
    store.adyenIpExist = false;
    store.isConsult = false;
    store.webhook = "";
    store.inputWebHook = false;
    store.inputIdIntegrator = false;
    store.idIntegrator = "";
    store.inputAutoValidate = false;
    store.autoValidate = "";
    store.inputCurrency = false;
    store.currency = "";
    store.adyenConfig = {};
}

export default store;
