import { PencilIcon, CheckboxIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { FlexboxGrid } from "@components/Common/FlexboxGrid";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import {
    store as device,
    setActions,
    setIsActionsClicked,
    setIsEdited,
} from "./store";

export default function CustomIcons({
    e,
    setSelectedRows,
    setIsAddCity,
    isAddCity,
    selectedRowShops,
    setDataIsUpdated,
    dataLivraison,
}: any) {
    const { t } = useTranslation();
    const { isActionsClicked, isEdited } = useSnapshot(device);

    const [isAlertModalOpened, setIsAlertModalOpened] = React.useState<boolean>(
        false
    );
    function alertToggle() {
        setIsAlertModalOpened(!isAlertModalOpened);
    }
    async function deleteVille() {
        let arrayCity: any[] = [];

        dataLivraison["STVilleLivraison"].forEach((element: any) => {
            if (e.selectedRow[t("Postal code")] !== element["CodePostale"]) {
                arrayCity.push(element);
            }
        });

        dataLivraison.STVilleLivraison = arrayCity;

        let schema: any = localStorage.getItem("schemaFranchise");

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/list/add/city/c&c`;

        let data = {
            shopId: selectedRowShops[0].shopId,
            schemaFranchise: schema,

            data: dataLivraison,
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };

        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            setDataIsUpdated!(true);
                            throw Error("error");
                        }
                        toast.success(
                            `${t("Deletion completed successfully")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );

                        setIsAlertModalOpened(!isAlertModalOpened);
                        setDataIsUpdated!(true);
                        selectedRowShops[0][
                            t("minimum price")
                        ] = JSON.stringify(dataLivraison);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    return (
        <div className="d-flex justify-content-between mt-2">
            <Tooltip title={t("Edit")}>
                <IconButton>
                    <PencilIcon
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setSelectedRows([e.selectedRow]);
                                    if (!isAddCity) {
                                        setIsAddCity(!isAddCity);
                                    }
                                });
                            } else {
                                setSelectedRows([e.selectedRow]);
                                if (!isAddCity) {
                                    setIsAddCity(!isAddCity);
                                }
                            }
                        }}
                        height={25}
                        width={25}
                        style={{ cursor: "pointer" }}
                        className={`${classnames("pointer__clz", {
                            // "not-allowed-icon__clz": isApplicationArchived,
                        })}`}
                    />
                </IconButton>
            </Tooltip>

            <Tooltip title={t("Delete")}>
                <IconButton>
                    <TrashIcon
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setSelectedRows([e.selectedRow]);
                                    setIsAlertModalOpened(!isAlertModalOpened);
                                });
                            } else {
                                setSelectedRows([e.selectedRow]);

                                setIsAlertModalOpened(!isAlertModalOpened);
                            }
                        }}
                        height={25}
                        width={25}
                        fill="red"
                        style={{ cursor: "pointer" }}
                        className={`${classnames("pointer__clz", {
                            // "not-allowed-icon__clz": isApplicationArchived,
                        })}`}
                    />
                </IconButton>
            </Tooltip>
            <div />
            <Modal
                isOpen={isAlertModalOpened}
                toggle={alertToggle}
                backdrop={false}
                fade={false}
                centered
            >
                <ModalHeader toggle={alertToggle}>
                    <span
                        style={{
                            textAlign: "left",
                            font: " normal normal 600 25px Segoe UI",
                            color: "#000000",
                        }}
                    >
                        {t("Alert")}
                    </span>
                </ModalHeader>
                <ModalBody
                    className="d-flex flex-column"
                    style={{ gap: "10px" }}
                >
                    <div
                        style={{
                            font: " normal normal 600 16px/20px Segoe UI",
                            textAlign: "left",
                            color: "#2B2828",
                            padding: "14px",
                        }}
                    >
                        {t(
                            "Do you want to confirm the deletion of the selected city?"
                        )}
                    </div>
                    <FlexboxGrid
                        className="pt-4 justify-content-end"
                        gap="10px"
                    ></FlexboxGrid>
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsAlertModalOpened(!isAlertModalOpened);
                            setDataIsUpdated(true);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className="mr-2"
                        variant="primary"
                        onClick={() => {
                            deleteVille();
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </div>
    );
}
