import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxIcon, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { diff } from "deep-diff";

import { store as userStore } from "@components/VerticalLayout/store";
import { store as kdsStore } from "../KdsProjectSetup/ModalContentWrapper/store";
import { store } from "../KdsProjectSetup/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type PropsType = {
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    isDataUpdated: boolean | number;
    setIsModalConfirmationConfigurationKds: Function;
    isModalConfirmationConfigurationKds: boolean;
    rowData: any;
};

export default function ModalConfirmationConfigurationKds({
    setIsDataUpdated,
    isDataUpdated,
    setIsModalConfirmationConfigurationKds,
    isModalConfirmationConfigurationKds,
    rowData,
}: PropsType) {
    const { t } = useTranslation();

    const { userID, franchiseID, operatorID, oneShop } = useSnapshot(userStore);
    const { view, language, design, beeper } = useSnapshot(kdsStore);
    const { template } = useSnapshot(store);

    const [
        isValidateButtonClicked,
        setIsValidateButtonClicked,
    ] = React.useState<boolean>(false);
    const [
        isValidateButtonClickedAssociated,
        setIsValidateButtonClickedAssociated,
    ] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");

    function applyChange(obj: any, path: any, kind: string, value: any) {
        if (
            path.includes("newCategoryAssignment") &&
            obj.view.article.newCategoryAssignment !== undefined
        ) {
            if (
                kind === "N" &&
                obj.view.article.newCategoryAssignment.find(
                    (el: any) => el.id === value.id
                ) === undefined
            ) {
                obj.view.article.newCategoryAssignment.push(value);
            } else if (kind === "D") {
                let index = obj.view.article.newCategoryAssignment.findIndex(
                    (el: any) => el.id === value.id
                );

                if (index !== -1) {
                    obj.view.article.newCategoryAssignment.splice(index, 1);
                }
            }
        } else {
            let bracketNotationString = path
                .map((element: any) => {
                    if (!isNaN(element)) {
                        return `[${element}]`;
                    }
                    return `['${element}']`;
                })
                .join("");

            kind === "D"
                ? // eslint-disable-next-line no-eval
                  eval(`delete obj${bracketNotationString}`)
                : // eslint-disable-next-line no-eval
                  eval(`obj${bracketNotationString} = value`);
        }
        return obj;
    }
    async function handleUpdateAssociateShopButtonOnClickEvent(
        shopId: any,
        idProject: any,
        shopTemplate: any
    ) {
        if (isValidateButtonClickedAssociated === false) {
            let differences: any = diff(template, {
                design: design,
                language: language,
                beeper: beeper,
                view: view,
            });
            setIsValidateButtonClickedAssociated(true);
            differences.forEach((change: any) => {
                const { path } = change;

                let kind =
                    change.item !== undefined && change.item.kind
                        ? change.item.kind
                        : change.kind;
                let rhs =
                    change?.rhs ??
                    change?.item?.lhs ??
                    change?.item?.rhs ??
                    null;
                let data = applyChange(
                    shopTemplate,
                    path,
                    kind,
                    JSON.parse(JSON.stringify(rhs))
                );
                shopTemplate = data;
            });
            let apiUrl: any;
            let savedData = {
                userId: userID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                franchiseId: franchiseID,
                shopId: shopId,
                idProject: idProject,
                template: shopTemplate,
            };
            apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/associated/kds`;
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.error) {
                            throw Error(result.message);
                        }
                        setIsValidateButtonClickedAssociated(false);
                    })
                    .catch((error) => {
                        setIsValidateButtonClickedAssociated(false);
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        }
    }
    async function handleUpdateShopButtonOnClickEvent() {
        if (isValidateButtonClicked === false) {
            setIsValidateButtonClicked(true);
            let apiUrlPlan = `${process.env.REACT_APP_API_V2_URL}/settings/project/configuration/kds`;
            toast.dismiss();
            let localData: any = [];
            let isApp: boolean = false;
            let shopIds = "";
            let rowsApplication: any = [];
            let differences: any = diff(template, {
                design: design,
                language: language,
                beeper: beeper,
                view: view,
            });

            Object.keys(rowData.associates).forEach((el: any) => {
                let templateShop = rowData.subRows.find(
                    (elt: any) => elt.id_boutique === el
                )?.template;

                differences.forEach((change: any) => {
                    const { path } = change;

                    let kind =
                        change.item !== undefined && change.item.kind
                            ? change.item.kind
                            : change.kind;
                    let rhs =
                        change?.rhs ??
                        change?.item?.lhs ??
                        change?.item?.rhs ??
                        null;
                    
                    let data = applyChange(
                        templateShop,
                        path,
                        kind,
                        JSON.parse(JSON.stringify(rhs))
                    );

                    templateShop = data;
                });
                localData.push(
                    fetch(apiUrlPlan, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            userId: userID,
                            franchiseId: franchiseID,
                            project_id: "PROJECT",
                            projectId: rowData.projectId,
                            shopId: el,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            data: {
                                template: { ...templateShop },
                            },
                        }),
                    }).then((response) => response.json())
                );
                rowData.subRows.forEach((elt: any) => {
                    if (elt.id_boutique === el) {
                        shopIds = el;
                        if (elt.subRows.length > 0) {
                            isApp = true;
                            rowsApplication = elt.subRows;
                        } else {
                            isApp = false;
                        }
                        handleUpdateAssociateShopButtonOnClickEvent(
                            el,
                            rowData.projectId,
                            elt.template
                        );
                    }
                });
            });

            try {
                mutate(
                    apiUrlPlan,
                    await Promise.all(localData).then(async (result: any) => {
                        let errors: boolean[] = result.map(
                            (el: any) => el.error
                        );
                        if (!errors.includes(true)) {
                            toast.success(
                                `${t("KDS configuration saved successfully")}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            if (isApp === true) {
                                rowsApplication.forEach((app: any) => {
                                    handleApplicationValidateButtonClickEvent(
                                        shopIds,
                                        app.appId,
                                        rowData.projectId,
                                        app.template
                                    );
                                });
                            } else {
                                setIsValidateButtonClicked(false);
                            }
                            setIsModalConfirmationConfigurationKds(
                                !isModalConfirmationConfigurationKds
                            );
                            // setIsDataUpdated(true);
                        } else {
                            throw Error(result.message);
                        }
                    })
                );
            } catch (e: any) {
                setIsValidateButtonClicked(false);
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    async function handleApplicationValidateButtonClickEvent(
        shopIds: any,
        appId: any,
        projectId: any,
        appTemplate: any
    ) {
        if (isValidateButtonClicked === false) {
            setIsValidateButtonClicked(true);
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/kds/template`;
            let differences: any = diff(template, {
                design: design,
                language: language,
                beeper: beeper,
                view: view,
            });
            differences.forEach((change: any) => {
                const { path } = change;

                let kind =
                    change.item !== undefined && change.item.kind
                        ? change.item.kind
                        : change.kind;
                let rhs =
                    change?.rhs ??
                    change?.item?.lhs ??
                    change?.item?.rhs ??
                    null;
                let data = applyChange(
                    appTemplate,
                    path,
                    kind,
                    JSON.parse(JSON.stringify(rhs))
                );
                appTemplate = data;
            });
            let savedData = {
                userId: userID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                franchiseId: franchiseID,
                shopId: shopIds,
                appId: "KDS",
                appId_children: appId,
                template: {
                    [projectId]: { ...appTemplate },
                },
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then(async (data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }

                            if (rowData.key_project === undefined || oneShop)
                                toast.success(
                                    `${t(
                                        "KDS configuration saved successfully"
                                    )}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                            setIsModalConfirmationConfigurationKds(
                                !isModalConfirmationConfigurationKds
                            );
                            setIsValidateButtonClicked(false);
                            setIsDataUpdated(true);
                        })
                );
            } catch (e: any) {
                setIsValidateButtonClicked(false);
                toast.error(`${t("Mistake ! Please try again")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    return (
        <React.Fragment>
            <Modal
                isOpen={isModalConfirmationConfigurationKds}
                style={{
                    maxWidth: "650px",
                    maxHeight: "500px",
                    marginTop: "300px",
                }}
            >
                <ModalHeader
                    toggle={() => {
                        setIsModalConfirmationConfigurationKds(
                            !isModalConfirmationConfigurationKds
                        );
                        setIsDataUpdated(!isDataUpdated);
                    }}
                >
                    <StyledH2>{t("Alert")}</StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel
                        className="pl-3 m-0"
                        style={{
                            font: "normal normal normal 20px/27px Segoe UI",
                            whiteSpace: "pre-line",
                            opacity: "1",
                            textAlign: "left",
                            color: "#000000",
                        }}
                    >
                        {rowData.id_boutique === undefined && !oneShop
                            ? t(
                                  "Attention, if you click on validate, the specific configuration of the shops will be edited."
                              )
                            : t(
                                  "Be careful, if you click on validate, the specific configuration of the applications will be edited."
                              )}
                        !
                    </StyledLabel>
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsModalConfirmationConfigurationKds(
                                !isModalConfirmationConfigurationKds
                            );
                            setIsDataUpdated(!isDataUpdated);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className="mr-2"
                        variant="primary"
                        onClick={() => {
                            if (rowData.key_project !== undefined && !oneShop) {
 
                                handleUpdateShopButtonOnClickEvent();
                            } else if (
                                rowData.key_project === undefined &&
                                !oneShop
                            ) {

                                rowData.subRows.forEach((el: any) => {
                                    handleApplicationValidateButtonClickEvent(
                                        rowData.id_boutique,
                                        el.appId,
                                        rowData.projectId,
                                        el.template
                                    );
                                });
                            } else if (oneShop) {
                                rowData.subRows.forEach((el: any) => {
                                    handleApplicationValidateButtonClickEvent(
                                        rowData.id_boutique,
                                        el.appId,
                                        rowData.projectId,
                                        el.template
                                    );
                                    handleUpdateAssociateShopButtonOnClickEvent(
                                        el.shopId,
                                        rowData.projectId,
                                        rowData.template
                                    );
                                });
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
