import React, { useState } from "react";
import {
    CrossIcon,
    StyledLabel,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "../../../components/Common/CustomCardStyled";

import { resetAllPeripherals } from "../../../pages/Applications/DeviceAssociateModal/store";
import { store, setActions, setIsActionsClicked } from "../store";

import { CashManagementPeripheralConfigure } from "./CashManagementPeripheralConfigure";
import { MoneticPeripheralConfigure } from "./MoneticPeripheralConfigure";
import { PrinterPeripheralConfigure } from "./PrinterPeripheralConfigure";

import { CardFooter as CardCashManagementFooter } from "./CashManagementPeripheralConfigure/CardFooter";
import { CardFooter as CardMoneticFooter } from "./MoneticPeripheralConfigure/CardFooter";
import { CardFooter as CardPrinterFooter } from "./PrinterPeripheralConfigure/CardFooter";
import { resetPrinterData } from "./PrinterPeripheralConfigure/store";
import { resetCashManagementData } from "./CashManagementPeripheralConfigure/store";
import { resetMoneticData } from "./MoneticPeripheralConfigure/store";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { getAuthorizationStates } from "@components/VerticalLayout/StyledSidebarMenu";

import "../index.css";

type peripheralConfigurationType = {
    setIsAddPeripheralClicked: Function;
    setDataIsUpdated: Function;
    isAddPeripheralClicked: boolean;
    isAddNewPeripheralButtonClicked: boolean;
    selectedRows: any;
    isComingFromModify?: boolean;
    setEditedData: Function;
    editedData: any;
    setIsEdited: Function;
    isEdited: boolean;
    shopIds: any;
    data: any;
    setIsConsult?: any;
    isConsult?: boolean;
    adyenConfig?: any;
};

export function PeripheralConfiguration({
    setIsAddPeripheralClicked,
    isAddPeripheralClicked,
    setDataIsUpdated,
    isAddNewPeripheralButtonClicked,
    selectedRows,
    isComingFromModify,
    setEditedData,
    editedData,
    setIsEdited,
    isEdited,
    shopIds,
    data,
    setIsConsult,
    isConsult,
}: peripheralConfigurationType) {
    const { t } = useTranslation();

    const { isActionsClicked } = useSnapshot(store);

    const [peripheralType, setPeripheralType] = useState<string>(
        editedData.peripheralType === "printer"
            ? t("Printers")
            : editedData.peripheralType === "monetic"
            ? t("Monetics")
            : editedData.peripheralType === "cash_management"
            ? t("Coiners")
            : ""
    );
    const [
        isConfirmationEditModalOpened,
        setIsConfirmationEditModalOpened,
    ] = React.useState<boolean>(false);
    const pathname = window.location.pathname.toString();

    const activateCheckbox = () => {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");
        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.remove("not-allowed-icon-check__clz");
        }
    };
    React.useEffect(() => {
        if (editedData?.length === 0) {
            setPeripheralType("");
        } else {
            setPeripheralType(
                editedData.peripheralType === "printer"
                    ? t("Printers")
                    : editedData.peripheralType === "monetic"
                    ? t("Monetics")
                    : editedData.peripheralType === "cash_management"
                    ? t("Coiners")
                    : ""
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddNewPeripheralButtonClicked, editedData]);

    let arrayPer: any = [];

    const modifyImp = getAuthorizationStates(
        "5590fe0f-69fc-9ff9-699d-d9a9defd12ab"
    ).Modifie;
    const modifyMon = getAuthorizationStates(
        "5590fe0f-69fc-9ff9-699d-d9a9defd12ac"
    ).Modifie;
    const modifyCM = getAuthorizationStates(
        "5590fe0f-69fc-9ff9-699d-d9a9defd12ad"
    ).Modifie;

    if (modifyImp) {
        arrayPer.push({
            label: t("Printers"),
            value: t("Printers"),
        });
    }
    if (modifyMon) {
        arrayPer.push({
            label: t("Monetics"),
            value: t("Monetics"),
        });
    }
    if (modifyCM) {
        arrayPer.push({
            label: t("Coiners"),
            value: t("Coiners"),
        });
    }

    return (
        <AvForm className="card">
            <CustomCardHeader
                className="d-flex align-items-center justify-content-between"
                style={{ maxHeight: "76px" }}
            >
                <h5 className="pt-3">
                    {isComingFromModify === false
                        ? t("Add device")
                        : isConsult
                        ? t("View a device")
                        : t("Modify a device")}
                </h5>
                <CrossIcon
                    style={{ cursor: "pointer" }}
                    height={13}
                    width={13}
                    onClick={() => {
                        setIsConsult(false);
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsAddPeripheralClicked!(
                                    !isAddPeripheralClicked
                                );
                                resetAllPeripherals();
                                resetPrinterData();
                                resetCashManagementData();
                                resetMoneticData();
                                setEditedData([]);
                                setIsEdited(false);
                                activateCheckbox();
                            });
                        } else {
                            setIsAddPeripheralClicked!(!isAddPeripheralClicked);
                            resetAllPeripherals();
                            resetPrinterData();
                            resetCashManagementData();
                            resetMoneticData();
                            setEditedData([]);
                            setIsEdited(false);
                            activateCheckbox();
                        }
                        setIsConsult(false);
                    }}
                    className="afp_icn_FRcloseForm"
                />
            </CustomCardHeader>
            <CustomCardBody
                className={`pl-3 pr-5 `}
                style={{
                    maxHeight:
                        pathname === "/peripherals/cash-management"
                            ? "530px"
                            : "503px",
                    overflowY: " scroll",
                }}
            >
                <StyledLabel style={{ marginTop: "10px" }}>
                    {t("Choice of peripherals")}
                </StyledLabel>
                <StyledSelectInput
                    value={
                        peripheralType === ""
                            ? null
                            : {
                                  label: peripheralType,
                                  value: peripheralType,
                              }
                    }
                    onChange={(e: any) => {
                        setPeripheralType(e.value);
                    }}
                    placeholder={t("Select")}
                    options={arrayPer}
                    validate={{
                        required: {
                            value: true,
                            errorMessage: `${t("Please select a mark")!}`,
                        },
                    }}
                    name="mark"
                    noOptionsMessage={() => t("No options")}
                    isDisabled={isComingFromModify ? true : false}
                    className="afp_drp_peripheralChoice"
                />
                {peripheralType === t("Coiners") ? (
                    <CashManagementPeripheralConfigure
                        editedData={editedData}
                        isEdited={isEdited}
                        setIsEdited={setIsEdited}
                        selectedRows={selectedRows}
                        isConsult={isConsult}
                        shopIds={shopIds}
                    />
                ) : null}
                {peripheralType === t("Monetics") ? (
                    <MoneticPeripheralConfigure
                        editedData={editedData}
                        setIsEdited={setIsEdited}
                        selectedRows={selectedRows}
                        isConsult={isConsult}
                        shopIds={shopIds}
                    />
                ) : null}
                {peripheralType === t("Printers") ? (
                    <PrinterPeripheralConfigure
                        editedData={editedData}
                        setIsEdited={setIsEdited}
                        selectedRows={selectedRows}
                        data={data}
                        shopIds={shopIds}
                        isConsult={isConsult}
                    />
                ) : null}
            </CustomCardBody>
            {peripheralType !== "" && (
                <CustomCardFooter className="pt-1 pb-2">
                    {peripheralType === t("Monetics") ? (
                        <CardMoneticFooter
                            isEdited={isEdited}
                            setEditedData={setEditedData}
                            setDataIsUpdated={setDataIsUpdated}
                            editedData={editedData}
                            selectedRows={selectedRows}
                            setIsAddPeripheralClicked={
                                setIsAddPeripheralClicked
                            }
                            isAddPeripheralClicked={isAddPeripheralClicked}
                            setIsEdited={setIsEdited}
                            setIsConfirmationEditModalOpened={
                                setIsConfirmationEditModalOpened
                            }
                            shopIds={shopIds}
                            isConsult={isConsult}
                            setIsConsult={setIsConsult}
                        />
                    ) : null}
                    {peripheralType === t("Coiners") ? (
                        <CardCashManagementFooter
                            setEditedData={setEditedData}
                            isEdited={isEdited}
                            peripheralType={peripheralType}
                            setDataIsUpdated={setDataIsUpdated}
                            editedData={editedData}
                            selectedRows={selectedRows}
                            setIsAddPeripheralClicked={
                                setIsAddPeripheralClicked
                            }
                            isAddPeripheralClicked={isAddPeripheralClicked}
                            setIsEdited={setIsEdited}
                            setIsConfirmationEditModalOpened={
                                setIsConfirmationEditModalOpened
                            }
                            shopIds={shopIds}
                            setIsConsult={setIsConsult}
                            isConsult={false}
                        />
                    ) : null}
                    {peripheralType === t("Printers") ? (
                        <CardPrinterFooter
                            setEditedData={setEditedData}
                            isEdited={isEdited}
                            setDataIsUpdated={setDataIsUpdated}
                            editedData={editedData}
                            selectedRows={selectedRows}
                            setIsAddPeripheralClicked={
                                setIsAddPeripheralClicked
                            }
                            isAddPeripheralClicked={isAddPeripheralClicked}
                            setIsEdited={setIsEdited}
                            setIsConfirmationEditModalOpened={
                                setIsConfirmationEditModalOpened
                            }
                            shopIds={shopIds}
                            isConsult={isConsult}
                            setIsConsult={setIsConsult}
                        />
                    ) : null}
                </CustomCardFooter>
            )}
            {isConfirmationEditModalOpened ? (
                <ConfirmationEditMessage
                    setDataIsUpdated={setDataIsUpdated}
                    isModalOpened={isConfirmationEditModalOpened}
                    setIsModalOpened={setIsConfirmationEditModalOpened}
                    isAddClicked={isAddPeripheralClicked}
                    setIsAddClicked={setIsAddPeripheralClicked}
                />
            ) : null}
        </AvForm>
    );
}
