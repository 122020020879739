import React, { useEffect } from "react";
import { TrashIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { MdCloudUpload } from "react-icons/md";
import { Card } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { setDimImageUploader, setInputImageUploader } from "../store";

import "./index.css";

type ImageUploaderPropsType = {
    uploadedImage: string | Blob;
    setUploadedImage: React.Dispatch<React.SetStateAction<string | Blob>>;
    id?: string;
    inputImage: boolean;
    setIsEdited: Function;
    setImg: Function;
    isJpgOrPng: boolean;
    setInputImage: Function;
    rowData: any;
    oneShop: boolean;
    inputImageUploader: boolean;
    salesSupport: any;
    dimImageUploader: boolean;
};

export function ImageUploader({
    uploadedImage,
    setUploadedImage,
    inputImage,
    id = "",
    setIsEdited,
    setImg,
    isJpgOrPng,
    setInputImage,
    rowData,
    oneShop,
    inputImageUploader,
    salesSupport,
    dimImageUploader,
}: ImageUploaderPropsType) {
    const [dragging, setDragging] = React.useState(false);
    const { t } = useTranslation();

    // function onFileChange(e: any) {
    //     console.log(e.target.files[0]);

    //     setImg(e.target.files[0].type);
    //     setUploadedImage(e.target.files[0]);
    //     setIsEdited(true);

    //     setInputImage(false);

    //     if (!isJpgOrPng) {
    //         setInputImageUploader(true);
    //     } else {
    //         setInputImageUploader(false);
    //     }
    // }
    function onFileChange(e: any) {
        const selectedFile = e.target.files[0];

        // Use FileReader to read the image file
        const reader = new FileReader();

        reader.onload = (event: any) => {
            // Create an image element to get dimensions
            const image = new Image();
            image.src = event.target.result;

            // Set the state with image dimensions
            image.onload = () => {
                const width = image.width;
                const height = image.height;
                setImg(selectedFile.type);
                setUploadedImage(selectedFile);
                console.log({ width, height });

                setIsEdited(true);
                setInputImage(false);
                if (!(width === 500 && height === 500)) {
                    if (salesSupport.indexOf("KIOSK") !== -1) {
                        setDimImageUploader(true);
                    } else {
                        setDimImageUploader(false);
                    }
                } else {
                    setDimImageUploader(false);
                }
            };
        };

        // Read the file as a data URL
        reader.readAsDataURL(selectedFile);
    }

    const handleClick = () => {
        setImg("");
        setUploadedImage("");
        setIsEdited(true);
        setInputImageUploader(false);
        setInputImage(false);
        setDimImageUploader(false);
    };
    useEffect(() => {
        if (!isJpgOrPng) {
            setInputImageUploader(true);
        } else {
            setInputImageUploader(false);
        }
    }, [isJpgOrPng, inputImage]);

    return (
        <div
            className="d-flex flex-wrap justify-content-center align-items-center"
            style={{
                border: dragging ? "dashed grey 4px" : "unset",
                borderRadius: "6px",
                height: "100%",
                width: "auto",
                padding: "1rem",
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDragging(false);
            }}
            onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setDragging(true);
            }}
            onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                    e.dataTransfer.files[0].type !== "image/png" &&
                    e.dataTransfer.files[0].type !== "image/jpeg" &&
                    e.dataTransfer.files[0].type !== "image/jpg"
                        ? toast.error("Only images are accepted")
                        : setUploadedImage(e.dataTransfer.files[0]);
                }
                setDragging(false);
            }}
        >
            <Card
                className={classnames("uploader__clz lgo_inp_FRlogo", {
                    "not-allowed-input__clz":
                        oneShop && rowData.Id !== undefined,
                })}
                style={{
                    borderColor:
                        inputImage || inputImageUploader || dimImageUploader
                            ? "red"
                            : "",
                }}
            >
                {uploadedImage ? (
                    <React.Fragment>
                        <div
                            className=" d-flex justify-content-center align-items-center"
                            style={{
                                height: "77%",
                                width: "100%",
                            }}
                        >
                            <img
                                src={
                                    typeof uploadedImage === "string"
                                        ? uploadedImage
                                        : URL.createObjectURL(uploadedImage)
                                }
                                width="77%"
                                height="100%"
                                alt=""
                            />
                        </div>
                        <div className=" d-flex justify-content-around">
                            <label
                                htmlFor={`file-${id}`}
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <input
                                    type="file"
                                    id={`file-${id}`}
                                    name="imgCollection"
                                    onChange={onFileChange}
                                    accept="image/*"
                                    style={{
                                        display: "none",
                                    }}
                                />
                            </label>
                            <TrashIcon
                                style={{ cursor: "pointer" }}
                                height={25}
                                width={25}
                                fill="red"
                                onClick={handleClick}
                                className="mt-2"
                            />
                        </div>
                    </React.Fragment>
                ) : (
                    <label htmlFor={`file-${id}`} style={{ cursor: "pointer" }}>
                        <MdCloudUpload
                            style={{
                                width: "139px",
                                height: "165px",
                                minHeight: "95px",
                                maxHeight: "106px",
                                color: "rgba(81, 77, 77, 0.85)",
                            }}
                            className="ml-5"
                        />
                        <div style={{ marginTop: 8 }}>
                            <span
                                style={{
                                    font: "normal normal 900 18px/22px Lato",
                                    color: "#2B2828",
                                }}
                            >
                                {t("Drag and drop your files here or")}
                            </span>
                        </div>

                        <input
                            type="file"
                            id={`file-${id}`}
                            name="imgCollection"
                            onChange={onFileChange}
                            accept="image/png,image/jpg, image/jpeg"
                            style={{ display: "none" }}
                        />
                    </label>
                )}
            </Card>
        </div>
    );
}
