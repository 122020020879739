import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import {
    setGlobalDataIsUpdated,
    setIsHistoryModalOpened,
    setIsInlineErrorCleared,
    setNumberActive,
    setNumberArchive,
    setPaymentMethodsData,
    setSelectedPaymentMethods,
    store as choiceStore,
    setListFranchiseArchived,
    setDataArchive,
    setSalesSupports,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setValidationAction,
    setArchivedModesInFranchise,
} from "../store";
import { store } from "../../../../components/VerticalLayout/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";

import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { CustomIcons } from "./CustomIcons";
import PaymentMethodForm from "./PaymentMethodForm";
import { CustomSideFilter } from "./CustomSideFilter";
import HistoryModal from "../HistoryModal";
import { ConfirmationActivePaymentMethod } from "./ConfirmationActivePaymentMethod";
import { ConfirmationActivationDeactivationMessageModal } from "@components/Common/ConfirmationActivationDeactivationMessageModal";
import { store as StoreConfirmationActivationDeactivation } from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";

import "./index.css";
import "../ShopsPaymentMethod/index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type PaymentMethodsType = {
    isArchivedPaymentMethodsClicked: boolean;
    setIsArchivedPaymentMethodsClicked: Function;
    setToastLimit: Function;
};

export default function PaymentMethods({
    isArchivedPaymentMethodsClicked,
    setIsArchivedPaymentMethodsClicked,
    setToastLimit,
}: PaymentMethodsType) {
    const { oneShop, shopID, userID, franchiseID, operatorID } = useSnapshot(
        store
    );
    const { activationDeactivationMessageModalOpened } = useSnapshot(
        StoreConfirmationActivationDeactivation
    );

    const {
        globalDataIsUpdated,
        isHistoryModalOpened,
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
        key,
    } = useSnapshot(choiceStore);
    const shopId: string = oneShop ? shopID : "0";
    const didMountRef = React.useRef(false);

    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const [isConsult, setIsConsult] = React.useState<boolean>(false);
    const [isAddChoiceClicked, setIsAddChoiceClicked] = useState(false);
    const [
        isModalConfirmationOpened,
        setIsModalConfirmationOpened,
    ] = React.useState<boolean>(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [
        isAddNewChoiceButtonClicked,
        setIsAddNewChoiceButtonClicked,
    ] = useState(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [paymentMethod, setPaymentMethod] = useState<any[]>([]);

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>({});

    let urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=archived`;
    let urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    async function getSaleSupport() {
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&franchiseId=${franchiseID}&type=activated`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    let shopSalesSupport: any[] = [];
                    let LocalSaleSupport: any[] = [];

                    result.data.forEach((el: any) => {
                        shopSalesSupport = el.subRows.map((element: any) =>
                            element["Support de vente"] === undefined
                                ? element["Sale support"]
                                : element["Support de vente"]
                        );
                        LocalSaleSupport.push({
                            id: el.shopId,
                            salesSupport: shopSalesSupport,
                        });
                    });
                    setSalesSupports(LocalSaleSupport);
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    React.useEffect(() => {
        let localShop: string[] = [];
        let array: any = [];

        selectedRows.forEach((element: any) => {
            array.push({ [element.idShop]: element.id });
        });
        setSelectedPaymentMethods(array);

        selectedRows.forEach((el: any) => {
            localShop.push(el.Boutique);
        });
        setNumberActive(
            data.othersData !== undefined ? data.othersData.activated : 0
        );
        setNumberArchive(
            data.othersData !== undefined ? data.othersData.archived : 0
        );

        setListFranchiseArchived(
            data.othersData !== undefined &&
                data.othersData.dataArchive !== undefined &&
                isArchivedPaymentMethodsClicked
                ? data?.othersData?.dataArchive.data
                : []
        );

        setDataArchive(
            data.othersData !== undefined &&
                data.othersData.archivedData !== undefined
                ? data.othersData.archivedData.data
                : []
        );
        setArchivedModesInFranchise(
            data.othersData !== undefined &&
                data.othersData.dataArchive !== undefined
                ? data?.othersData?.dataArchive.data
                : []
        );
        setPaymentMethodsData(data.data);
    }, [data, selectedRows, isArchivedPaymentMethodsClicked]);

    React.useEffect(() => {
        getSaleSupport();
        // eslint-disable-next-line
    }, []);

    const ListOfPays = (e: any) => {
        let listPays: any = [];

        listPays =
            e.e.selectedRow.Country.labels !== undefined
                ? e.e.selectedRow.Country.labels
                : e.e.selectedRow.Pays.labels !== undefined
                ? e.e.selectedRow.Pays.labels
                : e.e.selectedRow.Pays !== undefined
                ? e.e.selectedRow.Pays
                : "";

        return (
            <CustomColumnDisplay
                text={`${
                    Array.isArray(listPays)
                        ? listPays.join(",")
                        : e.e.selectedRow.Pays
                }`}
            />
        );
    };

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: t("Display name"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay text={e.selectedRow["Display name"]} />
                );
            },
        },
        {
            indexOFColumn: 1,
            columnName: t("Payment method"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={e.selectedRow["Type of payment method"]}
                    />
                );
            },
        },
        {
            indexOFColumn: 2,
            columnName: t("Country"),
            customJsx: (e: any) => {
                return <ListOfPays e={e} />;
            },
        },
        {
            indexOFColumn: 3,
            columnName: t("Sales support"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={e.selectedRow["Sales support"]}
                    />
                );
            },
        },
        {
            indexOFColumn: oneShop ? 6 : 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    selectedRows={selectedRows}
                    selectedRowData={e}
                    userData={{
                        userId: userID,
                        shopId: shopId,
                        franchiseId: franchiseID,
                        operatorID: operatorID,
                        uuidUser: uuidUser,
                    }}
                    setPaymentMethod={setPaymentMethod}
                    setIsAddChoiceClicked={setIsAddChoiceClicked}
                    setIsModalConfirmationOpened={setIsModalConfirmationOpened}
                    isModalConfirmationOpened={isModalConfirmationOpened}
                    setSelectedRows={setSelectedRows}
                    isArchivedPaymentMethodsClicked={
                        isArchivedPaymentMethodsClicked
                    }
                    setIsConsult={setIsConsult}
                />
            ),
        },
    ];

    React.useEffect(() => {
        if (filterActive) setIsAddChoiceClicked(false);
    }, [filterActive]);

    React.useEffect(() => {
        clearData();
        clearMultiCustomSelectionData();
    }, [isAddChoiceClicked, paymentMethod]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    React.useEffect(() => {
        setIsAddChoiceClicked(false);
        if (didMountRef.current) {
            setGlobalDataIsUpdated(true);
        } else {
            didMountRef.current = true;
        }
    }, [i18nextLng]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: isAddChoiceClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    key={key}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    url={
                        isArchivedPaymentMethodsClicked ? urlArchive : urlActive
                    }
                    canSelect
                    setData={setData}
                    customSelect
                    setSelectedRows={setSelectedRows}
                    canMovedCheckboxLeftOnExpand
                    customJsxSideFilterButton={
                        <CustomSideFilter
                            selectedRows={selectedRows}
                            choice={paymentMethod}
                            setIsInInitializationModalOpened={
                                setIsInInitializationModalOpened
                            }
                            setDataIsUpdated={setGlobalDataIsUpdated}
                            setLocalFilterActive={setLocalFilterActive}
                            setIsAddChoiceClicked={setIsAddChoiceClicked}
                            isAddChoiceClicked={isAddChoiceClicked}
                            setIsModalConfirmationOpened={
                                setIsModalConfirmationOpened
                            }
                            isModalConfirmationOpened={
                                isModalConfirmationOpened
                            }
                            isArchivedPaymentMethodsClicked={
                                isArchivedPaymentMethodsClicked
                            }
                            setIsArchivedPaymentMethodsClicked={
                                setIsArchivedPaymentMethodsClicked
                            }
                            setLimit={setToastLimit}
                        />
                    }
                    canResize
                    actionColumn={ActionColumn}
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={
                        setGlobalDataIsUpdated as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={globalDataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="Choice"
                />
                {isAddChoiceClicked ? (
                    <PaymentMethodForm
                        setToastLimit={setToastLimit}
                        allDataShops={
                            data.othersData !== undefined &&
                            !isArchivedPaymentMethodsClicked &&
                            data.othersData.allData !== undefined
                                ? data.othersData.allData.data
                                : []
                        }
                        isAddChoiceClicked={isAddChoiceClicked}
                        setIsAddChoiceClicked={setIsAddChoiceClicked!}
                        paymentMethod={paymentMethod}
                        setIsAddNewChoiceButtonClicked={
                            setIsAddNewChoiceButtonClicked
                        }
                        isAddNewChoiceButtonClicked={
                            isAddNewChoiceButtonClicked
                        }
                        setDataChoice={setPaymentMethod}
                        userData={{
                            userId: userID,
                            shopId: shopId,
                            franchiseId: franchiseID,
                        }}
                        isEdited={isEdited}
                        setIsEdited={setIsEdited}
                        setLocalFilterActive={setLocalFilterActive}
                        setIsConsult={setIsConsult}
                        isConsult={isConsult}
                    />
                ) : null}
            </div>
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewChoiceButtonClicked}
                    isClicked={isAddNewChoiceButtonClicked}
                    setIsEdited={setIsEdited}
                    setIsInlineErrorCleared={setIsInlineErrorCleared}
                />
            ) : null}
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    validationAction={validationAction}
                    actions={actions}
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isHistoryModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                />
            ) : null}
            {isModalConfirmationOpened === true ? (
                <ConfirmationActivePaymentMethod
                    setIsModalConfirmationOpened={setIsModalConfirmationOpened}
                    isModalConfirmationOpened={isModalConfirmationOpened}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    selectedRows={selectedRows}
                    setToastLimit={setToastLimit}
                />
            ) : null}
            {activationDeactivationMessageModalOpened && oneShop && (
                <ConfirmationActivationDeactivationMessageModal />
            )}
        </React.Fragment>
    );
}
