import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { StyledH2, StyledTextInput } from "@aureskonnect/react-ui";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import classnames from "classnames";

export default function General({ view, setView, rowData }: any) {
    const { t } = useTranslation();

    const [displaySchedule, setDisplaySchedule] = React.useState<any[]>(
        view.general.displaySchedule
    );

    const [customerInformation, setCustomerInformation] = React.useState<any[]>(
        view.general.customerInformation
    );
    const [delayTimeForKDS, setDelayTimeForKDS] = React.useState<any>(
        view.general.delayTimeForKDS
    );
    const [orderOrigin, setOrderOrigin] = React.useState<boolean>(
        view.general.orderOrigin
    );
    const [partnerPOSOrderNumber, setPartnerPOSOrderNumber] = React.useState<
        boolean
    >(view.general.partnerPOSOrderNumber);
    const [isBeepActivated, setIsBeepActivated] = React.useState<boolean>(
        view.general.isBeepActivated
    );
const [isLastPageVisible, setIsLastPageVisible] = React.useState<boolean>(
    view.general.isLastPageVisible
);
    const handleDisplayScheduleChange = (event: any, key: any) => {
        let newDisplaySchedule = [...displaySchedule] as any;
        newDisplaySchedule[key].delay = parseInt(event.target.value);
        setDisplaySchedule(newDisplaySchedule);
    };

    React.useEffect(() => {
        setView(
            JSON.parse(
                JSON.stringify({
                    ...view,
                    general: {
                        displaySchedule: displaySchedule,
                        customerInformation: customerInformation,
                        delayTimeForKDS: delayTimeForKDS,
                        orderOrigin: orderOrigin,
                        partnerPOSOrderNumber: partnerPOSOrderNumber,
                        isBeepActivated: isBeepActivated,
                        isLastPageVisible: isLastPageVisible,
                    },
                })
            )
        );

        // eslint-disable-next-line
    }, [
        displaySchedule,
        customerInformation,
        delayTimeForKDS,
        orderOrigin,
        partnerPOSOrderNumber,
        isBeepActivated,
        isLastPageVisible,
    ]);
    return (
        <React.Fragment>
            <StyledH2 className="ml-3 mt-2 mb-3">
                {t("Display schedule of the order by sales mode (in minutes)")}
            </StyledH2>
            {displaySchedule.map((element: any, key: number) => {
                return (
                    <div key={key} className="d-flex  align-items-center">
                        <div className="ml-5" style={{ width: "25%" }}>
                            {t(element.name)}
                        </div>
                        <div
                            style={{
                                height: "60px",
                            }}
                        >
                            <AvForm>
                                <StyledTextInput
                                    min="0"
                                    id="delivery"
                                    name="delivery"
                                    type="number"
                                    onChange={(e: any) => {
                                        handleDisplayScheduleChange(e, key);
                                    }}
                                    value={element.delay}
                                    errorMessage={t("This field is invalid")}
                                    className={`${classnames("", {
                                        kds_inp_deliveryOrderDisplayTime:
                                            key === 0,
                                        kds_inp_takeOutOrderDisplayTime:
                                            key === 1,
                                        kds_inp_restaurantOrderDisplayTime:
                                            key === 2,
                                        kds_inp_onTheSpotOrderDisplayTime:
                                            key === 3,
                                    })}`}
                                />
                            </AvForm>
                        </div>
                    </div>
                );
            })}
            <div className="border-footer__clz" />
            <StyledH2 className="ml-3 mt-2">
                {t(
                    "The calculation of delay time for display on KDS screens (in minutes)"
                )}
            </StyledH2>
            <AvForm
                model={{
                    form: delayTimeForKDS,
                    checkItOut: true,
                }}
            >
                <AvRadioGroup
                    name="form"
                    required
                    inline
                    errorMessage="Pick one!"
                >
                    <div className="d-flex align-items-center">
                        <div className="kds_chk_timeCalculationFormula1 ml-5">
                            <AvRadio
                                id={"Formula 1"}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setDelayTimeForKDS("Formula 1");
                                }}
                                value={"Formula 1"}
                                disabled={false}
                                style={{ width: "20px", height: "20px" }}
                            />
                        </div>
                        <div style={{ bottom: "3px", position: "relative" }}>
                            {t("Formula 1")}
                        </div>
                    </div>
                    <div
                        style={{
                            font: " normal normal normal 15px/20px Nunito Sans",
                            marginLeft: "86px",
                            color: "rgb(166, 163, 163)",
                        }}
                        className="mb-1"
                    >
                        {t(
                            "The preparation time of the order decreases to zero, then the delay is displayed by incrementing the duration from zero, with a red color."
                        )}
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="kds_chk_timeCalculationFormula2 ml-5">
                            <AvRadio
                                id={"Formula 2"}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setDelayTimeForKDS("Formula 2");
                                }}
                                value={"Formula 2"}
                                disabled={false}
                                style={{ width: "20px", height: "20px" }}
                            />
                        </div>
                        <div style={{ bottom: "3px", position: "relative" }}>
                            {t("Formula 2")}
                        </div>
                    </div>
                    <div
                        style={{
                            font: " normal normal normal 15px/20px Nunito Sans",
                            marginLeft: "86px",
                            color: "rgb(166, 163, 163)",
                        }}
                        className="mb-2"
                    >
                        {t(
                            "The preparation time increases until it reaches the configured duration, then the delay is displayed in red with a continuous incrementation of the displayed duration."
                        )}
                    </div>
                </AvRadioGroup>
            </AvForm>
            <div className="border-footer__clz" />
            <StyledH2 className="ml-3 mt-2">
                {t("Display customer information")}
            </StyledH2>
            {customerInformation.map((element: any, key: number) => {
                return (
                    <div
                        key={key}
                        className="d-flex  align-items-center justify-content-between mt-3"
                    >
                        <div className="ml-5">{t(element.name)}</div>
                        <div
                            className={`${classnames("float-right mr-5", {
                                kds_icn_displayCustomerNameInformation:
                                    key === 0,
                                kds_icn_displayCustomerInformationFirstName:
                                    key === 1,
                                kds_icn_displayCustomerInformationAdress:
                                    key === 2,
                            })}`}
                        >
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={element.state}
                                onChange={() => {
                                    let newView = [...customerInformation];
                                    newView[key].state = !element.state;
                                    setCustomerInformation(newView);
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                );
            })}
            <div className="border-footer__clz" />

            {rowData !== undefined && rowData[t("Type of KDS")] === "Assemblage" ? (
                <div>
                    <div className="d-flex  align-items-center justify-content-between">
                        <StyledH2 className="ml-3">
                            {t("Display the origin of the order")}
                        </StyledH2>
                        <div className="float-right kds_icn_showOriginTheOrder mr-5">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={orderOrigin}
                                onChange={() => {
                                    setOrderOrigin(!orderOrigin);
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                    <div className="border-footer__clz" />
                    <div className="d-flex  align-items-center justify-content-between">
                        <StyledH2 className="ml-3">
                            {t("Display the POS Partner order number")}
                        </StyledH2>
                        <div className="float-right kds_icn_displayOrderNumber mr-5">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={partnerPOSOrderNumber}
                                onChange={() => {
                                    setPartnerPOSOrderNumber(
                                        !partnerPOSOrderNumber
                                    );
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="d-flex  align-items-center justify-content-between">
                        <StyledH2 className="ml-3">
                            {t("Display the POS Partner order number")}
                        </StyledH2>
                        <div className="float-right kds_icn_displayOrderNumber mr-5">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={partnerPOSOrderNumber}
                                onChange={() => {
                                    setPartnerPOSOrderNumber(
                                        !partnerPOSOrderNumber
                                    );
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                    <div className="border-footer__clz" />
                    <div className="d-flex  align-items-center justify-content-between">
                        <StyledH2 className="ml-3">
                            {t(
                                "Activate an audible beep upon the arrival of a new order"
                            )}
                        </StyledH2>
                        <div className="float-right kds_icn_displayOrderNumber mr-5">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={isBeepActivated}
                                onChange={() => {
                                    setIsBeepActivated(!isBeepActivated);
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                    <div className="border-footer__clz" />
                    <div className="d-flex  align-items-center justify-content-between">
                        <StyledH2 className="ml-3">
                            {t(
                                "Display the last page on the screen when the order is set to the 'ready' state"
                            )}
                        </StyledH2>
                        <div className="float-right kds_icn_displayOrderNumber mr-5">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={isLastPageVisible}
                                onChange={() => {
                                    setIsLastPageVisible(!isLastPageVisible);
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
