import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    designStore,
    generalConfigStore,
    setPageShopCardMessage,
    setShopCardMessage,
} from "@store";

import { SwiperLanguagesWrapper } from "@components/Common/Design/SwiperLanguagesWrapper";

import { TableContent } from "./TableContent";

export function Translation(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template },
    } = useSnapshot(generalConfigStore);
    const { shopCardMessage } = useSnapshot(designStore);

    function handleResetLanguagesTranslationsOnClickEvent() {
        if (
            (template.pages.ways["orderTaking"] as OrderTakingPageType).options
                .shopCardMessage !== undefined
        ) {
            const localShopCardMessage = Object.fromEntries(
                Object.entries(shopCardMessage).map(([key, value]) => [
                    key,
                    {
                        ...value,
                        message: value.defaultMessage as string,
                    },
                ])
            );
            setShopCardMessage(localShopCardMessage);
            setPageShopCardMessage(localShopCardMessage, "orderTaking");
        }
    }

    return (
        <div
            className="rounded border mx-2"
            style={{
                borderColor: "#CECECE",
            }}
        >
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                <div className="text-left px-4 d-flex align-items-center  cursor__clz">
                    {t("Translation")}
                </div>
                <StyledIconButton
                    title={t("Reset")}
                    className="m-0"
                    onClick={() => {
                        handleResetLanguagesTranslationsOnClickEvent();
                    }}
                >
                    <RefreshIcon height={15} width={15} />
                </StyledIconButton>
            </div>
            <div className="p-2" style={{ width: "798px" }}>
                <SwiperLanguagesWrapper />
                <TableContent />
            </div>
        </div>
    );
}
