export const ITEMS_PER_PAGE = 5;
export const EMAIL_VALIDATION_REGEX = /\S+@\S+\.\S+/;
export const FORGOT_PASSWORD_CODE_LENGTH = 6;
export const FORGOT_PASSWORD_CODE_LENGHT = 6;
export const DAYS_IN_ONE_YEAR = 365;
export const ADVANCED_ITEMS_PER_PAGE = 7;
export const SPEEDS = [9600, 19200, 38400, 57600, 115200];
export const PORT_NUMBER_REG_EXP = new RegExp(/-?\d*\.?\d{1,2/);
export const PORTS = [
    "COM1",
    "COM2",
    "COM3",
    "COM4",
    "COM5",
    "COM6",
    "COM7",
    "COM8",
    "COM9",
];

const expressionIP = /^(?!0|\s)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/i;
const expressionLocalHost = /^localhost$/i;

export const ipMatchWord = new RegExp(
    `(${expressionIP.source})|(${expressionLocalHost.source})`
);
export const numberPositiveMatch = /^\+?\d+(\.\d+)?$/;

export const emailMatchWord = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export const httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
export const numberMatchWord = new RegExp(/^[1-9]\d*$/);
export const numberMatchWordWithNullInLeft = new RegExp(/^(0*[1-9]\d*)$/);
export const DEFAULT_TIMEOUT_IN_SECONDES = 3;
export const MIN_TIMEOUT_IN_SECONDES = 0;
export const languages = [
    { value: "fr", label: "French" },
    { value: "en", label: "English" },
    { value: "nl", label: "German" },
    { value: "es", label: "Spanish" },
    { value: "it", label: "Italian" },
    { value: "pt", label: "Portuguese" },
];
export const allLanguages = [
    "Albanais",
    "Allemand",
    "Afrikaans",
    "Araona",
    "Azéri",
    "Amazigh",
    "Arménien",
    "Aymara",
    "Anglais",
    "Arabe",
    "Bengali",
    "Bulgare",
    "Bésiro",
    "Bichelamar",
    "Biélorusse",
    "Birman",
    "Catalan",
    "Coréen",
    "Croate",
    "Cambodgien",
    "Chinois",
    "Danois",
    "Espagnol",
    "Finnois",
    "Français",
    "Japonais",
    "Portugais",
    "Russe",
    "Guarani",
    "Grec",
    "Hongrois",
    "Italien",
    "Kikongo",
    "Kiswahili",
    "Lingala",
    "Malais",
    "Mongol",
    "Néerlandais",
    "Occitan",
    "Ourdou",
    "Persan",
    "Quechua",
    "Roumain",
    "Samoan",
    "Serbe",
    "Sesotho",
    "Slovaque",
    "Slovène",
    "Suédois",
    "Tamoul",
    "Turc",
    "Canichana",
    "Cavineña",
    "Cayubaba",
    "Chácobo",
    "Chichewa",
    "Chimane",
    "Créole de Guinée-Bissau",
    "Créole haïtien",
    "Créole seychellois",
    "Divehi",
    "Dzongkha",
    "Ese 'ejja",
    "Estonien",
    "Éwé",
    "Fidjien",
    "Filipino",
    "Géorgien",
    "Gilbertin",
    "Guarasu'we",
    "Guarayu",
    "Hébreu",
    "Hindi",
    "Hindi des Fidji",
    "Hiri motu",
    "Indonésien",
    "Irlandais",
    "Islandais",
    "Itonama",
    "Kallawaya",
    "Kabiyè",
    "Kazakh",
    "Khmer",
    "Kirghiz",
    "Kirundi",
    "Lao",
    "Néo-zélandaise",
    "Papouasienne",
    "Latin",
    "Leko",
    "Letton",
    "Lituanien",
    "Luxembourgeois",
    "Macédonien",
    "Machineri",
    "Malgache",
    "Maltais",
    "Maori",
    "Maori des Îles Cook",
    "Maropa",
    "Marshallais",
    "Mirandais",
    "Mojeño-Trinitario",
    "Mojeño-Ignaciano",
    "Monténégrin",
    "Moré",
    "Mosetén",
    "Movima",
    "Nauruan",
    "Ndébélé du Transvaal",
    "Népalais",
    "Norvégien",
    "Ouzbek",
    "Pacahuara",
    "Pachto",
    "Paluan",
    "Polonais",
    "Puquina",
    "Sango",
    "Shikomor",
    "Shona",
    "Shuar",
    "Sindebele",
    "Singhalais",
    "Sirionó",
    "Somali",
    "Sotho du Nord",
    "Sotho du Sud",
    "Tacana",
    "Tadjik",
    "Tamazight",
    "Tapiete",
    "Tchèque",
    "Tétoum",
    "Tigrinya",
    "Thaï",
    "Tok pisin",
    "Tongien",
    "Toromona",
    "Turkmène",
    "Tuvaluan",
    "Ukrainien",
    "Uru-Chipaya",
    "Vietnamien",
    "Wichi",
    "Yaminahua",
    "Yuki",
    "Yaracaré",
    "Zamuco",
    "Zoulou",
    "Adyguéen",
    "Agul",
    "Altaï",
    "Assamais",
    "Avar",
    "Bachkir",
    "Bas saxon",
    "Basque",
    "Bouriate",
    "Cantonais",
    "Carolinien",
    "Chamorro",
    "Darguine",
    "Erzya",
    "Galicien",
    "Gallois",
    "Gwich'in",
    "Hawaïen",
    "Iakoute",
    "Ingouche",
    "Inuinnaqtun",
    "Inuktitut",
    "Kabarde",
    "Kalmouk",
    "Karatchaï-balkar",
    "Khakasse",
    "Komi",
    "Koumyk",
    "Lak",
    "Lezghien",
    "Mannois",
    "Mari",
    "Moksa",
    "Nogaï",
    "Occitan (aranais)",
    "Ossète",
    "Oudmourte",
    "Ouïghour",
    "Romanche",
    "Routoul",
    "Ruthène",
    "Same",
    "Sorabe",
    "Swati",
    "Tabassaran",
    "Tat",
    "Tatare",
    "Tchétchène",
    "Tchouvache",
    "Tibétain",
    "Tobi",
    "Touvain",
    "Tsakhur",
    "Tsonga",
    "Tswana",
    "Venda",
    "Xhosa",
];

export const TOP_BANNER_LOGO_PRINTER_OPTIONS_TAB = 0;
export const HEADER_PRINTER_OPTIONS_TAB = 1;
export const EMAIL_CONFIRMATION_PRINTER_OPTIONS_TAB = 2;
export const WITH_EMAIL_PRINTER_OPTIONS_TAB = 0;
export const WITHOUT_EMAIL_PRINTER_OPTIONS_TAB = 1;
export const VERIFICATION_EMAIL_PRINTER_OPTIONS_TAB = 0;
export const ENTRY_EMAIL_PRINTER_OPTIONS_TAB = 1;
export const AdyenCurrencies = [
    { code: "AED", name: "UAE Dirham", decimals: 2 },
    { code: "ALL", name: "Albanian Lek", decimals: 2 },
    { code: "AMD", name: "Armenian Dram", decimals: 2 },
    { code: "ANG", name: "Antillian Guilder", decimals: 2 },
    { code: "AOA", name: "Angolan Kwanza", decimals: 2 },
    { code: "ARS", name: "Nuevo Argentine Peso", decimals: 2 },
    { code: "AUD", name: "Australian Dollar", decimals: 2 },
    { code: "AWG", name: "Aruban Guilder", decimals: 2 },
    { code: "AZN", name: "Azerbaijani manat", decimals: 2 },
    {
        code: "BAM",
        name: "Bosnia and Herzegovina Convertible Marks",
        decimals: 2,
    },
    { code: "BBD", name: "Barbados Dollar", decimals: 2 },
    { code: "BDT", name: "Bangladesh Taka", decimals: 2 },
    { code: "BGN", name: "New Bulgarian Lev", decimals: 2 },
    { code: "BHD", name: "Bahraini Dinar", decimals: 3 },
    { code: "BMD", name: "Bermudian Dollar", decimals: 2 },
    { code: "BND", name: "Brunei Dollar", decimals: 2 },
    { code: "BOB", name: "Bolivia Boliviano", decimals: 2 },
    { code: "BRL", name: "Brazilian Real", decimals: 2 },
    { code: "BSD", name: "Bahamian Dollar", decimals: 2 },
    { code: "BWP", name: "Botswana Pula", decimals: 2 },
    { code: "BYN", name: "New Belarusian Ruble", decimals: 2 },
    { code: "BZD", name: "Belize Dollar", decimals: 2 },
    { code: "CAD", name: "Canadian Dollar", decimals: 2 },
    { code: "CHF", name: "Swiss Franc", decimals: 2 },
    { code: "CLP", name: "Chilean Peso", decimals: 2 },
    { code: "CNH", name: "Yuan Renminbi (offshore)", decimals: 2 },
    { code: "CNY", name: "Yuan Renminbi (onshore)", decimals: 2 },
    { code: "COP", name: "Colombian Peso", decimals: 2 },
    { code: "CRC", name: "Costa Rican Colon", decimals: 2 },
    { code: "CUP", name: "Cuban Peso", decimals: 2 },
    { code: "CVE", name: "Cape Verdi Escudo", decimals: 0 },
    { code: "CZK", name: "Czech Koruna", decimals: 2 },
    { code: "DJF", name: "Djibouti Franc", decimals: 0 },
    { code: "DKK", name: "Danish Krone", decimals: 2 },
    { code: "DOP", name: "Dominican Republic Peso", decimals: 2 },
    { code: "DZD", name: "Algerian Dinar", decimals: 2 },
    { code: "EGP", name: "Egyptian Pound", decimals: 2 },
    { code: "ETB", name: "Ethiopian Birr", decimals: 2 },
    { code: "EUR", name: "Euro", decimals: 2 },
    { code: "FJD", name: "Fiji Dollar", decimals: 2 },
    { code: "FKP", name: "Falkland Islands Pound", decimals: 2 },
    { code: "GBP", name: "Pound Sterling", decimals: 2 },
    { code: "GEL", name: "Georgian Lari", decimals: 2 },
    { code: "GHS", name: "Ghanaian Cedi (3rd)", decimals: 2 },
    { code: "GIP", name: "Gibraltar Pound", decimals: 2 },
    { code: "GMD", name: "Gambia Delasi", decimals: 2 },
    { code: "GNF", name: "Guinea Franc", decimals: 0 },
    { code: "GTQ", name: "Guatemala Quetzal", decimals: 2 },
    { code: "GYD", name: "Guyanese Dollar", decimals: 2 },
    { code: "HKD", name: "Hong Kong Dollar", decimals: 2 },
    { code: "HNL", name: "Honduras Lempira", decimals: 2 },
    { code: "HTG", name: "Haitian Gourde", decimals: 2 },
    { code: "HUF", name: "Hungarian Forint", decimals: 2 },
    { code: "IDR", name: "Indonesian Rupiah", decimals: 0 },
    { code: "ILS", name: "New Israeli Scheqel", decimals: 2 },
    { code: "INR", name: "Indian Rupee", decimals: 2 },
    { code: "IQD", name: "Iraqi Dinar", decimals: 3 },
    { code: "ISK", name: "Iceland Krona", decimals: 2 },
    { code: "JMD", name: "Jamaican Dollar", decimals: 2 },
    { code: "JOD", name: "Jordanian Dinar", decimals: 3 },
    { code: "JPY", name: "Japanese Yen", decimals: 0 },
    { code: "KES", name: "Kenyan Shilling", decimals: 2 },
    { code: "KGS", name: "Kyrgyzstan Som", decimals: 2 },
    { code: "KHR", name: "Cambodia Riel", decimals: 2 },
    { code: "KMF", name: "Comoro Franc", decimals: 0 },
    { code: "KRW", name: "South-Korean Won", decimals: 0 },
    { code: "KWD", name: "Kuwaiti Dinar", decimals: 3 },
    { code: "KYD", name: "Cayman Islands Dollar", decimals: 2 },
    { code: "KZT", name: "Kazakhstani Tenge", decimals: 2 },
    { code: "LAK", name: "Laos Kip", decimals: 2 },
    { code: "LBP", name: "Lebanese Pound", decimals: 2 },
    { code: "LKR", name: "Sri Lanka Rupee", decimals: 2 },
    { code: "LYD", name: "Libyan Dinar", decimals: 3 },
    { code: "MAD", name: "Moroccan Dirham", decimals: 2 },
    { code: "MDL", name: "Moldovia Leu", decimals: 2 },
    { code: "MKD", name: "Macedonian Denar", decimals: 2 },
    { code: "MMK", name: "Myanmar Kyat", decimals: 2 },
    { code: "MNT", name: "Mongolia Tugrik", decimals: 2 },
    { code: "MOP", name: "Macau Pataca", decimals: 2 },
    { code: "MRU", name: "Mauritania Ouguiya", decimals: 2 },
    { code: "MUR", name: "Mauritius Rupee", decimals: 2 },
    { code: "MVR", name: "Maldives Rufiyaa", decimals: 2 },
    { code: "MWK", name: "Malawi Kwacha", decimals: 2 },
    { code: "MXN", name: "Mexican Peso", decimals: 2 },
    { code: "MYR", name: "Malaysian Ringgit", decimals: 2 },
    { code: "MZN", name: "Mozambican Metical", decimals: 2 },
    { code: "NAD", name: "Namibian Dollar", decimals: 2 },
    { code: "NGN", name: "Nigerian Naira", decimals: 2 },
    { code: "NIO", name: "Nicaragua Cordoba Oro", decimals: 2 },
    { code: "NOK", name: "Norwegian Krone", decimals: 2 },
    { code: "NPR", name: "Nepalese Rupee", decimals: 2 },
    { code: "NZD", name: "New Zealand Dollar", decimals: 2 },
    { code: "OMR", name: "Rial Omani", decimals: 3 },
    { code: "PAB", name: "Panamanian Balboa", decimals: 2 },
    { code: "PEN", name: "Peruvian Nuevo Sol", decimals: 2 },
    { code: "PGK", name: "New Guinea Kina", decimals: 2 },
    { code: "PHP", name: "Philippine Peso", decimals: 2 },
    { code: "PKR", name: "Pakistan Rupee", decimals: 2 },
    { code: "PLN", name: "New Polish Zloty", decimals: 2 },
    { code: "PYG", name: "Paraguay Guarani", decimals: 0 },
    { code: "QAR", name: "Qatari Rial", decimals: 2 },
    { code: "RON", name: "New Romanian Lei", decimals: 2 },
    { code: "RSD", name: "Serbian Dinar", decimals: 2 },
    { code: "RUB", name: "Russian Ruble", decimals: 2 },
    { code: "RWF", name: "Rwanda Franc", decimals: 0 },
    { code: "SAR", name: "Saudi Riyal", decimals: 2 },
    { code: "SBD", name: "Solomon Island Dollar", decimals: 2 },
    { code: "SCR", name: "Seychelles Rupee", decimals: 2 },
    { code: "SEK", name: "Swedish Krone", decimals: 2 },
    { code: "SGD", name: "Singapore Dollar", decimals: 2 },
    { code: "SHP", name: "St. Helena Pound", decimals: 2 },
    { code: "SLE", name: "Sierra Leone Leone", decimals: 2 },
    { code: "SOS", name: "Somalia Shilling", decimals: 2 },
    { code: "SRD", name: "Surinamese dollar", decimals: 2 },
    { code: "STN", name: "Sao Tome & Principe Dobra", decimals: 2 },
    { code: "SVC", name: "El Salvador Colón", decimals: 2 },
    { code: "SZL", name: "Swaziland Lilangeni", decimals: 2 },
    { code: "THB", name: "Thai Baht", decimals: 2 },
    { code: "TND", name: "Tunisian Dinar", decimals: 3 },
    { code: "TOP", name: "Tonga Pa'anga", decimals: 2 },
    { code: "TRY", name: "New Turkish Lira", decimals: 2 },
    { code: "TTD", name: "Trinidad & Tobago Dollar", decimals: 2 },
    { code: "TWD", name: "New Taiwan Dollar", decimals: 2 },
    { code: "TZS", name: "Tanzanian Shilling", decimals: 2 },
    { code: "UAH", name: "Ukraine Hryvnia", decimals: 2 },
    { code: "UGX", name: "Uganda Shilling", decimals: 0 },
    { code: "USD", name: "US Dollars", decimals: 2 },
    { code: "UYU", name: "Peso Uruguayo", decimals: 2 },
    { code: "UZS", name: "Uzbekistani Som", decimals: 2 },
    { code: "VEF", name: "Venezuelan Bolívar", decimals: 2 },
    { code: "VND", name: "Vietnamese New Dong", decimals: 0 },
    { code: "VUV", name: "Vanuatu Vatu", decimals: 0 },
    { code: "WST", name: "Samoan Tala", decimals: 2 },
    { code: "XAF", name: "CFA Franc BEAC", decimals: 0 },
    { code: "XCD", name: "East Caribbean Dollar", decimals: 2 },
    { code: "XOF", name: "CFA Franc BCEAO", decimals: 0 },
    { code: "XPF", name: "CFP Franc", decimals: 0 },
    { code: "YER", name: "Yemeni Rial", decimals: 2 },
    { code: "ZAR", name: "South African Rand", decimals: 2 },
    { code: "ZMW", name: "Zambian Kwacha", decimals: 2 },
];

export const UID_ENTER_BUTTON = "fb60d068-b372-4537-afd1-bb18d42da854";
export const UID_SCAN_BUTTON = "049c04b4-9b01-42a6-8497-8b2a2aedd2d3";
export const UID_USE_BUTTON = "bbf6b88b-b5f4-438f-b8b0-0c7827083b53";
export const UID_LOGOUT_BUTTON = "4bb339a4-ec7b-4d66-9fb0-9e71c1d6d983";
export const UID_SELECT_BUTTON = "c3f52255-7cd6-4024-b784-52a79172254a";

export const VALIDATE_BUTTON_ROLE = 1;
export const NO_THANKS_BUTTON_ROLE = 17;
export const HAS_NO_LOYALTY = 0;
export const CESAR_LOYALTY = 1;
export const ZEROSIX_LOYALTY = 3;
export const SYNAPSY_LOYALTY = 3;
export const GODIA_LOYALTY = 4;
export const POS_EDITOR_CESAR = "10089f6a-879e-4b6d-a7e8-41815fd25a7c";
export const POS_EDITOR_TCPOS = "10c6533d-d0ef-45a1-a549-795253673b7d";
