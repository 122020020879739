import React from "react";
import { useTranslation } from "react-i18next";
import { CardFooter } from "reactstrap";
import { CheckboxIcon, StyledButton } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import {
    setIsModalConfirmationOpened,
    store as userStore,
} from "@components/VerticalLayout/store";
import { store, resetStore, setIsPrinterModified } from "../../store";

import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type PrepheralCardFooterType = {
    dataIsUpdated: boolean | number;
    setIsEdited: Function;
    setIsDeviceAssociateModalOpened: Function;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
    setDataIsUpdated: Function;
};

export default function PrepheralCardFooter({
    dataIsUpdated,
    setIsEdited,
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    rowData,
    setDataIsUpdated,
}: PrepheralCardFooterType) {
    const { t } = useTranslation();

    const APPID = "SCO";
    const {
        cashManagements,
        selectedRowData,
        monetics,
        printerSelected,
        checkModificationEffect,
    } = useSnapshot(store);

    const {
        userID,
        franchiseID,
        shopID,
        oneShop,
        isModalConfirmationOpened,
        operatorID,
    } = useSnapshot(userStore);

    function checkDisassociatedPeripheral(lastList: any, newList: any) {
        let res: any = [];
        for (let index = 0; index < lastList.length; index++) {
            const e = lastList[index];
            if (newList.indexOf(e) === -1 && e !== undefined) {
                res.push(e);
            }
        }
        return res;
    }

    const [peripheralAarrayError, setPeripheralArrayError] = React.useState<
        string[]
    >([]);

    async function handleValidateButtonClickEvent() {
        let ListDisassociatePrinter: any = checkDisassociatedPeripheral(
            // eslint-disable-next-line array-callback-return
            rowData[0].subRows.map(function (item: any) {
                if (item.peripheralType === "printer") {
                    return item["peripheralId"];
                }
            }),
            printerSelected.map(function (item: any) {
                return item["Id"];
            })
        );

        let ListDisassociateMonetics: any = checkDisassociatedPeripheral(
            // eslint-disable-next-line array-callback-return
            rowData[0].subRows.map(function (item: any) {
                if (item.peripheralType === "monetic") {
                    return item["peripheralId"];
                }
            }),
            monetics.map(function (item: any) {
                return item["Id"];
            })
        );
        let ListDisassociateCashManagment: any = checkDisassociatedPeripheral(
            // eslint-disable-next-line array-callback-return
            rowData[0].subRows.map(function (item: any) {
                if (item.peripheralType === "cash_management") {
                    return item["peripheralId"];
                }
            }),
            cashManagements.map(function (item: any) {
                return item["Id"];
            })
        );

        let localDesPrinters: any = [];
        let localDesMonetics: any = [];
        let localDesCashManagement: any = [];

        for (let i = 0; i < ListDisassociatePrinter.length; i++) {
            const e = ListDisassociatePrinter[i];
            let name = "";
            rowData[0].subRows.forEach((el: any) => {
                if (el.peripheralId === e) {
                    name = el[t("Device")];
                }
            });

            let obj = {
                appId: APPID,
                appId_children: [rowData[0].Application],
                franchiseId: franchiseID,
                peripheralId: e,
                shopId: oneShop ? shopID.toString() : rowData[0].shopId,
                userId: userID,
                peripheralName: name,
                operatorId: operatorID,
                designation: rowData[0]["Designation"],
            };
            localDesPrinters.push(
                fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/printer`,
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(obj),
                    }
                ).then((response) => response.json())
            );
        }
        for (let i = 0; i < ListDisassociateMonetics.length; i++) {
            const e = ListDisassociateMonetics[i];
            let name = "";
            rowData[0].subRows.forEach((el: any) => {
                if (el.peripheralId === e) {
                    name = el[t("Device")];
                }
            });
            let obj = {
                appId: APPID,
                appId_children: [rowData[0].Application],
                franchiseId: franchiseID,
                peripheralId: e,
                shopId: oneShop ? shopID.toString() : rowData[0].shopId,
                userId: userID,
                peripheralName: name,
                operatorId: operatorID,
                designation: rowData[0]["Désignation"],
            };
            localDesMonetics.push(
                fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/monetic`,
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(obj),
                    }
                ).then((response) => response.json())
            );
        }
        for (let i = 0; i < ListDisassociateCashManagment.length; i++) {
            const e = ListDisassociateCashManagment[i];
            let name = "";
            rowData[0].subRows.forEach((el: any) => {
                if (el.peripheralId === e) {
                    name = el[t("Device")];
                }
            });
            let obj = {
                appId: APPID,
                operatorId: operatorID,
                appId_children: [rowData[0].Application],
                franchiseId: franchiseID,
                peripheralId: e,
                shopId: oneShop ? shopID.toString() : rowData[0].shopId,
                userId: userID,
                peripheralName: name,
                designation: rowData[0]["Designation"],
            };
            localDesCashManagement.push(
                fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/cashManagement`,
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(obj),
                    }
                ).then((response) => response.json())
            );
        }
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/printer`,
                await Promise.all(localDesPrinters).then((result) => {})
            );
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/monetic`,
                await Promise.all(localDesMonetics).then((result) => {})
            );
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/cashManagement`,
                await Promise.all(localDesCashManagement).then((result) => {})
            );
        } catch (e) {}

        if (printerSelected.length > 0) {
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/printer`;
            let userId: number;
            let shopId: string;
            let franchiseId: string;
            if (oneShop) {
                userId = userID;
                shopId = shopID.toString();
                franchiseId = franchiseID;
            } else {
                userId = userID;
                shopId = rowData[0].shopId;
                franchiseId = franchiseID;
            }
            let printersObj: any = {};

            printerSelected.forEach((el: any) => {
                if (
                    el["Id"] !== undefined &&
                    ListDisassociatePrinter.indexOf(el["Id"]) === -1 &&
                    rowData[0].subRows.every(
                        (elt: any) => elt.peripheralId !== el.Id
                    )
                ) {
                    printersObj[el["Id"]] = {
                        id_printer: el["Id"],
                        designation: el["Nom de l'imprimante"],
                        mark: el["Marque"],
                        port: el["port"],
                        note: el["Remarque"],
                        status: "in line",
                        state: "to configure",
                        path: el["path"],
                        font_size: el["Taille police"],
                        edit_date: "",
                        type: el["type"],
                        add_date: "",
                        speed: el["speed"],
                        ip: el["ip"],
                        port_series: el["port_series"],
                        ticket_width: el["ticket_width"],
                        general: {},
                        customer_ticket: {},
                        Kitchen_ticket: {},
                        modelKitchenTicket: {},
                        divers: {},
                        isArchived:
                            el["isArchived"] !== undefined
                                ? el["isArchived"]
                                : false,
                    };
                }
            });

            let savedData = {
                userId: userId,
                shopId: shopId,
                franchiseId: franchiseId,
                appId: APPID,
                operatorId: operatorID,
                appId_children: [rowData[0]["appId"]],
                data: printersObj,
                designation: rowData[0].Application,
            };
            if (Object.keys(printersObj).length !== 0) {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                            resetStore();

                            setDataIsUpdated(!dataIsUpdated);
                            setIsEdited(false);
                        })
                        .catch((e: any) => {
                            peripheralAarrayError.push("printer");
                            setPeripheralArrayError(peripheralAarrayError);
                        })
                );
            }
        }

        if (monetics.length > 0) {
            let userId: number;
            let shopId: string;
            let franchiseId: string;
            if (oneShop) {
                userId = userID;
                shopId = shopID.toString();
                franchiseId = franchiseID;
            } else {
                userId = userID;
                shopId = rowData[0].shopId;
                franchiseId = franchiseID;
            }
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/monetic`;
            let moneticsObj: any = {};

            monetics.forEach((el: any) => {
                if (
                    el["Id"] !== undefined &&
                    ListDisassociateMonetics.indexOf(el["Id"]) === -1 &&
                    rowData[0].subRows.every(
                        (elt: any) => elt.peripheralId !== el.Id
                    )
                ) {
                    moneticsObj[el["Id"]] = {
                        id_monetic: el["Id"],
                        designation: el["Désignation"],
                        mark: el["Marque"],
                        port: el["port"],
                        note: el["Remarque"],
                        status: "in line",
                        state: "to configure",
                        path: el["url"],
                        android: el["android"],
                        merchant_code: el["merchant_code"],
                        tpe_name: el["tpe_name"],
                        device_code: el["device_code"],
                        speed: el["speed"],
                        ip: el["ip"],
                        payment_type: el["payment_type"],
                        kiss: el["kiss"],
                        reference: el["Référence"],
                        general: {},
                        terminal_type: el["terminal_type"],
                        setup_type: el["setup_type"],
                        timeout: el["timeout"],
                        payment_method: el["payment_method"],
                    };
                }
            });
            let savedData = {
                userId: userId,
                shopId: shopId,
                franchiseId: franchiseId,
                appId: APPID,
                operatorId: operatorID,
                appId_children: [rowData[0]["appId"]],
                data: moneticsObj,
                designation: rowData[0].Application,
            };

            if (Object.keys(moneticsObj).length !== 0) {
                try {
                    mutate(
                        apiUrl,
                        await fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                            method: "POST",
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    throw Error(
                                        "Error while saving selection!"
                                    );
                                }

                                setDataIsUpdated(!dataIsUpdated);
                                resetStore();
                                setIsEdited(false);
                            })
                    );
                } catch (e: any) {
                    peripheralAarrayError.push("monetics");
                    setPeripheralArrayError(peripheralAarrayError);
                }
            }
        }
        if (cashManagements.length > 0) {
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/cashManagement`;
            let userId: number;
            let shopId: string;
            let franchiseId: string;

            if (oneShop) {
                userId = userID;
                shopId = shopID.toString();
                franchiseId = franchiseID;
            } else {
                userId = userID;
                shopId = rowData[0].shopId.toString();
                franchiseId = franchiseID;
            }
            let CashManagementObject: any = {};
            cashManagements.forEach((el: any) => {
                if (
                    el["Id"] !== undefined &&
                    ListDisassociateCashManagment.indexOf(el["Id"]) === -1 &&
                    rowData[0].subRows.every(
                        (elt: any) => elt.peripheralId !== el.Id
                    )
                ) {
                    CashManagementObject[el["Id"]] = {
                        id_cash_management: el["Id"],
                        designation: el[t("Designation")],
                        mark: el[t("Mark")],
                        port_number: el["port_number"],
                        reference: el["Référence"],
                        note: el["Remarque"],
                        server_directory: el["server_directory"],
                        activate: "",
                        user: el["user"],
                        ip: el["ip"],
                        password: el["password"],
                        pos_id: el["pos_id"],
                        coin_loading_alert: {},
                        empty_coin_alert: {},
                        coin_status: {},
                        collection_alert: {},
                        withdrawal_Reason: {},
                        maintenance: {},
                        cash_Fund: {},
                        accepted_denomination: {
                            "5.00 €": false,
                            "10.00 €": false,
                            "20.00 €": false,
                            "50.00 €": false,
                            "100.00 €": false,
                            "200.00 €": false,
                            "500.00 €": false,
                        },
                    };
                }
            });
            let savedData = {
                userId: userId,
                shopId: shopId,
                franchiseId: franchiseId,
                appId: APPID,
                operatorId: operatorID,
                appId_children: [rowData[0]["appId"]],
                data: CashManagementObject,
                designation: rowData[0].Application,
            };
            if (Object.keys(CashManagementObject).length !== 0) {
                try {
                    mutate(
                        apiUrl,
                        await fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                            method: "POST",
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    throw Error(
                                        "Error while saving selection!"
                                    );
                                }
                                setDataIsUpdated(!dataIsUpdated);
                                resetStore();
                                setIsEdited(false);
                            })
                    );
                } catch (e: any) {
                    peripheralAarrayError.push("cashManegment");
                    setPeripheralArrayError(peripheralAarrayError);
                }
            }
        }
        if (peripheralAarrayError.length > 0) {
            resetStore();
            toast.error(`${t("Mistake ! Please try again")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            setIsDeviceAssociateModalOpened(isDeviceAssociateModalOpened);
        } else {
            resetStore();
            setIsDeviceAssociateModalOpened(!isDeviceAssociateModalOpened);
            setDataIsUpdated(!dataIsUpdated);
            toast.success(
                `${t("The association of this device was successful")!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        }
    }

    React.useEffect(() => {
        let selectedCashManagementData: any = [];
        let selectedMoneticData: any = [];
        if (selectedRowData.subRows !== undefined) {
            selectedRowData.subRows.forEach((el: any) => {
                if (el.peripheralType === "cash_management") {
                    selectedCashManagementData.push(el.Périphérique);
                }
                if (el.peripheralType === "monetic") {
                    selectedMoneticData.push(el.Périphérique);
                }
            });
        }
    }, [selectedRowData, cashManagements, monetics]);

    return (
        <div>
            <CardFooter className="Card-footer__clz">
                <div className="justify-content-between d-flex align-items-right  float-right p-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2"
                        variant="light"
                        onClick={() => {
                            setIsDeviceAssociateModalOpened(
                                !isDeviceAssociateModalOpened
                            );
                            setDataIsUpdated(!dataIsUpdated);
                            resetStore();
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <StyledButton
                        rounded
                        variant="primary"
                        onClick={() => {
                            if (checkModificationEffect === true) {
                                handleValidateButtonClickEvent();
                            } else {
                                setIsModalConfirmationOpened(
                                    !isModalConfirmationOpened
                                );
                            }
                            setIsPrinterModified(false);
                        }}
                        className="afa_btn_validatePopup"
                    >
                        {t("Validate")}
                    </StyledButton>
                </div>
            </CardFooter>
        </div>
    );
}
