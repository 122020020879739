import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { setPattern, setDuration } from "../../store";

import { capitalize } from "@helpers/general";

import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

export function ConfirmationPauseOrdersMessage({
    isModalConfirmationOpened,
    setIsModalConfirmationOpened,
    setIsPauseOrders,
    isPauseOrders,
    setLocalDuration,
    setLocalPattern,
}: any) {
    const { t } = useTranslation();

    return (
        <StyledModal toggle={true} isOpen={isModalConfirmationOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalConfirmationOpened(!isModalConfirmationOpened);
                }}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-4">
                    {t(
                        "Do you want to confirm the deactivation of the “Interrupt order” option?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    rounded
                    variant="light"
                    onClick={() => {
                        setIsModalConfirmationOpened(
                            !isModalConfirmationOpened
                        );
                    }}
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <StyledButton
                    rounded
                    variant="primary"
                    onClick={() => {
                        setIsModalConfirmationOpened(
                            !isModalConfirmationOpened
                        );
                        setDuration("");
                        setPattern("");
                        setLocalDuration("");
                        setLocalPattern("");
                        setIsPauseOrders(!isPauseOrders);
                    }}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}
