import React from "react";
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useSnapshot } from "valtio";
import {
    AngleSmallLeftIcon,
    AngleSmallRightIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";

import { designStore, setSelectedLanguage } from "@store";

import { capitalize } from "@helpers/general";

import "../index.css";
import "swiper/swiper-bundle.css";

export function SwiperLanguagesWrapper(): JSX.Element {
    const { selectedLanguage, activePageName, activeLanguages } = useSnapshot(
        designStore
    );

    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);

    return (
        <div>
            {activeLanguages.length < 5 ? (
                <div
                    className="d-flex flex-row justify-content-center align-items-center"
                    style={{
                        width: "95%",
                        height: "36px !important",
                        gap: "10px",
                        marginLeft: "20px",
                        marginBottom: "2px",
                        marginTop: "2px",
                    }}
                >
                    {activeLanguages.map((language, index) => {
                        return (
                            <div
                                key={index}
                                className={classNames(
                                    "width__clz border border-white rounded",
                                    {
                                        "slide-color__clz":
                                            language.name === selectedLanguage,
                                    }
                                )}
                                style={{
                                    width: "100%",
                                }}
                                onClick={() => {
                                    setSelectedLanguage(language.name);
                                }}
                            >
                                {capitalize(language.title)}
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "95%", marginLeft: "13px" }}
                >
                    <StyledIconButton
                        ref={navigationPrevRef}
                        className="m-0 p-0 swiper-button__clz"
                    >
                        <AngleSmallLeftIcon
                            height={50}
                            width={40}
                            fill="#323332"
                        />
                    </StyledIconButton>
                    <Swiper
                        className="design-ways-swiper-wrapper__clz"
                        modules={[Navigation]}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        spaceBetween={14}
                        slidesPerView={4}
                    >
                        {activeLanguages.map((item, index) => {
                            return (
                                <SwiperSlide
                                    className={classNames(
                                        "width__clz border border-white rounded",
                                        {
                                            "languages-swiper-slide-width__clz":
                                                activePageName !== "payment",
                                            "languages-payment-swiper-slide-width__clz":
                                                activePageName === "payment",
                                            "slide-color__clz":
                                                selectedLanguage === item.name,
                                        }
                                    )}
                                    key={index}
                                    onClick={() => {
                                        setSelectedLanguage(item.name);
                                    }}
                                >
                                    <span
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        {capitalize(item.title)}
                                    </span>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <StyledIconButton
                        ref={navigationNextRef}
                        className="m-0 p-0 swiper-button__clz"
                    >
                        <AngleSmallRightIcon
                            height={50}
                            width={40}
                            fill="#323332"
                        />
                    </StyledIconButton>
                </div>
            )}
        </div>
    );
}
