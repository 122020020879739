import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { StyledIconButton, RefreshIcon } from "@aureskonnect/react-ui";
import { Card } from "reactstrap";
import { uid } from "react-uid";

import {
    generalConfigStore,
    setIsResetOneImageButtonClicked,
    setProjectFiles,
    setImageKey,
    setFiles,
} from "@store";
import { UsualModeWrapper } from "./UsualModeWrapper";
import { getImageContentById } from "@helpers/general";

import { Uploader } from "@components/Uploader";

export function PrmWrapper(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template },
        imageKey,
        files,
    } = useSnapshot(generalConfigStore);

    const data = files.filter(
        (image) =>
            image.id ===
            (template.content.generalDesign as GeneralDesignType).prm.id
    )[0];

    const imageContent = getImageContentById(data.id, files);

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...files];

        const image = imagesArray.find((element) => element.id === id);
        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);

            imagesArray[imageIndex] = {
                ...image,
                content: imagesArray[imageIndex].defaultImage as string,
                type: "image/png",
            };
        }
        setProjectFiles(imagesArray);
        setFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }
    return (
        <div
            className="d-flex flex-row justify-content-center"
            style={{ gap: "80px" }}
        >
            <div
                className="d-flex flex-column cursor__clz"
                style={{ gap: "10px" }}
            >
                <Card
                    style={{
                        width: "270px",
                        height: "230px",
                        borderRadius: "20px",
                        display: "grid",
                        gridTemplateRows: "0.1fr 0.8fr auto",
                        gap: "10px",
                    }}
                >
                    <div className="d-flex justify-content-end">
                        <StyledIconButton
                            title={t("Reset to default image")}
                            outline={true}
                            variant="danger"
                            className="m-0 p-0 "
                            icon="RefreshIcon"
                            style={{ height: "20%", width: "20px" }}
                            onClick={() => {
                                handleResetImagesOnClickEvent(data.id);
                                setIsResetOneImageButtonClicked(true);
                            }}
                        >
                            <RefreshIcon height={20} width={20} />
                        </StyledIconButton>
                    </div>
                    <div className="d-flex justify-content-center align-items-end">
                        {(data.type as string).includes("video") ? (
                            <video
                                key={imageKey}
                                autoPlay
                                muted
                                loop
                                src={imageSrc}
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "fill",
                                    borderRadius: "15px",
                                }}
                            />
                        ) : (
                            <img
                                key={imageKey}
                                alt="img"
                                src={imageSrc}
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: "15px",
                                }}
                            />
                        )}
                    </div>

                    <div className="d-flex justify-content-center align-items-center h-100 cmn_icn_uploadPhoto">
                        <StyledIconButton
                            style={{
                                width: "50px",
                                height: "50px",
                                boxShadow: "0px 3px 6px #00000029",
                            }}
                            className="m-0"
                        >
                            <Uploader id={data.id} key={imageKey} />
                        </StyledIconButton>
                    </div>
                </Card>

                <div className="d-flex justify-content-center align-items-center">
                    <span
                        style={{
                            font: "normal normal normal 15px/20px Nunito Sans",
                            color: "black",
                        }}
                    >
                        {t(data.name)}
                    </span>
                </div>
            </div>
            <UsualModeWrapper />
        </div>
    );
}
