import { SettingsIcon } from "@aureskonnect/react-ui";
import classnames from "classnames";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";

import { setIsModalOpened, store } from "./store";
import { store as useStore } from "@components/VerticalLayout/store";

import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";

export default function CustomIcons({ e, setSelectedRows }: any) {
    const { t } = useTranslation();
    const { isModalOpened, modify } = useSnapshot(store);
    const { oneShop } = useSnapshot(useStore);

    return (
        <React.Fragment>
            {modify === true ? (
                <>
                    <Tooltip title={t("configure")}>
                        <IconButton>
                            <SettingsIcon
                                className={`${classnames("pointer__clz ml-3", {
                                    "not-allowed-icon__clz":
                                        !oneShop && e.selectedRow[t("Apk")],
                                })}`}
                                onClick={() => {
                                    if (
                                        e.selectedRow["application"] ===
                                            false &&
                                        e.selectedRow["Web site"] === 0
                                    ) {
                                        toast.info(
                                            `${t(
                                                "Please note you cannot access this parameter. The app and site are disabled."
                                            )!}`,
                                            {
                                                position:
                                                    toast.POSITION.TOP_CENTER,
                                                autoClose: 2000,
                                                theme: "colored",
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                            }
                                        );
                                    } else {
                                        setIsModalOpened(!isModalOpened);
                                        setSelectedRows([e.selectedRow]);
                                    }
                                }}
                                height={22}
                                width={22}
                            />
                        </IconButton>
                    </Tooltip>
                </>
            ) : (
                <div>
                    <Tooltip title={t("To consult")}>
                        <IconButton>
                            <ConsultSvgIcon
                                className={`${classnames(
                                    "pointer__clz afa_icn_toConsult ml-2",
                                    {
                                        "not-allowed-icon__clz":
                                            !oneShop && e.selectedRow[t("Apk")],
                                    }
                                )}`}
                                height={35}
                                width={35}
                                style={{
                                    cursor: "pointer",
                                    position: "relative",
                                    bottom: "5px",
                                }}
                                fill="black"
                                onClick={async () => {
                                    setIsModalOpened(!isModalOpened);
                                    setSelectedRows([e.selectedRow]);
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </React.Fragment>
    );
}
