import React, { useState } from "react";
import {
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import {
    AvRadioGroup,
    AvRadio,
    AvGroup,
    AvForm,
} from "availity-reactstrap-validation";
import { store as userStore } from "@components/VerticalLayout/store";

import { useSnapshot } from "valtio";
import classnames from "classnames";

import { setPrinterData, store } from "./store";

import {
    ipMatchWord,
    numberMatchWordWithNullInLeft,
    SPEEDS,
} from "../../../../constants";
import { checkIpIfExist } from "@helpers/general";

type PrinterType = {
    editedData: any;
    setIsEdited: Function;
    selectedRows: any;
    data?: any;
    shopIds?: any;
    isConsult: any;
};
export function PrinterPeripheralConfigure({
    editedData,
    data,
    setIsEdited,
    selectedRows,
    shopIds,
    isConsult,
}: PrinterType) {
    const { t } = useTranslation();
    const { shopID, resourceIp, oneShop } = useSnapshot(userStore);

    const {
        printerType,
        printerPath,
        designation,
        mark,
        note,
        speed,
        ip,
        port_series,
        fontSize,
        ticketWidth,
        ipPortNumber,
        isNameExist,
        inputDesignation,
        inputIp,
        isIpPrinter,
        isUsbPrinter,
        ipExist,
        emptyIp,
        printerCode,
        codeIsExist,
        tickets,
        inputTicket,
    } = useSnapshot(store);
    const shopId = oneShop ? shopID : selectedRows?.shopId;
    const fontSizeArray: {
        label: number;
        value: number;
    }[] = [...Array(16).keys()].map((e: any, index: number) => {
        return {
            label: index + 1,
            value: index + 1,
        };
    });

    const ticketWidthArray: {
        label: number;
        value: number;
    }[] = [...Array(7).keys()].map((e: any, index: number) => {
        return {
            label: index + 32,
            value: index + 32,
        };
    });
    const [selectedShopPrinterCodes, setSelectedShopPrinterCodes] = useState<
        any
    >([]);
    const [ticketsList, setTicketsList] = React.useState<any[]>([]);

    const portNumArray: {
        label: string;
        value: string;
    }[] = [...Array(32).keys()].map((e: any, index: number) => {
        if (printerType === "series")
            return {
                label: `COM ${index + 1}`,
                value: `COM ${index + 1}`,
            };
        return { label: `LPT ${index + 1}`, value: `LPT ${index + 1}` };
    });

    const printerSpeedArray: {
        label: number;
        value: number;
    }[] = SPEEDS.map((element: number) => {
        return {
            label: element,
            value: element,
        };
    });

    React.useEffect(() => {
        if (printerType !== "" && printerType !== undefined) {
            setPrinterData(
                printerType === "series" ? "COM 2" : "7000",
                "portNum"
            );
            setPrinterData(printerType === "ip" ? true : false, "isIpPrinter");
            setPrinterData(
                printerType === "usb" ? true : false,
                "isUsbPrinter"
            );
            setPrinterData(false, "inputDesignation");
            setPrinterData(false, "inputIp");
            setPrinterData(false, "inputPrinterPath");
            setPrinterData("COM 2", "port_series");
        }
    }, [printerType]);

    React.useEffect(() => {
        if (editedData.length !== 0) {
            setPrinterData(editedData[t("Peripheral")], "designation");
            setPrinterData(editedData.path, "printerPath");
            setPrinterData(
                editedData.type !== undefined ? editedData.type : "ip",
                "printerType"
            );
            setPrinterData(
                editedData.type !== "ip"
                    ? editedData["IP/port"]
                    : printerType === "series"
                    ? "COM 2"
                    : "LPT 2",
                "portNum"
            );
            setPrinterData(
                editedData.tickets === undefined ||
                    editedData.tickets === "" ||
                    editedData.tickets.length === 0
                    ? null
                    : {
                          label: t(editedData.tickets.label),
                          value: editedData.tickets.value,
                      },
                "tickets"
            );
            setPrinterData(editedData.path, "printerPath");
            setPrinterData(editedData.speed, "speed");
            setPrinterData(editedData.font_size, "fontSize");
            setPrinterData(editedData.ticket_width, "ticketWidth");
            setPrinterData(editedData.Marque, "mark");
            setPrinterData(editedData.Remarque, "note");
            setPrinterData(editedData.type === "ip" ? editedData.ip : "", "ip");
            setPrinterData(editedData.port_series, "port_series");
            printerType === "ip"
                ? setPrinterData(editedData.port, "ipPortNumber")
                : setPrinterData(editedData.port, "portNum");
        } else {
            setPrinterData({ label: t("Customer"), value: "01" }, "tickets");
            setPrinterData(editedData.port_series, "COM1");
            setPrinterData(19200, "speed");
            setPrinterData("", "mark");
            setPrinterData("", "ip");
            setPrinterData("ip", "printerType");
            setPrinterData("", "note");
            setPrinterData(9, "fontSize");
            setPrinterData("", "printerPath");
            setPrinterData(40, "ticketWidth");

            setPrinterData(
                printerType === "series" ? "COM 2" : "LPT 2",
                "portNum"
            );
            setPrinterData("7000", "ipPortNumber");
            setPrinterData(false, "inputDesignation");
            setPrinterData(false, "inputIp");
            setPrinterData(false, "inputPrinterPath");
            setPrinterData("COM 2", "port_series");
        }
        // eslint-disable-next-line
    }, [editedData, t]);

    React.useEffect(() => {
        if (editedData.length !== 0) {
            setPrinterData(
                editedData.code !== undefined ? editedData.code : 1,
                "printerCode"
            );
        } else {
            setPrinterData(
                selectedShopPrinterCodes.length > 0
                    ? Math.max(...selectedShopPrinterCodes) + 1
                    : 1,
                "printerCode"
            );
        }
    }, [editedData, selectedShopPrinterCodes]);

    React.useEffect(() => {
        setPrinterData(
            shopIds.length > 1 || shopIds.length === 0
                ? false
                : checkIpIfExist(
                      resourceIp,
                      ip,
                      editedData?.peripheralId !== undefined
                          ? editedData?.peripheralId
                          : "",
                      editedData?.peripheralId !== undefined
                          ? editedData?.shopId
                          : shopId,
                      "printer"
                  ),
            "ipExist"
        );
        if (ip !== "") {
            setPrinterData(false, "ipEmpty");
            setPrinterData(!ip.match(ipMatchWord), "inputIp");
        }

        if (ip === "") {
            setPrinterData(false, "ipExist");
            setPrinterData(false, "inputIp");
            setPrinterData(false, "ipEmpty");
        }
        if (editedData?.peripheralId !== undefined) {
            setPrinterData(
                checkIpIfExist(
                    resourceIp,
                    ip,
                    editedData?.peripheralId,
                    oneShop ? shopID : editedData?.shopId,
                    "printer"
                ),
                "ipExist"
            );
        } else if (oneShop) {
            setPrinterData(
                checkIpIfExist(resourceIp, ip, "", shopID, "printer"),
                "ipExist"
            );
        }
        if (shopIds.length > 1) {
            setPrinterData(false, "ipExist");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData, shopId, ip, shopIds]);

    React.useEffect(() => {
        let res: number = resourceIp.filter((x: any) => {
            if (editedData?.peripheralId !== undefined) {
                return (
                    x.shopId === shopId &&
                    x.type === "printer" &&
                    x.id !== editedData?.peripheralId &&
                    x.designation.toUpperCase() === designation.toUpperCase()
                );
            } else {
                return (
                    x.shopId === shopId &&
                    x.type === "printer" &&
                    x.designation.toUpperCase() === designation.toUpperCase()
                );
            }
        }).length;
        setPrinterData(res > 0, "isNameExist");
        let filteredData = JSON.parse(JSON.stringify(resourceIp))
            ?.filter(
                (item: any) =>
                    item?.shopId === shopId &&
                    item?.type !== "printer" &&
                    item.ip.trim() !== "" &&
                    item.ip.trim() !== editedData[t("IP address")]
            )
            .map((y: any) => {
                return y.ip;
            });

        filteredData = [...new Set(filteredData)];
        if (filteredData.includes(ip)) {
            setPrinterData(true, "ipExist");
        }
        if (shopIds.length > 1) {
            setPrinterData(false, "ipExist");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData, shopId, designation, printerType, ip]);

    React.useEffect(() => {
        setTicketsList([
            { label: t("Customer"), value: "01" },
            { label: t("Production"), value: "02" },
            {
                label: t("Sticker"),
                value: "03",
            },
        ]);
    }, [t]);

    React.useEffect(() => {
        if (oneShop && data !== undefined) {
            let localCodes = data.data.map((el: any) => el.code);
            if (localCodes !== undefined)
                setSelectedShopPrinterCodes(
                    localCodes
                        .filter((elem: any) => elem !== undefined)
                        .map((el: any) => Number(el))
                );
        }
        if (!oneShop) {
            if (editedData.length !== 0) {
                setSelectedShopPrinterCodes(
                    data.data
                        .filter((el: any) => {
                            return editedData.shopId === el.shopId;
                        })
                        .map((element: any) => element.subRows)
                        .reduce(function (acc: any, curr: any) {
                            return acc.concat(curr);
                        }, [])
                        .map((el: any) => el.code)
                        .filter((elem: any) => elem !== undefined)
                        .map((el: any) => Number(el))
                );
            } else
                setSelectedShopPrinterCodes(
                    data.data
                        .filter((el: any) => {
                            return shopIds.includes(el.shopId);
                        })
                        .map((element: any) => element.subRows)
                        .reduce(function (acc: any, curr: any) {
                            return acc.concat(curr);
                        }, [])
                        .map((el: any) => el.code)
                        .filter((elem: any) => elem !== undefined)
                        .map((el: any) => Number(el))
                );
        }
        // eslint-disable-next-line
    }, [shopIds, oneShop, t, data]);

    React.useEffect(() => {
        if (editedData.length === 0) {
            selectedShopPrinterCodes.includes(Number(printerCode))
                ? setPrinterData(true, "codeIsExist")
                : setPrinterData(false, "codeIsExist");
        } else {
            selectedShopPrinterCodes
                .filter((elem: any) => Number(elem) !== Number(editedData.code))
                .includes(Number(printerCode))
                ? setPrinterData(true, "codeIsExist")
                : setPrinterData(false, "codeIsExist");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData, printerCode, selectedShopPrinterCodes]);

    return (
        <React.Fragment>
            <AvForm
                key={`printerType"${printerType}`}
                model={{
                    locationType:
                        printerType !== undefined ? printerType : "ip",
                    checkItOut: true,
                }}
                style={{ marginTop: "20px" }}
                className={`  ${isConsult === true ? "pe-none__clz" : ""}`}
            >
                <StyledLabel>{t("Printer type")}</StyledLabel>

                <AvRadioGroup name="locationType" required inline>
                    <AvRadio
                        onChange={() => {
                            setPrinterData("ip", "printerType");
                            setIsEdited(true);
                        }}
                        className="ml-4 imp_icn_ip"
                        label={<StyledLabel>{t("IP")}</StyledLabel>}
                        name="type"
                        value="ip"
                    />
                    <AvRadio
                        onChange={() => {
                            setPrinterData("series", "printerType");
                            setIsEdited(true);
                        }}
                        id="series"
                        className="ml-4 imp_icn_series"
                        label={<StyledLabel>{t("Series")}</StyledLabel>}
                        name="type"
                        value="series"
                    />
                    <AvRadio
                        className="ml-4 imp_icn_usb"
                        onChange={() => {
                            setPrinterData("usb", "printerType");
                            setIsEdited(true);
                        }}
                        label={<StyledLabel>{t("USB")}</StyledLabel>}
                        name="type"
                        value="usb"
                        id="usb"
                    />
                </AvRadioGroup>
                <AvGroup style={{ width: isUsbPrinter && "460px" }}>
                    <StyledLabel htmlFor="name" className="required__clz mt-3">
                        {t("Designation")}
                    </StyledLabel>
                    <StyledTextInput
                        className={classnames("afp_inp_deviceName", {
                            input__clz:
                                inputDesignation ||
                                isNameExist ||
                                (designation.trim() === "" &&
                                    designation !== ""),
                        })}
                        id="designation"
                        name="designation"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setPrinterData(e.target.value, "designation");
                            setIsEdited(true);
                            setPrinterData(false, "inputDesignation");
                        }}
                        value={designation}
                    />
                    {inputDesignation ||
                    (designation.trim() === "" && designation !== "") ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please enter a designation")}
                        </div>
                    ) : null}
                    {isNameExist ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("The name is already exists")!}
                        </div>
                    ) : null}
                </AvGroup>
                {printerType === "usb" ? (
                    <div className="justify-content-between d-flex mt-3">
                        <AvGroup style={{ width: "500px" }}>
                            <StyledLabel htmlFor="path" className=" ">
                                {t("Printer name")}
                            </StyledLabel>
                            <StyledTextInput
                                className={classnames({})}
                                id="path"
                                name="path"
                                placeholder={t("Write")}
                                type="text"
                                value={printerPath}
                                onChange={(e: any) => {
                                    setPrinterData(
                                        e.target.value,
                                        "printerPath"
                                    );
                                    setIsEdited(true);
                                    setPrinterData(false, "inputPrinterPath");
                                }}
                                style={{ width: "460px" }}
                            />
                        </AvGroup>
                    </div>
                ) : null}
                <AvGroup
                    style={{
                        width: "100%",
                    }}
                >
                    <StyledLabel
                        className="required__clz mt-3"
                        htmlFor="example-input"
                    >
                        {t("Printer code")}
                    </StyledLabel>
                    <StyledTextInput
                        className={classnames("imp_inp_code")}
                        autocomplete="off"
                        id="code"
                        name="code"
                        placeholder={t("Write")}
                        onChange={(e: any) => {
                            setPrinterData(e.target.value, "printerCode");
                            setIsEdited(true);
                            const isValid = e.target.value.match(
                                numberMatchWordWithNullInLeft
                            );
                            setPrinterData(isValid !== null, "isCodeValid");
                        }}
                        value={printerCode}
                        validate={{
                            required: {
                                value: true,
                                errorMessage: `${t(
                                    "Please enter a printer code"
                                )!}`,
                            },
                            pattern: {
                                value: numberMatchWordWithNullInLeft,
                                errorMessage: t("Printer code is invalid"),
                            },
                        }}
                        maxMenuHeight="90px"
                    />
                    {codeIsExist ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("The code is already exists")!}
                        </div>
                    ) : null}
                </AvGroup>
                {isIpPrinter ? (
                    <AvGroup>
                        <StyledLabel
                            htmlFor="example-input"
                            className="required__clz mt-3"
                        >
                            {t("IP address")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("afp_inp_ipAdresse", {
                                input__clz: inputIp || emptyIp || ipExist,
                            })}
                            id="ip"
                            name="ip"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                if (!e.target.value.match(ipMatchWord)) {
                                    setPrinterData(e.target.value, "ip");
                                    setIsEdited(true);
                                    setPrinterData(true, "inputIp");
                                } else {
                                    setPrinterData(e.target.value, "ip");
                                    setIsEdited(true);
                                    setPrinterData(false, "inputIp");
                                }
                                if (e.target.value === "") {
                                    setPrinterData(false, "emptyIp");
                                }
                            }}
                            value={ip}
                        />
                        {inputIp || emptyIp || ipExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t(
                                    emptyIp
                                        ? "Please enter a IP address"
                                        : inputIp
                                        ? "IP address is invalid"
                                        : "The ip address is already exists"
                                )}
                            </div>
                        ) : null}
                    </AvGroup>
                ) : null}
                {isIpPrinter || printerType === "series" ? (
                    <div
                        className="d-flex align-items-start mb-2"
                        style={{ gap: "10%" }}
                    >
                        {isIpPrinter ? (
                            <AvGroup style={{ width: "500px" }}>
                                <StyledLabel
                                    className="mt-3"
                                    htmlFor="example-input"
                                >
                                    {t("Port number")}
                                </StyledLabel>
                                <StyledTextInput
                                    autocomplete="off"
                                    id="remark"
                                    name="remark"
                                    className="afp_inp_portNumber"
                                    placeholder={t("Write")}
                                    onChange={(e: any) => {
                                        setPrinterData(
                                            e.target.value,
                                            "ipPortNumber"
                                        );
                                        setIsEdited(true);
                                    }}
                                    value={ipPortNumber}
                                />
                            </AvGroup>
                        ) : (
                            <AvGroup
                                style={{
                                    width: "190px",
                                }}
                            >
                                <StyledLabel
                                    className="mt-3"
                                    htmlFor="example-input"
                                >
                                    {t("Port number")}
                                </StyledLabel>
                                <StyledSelectInput
                                    name="port_num"
                                    id="port_num"
                                    options={portNumArray}
                                    onChange={(e: any) => {
                                        setPrinterData(e.label, "port_series");
                                        setIsEdited(true);
                                    }}
                                    placeholder={t("Write")}
                                    value={{
                                        value: port_series,
                                        label: port_series,
                                    }}
                                    noOptionsMessage={() => t("No options")}
                                    className="afp_inp_portNumber"
                                />
                            </AvGroup>
                        )}

                        {printerType === "series" && (
                            <AvGroup
                                style={{
                                    width: isUsbPrinter ? "250px" : "100%",
                                }}
                            >
                                <StyledLabel className="mt-3" htmlFor="speed">
                                    {t("Speed")}
                                </StyledLabel>
                                <StyledSelectInput
                                    value={{
                                        label: speed,
                                        value: speed,
                                    }}
                                    id="speed"
                                    name="speed"
                                    placeholder={t("Write")}
                                    options={printerSpeedArray}
                                    onChange={(e: any) => {
                                        setPrinterData(e.label, "speed");
                                        setIsEdited(true);
                                    }}
                                    noOptionsMessage={() => t("No options")}
                                    className="afp_inp_speed"
                                />
                            </AvGroup>
                        )}
                    </div>
                ) : null}
                <div
                    className="d-flex align-items-start"
                    style={{ gap: "10%" }}
                >
                    <AvGroup style={{ width: "260px" }}>
                        <StyledLabel className="mt-3" htmlFor="size">
                            {t("Font size")}
                        </StyledLabel>
                        <StyledSelectInput
                            value={{
                                label: fontSize,
                                value: fontSize,
                            }}
                            name="font_size"
                            id="label"
                            options={fontSizeArray}
                            placeholder={t("Write")}
                            onChange={(e: any) => {
                                setPrinterData(e.label, "fontSize");
                                setIsEdited(true);
                            }}
                            noOptionsMessage={() => t("No options")}
                            className="afp_inp_fontSize"
                        />
                    </AvGroup>

                    <AvGroup
                        style={{
                            width: !isUsbPrinter ? "250px" : "208px",
                        }}
                    >
                        <StyledLabel className="mt-3" htmlFor="width">
                            {t("Ticket width")}
                        </StyledLabel>
                        <StyledSelectInput
                            value={{
                                label: ticketWidth,
                                value: ticketWidth,
                            }}
                            name="ticket_width"
                            id="ticket_width"
                            options={ticketWidthArray}
                            className="afp_inp_ticketWidth"
                            placeholder={t("Write")}
                            onChange={(e: any) => {
                                setPrinterData(e.label, "ticketWidth");
                                setIsEdited(true);
                            }}
                            noOptionsMessage={() => t("No options")}
                        />
                    </AvGroup>
                </div>
                <AvGroup style={{ width: isUsbPrinter && "460px" }}>
                    <StyledLabel className="mt-3" htmlFor="mark">
                        {t("Mark")}
                    </StyledLabel>
                    <StyledTextInput
                        id="mark"
                        name="mark"
                        placeholder={t("Write")}
                        className="afp_inp_mark"
                        type="text"
                        onChange={(e: any) => {
                            setPrinterData(e.target.value, "mark");
                            setIsEdited(true);
                        }}
                        value={mark}
                    />
                </AvGroup>
                <AvGroup>
                    <StyledLabel htmlFor="text" className="required__clz mt-3">
                        {t("Ticket")}
                    </StyledLabel>
                    <StyledSelectInput
                        onDelete={() => {
                            setIsEdited(true);
                        }}
                        onChange={(e: any) => {
                            setIsEdited(true);
                            setPrinterData(e, "tickets");
                            setPrinterData(false, "inputTicket");
                        }}
                        className={classnames("imp_drp_ticket", {
                            input__clz: inputTicket,
                        })}
                        placeholder={`${t("Select")}…`}
                        value={tickets}
                        name="country"
                        isClearable
                        options={ticketsList}
                        noOptionsMessage={() => t("No options")}
                    />
                    {inputTicket ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please select a ticket")}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup style={{ width: isUsbPrinter && "460px" }}>
                    <StyledLabel className="mt-3" htmlFor="remark">
                        {t("Remark")}
                    </StyledLabel>
                    <StyledTextInput
                        id="remark"
                        name="remark"
                        className="afp_inp_remark"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setPrinterData(e.target.value, "note");
                            setIsEdited(true);
                        }}
                        value={note}
                    />
                </AvGroup>
            </AvForm>
        </React.Fragment>
    );
}
