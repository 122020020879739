import React from "react";
import { StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DynamicTable } from "@maherunlocker/custom-react-table";

import classnames from "classnames";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";

import { useTranslation } from "react-i18next";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

export default function OriginHistory({
    url,
    isModalOpened,
    setIsModalOpened,
}: any) {
    const { t } = useTranslation();

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [data, setData] = React.useState<any>({});
    const [des, setDes] = React.useState<any>({});

    React.useEffect(() => {
        setDes(data?.othersData?.des);
    }, [data]);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [, setSelectedRows] = React.useState<any[]>([]);

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    function ListItemModification(e: any) {
        let re = "";

        for (let index = 0; index < e.length; index++) {
            let item = e[index].item;
            if (item === "code") {
                item = t("Printer code");
            } else if (item === "value") {
                item = "VAT rate";
            }
            if (index === e.length - 1) {
                re = re + t(item);
            } else {
                re = re + t(item) + " , ";
            }
        }

        return re;
    }
    function ListItemModificationAfterValues(e: any) {
        let re = "";

        for (let index = 0; index < e.length; index++) {
            let item = e[index].newValue;

            if (index === e.length - 1) {
                re =
                    re +
                    t(
                        e[index].item === "ticket" &&
                            e[index].newValue !== undefined
                            ? e[index].newValue.label
                            : item
                    );
            } else {
                re =
                    re +
                    t(
                        e[index].item === "ticket" &&
                            e[index].newValue !== undefined
                            ? e[index].newValue.label
                            : item
                    ) +
                    ", ";
            }
        }
        if (re[re.length - 2] === ",") {
            re = re.substring(0, re.length - 2);
        }
        return re;
    }
    function ListItemModificationBeforeValues(e: any) {
        let re = "";

        for (let index = 0; index < e.length; index++) {
            let item = e[index].oldValue;
            if (index === e.length - 1) {
                re =
                    re +
                    t(
                        e[index].item === "ticket" &&
                            e[index].oldValue !== undefined
                            ? e[index].oldValue.label
                            : item
                    );
            } else {
                re =
                    re +
                    t(
                        e[index].item === "ticket" &&
                            e[index].oldValue !== undefined
                            ? e[index].oldValue.label
                            : item
                    ) +
                    ", ";
            }
        }
        if (re[re.length - 2] === ",") {
            re = re.substring(0, re.length - 2);
        }
        return re;
    }

    type CustomColumnProps = {
        indexOFColumn: number;
        columnName: string;
        customJsx: React.ReactNode;
    };
    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: t("Printer name"),

            customJsx: (e: any) => <div>{e.selectedRow["Printer"]}</div>,
        },
        {
            indexOFColumn: 1,
            columnName: t("Action"),

            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={
                            e.selectedRow["Action"] === "Modification"
                                ? t("Edit") +
                                  " " +
                                  t("of") +
                                  " " +
                                  ListItemModification(e.selectedRow["details"])
                                : e.selectedRow["Action"] === "Assignment"
                                ? e.selectedRow.details?.After === undefined
                                    ? e.selectedRow["After"]
                                    : `${t(e.selectedRow["Action"])} au ${
                                          e.selectedRow.details?.After !==
                                          undefined
                                              ? e.selectedRow.details.After
                                              : ""
                                      }`
                                : e.selectedRow["Action"] === "Reassignment"
                                ? `${t(e.selectedRow["Action"])} au ${
                                      e.selectedRow.details.After
                                  }`
                                : e.selectedRow["Action"] === "Dissociation"
                                ? e.selectedRow["After"]
                                : t(e.selectedRow["Action"])
                        }
                    />
                );
            },
        },
        {
            indexOFColumn: 2,
            columnName: t("Before edit"),

            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        e.selectedRow["Action"] === "Modification"
                            ? ListItemModificationBeforeValues(
                                  e.selectedRow["details"]
                              )
                            : e.selectedRow["Action"] === "Archiving"
                            ? `Status : ${t("Actived")}`
                            : e.selectedRow["Action"] === "Unarchiving"
                            ? `Status : ${t("Archived")}`
                            : e.selectedRow["Action"] === "Activation"
                            ? `Etat : ${t("Desactived")}`
                            : e.selectedRow["Action"] === "Deactivation"
                            ? `Etat : ${t("Actived")}`
                            : e.selectedRow["Action"] === "Creation"
                            ? ""
                            : e.selectedRow["Action"] === "Assignment"
                            ? ""
                            : e.selectedRow["Action"] === "Reassignment"
                            ? ""
                            : t(e.selectedRow["Before"])
                    }
                />
            ),
        },

        {
            indexOFColumn: 3,
            columnName: t("After edit"),

            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        e.selectedRow["Action"] === "Modification"
                            ? ListItemModificationAfterValues(
                                  e.selectedRow["details"]
                              )
                            : e.selectedRow["Action"] === "Reassignment" ||
                              e.selectedRow["Action"] === "Assignment"
                            ? ""
                            : e.selectedRow["Action"] === "Archiving"
                            ? `Status : ${t("Archived")}`
                            : e.selectedRow["Action"] === "Unarchiving"
                            ? `Status : ${t("Actived")}`
                            : e.selectedRow["Action"] === "Activation"
                            ? `Etat : ${t("Actived")}`
                            : e.selectedRow["Action"] === "Deactivation"
                            ? `Etat : ${t("Desactived")}`
                            : e.selectedRow["Action"] === "Creation"
                            ? e.selectedRow["Printer"]
                            : e.selectedRow["Action"] === "Dissociation"
                            ? ""
                            : e.selectedRow["After"]
                    }
                />
            ),
        },

        {
            indexOFColumn: 4,
            columnName: t("Operator"),

            customJsx: (e: any) => <div>{e.selectedRow[t("Operator")]}</div>,
        },
    ];

    return (
        <StyledModal
            toggle={true}
            isOpen={isModalOpened}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <StyledH2 className="text-uppercase printer_txt_HStitleHistory">
                    {t("History")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <div
                    className={`${classnames("title__clz", {
                        "not-allowed-icon__clz": des === "",
                    })}`}
                >
                    {t("Printer name") + " "} :{des}{" "}
                </div>
                <DynamicTable
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    url={url}
                    setSelectedRows={setSelectedRows}
                    canResize
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    actionColumn={ActionColumn}
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight="500px"
                    name="vatRateHistory"
                    setData={setData}
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomMainColorButton
                    className="printer_btn_HSclose"
                    variant="primary"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Close")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
