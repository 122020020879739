import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    CheckboxIcon,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import NumericInput from "react-numeric-input";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import {
    store as device,
    setIsActionsClicked,
    setActions,
    setIsEdited,
    setIsPlusIconClicked,
} from "./store";

import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type ModeOfSaleFormType = {
    isAddZone: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;

    selectedRows: any;
    setLocalFilterActive: Function;
    setIsAddZone: Function;

    isAddNewDeviseButtonClicked: boolean;
    setSelectedRows: Function;
    selectedRowShops: any;
    list: any;
    dataLivraison: any;
    setDataLivraison:Function
};

export default function ZoneForm({
    setIsAddZone,
    isAddZone,
    setDataIsUpdated,
    selectedRows,
    setLocalFilterActive,
    isAddNewDeviseButtonClicked,
    setSelectedRows,
    selectedRowShops,
    list,
    dataLivraison,
    setDataLivraison
}: ModeOfSaleFormType) {
    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const { isActionsClicked, isEdited, isPlusIconClicked } = useSnapshot(
        device
    );

    const [
        isClickedBouttonValider,
        setIsClickedBouttonValider,
    ] = React.useState<boolean>(true);
    const [isDeliveryNull, setIsDeliveryNull] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [selectCountry, setSelectCountry] = React.useState<boolean>(false);
    const [tva, setTva] = React.useState<string>("");

    const [shippingCosts, setShippingCosts] = React.useState<any>(0);
     const [isNullMinimumAmount, setIsNullMinimumAmount] = React.useState<boolean>(
      false
      );
    const [inputTva, setInputTva] = React.useState<boolean>(false);
      const [isOverlap, setIsOverlap] = React.useState<boolean>(false);

    const [remoteDebut, setRemoteDebut] = React.useState<number>(0);
    const [endDistance, setEndDistance] = React.useState<number>(0);
    const [countryData, setCountryData] = React.useState<string>("");
    const [code, setCode] = React.useState<any[]>([]);
    const numberMatchWord = new RegExp(/^\d+(,\d+)?(\.\d+)?$/);
    const [listTva, setListTva] = React.useState<any[]>([]);

    const [title, setTitle] = React.useState<string>("");
    const [isValidationNumber, setIsValidationNumber] = React.useState<boolean>(
        false
    );
    const [
        isValidationtMinimumAmount,
        setIsValidationtMinimumAmount,
    ] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    const [isDistanceExist, setIsDistanceExist] = React.useState<boolean>(
        false
    );
    const [isDistanceEdited, setIsDistanceEdited] = React.useState<boolean>(
        false
    );
    const [minimumAmount, setMinimumAmount] = React.useState<any>(0);

    const dataFetch = async () => {
        setListTva(
            selectedRowShops[0]["list Of VatRate"].map((el: any) => {
                return {
                    label: el.value,
                    value: el.id,
                };
            })
        );
    };

    function listDistance() {
        let listEndDistance: any[] = [];

        dataLivraison.stZoneLivraison.forEach((element: any) => {
            listEndDistance.push({
                end: element["FinDistance"],
                debut: element["DebutDistance"],
            });
        });
        return listEndDistance;
    }
    let listDistanceZone: any = listDistance();

    const CountryInformation = async () => {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/code/pays?pays=${countryData}`;

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                        "Accept-Language": i18nextLng,
                    },

                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error!");
                        }
                        setCode(
                            data.data.map((el: any) => {
                                return {
                                    label: el.code_post,
                                    value: el.nom_commune,
                                    code: el.pays,
                                    pays: el.nom_fr_fr,
                                };
                            })
                        );
                    })
            );
        } catch (e: any) {}
    };

    let schema: any = localStorage.getItem("schemaFranchise");

    function removeLeadingZeros(number: string): number {
         let result: string = parseInt(number, 10).toString();
         if (result.includes(".")) {
             return parseFloat(result);
         } else {
             return parseInt(result, 10);
         }
    }

    async function addZone() {
        let arrayCountry: any[] = [];
        dataLivraison.tabPaysLivrable > 0 &&
            dataLivraison.tabPaysLivrable.forEach((el: any) => {
                arrayCountry.push(el.Nom);
            });
        const isCountryInArray = dataLivraison.tabPaysLivrable.some(
            (obj: any) => obj.Nom === countryData
        );
        let idTva: any;
        let codePays: any;
        if (tva === "20") {
            idTva = "2";
        } else if (tva === "10") {
            idTva = "1";
        } else if (tva === "5.5") {
            idTva = "3";
        } else {
            listTva.forEach((el: any) => {
                if (
                    el.value !== "20" ||
                    el.value !== "10" ||
                    el.value !== "5.5"
                ) {
                    idTva = el.id;
                }
            });
        }
        code.forEach((el: any) => {
            if (countryData === el.pays) {
                codePays = el.code;
            }
        });

        let dataZone: any = {
            DebutDistance: remoteDebut,
            FinDistance: endDistance,
            MinCommande: minimumAmount,
            FraisLivraison: shippingCosts,
            idTva: idTva,
            TauxTva: tva,
            detailsZone: `\u001bPG+De ${remoteDebut}km à ${endDistance}km \u001bPG-: Frais liv : ${shippingCosts} € \u001bPG- - Min CMD : ${minimumAmount} €`,
            infoZone: `\u001bPG+De ${remoteDebut}km à ${endDistance}km :\u001bPG- (Frais de liv:${shippingCosts} € -Min commande:${minimumAmount}€)`,
        };     

        dataLivraison.stZoneLivraison.push(dataZone);

        if (!isCountryInArray) {
            dataLivraison.tabPaysLivrable.push({
                Nom: countryData,
                Refpays: codePays,
            });
        }
        dataLivraison.LivraisonVille = false;
        dataLivraison.Livraisonzone = true;
        setIsClickedBouttonValider(false);
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/list/add/city/c&c`;

        let data = {
            shopId: selectedRowShops[0].shopId,
            schemaFranchise: schema,

            data: dataLivraison,
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };

        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            setDataIsUpdated!(true);
                            throw Error("error");
                        }
                        toast.success(`${t("Area added successfully")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        });
                        setDataLivraison(dataLivraison);
                        setIsLoading(false);
                        setShippingCosts(0);
                        selectedRowShops[0][
                            t("minimum price")
                        ] = JSON.stringify(dataLivraison);
                        setRemoteDebut(0);
                        setEndDistance(0);
                        setMinimumAmount(0);
                        setCountryData("");
                        setDataIsUpdated!(true);
                        setIsEdited(false);
                        setLocalFilterActive(false);
                        setTva("");
                        clearData();
                        clearMultiCustomSelectionData();

                        setIsClickedBouttonValider(true);
                    })
            );
        } catch (e) {
                    setIsLoading(false);
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function editZone() {
        if (
            !isEdited ||
            (isDistanceEdited &&
                selectedRows.length > 0 &&
                selectedRows[0][t("remote Debut")] === remoteDebut &&
                selectedRows[0][t("end Distance")] === endDistance)
        ) {
            setIsModalOpened(true);
        } else {
            let idTva: any;
            if (tva === "20") {
                idTva = "2";
            } else if (tva === "10") {
                idTva = "1";
            } else if (tva === "5.5") {
                idTva = "3";
            } else {
                listTva.forEach((el: any) => {
                    if (
                        el.value !== "20" ||
                        el.value !== "10" ||
                        el.value !== "5.5"
                    ) {
                        idTva = el.id;
                    }
                });
            }

            dataLivraison.stZoneLivraison.forEach((element: any) => {
                if (
                    selectedRows.length > 0 &&
                    selectedRows[0][t("remote Debut")] ===
                        element["DebutDistance"] &&
                    selectedRows[0][t("end Distance")] ===
                        element["FinDistance"]
                ) {
                    element["DebutDistance"] = remoteDebut;
                    element["FinDistance"] = endDistance;
                    element["MinCommande"] = minimumAmount;
                    element["FraisLivraison"] = shippingCosts;
                    element["idTva"] = idTva;
                    element["TauxTva"] = tva;
                    element[
                        "detailsZone"
                    ] = `\u001bPG+De ${remoteDebut}km à ${endDistance}km \u001bPG-: Frais liv : ${shippingCosts} € \u001bPG- - Min CMD : ${minimumAmount} €`;
                    element[
                        "infoZone"
                    ] = `\u001bPG+De ${remoteDebut}km à ${endDistance}km :\u001bPG- (Frais de liv:${shippingCosts} € -Min commande:${minimumAmount}€)`;
                }
            });
            setIsClickedBouttonValider(false);
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/list/add/city/c&c`;

            let data = {
                shopId: selectedRowShops[0].shopId,
                schemaFranchise: schema,

                data: dataLivraison,
            };

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };

            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                setDataIsUpdated!(true);
                                throw Error("error");
                            }
                            toast.success(
                                `${t("modification made successfully")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsLoading(false);
                            setCountryData("");
                            setSelectedRows([]);
                            setDataIsUpdated!(true);
                            setIsEdited(false);
                            setLocalFilterActive(false);
                            setShippingCosts(0);
                            selectedRowShops[0][
                                t("minimum price")
                            ] = JSON.stringify(dataLivraison);
                            setRemoteDebut(0);
                            setEndDistance(0);
                            setMinimumAmount(0);
                            setIsAddZone(!isAddZone);
                            clearData();
                            clearMultiCustomSelectionData();

                            setInputTva(false);
                            setIsValidationNumber(false);
                            setIsValidationtMinimumAmount(false);
                            setSelectCountry(false);
                            setSelectCountry(false);
                            setIsPlusIconClicked(false);

                            setIsClickedBouttonValider(true);
                        })
                );
            } catch (e) {
                        setIsLoading(false);
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    let countryShop: any[] = [
        {
            label: selectedRowShops[0]["Country shop"],
            value: selectedRowShops[0]["Country shop"],
        },
    ];

    React.useEffect(() => {
        CountryInformation();
        //eslint-disable-next-line
    }, [countryData]);

    useEffect(() => {
        if (selectedRows.length > 0) {
            setTitle(t("Modify an area"));
            setCountryData(selectedRowShops[0]["Country shop"]);
            setRemoteDebut(selectedRows[0][t("remote Debut")]);
            setEndDistance(selectedRows[0][t("end Distance")]);
            setTva(
                list.includes(selectedRows[0]["Tva"].toString())
                    ? selectedRows[0]["Tva"]
                    : ""
            );
            setMinimumAmount(
                selectedRows[0][t("Minimum amount to place an order")]
            );
            setShippingCosts(selectedRows[0][t("Shipping costs")]);
        } else {
            setTitle(t("Add a delivery zone"));

            setShippingCosts(0);

            setRemoteDebut(0);
            setEndDistance(0);
            setMinimumAmount(0);
            setCountryData("");
            setTva("");
            setIsEdited(false);

            setIsClickedBouttonValider(true);
        }

        // eslint-disable-next-line
    }, [isAddNewDeviseButtonClicked, selectedRows, t]);

    React.useEffect(() => {
        dataFetch();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        clearData();

        clearMultiCustomSelectionData();
    }, [isAddZone]);

    React.useEffect(() => {
        let localOverlap: boolean = false;
        if (!isLoading) {
            for (let el of listDistanceZone) {
                if (
                    (selectedRows.length === 0 && isDistanceEdited) ||
                    (selectedRows.length > 0 &&
                        !(
                            remoteDebut ===
                                selectedRows[0][t("remote Debut")] &&
                            endDistance === selectedRows[0][t("end Distance")]
                        ) &&
                        isDistanceEdited)
                ) {
                    if (
                        (remoteDebut > el.debut && remoteDebut < el.end) ||
                        (remoteDebut === el.debut && endDistance !== 0) ||
                        (remoteDebut < el.debut && endDistance > el.end) ||
                        (endDistance > el.debut && endDistance < el.end) ||
                        (remoteDebut < el.end && endDistance === el.end)
                    ) {
                        localOverlap = true;
                    }
                } else {
                    localOverlap = false;
                }
            }
        }

        setIsOverlap(localOverlap);
        // eslint-disable-next-line
    }, [endDistance, remoteDebut, selectedRows, listDistanceZone,isDistanceEdited,isLoading,t]);

    React.useEffect(() => {
        if (isPlusIconClicked || selectedRows.length > 0) {
            setSelectCountry(false);
            setInputTva(false);
            setIsDistanceExist(false);
            setIsPlusIconClicked(false);
        }
        if (remoteDebut !== 0 || endDistance !== 0) {
            setIsDistanceExist(false);
        }
       
    }, [isPlusIconClicked, selectedRows, remoteDebut, endDistance]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3 dev_txt_FRtitleFormCurrency">
                        {title}
                    </h5>
                    <CrossIcon
                        className="icon-close-notification__clz dev_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    // setDevises([]);
                                    setIsAddZone(!isAddZone);

                                    setIsEdited(false);
                                    clearData();
                                    clearMultiCustomSelectionData();
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setSelectedRows([]);
                                setIsAddZone(!isAddZone);
                                setIsEdited(false);
                                clearData();
                                clearMultiCustomSelectionData();
                                setDataIsUpdated!(true);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className="pl-3 pr-5"
                    style={{
                        maxHeight: "520px",
                        overflowY: " scroll",
                    }}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Country")}
                        </StyledLabel>

                        <StyledSelectInput
                            isClearable
                            autoFocus
                            noOptionsMessage={() => t("No options")}
                            options={countryShop}
                            autocomplete="off"
                            className={classnames("", {
                                input__clz: selectCountry,
                            })}
                            name="change"
                            onChange={(e: any) => {
                                if (e !== null) {
                                    setCountryData(e.label);
                                } else {
                                    setCountryData("");
                                }
                                setSelectCountry(false);
                                setIsEdited(true);

                                setIsClickedBouttonValider(true);
                            }}
                            value={
                                countryData === ""
                                    ? null
                                    : {
                                          label: countryData,
                                          value: countryData,
                                      }
                            }
                            placeholder={`${t("Select")}…`}
                            type="text"
                        />
                        {selectCountry ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a country")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Delivery area (in Km)")}
                        </StyledLabel>
                        <div className="d-flex justify-content-between ml-3">
                            <span
                                style={{
                                    top: "499px",
                                    left: "1304px",
                                    height: "21px",
                                    textAlign: "left",
                                    font:
                                        "normal normal normal 16px/21px Segoe UI",

                                    color: "#2B2828",
                                    opacity: 1,
                                }}
                                className="px-1 mt-2"
                            >
                                {t("Of")}
                            </span>
                            <NumericInput
                                className={classnames(
                                    "form-control-input-zone__clz",
                                    {
                                        input__clz:
                                            isDistanceExist || isOverlap,
                                        // readOnly__clz: isConsult,
                                    }
                                )}
                                size={25}
                                min={0}
                                max={5000}
                                step={1}
                                onChange={(num: number | null) => {
                                    if (num !== null) {
                                        setRemoteDebut(num);
                                        setIsEdited(true);
                                        setIsDistanceEdited(true);
                                    }
                                }}
                                value={remoteDebut}
                            />

                            <span
                                className="ml-2 mt-2 px-1"
                                style={{
                                    top: "499px",
                                    left: "1304px",
                                    width: "25px",
                                    height: "21px",

                                    textAlign: "left",
                                    font:
                                        "normal normal normal 16px/21px Segoe UI",

                                    color: "#2B2828",
                                    opacity: 1,
                                }}
                            >
                                {t("at")}
                            </span>
                            <NumericInput
                                className={classnames(
                                    "form-control-input-zone__clz",
                                    {
                                        input__clz:
                                            isDistanceExist ||
                                            isOverlap ||
                                            remoteDebut > endDistance,
                                    }
                                )}
                                size={25}
                                min={0}
                                max={5000}
                                step={1}
                                onChange={(num: number | null) => {
                                    setEndDistance(
                                        num !== null ? (num > 0 ? num : 0) : 0
                                    );
                                    setIsEdited(true);
                                    setIsDistanceEdited(true);
                                }}
                                value={endDistance}
                            />
                        </div>
                        {isDistanceExist || remoteDebut > endDistance ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                    marginLeft: "4rem",
                                }}
                            >
                                {t("Please enter a valid distance")}
                            </div>
                        ) : null}
                        {isOverlap === true ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                    marginLeft: "4rem",
                                }}
                            >
                                {t("Overlap detected")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("TVA")}
                        </StyledLabel>
                        <StyledSelectInput
                            isClearable
                            noOptionsMessage={() => t("No options")}
                            options={listTva}
                            autocomplete="off"
                            className={classnames("", {
                                input__clz: inputTva,
                                // readOnly__clz: isConsult,
                            })}
                            name="tva"
                            onChange={(e: any) => {
                                if (e !== null) {
                                    setTva(e.label);
                                } else {
                                    setTva("");
                                }

                                setIsEdited(true);
                                setInputTva(false);

                                setIsClickedBouttonValider(true);
                            }}
                            value={
                                tva === ""
                                    ? null
                                    : {
                                          label: tva,
                                          value: tva,
                                      }
                            }
                            placeholder={`${t("Select")}…`}
                            type="text"
                            maxMenuHeight="80px"
                        />
                        {inputTva ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a vat rate")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="Shipping">
                            {t("Shipping costs")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            id="remark"
                            name="remark"
                            value={shippingCosts.toString()}
                            type="text"
                            onChange={(e: any) => {
                                setIsClickedBouttonValider(true);
                                if (e.target.value.match(numberMatchWord)) {
                                    setIsDeliveryNull(false);
                                    setShippingCosts(
                                        removeLeadingZeros(e.target.value)
                                    );
                                    setIsValidationNumber(false);
                                } else {
                                    setIsValidationNumber(true);
                                    setIsDeliveryNull(false);
                                }
                                if (e.target.value === "") {
                                    setShippingCosts(0);
                                    setIsDeliveryNull(true);
                                    setIsValidationNumber(false);
                                }
                                setIsEdited!(true);
                            }}
                            className={classnames("", {
                                input__clz:
                                    isValidationNumber || isDeliveryNull,
                            })}
                        />

                        {isValidationNumber && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please insert a number")!}
                            </div>
                        )}
                        {isDeliveryNull && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please insert a delivery charge")!}
                            </div>
                        )}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="remark">
                            {t("Minimum amount to place an order")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            id="commande"
                            name="commande"
                            type="text"
                            onChange={(e: any) => {
                                setIsClickedBouttonValider(true);

                                if (e.target.value.match(numberMatchWord)) {
                                    setMinimumAmount(
                                        removeLeadingZeros(e.target.value)
                                    );
                                    setIsNullMinimumAmount(false);
                                    setIsValidationtMinimumAmount(false);
                                } else {
                                    setIsNullMinimumAmount(false);
                                    setIsValidationtMinimumAmount(true);
                                }
                                if (e.target.value === "") {
                                    setMinimumAmount(0);
                                    setIsNullMinimumAmount(true);
                                    setIsValidationtMinimumAmount(false);
                                }

                                setIsEdited!(true);
                            }}
                            value={minimumAmount.toString()}
                            className={classnames("", {
                                input__clz:
                                    isValidationtMinimumAmount ||
                                    isNullMinimumAmount,
                            })}
                        />

                        {isValidationtMinimumAmount && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please insert a number")!}
                            </div>
                        )}
                        {isNullMinimumAmount && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please insert a Minimum Amount")!}
                            </div>
                        )}
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 dev_btn_FRcancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setIsAddZone(!isAddZone);
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setIsAddZone(!isAddZone);
                                setDataIsUpdated!(true);
                            }
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className="dev_btn_FRvalidate"
                        disabled={!isClickedBouttonValider}
                        onClick={() => {
                            if (countryData === "") setSelectCountry(true);
                            if (tva === "") setInputTva(true);
                            if (remoteDebut === 0 && endDistance === 0)
                                setIsDistanceExist(true);
                            if (shippingCosts === "") {
                                setIsDeliveryNull(true);
                            }
                            if (minimumAmount === "") {
                                setIsNullMinimumAmount(true);
                            }

                            if (
                                countryData !== "" &&
                                tva !== "" &&
                                endDistance !== 0 &&
                                !isOverlap &&
                                !isValidationtMinimumAmount &&
                                !isValidationNumber &&
                                !isNullMinimumAmount &&
                                !isDeliveryNull
                            ) {
                                  setIsLoading(true);
                                if (selectedRows.length === 0) {            
                                    addZone();
                                } else {
                                    editZone();
                                }
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>

            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddZone}
                setIsAddClicked={setIsAddZone}
                setEditedData={setSelectedRows}
            />
        </React.Fragment>
    );
}
