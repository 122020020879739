import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import {
    CheckboxIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";
import { uuid } from "uuidv4";

import store, {
    setArchivedData,
    setGlobalDataIsUpdated,
    setIsArchiveModalOpened,
    setIsHistoryModalOpened,
    setSelectedPaymentMethods,
    setSelectedHistoryPaymentMethods,
    setIsInputUpdated,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setIsLoading,
    setKey,
} from "../store";
import { store as storeData } from "@components/VerticalLayout/store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ErrorToast from "@components/Common/ErrorTost";
import { ArchivedValidationModal } from "@components/Common/ArchivedValidationModal";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import {
    setActivationDeactivationMessageModalOpened,
    setMessage,
    setYesFunction,
} from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";
import { generalConfigStore, setItemsDisable } from "@store";

type CustomIconsType = {
    selectedRows: any;
    selectedRowData: any;
    setPaymentMethod: Function;
    userData: {
        userId: number;
        shopId: number | string;
        franchiseId: string;
        operatorID: string;
        uuidUser: any;
    };
    setIsAddChoiceClicked: Function;
    setIsModalConfirmationOpened: Function;
    isModalConfirmationOpened: boolean;
    setSelectedRows: Function;
    isArchivedPaymentMethodsClicked: boolean;
    setIsConsult: Function;
};

export function CustomIcons({
    selectedRowData,
    setPaymentMethod,
    userData,
    setIsAddChoiceClicked,
    setIsModalConfirmationOpened,
    isModalConfirmationOpened,
    setSelectedRows,
    isArchivedPaymentMethodsClicked,
    setIsConsult,
}: CustomIconsType) {
    const { t } = useTranslation();
    const { oneShop, shopID, operatorID, userID, franchiseID } = useSnapshot(
        storeData
    );
    const {
        isInputUpdated,
        isArchiveModalOpened,
        isHistoryModalOpened,
        shopsPaymentMethodsData,
        listFranchiseArchived,
        isActionsClicked,
        isEdited,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { itemsDisable } = useSnapshot(generalConfigStore);

    const [choiceState, setPaymentMethodState] = React.useState<boolean>(
        selectedRowData.selectedRow.isActive
    );
    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        false
    );
    const [
        isArchivedValidationModalOpened,
        setIsArchivedValidationModalOpened,
    ] = React.useState<boolean>(false);
    const shopId = oneShop ? shopID : 0;
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const handleTrashButtonClicked = async (selectedRow: any) => {
        let idsAssociated: any = [];
        shopsPaymentMethodsData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.id);
            });
        });

        if (
            selectedRow.idShop === "0" &&
            idsAssociated.includes(selectedRow.id)
        ) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
            setIsLoading(false);
        } else {
            let isConsumed = await isInformationModeConsumed();
            if (isConsumed === true) {
                ErrorToast(
                    t(
                        "Please note, you cannot archive an element used in a template"
                    )
                );
                setIsLoading(false);
            } else {
                setIsArchivedValidationModalOpened(
                    !isArchivedValidationModalOpened
                );
            }
        }
    };

    async function isInformationModeConsumed() {
        console.log({ selectedRowData });
        toast.dismiss();
        let isInformationModeUsed = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/consumedSettingGeneral?shopId=${selectedRowData.selectedRow.idShop}&franchiseId=${franchiseID}&type=meansOfPayment`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (
                        result.data.some(
                            (el: any) =>
                                el.id === selectedRowData.selectedRow.id
                        )
                    ) {
                        isInformationModeUsed = true;
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isInformationModeUsed;
    }

    async function handleStateChange(state: any) {
        setIsLoading(true);
        let dataConfig = {
            userId: userData.userId,
            shopId: [selectedRowData.selectedRow.idShop],
            franchiseId: userData.franchiseId,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [selectedRowData.selectedRow.id]: {
                    designation:
                        selectedRowData.selectedRow[
                            t("Type of payment method")
                        ],
                    currency: selectedRowData.selectedRow.Devise,
                    display: selectedRowData.selectedRow[t("Display name")],
                    saleSupport:
                        selectedRowData.selectedRow[t("Support de vente")],
                    shopId: userData.shopId,
                    languages: selectedRowData.selectedRow.languages,
                    country: selectedRowData.selectedRow[t("Country")],
                    tag: selectedRowData.selectedRow.Tag,
                    isActive: state,
                    isArchived: selectedRowData.selectedRow.isArchived,
                    advancedDisplayName:
                        selectedRowData.selectedRow.advancedDisplayName,
                },
            },
        };

        const isStateChange = true;
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ dataConfig, isStateChange }),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }

                    toast.success(
                        state === true
                            ? `${t(
                                  "Payment method has been successfully activated"
                              )}`
                            : `${t(
                                  "Payment method has been successfully deactivated"
                              )}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsLoading(false);
                    setKey(uuid());
                    setGlobalDataIsUpdated(true);
                    if (
                        !choiceState ===
                        false
                    ) {
                        const item = [
                            ...JSON.parse(
                                JSON.stringify(
                                    itemsDisable
                                )
                            ),
                            {
                                id:
                                    selectedRowData
                                        .selectedRow
                                        .id,
                                state: !choiceState,
                                shopId:
                                    selectedRowData
                                        .selectedRow
                                        .idShop,
                            },
                        ];
                        setItemsDisable(
                            item
                        );
                        localStorage.setItem(
                            "itemsDisable",
                            JSON.stringify(
                                item
                            )
                        );
                    } else if (
                        !choiceState ===
                        true
                    ) {
                        const item = itemsDisable.filter(
                            (item: any) =>
                             !(   item.id ===
                                selectedRowData
                                    .selectedRow
                                    .id && item.shopId === selectedRowData
                                    .selectedRow
                                    .idShop
                        ));
                        setItemsDisable(
                            item
                        );
                        localStorage.setItem(
                            "itemsDisable",
                            JSON.stringify(
                                item
                            )
                        );
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setKey(uuid());
                    setIsLoading(false);
                })
        );
    }

    async function handleActivePaymentMethod(selectedRowData: any) {
        let dataConfig = {
            userId: userData.userId,
            shopId: [selectedRowData.selectedRow.idShop],
            franchiseId: userData.franchiseId,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [selectedRowData.selectedRow.id]: {
                    designation:
                        selectedRowData.selectedRow[
                            t("Type of payment method")
                        ],
                    currency: selectedRowData.selectedRow.Devise,
                    display: selectedRowData.selectedRow[t("Display name")],
                    languages: selectedRowData.selectedRow.languages,
                    shopId: selectedRowData.selectedRow.idShop,
                    saleSupport:
                        selectedRowData.selectedRow[t("Support de vente")],
                    country: selectedRowData.selectedRow[t("Country")],
                    tag: selectedRowData.selectedRow.Tag,
                    isActive:
                        selectedRowData.selectedRow.idShop === "0"
                            ? true
                            : false,
                    isArchived: false,
                    advancedDisplayName:
                        selectedRowData.selectedRow.advancedDisplayName,
                },
            },
        };
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t("The Payment method is successfully unarchived")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsLoading(false);
                    setKey(uuid());
                    setGlobalDataIsUpdated(true);
                    if (selectedRowData.selectedRow.idShop !== "0") {
                        if (
                            selectedRowData.selectedRow.isActive === false &&
                            itemsDisable.find(
                                (item: any) =>
                                    item.id ===
                                        selectedRowData.selectedRow.id &&
                                    item.shopId ===
                                        selectedRowData.selectedRow.idShop
                            ) === undefined
                        ) {
                            const item = [
                                ...JSON.parse(JSON.stringify(itemsDisable)),
                                {
                                    id: selectedRowData.selectedRow.id,
                                    state: false,
                                    shopId: selectedRowData.selectedRow.idShop,
                                },
                            ];
                            setItemsDisable(item);
                            localStorage.setItem(
                                "itemsDisable",
                                JSON.stringify(item)
                            );
                        }
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsLoading(false);
                    setKey(uuid());
                })
        );
    }

    async function handleArchivePaymentMethod(element: any) {
        setArchivedData(selectedRowData);
        setIsLoading(true);
        setIsArchiveModalOpened!(!isArchiveModalOpened);
        let dataConfig = {
            userId: userData.userId,
            shopId: [element.idShop],
            franchiseId: userData.franchiseId,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [element.id]: {
                    designation: element[t("Type of payment method")],
                    currency: element.Devise,
                    display: selectedRowData.selectedRow[t("Display name")],
                    saleSupport:
                        selectedRowData.selectedRow[t("Support de vente")],
                    shopId: element.idShop,
                    country: element[t("Country")],
                    tag: element.Tag,
                    languages: element.languages,
                    isActive: element.isActive,
                    isArchived: true,
                    advancedDisplayName:
                        selectedRowData.selectedRow.advancedDisplayName,
                },
            },
        };
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t("The Payment method is successfully archived")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsLoading(false);
                    setKey(uuid());
                    setGlobalDataIsUpdated(true);
                    setIsValidateClicked(false);
                    setIsArchivedValidationModalOpened(false);
                    const item = itemsDisable.find(
                        (item: any) =>
                            item.id === selectedRowData.selectedRow.id &&
                            item.shopId === selectedRowData.selectedRow.idShop
                    );
                    if (item !== undefined) {
                        const item = itemsDisable.filter(
                            (item: any) =>
                                !(
                                    item.id ===
                                        selectedRowData.selectedRow.id &&
                                    item.shopId ===
                                        selectedRowData.selectedRow.idShop
                                )
                        );
                        setItemsDisable(item);
                        localStorage.setItem(
                            "itemsDisable",
                            JSON.stringify(item)
                        );
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsLoading(false);
                    setKey(uuid());
                })
        );
    }

    async function isSaleSupportArchived() {
        toast.dismiss();
        let isSaleSupportArchived = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=archived`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            })
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((elt: any) => {
                        if (
                            selectedRowData.selectedRow[t("Sales support")]
                                .split(",")
                                .includes(elt[t("Sale support")])
                        ) {
                            isSaleSupportArchived = true;
                        }
                    });
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isSaleSupportArchived;
    }

    let dataArchive: any[] = [];

    if (!oneShop) {
        dataArchive = ArchivedPaymentMethod();
    } else {
        dataArchive = ArchivedPaymentMethodFranchise();
    }

    function ArchivedPaymentMethodFranchise() {
        let idsAssociated: any[] = [];
        if (shopsPaymentMethodsData !== undefined) {
            shopsPaymentMethodsData.forEach((element: any) => {
                idsAssociated.push(element.id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function ArchivedPaymentMethod() {
        let idsAssociated: any[] = [];
        if (shopsPaymentMethodsData !== undefined) {
            Object.values(shopsPaymentMethodsData).forEach((element: any) => {
                element?.subRows.forEach((el: any) => {
                    idsAssociated.push(el.id);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    React.useEffect(() => {
        if (isValidateClicked) {
            handleArchivePaymentMethod(selectedRowData.selectedRow);
        }
        // eslint-disable-next-line
    }, [isValidateClicked, selectedRowData]);

    return (
        <React.Fragment>
            <div className="d-flex justify-content-around">
                {modify === false ? (
                    <Tooltip title={t("To consult")}>
                        <div>
                            {" "}
                            <ConsultSvgIcon
                                className="pointer__clz afa_icn_toConsult"
                                height={35}
                                width={35}
                                style={{
                                    cursor: "pointer",
                                    position: "relative",
                                    bottom: "5px",
                                }}
                                fill="black"
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            clearData();
                                            clearMultiCustomSelectionData();
                                            setPaymentMethod(
                                                selectedRowData.selectedRow
                                            );
                                        });
                                    } else {
                                        setPaymentMethod(
                                            selectedRowData.selectedRow
                                        );
                                        setIsAddChoiceClicked(true);
                                        setIsInputUpdated(!isInputUpdated);
                                    }
                                    setIsConsult(true);
                                }}
                            />
                        </div>
                    </Tooltip>
                ) : null}
                {modify === true ? (
                    <>
                        <Tooltip
                            title={
                                isArchivedPaymentMethodsClicked ? "" : t("Edit")
                            }
                        >
                            <div>
                                <PencilIcon
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                clearData();
                                                clearMultiCustomSelectionData();
                                                setPaymentMethod(
                                                    selectedRowData.selectedRow
                                                );
                                            });
                                        } else {
                                            setPaymentMethod(
                                                selectedRowData.selectedRow
                                            );
                                            setIsAddChoiceClicked(true);
                                            setIsInputUpdated(!isInputUpdated);
                                        }
                                    }}
                                    height={25}
                                    width={25}
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        "pointer__clz cmn_icn_FRedit",
                                        {
                                            "not-allowed-icon__clz":
                                                isArchivedPaymentMethodsClicked ||
                                                isLoading,
                                        },
                                        oneShop ? "mr-2" : "ml-3"
                                    )}`}
                                />
                            </div>
                        </Tooltip>
                        {selectedRowData.selectedRow.idShop !== "0" ? (
                            <Tooltip
                                title={
                                    isArchivedPaymentMethodsClicked
                                        ? ""
                                        : choiceState === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={choiceState}
                                        onChange={async (e: boolean) => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    setIsAddChoiceClicked(
                                                        false
                                                    );
                                                    setMessage(
                                                        choiceState
                                                            ? t(
                                                                  "Caution, the Payment method may be used by other modules.\n Would you like to confirm the deactivation of the selected Payment method?"
                                                              )
                                                            : t(
                                                                  "Would you like to confirm the activation of the selected Payment method?"
                                                              )
                                                    );
                                                    setYesFunction(() => {
                                                        setPaymentMethodState(
                                                            !choiceState
                                                        );
                                                        handleStateChange(e);
                                                     
                                                    });

                                                    setActivationDeactivationMessageModalOpened(
                                                        true
                                                    );
                                                });
                                            } else {
                                                setIsAddChoiceClicked(false);
                                                setMessage(
                                                    choiceState
                                                        ? t(
                                                              "Caution, the Payment method may be used by other modules.\n Would you like to confirm the deactivation of the selected Payment method?"
                                                          )
                                                        : t(
                                                              "Would you like to confirm the activation of the selected Payment method?"
                                                          )
                                                );
                                                setYesFunction(() => {
                                                    setPaymentMethodState(
                                                        !choiceState
                                                    );
                                                    handleStateChange(e);
                                                 
                                                });

                                                setActivationDeactivationMessageModalOpened(
                                                    true
                                                );
                                            }
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "cmn_icn_BTenableDisable",
                                            {
                                                "not-allowed-icon__clz":
                                                    isArchivedPaymentMethodsClicked ||
                                                    isLoading,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                        ) : null}

                        {!isArchivedPaymentMethodsClicked ? (
                            <Tooltip title={t("Archive")}>
                                <div>
                                    <TrashIcon
                                        onClick={async () => {
                                            setIsLoading(true);
                                            if (isEdited === true) {
                                                setIsLoading(false);
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(async () => {
                                                    setIsAddChoiceClicked(
                                                        false
                                                    );
                                                    setIsEdited(false);
                                                    handleTrashButtonClicked(
                                                        selectedRowData.selectedRow
                                                    );
                                                    setPaymentMethod([]);
                                                    setIsAddChoiceClicked(
                                                        false
                                                    );
                                                    clearData();
                                                    clearMultiCustomSelectionData();
                                                });
                                            } else {
                                                setIsLoading(false);
                                                setIsAddChoiceClicked(false);
                                                handleTrashButtonClicked(
                                                    selectedRowData.selectedRow
                                                );
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        fill="red"
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz  cmn_icn_FRarchived",
                                            {
                                                "not-allowed-icon__clz":
                                                    isArchivedPaymentMethodsClicked ||
                                                    isLoading,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                        ) : (
                            <Tooltip
                                title={
                                    shopID !== "0" &&
                                    listFranchiseArchived.find(
                                        (el: any) =>
                                            el.id ===
                                            selectedRowData.selectedRow.id
                                    ) !== undefined
                                        ? ""
                                        : t("Unarchive")
                                }
                            >
                                <div>
                                    <EyeIcon
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        onClick={async () => {
                                            setIsLoading(true);
                                            setSelectedRows([
                                                selectedRowData.selectedRow,
                                            ]);
                                            let isArchived = await isSaleSupportArchived();

                                            if (isArchived === true) {
                                                ErrorToast(
                                                    `${t(
                                                        "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                                    )!}`
                                                );
                                                setIsLoading(false);
                                            } else if (
                                                dataArchive.includes(
                                                    selectedRowData.selectedRow
                                                        .id
                                                ) &&
                                                !oneShop
                                            ) {
                                                setIsModalConfirmationOpened(
                                                    !isModalConfirmationOpened
                                                );
                                            } else {
                                                handleActivePaymentMethod(
                                                    selectedRowData
                                                );
                                            }
                                        }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRunarchive",
                                            {
                                                "not-allowed-icon__clz":
                                                    (shopID !== "0" &&
                                                        listFranchiseArchived.find(
                                                            (el: any) =>
                                                                el.id ===
                                                                selectedRowData
                                                                    .selectedRow
                                                                    .id
                                                        ) !== undefined) ||
                                                    isLoading,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </>
                ) : null}
                <Tooltip title={t("Consult history")}>
                    <div>
                        <TimePastSvgIcon
                            style={{
                                cursor: "pointer",
                            }}
                            className={`${classnames(
                                "pointer__clz  cmn_icn_FRhistory",
                                {
                                    "not-allowed-icon__clz": isLoading,
                                },
                                oneShop ? "mr-2" : "ml-3"
                            )}`}
                            onClick={(e: any) => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                        setIsEdited(false);
                                        setPaymentMethod([]);
                                        setIsAddChoiceClicked(false);
                                        clearData();
                                        clearMultiCustomSelectionData();
                                    });
                                    setSelectedHistoryPaymentMethods(
                                        selectedRowData.selectedRow
                                    );

                                    setSelectedPaymentMethods([
                                        {
                                            [selectedRowData.selectedRow
                                                .idShop]:
                                                selectedRowData.selectedRow.id,
                                        },
                                    ]);
                                } else {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                    setSelectedHistoryPaymentMethods(
                                        selectedRowData.selectedRow
                                    );

                                    setSelectedPaymentMethods([
                                        {
                                            [selectedRowData.selectedRow
                                                .idShop]:
                                                selectedRowData.selectedRow.id,
                                        },
                                    ]);
                                }
                            }}
                        />
                    </div>
                </Tooltip>
            </div>
            {isArchivedValidationModalOpened ? (
                <ArchivedValidationModal
                    text={t(
                        "Attention, this (these) payment method can be used by other modules.\n\nDo you want to confirm the archiving of the selected of payment method(s)"
                    )}
                    isModalOpened={isArchivedValidationModalOpened}
                    setIsModalOpened={setIsArchivedValidationModalOpened}
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
        </React.Fragment>
    );
}
