import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";

import { store } from "@components/VerticalLayout/store";
import {
    store as modeOfInformation,
    setNumberActive,
    setNumberArchived,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setGlobalDataIsUpdated,
    setIsLoading,
    setKey,
} from "./store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationDeleteMessageType = {
    e: any;
    setIsModalOpened: Function;
    isModalOpened: boolean;
    setDataIsUpdated: Function;
    selectedRows: any;
    isOneShop: boolean;
};

export function ConfirmationActiveModeOfInformation({
    e,
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    selectedRows,
    isOneShop,
}: ConfirmationDeleteMessageType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const [error, setError] = React.useState<boolean>(false);
    const {
        numberActive,
        numberArchived,
        numberActiveMultiShop,
        numberArchiveMultiShop,
        shopsModeInformationData,
    } = useSnapshot(modeOfInformation);
    const uuidUser = localStorage.getItem("uuidUser");

    function ArchiveModeOfInformation(e: any) {
        let idsAssociated = [0];
        if (shopsModeInformationData.data !== undefined) {
            shopsModeInformationData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        if (el.Id === e.Id) idsAssociated.push(el.shopId);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    async function activeModeInformation(isValidation: boolean) {
        setIsLoading(true);
        toast.dismiss();
        let localData: any[] = [];
        selectedRows.forEach((element: any) => {
            let shopsIdArchived = ArchiveModeOfInformation(element);
            if (element.Id !== undefined) {
                let dataModeInformation = {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: isValidation ? shopsIdArchived : [0],
                    franchiseId: franchiseID,
                    data: {
                        [element.Id]: {
                            designation: element[t("Mode of information")],
                            idMI: element?.idMI,
                            supportOfSale: element[t("Sales support")],
                            salesSupportData: [element[t("Sales support")]],
                            displayName: element[t("Display name")],
                            modeOfSale: element[t("Sales method")],
                            tag: element.tag,
                            isArchived: false,
                            state:
                                element.shopId === "0" ? true : element.state,
                            shopId: element.shopId,
                            stateShop: true,
                            advancedDisplayName: element.advancedDisplayName,
                        },
                    },
                };
                localData.push(dataModeInformation);
            }
        });
        if (localData.length > 0) {
            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_information`,
                    await fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_information`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(localData),
                        }
                    ).then((result) => console.log(result))
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
                setError(true);
            }
            if (error === false) {
                toast.success(
                    `${t("Information mode has been successfully unarchived")}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setIsLoading(false);
                setKey(uuid());
                setGlobalDataIsUpdated(true);
            }
        }
    }

    function CountArchiveAndActiveNumber() {
        isOneShop
            ? setNumberActive(
                  selectedRows.length !== 0
                      ? numberActive + selectedRows.length
                      : numberActive + 1
              )
            : setNumberActiveMultiShop(
                  selectedRows.length !== 0
                      ? numberActiveMultiShop + selectedRows.length
                      : numberActiveMultiShop + 1
              );
        isOneShop
            ? setNumberArchived(
                  selectedRows.length !== 0
                      ? numberArchived - selectedRows.length
                      : numberArchived - 1
              )
            : setNumberArchiveMultiShop(
                  selectedRows.length !== 0
                      ? numberArchiveMultiShop - selectedRows.length
                      : numberArchiveMultiShop - 1
              );
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setDataIsUpdated(true);
                    // setGlobalDataIsUpdated;
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Attention! the Mode d'information is archived at store level.\n\nDo you want to activate it in shops too?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        activeModeInformation(false);
                        CountArchiveAndActiveNumber();
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        activeModeInformation(true);
                        setIsModalOpened(!isModalOpened);
                        CountArchiveAndActiveNumber();
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
