import React from "react";
import { useSnapshot } from "valtio";
import {
    CheckIcon,
    StyledButton,
    StyledH2,
    StyledModal,
} from "@aureskonnect/react-ui";
import classNames from "classnames";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { t } from "i18next";

import {
    computedStore,
    generalConfigStore,
    setGeneralChosenTransition,
    setGeneralTransition,
    setIsAnimationIn,
    setIsSpecificTransitionChosen,
    setItems,
    store,
} from "@store";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { toCamelCase } from "@helpers/general";

type PropsType = {
    transition: string;
};

export function TransitionCardWrapper({ transition }: PropsType): JSX.Element {
    const {
        transitions,
        generalTransition,
        isSpecificTransitionChosen,
    } = useSnapshot(generalConfigStore);
    const { items } = useSnapshot(computedStore);
    const { mainColor } = useSnapshot(store);

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    function handleChooseGeneralTransitionOnClickEvent(transition: string) {
        setIsAnimationIn(false);

        setGeneralTransition(transitions[transition]);
        setGeneralChosenTransition(transition);
        setIsSpecificTransitionChosen(false);

        const newState = Object.values(items.ways).map(
            (
                item:
                    | PageType
                    | ConsommationPageType
                    | StandbyScreenPageType
                    | PaymentPageType
                    | OrderTakingPageType
                    | PrinterOptionsPageType
            ) => {
                return {
                    ...item,
                    animation: transition,
                };
            }
        );

        const newLocalProjectWays: ItemsType = {};

        newState.forEach((item) => {
            newLocalProjectWays[item.name] = { ...item };
        });

        setItems({ ...items, ways: newLocalProjectWays });

        setTimeout(() => {
            setIsAnimationIn(true);
        }, 1000);
    }

    function handleTransitionCardOnClickEvent(transition: string) {

        if (
            isSpecificTransitionChosen === false 
        ) {
            handleChooseGeneralTransitionOnClickEvent(transition);
        } else {
            setIsModalOpened(true);
        }
    }

    return (
        <React.Fragment>
            <StyledModal
                toggle={setIsModalOpened}
                isOpen={isModalOpened}
                centered
            >
                <ModalHeader
                    className="text-capitalize"
                    toggle={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
                </ModalHeader>
                <ModalBody>
                    {t(
                        "This change causes the modification of the transition chosen in the design module"
                    )}
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                        }}
                        rounded={true}
                        variant="primary"
                        outline
                    >
                        {t("Back")}
                    </CustomSecondaryColorButton>
                    <StyledButton
                        onClick={() => {
                            handleChooseGeneralTransitionOnClickEvent(
                                transition
                            );
                            setIsModalOpened(!isModalOpened);
                        }}
                        rounded={true}
                        variant="danger"
                    >
                        {t("Change")}
                    </StyledButton>
                </ModalFooter>
            </StyledModal>
            <div
                style={{
                    height: "210px",
                    width: "170px",
                }}
                className={`d-grid__clz justify-content-center ksk_img_${toCamelCase(transition)}`}
            >
                <div
                    className="d-flex justify-content-center"
                    onClick={() => handleTransitionCardOnClickEvent(transition)}
                    style={{cursor:"pointer"}}
                >
                    <div
                        className={classNames("d-grid__clz", {
                            "align-items-center":
                                generalTransition.prettyName !==
                                transitions[transition].prettyName,
                        })}
                        style={{
                            height: "160px",
                            width: "160px",
                            border: "1px solid #CBCBCB",
                            borderRadius: "17px",
                            borderBottom:
                                generalTransition.prettyName ===
                                transitions[transition].prettyName
                                    ? `10px solid ${mainColor}`
                                    : "1px solid #BCBCBC",
                            gridTemplateColumns:
                                generalTransition.prettyName ===
                                transitions[transition].prettyName
                                    ? "0.1fr auto"
                                    : "",
                        }}
                    >
                        {generalTransition.prettyName ===
                        transitions[transition].prettyName ? (
                            <CheckIcon
                                className="mt-2 ml-2"
                                height={30}
                                width={30}
                                style={{
                                    fill: mainColor,
                                    cursor: "pointer",
                                }}
                            />
                        ) : null}
                        <div
                            className={classNames("d-flex align-items-center", {
                                "justify-content-center ":
                                    generalTransition.prettyName !==
                                    transitions[transition].prettyName,
                            })}
                        >
                            <img
                                src={"./images/transition.png"}
                                style={{
                                    height: "90px",
                                    width: "90px",
                                }}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <span className="text-center" style={{ fontSize: "14px" }}>
                        {t(transitions[transition].prettyName)}
                    </span>
                </div>
            </div>
        </React.Fragment>
    );
}
