import React from "react";
import { setDataModal } from "../store";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import ModalPass from "../ModalPass";
import { DuplicateIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import classnames from "classnames";
import PrinterSvgIcon from "@components/Common/SvgIcons/PrinterSvgIcon";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import Look from "@components/Common/SvgIcons/Look";

export function CustomIcons({
    e,
    setIsAddCustomerAccountModalClicked,
    isAddCustomerAccountModalClicked,
    setCosutmerName,
}: any) {
    const { t } = useTranslation();
    const [modalPass, setModalPass] = React.useState<boolean>(false);
    const [uid, setUid] = React.useState<boolean>(false);

    return (
        <React.Fragment>
            <div className="d-flex justify-content-around ">
                <Tooltip title={t("To consult").toString()}>
                    <div className="p-1">
                        <ConsultSvgIcon
                            className="cmn_icn_toConsult"
                            height={35}
                            width={35}
                            onClick={() => {
                                e.selectedRow["Société"] =
                                    e.selectedRow[t("Shop")];
                                setDataModal(e.selectedRow);
                                setIsAddCustomerAccountModalClicked(
                                    !isAddCustomerAccountModalClicked
                                );
                                setCosutmerName(e.selectedRow[t("Shop")]);
                                setUid(e.selectedRow.uid);
                            }}
                            style={{
                                cursor: "pointer",
                                position: "relative",
                                bottom: "5px",
                            }}
                        />
                    </div>
                </Tooltip>

                <div className="p-1">
                    <Tooltip title={t("Modification passcode").toString()}>
                        <div>
                            <Look
                                height={40}
                                width={40}
                                fill="black"
                                onClick={() => {
                                    setModalPass(true);
                                    setUid(e.selectedRow.uid);
                                }}
                                style={{
                                    cursor: "pointer",
                                }}
                                className={`${classnames(
                                    "pointer__clz cmn_icn_BThistory cmn_icn_history",
                                    {}
                                )}`}
                            />
                        </div>
                    </Tooltip>
                </div>
                {modalPass && (
                    <>
                        <ModalPass
                            isModalPassClicked={modalPass}
                            setIsModalPassClicked={setModalPass}
                            cosutmerName={"cosutmerName"}
                            uid={uid}
                            setDataIsUpdated={"setDataIsUpdated"}
                        />
                    </>
                )}
            </div>
        </React.Fragment>
    );
}
