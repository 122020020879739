import React from "react";
import {
    PencilIcon,
    SettingsIcon,
    DisassociateIcon,
    ShieldExclamationIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { mutate } from "swr";

import {
    setProjectId,
    store as userStore,
} from "@components/VerticalLayout/store";
import { setIsConsult, store } from "../../store/project/index";
import { setIsKioskSetting } from "../GeneralConfig/store";
import {
    setChosenTemplate,
    setDefaultLanguage,
    setIsConfigActive,
    setIsCustomerAccountActive,
    setIsModalOpened,
    setItems,
    setProject,
    setSelectedLanguage,
    setSelectedSaleModeValue,
    projectStore,
    setInitItemsFromGeneralSetting,
    setConfigOptionsCustomerAccount,
    setIsStateChanged,
    setFiles,
    setActiveLanguages,
    setIsInformationModesEmpty,
    setIsModesModalOpened,
    setInitStoreVariables,
    setInitDesignStoreVariables,
    setDefaultWays,
    setPaymentMainContent,
} from "@store";
import {
    setIsModesModalDisassociate,
    setSelectedProject,
} from "../../../src/store/project";
import {
    store as storeProject,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "./store";

import { FlexboxGrid } from "@components/Common/FlexboxGrid";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { moveArrayElementToTheBegin } from "@helpers/general";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";

export function CustomIcons({
    e,
    setRowData,
    setIsCreatedProject,
    isCreatedProject,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    isArchivedTemplateClicked,
    isConsultForm,
    setIsConsultForm,
}: any) {
    const { t } = useTranslation();
    const { isLoading, modify, dataProjectFranchise } = useSnapshot(store);

    const { isActionsClicked, isEdited } = useSnapshot(storeProject);
    const { isModesModalDisassociate } = useSnapshot(projectStore);
    const { oneShop, shopID, franchiseID } = useSnapshot(userStore);

    const [isAlertModalOpened, setIsAlertModalOpened] = React.useState<boolean>(
        false
    );

    function alertToggle() {
        setIsAlertModalOpened(!isAlertModalOpened);
    }

    async function getApplicationTemplate() {
        let template: any = "";
        let apiUrl = `${
            process.env.REACT_APP_API_V2_URL
        }/settings/applicationTemplate?userId=106&shopId=${
            oneShop ? shopID : e.selectedRow.shopId
        }&franchiseId=${franchiseID}&projectId=${
            e.selectedRow.id_project
        }&applicationId=${e.selectedRow.appId}`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        template = data[0];
                    })
            );
        } catch (e: any) {}

        return template;
    }
    async function handleConsult() {
        setIsConsult(true);
        let dataProject = await getApplicationTemplate();
        let template = dataProject.template;
        let files = dataProject.files;
        setIsModesModalOpened(true);
        setIsKioskSetting(false);
        setInitStoreVariables();
        setInitDesignStoreVariables();
        setIsConfigActive(true);
        setRowData({
            ...e.selectedRow,
            files: [...files],
            template: { ...template },
        });
        setProject({
            ...e.selectedRow,
            files: [...files],
            template: { ...template },
        });
        if (template.id !== "") {
            setPaymentMainContent(
                ((template?.content as ProjectContentType)[
                    "payment"
                ] as PaymentContentItemType).items
            );
            if (
                (template.content
                    .salesMethods as ProjectContentItemType).items.filter(
                    (item: any) =>
                        item.active === true &&
                        item.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                ).length === 0 ||
                (template.content.languages as LanguagesType).items.filter(
                    (item: any) => item.active === true
                ).length === 0
            ) {
                setIsModesModalOpened(false);
                setIsAlertModalOpened(!isAlertModalOpened);
            } else {
                setChosenTemplate(template, files);

                setDefaultLanguage(
                    (template.content.languages as LanguagesType).items.filter(
                        (language) => language.isDefault === true
                    )[0]
                );
                setSelectedLanguage(
                    (template.content.languages as LanguagesType).items.filter(
                        (language) => language.isDefault === true
                    )[0].name
                );
                const selectedSaleMethodDeliveryExist = (template.content
                    .salesMethods as ProjectContentItemType).items.filter(
                    (sale: any) => sale.name === "Delivery"
                )[0];
                if (
                    selectedSaleMethodDeliveryExist !== undefined &&
                    selectedSaleMethodDeliveryExist.active === true
                ) {
                    setIsCustomerAccountActive(true);

                    setIsStateChanged(true);
                    setConfigOptionsCustomerAccount(true);
                } else {
                    setIsCustomerAccountActive(
                        (template.content.options as OptionsType).items.filter(
                            (item) => item.title === "Customer account"
                        )[0].active
                    );
                }

                if (
                    (template.content
                        .informationModes as ProjectContentItemType).items
                        .length === 0 ||
                    (template.content
                        .informationModes as ProjectContentItemType).items.filter(
                        (item: ProjectMainContentItemType) => {
                            return (template.content
                                .salesMethods as ProjectContentItemType).items.filter(
                                (saleMethod: any) => {
                                    return (
                                        Object.keys(item.active).includes(
                                            saleMethod.name
                                        ) && saleMethod.active === true
                                    );
                                }
                            );
                        }
                    ).length === 0
                ) {
                    setIsInformationModesEmpty(true);
                } else {
                    setSelectedSaleModeValue(
                        (template.content
                            .salesMethods as ProjectContentItemType).items.filter(
                            (saleMethod) => {
                                return (
                                    saleMethod.name !== "Retrait C&C" &&
                                    (saleMethod.informationModes as InformationModesContentType)
                                        .items.length !== 0 &&
                                    saleMethod.active === true
                                );
                            }
                        )[0].name
                    );
                }

                setInitItemsFromGeneralSetting({
                    informationModes: template.content.informationModes.items,
                    saleMethods: template.content.salesMethods.items,
                    reglements: template.content.meansOfPayment.items,
                    languages: template.content.languages.items,
                    logo: template.content.generalDesign.logo,
                });
                setItems(template.pages);
                setDefaultWays(template.pages);
            }
        }
        setProjectId(e.selectedRow.projectId);

        setIsModalOpened(true);
    }
    const handleSettingIconOnClickEvent = async () => {
        let dataProject = await getApplicationTemplate();
        let template = dataProject.template;
        let files: any = dataProject.files;
        let hoursOfUse: any = dataProject.hoursOfUse;

        if (template !== "" && template?.id !== "") {
            if (
                (template.content
                    .salesMethods as ProjectContentItemType).items.filter(
                    (item: any) =>
                        item.active === true &&
                        item.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                ).length === 0 ||
                (template.content.languages as LanguagesType).items.filter(
                    (item: any) => item.active === true
                ).length === 0
            ) {
                setIsKioskSetting(false);
                setIsModalOpened(false);
                setIsAlertModalOpened(!isAlertModalOpened);
            } else {
                setProjectId(e?.selectedRow?.id_project);
                setProject({
                    ...e.selectedRow,
                    template: { ...template },
                    files: [...files],
                });
                if (
                    (template.content
                        .informationModes as ProjectContentItemType).items
                        .length === 0 ||
                    (template.content
                        .informationModes as ProjectContentItemType).items.map(
                        (item: ProjectMainContentItemType) => {
                            return (template.content
                                .salesMethods as ProjectContentItemType).items
                                .filter((saleMethod: any) => {
                                    return (
                                        Object.keys(item.active).includes(
                                            saleMethod.name
                                        ) && saleMethod.active === true
                                    );
                                })
                                .every(
                                    (elt: any) =>
                                        elt.informationModes.items.length === 0
                                );
                        }
                    )
                ) {
                    setIsInformationModesEmpty(true);
                    setSelectedSaleModeValue("");
                } else {
                    setSelectedSaleModeValue(
                        (template.content
                            .salesMethods as ProjectContentItemType).items.filter(
                            (saleMethod) => {
                                return (
                                    saleMethod.name !== "Retrait C&C" &&
                                    (saleMethod.informationModes as InformationModesContentType)
                                        .items.length !== 0 &&
                                    saleMethod.active === true
                                );
                            }
                        )[0].name
                    );
                }
                setRowData({
                    ...e.selectedRow,
                    template: { ...template },
                    files: [...files],
                });
                setIsKioskSetting(true);
                setIsModalOpened(true);
                setIsConfigActive(true);

                localStorage.setItem("hoursOfUse", JSON.stringify(hoursOfUse));
                setFiles(files);
                setDefaultLanguage(
                    (template.content.languages as LanguagesType).items.filter(
                        (language) => language.isDefault === true
                    )[0]
                );
                setSelectedLanguage(
                    (template.content.languages as LanguagesType).items.filter(
                        (language) => language.isDefault === true
                    )[0].name
                );
                setActiveLanguages(
                    moveArrayElementToTheBegin(
                        (template.content.languages as LanguagesType).items
                            .filter((language) => language.active === true)
                            .filter(
                                (element: LanguagesItemType): boolean =>
                                    element.isDefault === true
                            )[0],
                        (template.content
                            .languages as LanguagesType).items.filter(
                            (language) => language.active === true
                        )
                    )
                );
                const selectedSaleMethodDeliveryExist = (template.content
                    .salesMethods as ProjectContentItemType).items.filter(
                    (sale: any) => sale.name === "Delivery"
                )[0];
                if (
                    selectedSaleMethodDeliveryExist !== undefined &&
                    selectedSaleMethodDeliveryExist.active === true
                ) {
                    setIsCustomerAccountActive(true);

                    setIsStateChanged(true);
                    setConfigOptionsCustomerAccount(true);
                } else {
                    setIsCustomerAccountActive(
                        (template.content.options as OptionsType).items.filter(
                            (item) => item.title === "Customer account"
                        )[0].active
                    );
                }

                setInitItemsFromGeneralSetting({
                    informationModes: template.content.informationModes.items,
                    saleMethods: template.content.salesMethods.items,
                    reglements: template.content.meansOfPayment.items,
                    languages: template.content.languages.items,
                    logo: template.content.generalDesign.logo,
                });
                setItems(template.pages);
            }
        }
    };

    return (
        <React.Fragment>
            <Modal
                isOpen={isAlertModalOpened}
                toggle={alertToggle}
                backdrop={false}
                fade={false}
                centered
            >
                <ModalHeader toggle={alertToggle}>
                    <span
                        style={{
                            textAlign: "left",
                            font: " normal normal 600 25px Segoe UI",
                            color: "#000000",
                        }}
                    >
                        {" "}
                        {t("Alert")}
                    </span>
                </ModalHeader>
                <ModalBody
                    className="d-flex flex-column"
                    style={{ gap: "10px" }}
                >
                    <div
                        style={{
                            textAlign: "center",
                            font: " normal normal 600 16px/10px Segoe UI",
                            color: "#2B2828",
                        }}
                    >
                        {t("Warning! Missing setting.")}
                    </div>
                    <div
                        style={{
                            font: " normal normal 600 16px/20px Segoe UI",
                            textAlign: "left",
                            color: "#2B2828",
                            padding: "14px",
                        }}
                    >
                        {t(
                            "To configure your KIOSK, it is essential to configure the elements in the general configuration section."
                        )}
                    </div>
                    <FlexboxGrid
                        className="pt-4 justify-content-end"
                        gap="10px"
                    >
                        <CustomMainColorButton
                            rounded
                            variant="primary"
                            onClick={() => setIsAlertModalOpened(false)}
                        >
                            {t("Close")}
                        </CustomMainColorButton>
                    </FlexboxGrid>
                </ModalBody>
            </Modal>

            <React.Fragment>
                {e.selectedRow.key_project !== undefined ? (
                    <div
                        className={`${classnames(
                            "d-flex justify-content-around",
                            {
                                "not-allowed-icon__clz": isLoading,
                            }
                        )}`}
                    >
                        {(modify === false || isArchivedTemplateClicked) && (
                            <Tooltip title={t("To consult").toString()}>
                                <div className="">
                                    <ConsultSvgIcon
                                        className="cmn_icn_toConsult"
                                        height={35}
                                        width={35}
                                        onClick={() => {
                                            setIsConsultForm(true);
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    setIsCreatedProject(true);
                                                    setRowData(e.selectedRow);
                                                });
                                            } else {
                                                setIsCreatedProject(true);
                                                setRowData(e.selectedRow);
                                            }
                                        }}
                                        style={{
                                            cursor: "pointer",
                                            position: "relative",
                                            bottom: "5px",
                                        }}
                                    />
                                </div>
                            </Tooltip>
                        )}
                        {modify === true &&
                            !isArchivedTemplateClicked &&
                            dataProjectFranchise !== undefined && (
                                <>
                                    <Tooltip
                                        title={
                                            JSON.parse(
                                                JSON.stringify(
                                                    dataProjectFranchise
                                                )
                                            ).find(
                                                (el: any) =>
                                                    el.projectId ===
                                                    e.selectedRow.projectId
                                            ) !== undefined &&
                                            JSON.parse(
                                                JSON.stringify(
                                                    dataProjectFranchise
                                                )
                                            ).find(
                                                (el: any) =>
                                                    el.projectId ===
                                                    e.selectedRow.projectId
                                            ).shopKey.length > 1 &&
                                            oneShop
                                                ? ""
                                                : t("Modify project")
                                        }
                                    >
                                        <div>
                                            <PencilIcon
                                                onClick={() => {
                                                    if (isEdited === true) {
                                                        setIsActionsClicked(
                                                            !isActionsClicked
                                                        );
                                                        setActions(() => {
                                                            setIsEdited(false);
                                                            setIsCreatedProject(
                                                                true
                                                            );
                                                            setRowData(
                                                                e.selectedRow
                                                            );
                                                        });
                                                    } else {
                                                        setIsCreatedProject(
                                                            true
                                                        );
                                                        setRowData(
                                                            e.selectedRow
                                                        );
                                                    }
                                                }}
                                                height={25}
                                                width={25}
                                                className={`${classnames(
                                                    "pointer__clz ml-3 cmn_icn_FRedit",
                                                    {
                                                        "not-allowed-icon__clz":
                                                            isArchivedTemplateClicked ||
                                                            (JSON.parse(
                                                                JSON.stringify(
                                                                    dataProjectFranchise
                                                                )
                                                            ).find(
                                                                (el: any) =>
                                                                    el.projectId ===
                                                                    e
                                                                        .selectedRow
                                                                        .projectId
                                                            ) !== undefined &&
                                                                JSON.parse(
                                                                    JSON.stringify(
                                                                        dataProjectFranchise
                                                                    )
                                                                ).find(
                                                                    (el: any) =>
                                                                        el.projectId ===
                                                                        e
                                                                            .selectedRow
                                                                            .projectId
                                                                ).shopKey
                                                                    .length >
                                                                    1 &&
                                                                oneShop),
                                                    }
                                                )}`}
                                            />
                                        </div>
                                    </Tooltip>
                                </>
                            )}
                        <Tooltip title={t("Historical")}>
                            <div>
                                <TimePastSvgIcon
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                setIsCreatedProject(
                                                    !isCreatedProject
                                                );
                                                setIsHistoryModalOpened(true);
                                                setSelectedProject([
                                                    {
                                                        [shopID]:
                                                            e.selectedRow
                                                                .projectId,
                                                    },
                                                ]);
                                                setRowData([e.selectedRow]);
                                            });
                                        } else {
                                            setIsCreatedProject(
                                                !isCreatedProject
                                            );
                                            setIsHistoryModalOpened(true);
                                            setSelectedProject([
                                                {
                                                    [shopID]:
                                                        e.selectedRow.projectId,
                                                },
                                            ]);
                                            setRowData([e.selectedRow]);
                                        }
                                    }}
                                    className="pointer__clz ml-2"
                                />
                            </div>
                        </Tooltip>
                    </div>
                ) : e.selectedRow.id_boutique !== undefined &&
                  modify === true ? (
                    <React.Fragment>
                        <Tooltip
                            title={t("Unlink the store from the Template")}
                        >
                            <div>
                                <DisassociateIcon
                                    height={40}
                                    width={35}
                                    fill="white"
                                    className={`${classnames(
                                        "pointer__clz cgt_icn_unlinkShop",
                                        {
                                            "not-allowed-icon__clz":
                                                isArchivedTemplateClicked ||
                                                isLoading,
                                        }
                                    )}`}
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                setIsCreatedProject(false);
                                                if (
                                                    e.selectedRow.Status ===
                                                    "Publié"
                                                ) {
                                                    toast.error(
                                                        `${t(
                                                            "Unable to unlink a store from a published Template."
                                                        )!}`,
                                                        {
                                                            position:
                                                                toast.POSITION
                                                                    .TOP_CENTER,
                                                            autoClose: 2000,
                                                            theme: "colored",
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            icon: (
                                                                <ShieldExclamationIcon
                                                                    width={25}
                                                                    height={25}
                                                                    fill="white"
                                                                />
                                                            ),
                                                        }
                                                    );
                                                } else {
                                                    setIsModesModalDisassociate(
                                                        !isModesModalDisassociate
                                                    );
                                                }

                                                setRowData([e.selectedRow]);
                                            });
                                        } else {
                                            setIsCreatedProject(false);
                                            if (
                                                e.selectedRow.Status ===
                                                "Publié"
                                            ) {
                                                toast.error(
                                                    `${t(
                                                        "Unable to unlink a store from a published Template."
                                                    )!}`,
                                                    {
                                                        position:
                                                            toast.POSITION
                                                                .TOP_CENTER,
                                                        autoClose: 2000,
                                                        theme: "colored",
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        icon: (
                                                            <ShieldExclamationIcon
                                                                width={25}
                                                                height={25}
                                                                fill="white"
                                                            />
                                                        ),
                                                    }
                                                );
                                            } else {
                                                setIsModesModalDisassociate(
                                                    !isModesModalDisassociate
                                                );
                                            }

                                            setRowData([e.selectedRow]);
                                        }
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </React.Fragment>
                ) : e?.selectedRow?.subRows === undefined && modify === true ? (
                    <div
                        className={`${classnames(
                            "d-flex justify-content-between",
                            {
                                "not-allowed-icon__clz": isLoading,
                            }
                        )}`}
                    >
                        <Tooltip title={t("configure")}>
                            <div>
                                <SettingsIcon
                                    className={`${classnames(
                                        "pointer__clz ml-3",
                                        {
                                            "not-allowed-icon__clz":
                                                e?.selectedRow
                                                    .isProjectConfigured ===
                                                    false ||
                                                isArchivedTemplateClicked,
                                        }
                                    )}`}
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                setIsCreatedProject(false);
                                                handleSettingIconOnClickEvent();
                                            });
                                        } else {
                                            setIsCreatedProject(false);
                                            handleSettingIconOnClickEvent();
                                        }
                                    }}
                                    height={22}
                                    width={22}
                                />
                            </div>
                        </Tooltip>{" "}
                        <Tooltip title={t("To consult").toString()}>
                            <div>
                                <ConsultSvgIcon
                                    className="cmn_icn_toConsult"
                                    height={35}
                                    width={35}
                                    onClick={async () => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(async () => {
                                                setIsEdited(false);
                                                await handleConsult();
                                            });
                                        } else {
                                            await handleConsult();
                                        }
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "5px",
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </div>
                ) : null}
            </React.Fragment>
        </React.Fragment>
    );
}
