import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { v4 as uuid } from "uuid";

import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { resetAllPeripherals } from "@pages/Applications/DeviceAssociateModal/store";
import { store } from "@components/VerticalLayout/store";
import {
    store as logo,
    setNumberActive,
    setIslogoAssociatedModified,
    setIsDataUpdated as setIsDataUpdatedShops,
    setIsValidateClicked,
    setInputImageUploader,
    setIsConfirmationModal,
    setIsActionsClicked,
    setActions,
    setIsEdited,
    setIsPlusIconClicked,
    setEditedDataLogo,
    setDimImageUploader,
} from "../store";

import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import MultiCustomSelect from "@components/Common/MultiCustomSelect";
import { ImageUploader } from "./ImageUploader";
import { ConfirmationModificationModal } from "./ConfirmationModificationModal";
import { ConfirmationAssociationBoutiqueModal } from "./ConfirmationAssociationBoutiqueModal";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import { detailedDiff } from "deep-object-diff";

type ModeOfSaleFormType = {
    setIsAddLogoClicked: Function;
    isAddLogoClicked: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    rowData: React.SetStateAction<any>;
    setLogoData: Function;
    setLocalFilterActive: Function;
    isAddNewLogoButtonClicked: boolean;
    isConsult: boolean;
    setIsConsult: Function;
    dataState: any;
};

export default function LogoForm({
    setIsAddLogoClicked,
    isAddLogoClicked,
    setDataIsUpdated,
    rowData,
    setLogoData,
    setLocalFilterActive,
    isAddNewLogoButtonClicked,
    setIsConsult,
    isConsult,
    dataState,
}: ModeOfSaleFormType) {
    const { t } = useTranslation();
    const { userID, franchiseID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const [isNameExist, setIsNameExist] = React.useState<boolean>(false);
    const {
        numberActive,
        shopsLogoData,
        isLogoAssociatedModified,
        inputImageUploader,
        dataFranchiseLogo,
        dataDesignationLogo,
        listFranchiseArchived,
        isActionsClicked,
        isEdited,
        isPlusIconClicked,
        dimImageUploader,
    } = useSnapshot(logo);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");
    const [uploadedImage, setUploadedImage] = React.useState<any>("");
    const [selectSales, setSelectSales] = React.useState<boolean>(false);
    const [associated, setAssociated] = React.useState<any[]>([]);
    const [designation, setDesignation] = React.useState<string>("");
    const [salesSupportData, setSalesSupportData] = React.useState<any>([]);
    const [salesSupport, setSalesSupport] = React.useState<any>([]);
    const [title, setTitle] = React.useState<string>("");
    const [
        isModifiedAssociateModal,
        setIsModifiedAssociateModal,
    ] = React.useState<boolean>(false);

    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [img, setImg] = React.useState("");
    const [isJpgOrPng, setIsJpgOrPng] = React.useState(
        salesSupport.indexOf("KIOSK") !== -1
            ? img === "image/webp" ||
                  img === "image/png" ||
                  /\.(webp|png)$/i.test(uploadedImage?.name)
            : img === "image/jpeg" ||
                  img === "image/png" ||
                  img === "image/ico" ||
                  img === "image/jpg" ||
                  /\.(jpg|png|ico|jpeg)$/i.test(uploadedImage?.name)
    );

    const [inputImage, setInputImage] = React.useState<boolean>(false);

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    const shopId = oneShop ? shopID : "0";
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);

    function AssociateLogo() {
        let idsAssociated: string[] = [];
        let associated: any[] = [];
        shopsLogoData.forEach((element: any) => {
            element.subRows.forEach((el: any) => {
                if (el.Id === rowData.Id) {
                    idsAssociated.push(element.shopId);
                    associated.push(el);
                }
            });
        });
        idsAssociated = [...new Set(idsAssociated)];
        return associated;
    }

    let localNames: string[] = [];
    let LocalNames = fetchDataName();

    function fetchDataName() {
        if (dataDesignationLogo !== undefined) {
            dataDesignationLogo.forEach((el: any) => {
                localNames.push(el);
            });
        }

        return localNames;
    }

    let designationFranchise: string[] = [];
    designationFranchise = fetchDataDesignationLogo();

    let designationFranchiseArchivage: string[] = fetchDataNameFranchiseArchivage();

    function fetchDataDesignationLogo() {
        if (dataFranchiseLogo !== undefined) {
            Object.values(dataFranchiseLogo).forEach((element: any) => {
                designationFranchise.push(element[t("Designation")]);
            });
        }

        return designationFranchise;
    }

    function fetchDataNameFranchiseArchivage() {
        let localNamesFranchise: string[] = [];
        if (listFranchiseArchived !== undefined) {
            listFranchiseArchived.forEach((el: any) => {
                localNamesFranchise.push(el);
            });
        }
        return localNamesFranchise;
    }
    const fetchSaleSupport = async () => {
        const headers = new Headers();
        headers.append("Accept-Language", i18nextLng);
        const data = await (
            await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`,
                {
                    headers: headers,
                }
            )
        ).json();
        let saleSupportList: any = [];
        data.data.forEach((element: any) => {
            saleSupportList.push({
                label: element[t("Sale support")],
                value: element["Id"],
            });
        });
        setSalesSupportData(data.data.map((el: any) => el[t("Sale support")]));
    };

    React.useEffect(() => {
        fetchSaleSupport();
        //eslint-disable-next-line
    }, []);
    async function addLogo() {
        toast.dismiss();
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            const id = uuid();
            let dataLogo = {
                userId: userID,
                shopId: oneShop ? [shopId, 0] : [shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                adding: true,
                data: {
                    [id]: {
                        id: id,
                        designation: designation,
                        urlImage: uploadedImage,
                        isArchived: false,
                        isActive: oneShop ? false : true,
                        support_de_vente: salesSupport.join(","),
                        shopId: shopId,
                        img: img,
                    },
                },
            };

            const data = new FormData();
            data.append("dataLogo", JSON.stringify(dataLogo));
            data.append("image", uploadedImage);
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
            const requestOptions = {
                method: "POST",
                body: data,
                headers: {
                    "Accept-Language": i18nextLng,
                },
            };
            if (designationFranchise.includes(designation) && oneShop) {
                setIsModifiedAssociateModal(true);
                setIsValidateButtonDisabled(false);
            } else if (
                designationFranchiseArchivage.includes(designation) &&
                oneShop
            ) {
                setIsConfirmationModal(true);
                setIsValidateButtonDisabled(false);
            } else {
                try {
                    mutate(
                        apiUrlAdd,
                        await fetch(apiUrlAdd, requestOptions)
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    throw Error("error");
                                }
                                toast.success(
                                    `${t("logo is successfully added")!}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        theme: "colored",
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );

                                setDataIsUpdated!(true);
                                setLogoData({});
                                setIsEdited(false);
                                resetAllPeripherals();
                                setDesignation("");
                                setSalesSupport([]);
                                setUploadedImage("");
                                setNumberActive(numberActive + 1);
                                setLocalFilterActive(false);
                                clearData();
                                clearMultiCustomSelectionData();
                                setInputImage(false);
                                setInputImageUploader(false);
                                setDimImageUploader(false);
                                setIsValidateButtonDisabled(false);
                            })
                    );
                } catch (e) {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }

    async function editLogo() {
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            setIsValidateClicked(false);
            setIsEdited(false);

            const id = rowData.Id;
            let urlImage = oneShop ? rowData.Image : uploadedImage;
            let dataAssociate: any[];
            let dataLogo = {
                userId: userID,
                shopId: [shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: {
                    [id]: {
                        designation: designation,
                        tag: rowData.tag,
                        isArchived: rowData.isArchived,
                        isActive:
                            Object.keys(dataState).length === 0
                                ? rowData.isActive
                                : rowData.Id === dataState.id
                                ? dataState.state
                                : rowData.isActive,
                        shopId: shopId,
                        urlImage: urlImage,
                        support_de_vente: salesSupport.join(","),
                        img: oneShop ? rowData.img : img,
                    },
                },
            };
            let updatedData: any = {};
            let oldData = {
                designation: rowData[t("Designation")],
                support_de_vente: rowData[t("Sales support")],
                urlImage: rowData.Image,
            };
            if (
                rowData[t("Sales support")].includes("KIOSK") === true ||
                dataLogo.data[id].support_de_vente.includes("KIOSK") === true
            ) {
                updatedData = getSalesSupportUpdated(
                    oldData,
                    {
                        designation: designation,
                        support_de_vente: salesSupport.join(","),
                        urlImage: urlImage,
                    },
                    "KIOSK",
                    "support_de_vente"
                );
            }

            setEditedDataLogo(dataLogo);
            let data;
            if (!oneShop) {
                data = new FormData();
                data.append(
                    "dataLogo",
                    JSON.stringify({ dataLogo, updatedData })
                );
                data.append("image", uploadedImage);
            } else {
                data = new FormData();
                data.append(
                    "dataLogo",
                    JSON.stringify({ dataLogo, updatedData })
                );
                data.append("image", "");
            }
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
            const requestOptions = {
                method: "POST",
                headers: {
                    "Accept-Language": i18nextLng,
                },
                body: data,
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            if (!oneShop) {
                                dataAssociate = AssociateLogo();
                            }
                            setDataIsUpdated!(true);
                            toast.success(
                                !(dataAssociate.length > 0)
                                    ? `${t(
                                          "The logo is changed successfully"
                                      )!}`
                                    : `${t(
                                          "The logo of the franchise is changed successfully"
                                      )!}`,

                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );

                            if (dataAssociate.length > 0) {
                                setIslogoAssociatedModified(true);
                            } else {
                                setIsAddLogoClicked(!isAddLogoClicked);
                                setIsValidateClicked(true);
                                resetAllPeripherals();
                                setIsDataUpdatedShops(true);
                                setSalesSupport([]);
                                clearData();
                                clearMultiCustomSelectionData();
                                setInputImage(false);
                                setInputImageUploader(false);
                                setDimImageUploader(false);
                            }
                            setIsValidateButtonDisabled(false);
                        })
                );
            } catch (e) {
                toast.success(`${t("The logo is changed successfully")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                });
                setIsAddLogoClicked(!isAddLogoClicked);
                setIsValidateClicked(true);
            }
        }
    }

    useEffect(() => {
        if (rowData.Id !== undefined) {
            setTitle(t(isConsult ? "View a logo" : "Modify a logo"));
            setDesignation(rowData[t("Designation")]);

            setUploadedImage(rowData.Image);

            setImg(rowData.img);
            setSalesSupport(rowData[t("Sales support")]?.split(","));
        } else {
            setDesignation("");
            setTitle(t("Add a logo"));
            setIsEdited(false);
            setUploadedImage("");
            setInputImage(false);

            setInputImageUploader(false);
            setDimImageUploader(false);
            setInputDesignation(false);
            setSelectSales(false);
            setSalesSupport([]);
        }
        setAssociated(AssociateLogo);
        // eslint-disable-next-line
    }, [rowData, t, isAddNewLogoButtonClicked]);

    useEffect(() => {
        if (Object.values(rowData)?.length !== 0) {
            if (rowData[t("Designation")] !== "") {
                setInputDesignation(false);
            }
            if (rowData["Image"] !== "") {
                setInputImage(false);
            }
            if (rowData[t("Sales support")]?.length !== 0) {
                setSelectSales(false);
            }
        }
    }, [rowData, t]);
    useEffect(() => {
        clearData();
        clearMultiCustomSelectionData();
    }, [isAddLogoClicked, rowData.Id]);

    React.useEffect(() => {
        if (isPlusIconClicked) {
            setInputDesignation(false);
            setInputImage(false);
            setSelectSales(false);
            setIsPlusIconClicked(false);
        }
    }, [isPlusIconClicked]);
    React.useEffect(() => {
        setIsJpgOrPng(
            salesSupport.indexOf("KIOSK") !== -1
                ? img === "image/webp" ||
                      img === "image/png" ||
                      /\.(webp|png)$/i.test(uploadedImage?.name)
                : img === "image/jpeg" ||
                      img === "image/png" ||
                      img === "image/ico" ||
                      img === "image/jpg" ||
                      /\.(jpg|png|ico|jpeg)$/i.test(uploadedImage?.name)
        );
        if (!isJpgOrPng && img !== "") {
            setInputImageUploader(true);
        } else {
            setInputImageUploader(false);
        }
    }, [img, uploadedImage, salesSupport, isJpgOrPng]);

    console.log({
        inputImage,
        inputImageUploader,
        dimImageUploader,
        img,
        isJpgOrPng,
    });

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3 lgo_txt_FRtitleFormLogo">{title}</h5>
                    <CrossIcon
                        className="icon-close-notification__clz lgo_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsConsult(false);
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    // setLogoData({});
                                    resetAllPeripherals();
                                    clearData();
                                    clearMultiCustomSelectionData();
                                    setIsAddLogoClicked!(!isAddLogoClicked);
                                    setIsEdited(false);
                                    setInputImage(false);
                                    setInputImageUploader(false);
                                    setDimImageUploader(false);
                                    setSalesSupport([]);
                                    // setDataIsUpdated!(true);
                                });
                            } else {
                                // setLogoData({});
                                resetAllPeripherals();
                                clearData();
                                clearMultiCustomSelectionData();
                                setIsAddLogoClicked!(!isAddLogoClicked);
                                setIsEdited(false);
                                setInputImage(false);
                                setInputImageUploader(false);
                                setDimImageUploader(false);
                                setSalesSupport([]);
                                // setDataIsUpdated!(true);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5`}
                    style={{
                        maxHeight: "470px",
                        overflowY: " auto",
                    }}
                >
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Designation")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("lgo_inp_FRdesignation", {
                                input__clz: inputDesignation || isNameExist,
                                "not-allowed-input__clz":
                                    oneShop && rowData.Id !== undefined,
                            })}
                            autocomplete="off"
                            name="designation"
                            onChange={(e: any) => {
                                if (
                                    LocalNames.includes(e.target.value.trim())
                                ) {
                                    setIsNameExist(true);
                                } else {
                                    setIsNameExist(false);
                                }

                                setDesignation(e.target.value);

                                setIsEdited(true);
                                setInputDesignation(false);
                            }}
                            value={designation}
                            placeholder={t("Write")}
                            type="text"
                            autoFocus={!oneShop && isConsult === false}
                        />
                        {inputDesignation ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a designation")}
                            </div>
                        ) : null}
                        {isNameExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("The name is already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz  mt-3"
                        >
                            {t("Sale support")}
                        </StyledLabel>

                        <MultiCustomSelect
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            result={salesSupport}
                            setResult={setSalesSupport}
                            data={salesSupportData}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setSelectSales(false);
                            }}
                            className={classnames("lgo_inp_FRsalesSupport", {
                                invalid__clz: selectSales,
                            })}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="saleSupport"
                        />

                        {selectSales ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a sale support")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel className="required__clz mt-3">
                            {t("Logo")}
                        </StyledLabel>
                        ({t("Please insert a png or webp (500*500) image.")})
                        <ImageUploader
                            uploadedImage={uploadedImage}
                            setUploadedImage={setUploadedImage}
                            inputImage={inputImage}
                            setIsEdited={setIsEdited}
                            setImg={setImg}
                            isJpgOrPng={isJpgOrPng}
                            setInputImage={setInputImage}
                            rowData={rowData}
                            oneShop={oneShop}
                            salesSupport={salesSupport}
                            inputImageUploader={inputImageUploader}
                            dimImageUploader={dimImageUploader}
                        />
                        {inputImage ||
                        inputImageUploader ||
                        dimImageUploader ? (
                            <div
                                className="ml-5"
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t(
                                    "Please insert a png or webp (500*500) image."
                                )}
                            </div>
                        ) : null}
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 lgo_btn_FRcancel cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setActions(() => {
                                    setIsEdited(false);
                                    setIsAddLogoClicked(!isAddLogoClicked);
                                    clearData();
                                    clearMultiCustomSelectionData();
                                    setDataIsUpdated!(true);
                                });
                                setIsActionsClicked(!isActionsClicked);
                            } else {
                                setIsAddLogoClicked(!isAddLogoClicked);
                                clearData();
                                clearMultiCustomSelectionData();
                                setDataIsUpdated!(true);
                            }
                            setIsConsult(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className={`lgo_btn_FRvalidate cmn_btn_validate ${classnames(
                            { "not-allowed-input-vr__clz": isConsult }
                        )}`}
                        // disabled={isValidateButtonDisabled}
                        variant="primary"
                        onClick={() => {
                            if (uploadedImage === "") setInputImage(true);
                            if (designation === "") setInputDesignation(true);
                            if (salesSupport.length === 0) setSelectSales(true);
                            else if (
                                rowData[t("Designation")] === designation &&
                                rowData?.Image === uploadedImage &&
                                rowData[t("Sales support")] ===
                                    salesSupport.join(",")
                            ) {
                                setIsModalOpened(true);
                            } else if (
                                salesSupport.length !== 0 &&
                                designation !== "" &&
                                uploadedImage !== "" &&
                                salesSupport !== "" &&
                                isJpgOrPng &&
                                dimImageUploader === false &&
                                !isNameExist &&
                                rowData.Id !== undefined
                            ) {
                                editLogo();
                            } else if (
                                salesSupport.length !== 0 &&
                                designation !== "" &&
                                uploadedImage !== "" &&
                                salesSupport !== "" &&
                                isJpgOrPng &&
                                dimImageUploader === false &&
                                !isNameExist
                            ) {
                                addLogo();
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            {isLogoAssociatedModified ? (
                <ConfirmationModificationModal
                    isModalOpened={isLogoAssociatedModified}
                    setIsModalOpened={setIslogoAssociatedModified}
                    setIsAddLogoClicked={setIsAddLogoClicked}
                    isAddLogoClicked={isAddLogoClicked}
                    shopId={associated}
                    associated={associated}
                    setDataIsUpdated={setDataIsUpdated}
                    uploadedImage={uploadedImage}
                    salesSupport={salesSupport.join(",")}
                    rowData={rowData}
                />
            ) : null}
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddLogoClicked}
                setIsAddClicked={setIsAddLogoClicked}
                setEditedData={setLogoData}
                setIsEdited={setIsEdited}
            />
            {isModifiedAssociateModal && (
                <ConfirmationAssociationBoutiqueModal
                    isModalOpened={isModifiedAssociateModal}
                    setIsModalOpened={setIsModifiedAssociateModal}
                    setDataIsUpdated={setDataIsUpdated}
                    designation={designation}
                    setDesignation={setDesignation}
                    setSalesSupport={setSalesSupport}
                    salesSupport={salesSupport}
                    setUploadedImage={setUploadedImage}
                    setLocalFilterActive={setLocalFilterActive}
                />
            )}
        </React.Fragment>
    );
}

function getSalesSupportUpdated(
    oldData: any,
    newData: any,
    selectedSaleSupport: any,
    saleSupport: string
) {
    const diff = detailedDiff(oldData, newData);
    let isSaleSupportKIOSKDeleted = false;
    let isSaleSupportKIOSKAdded = false;
    let isSaleMethodHasSaleSupportKIOSK = false;

    let saleSupportDeleted: any = [];
    if (Object.keys(diff.deleted).length > 0) {
        if (Object.keys(diff.deleted).includes(saleSupport) === true) {
            Object.keys(diff.deleted[saleSupport]).forEach(
                (element: number) => {
                    saleSupportDeleted.push(oldData[saleSupport][element]);
                }
            );

            if (saleSupportDeleted.includes(selectedSaleSupport)) {
                isSaleSupportKIOSKDeleted = true;
            }
        }
    }
    if (Object.keys(diff.updated).length > 0) {
        if (Object.keys(diff.updated).includes(saleSupport) === true) {
            const saleSupportUpdated = Object.values(diff.updated[saleSupport]);

            if (saleSupportUpdated.includes(selectedSaleSupport) === false) {
                if (
                    oldData[saleSupport]
                        .split(",")
                        .includes(selectedSaleSupport) === true
                ) {
                    isSaleSupportKIOSKDeleted = true;
                } else if (
                    newData[saleSupport]
                        .split(",")
                        .includes(selectedSaleSupport) === true
                ) {
                    isSaleSupportKIOSKAdded = true;
                }
            }
        }
    }
    if (Object.keys(diff.added).length > 0) {
        if (
            Object.keys(diff.added).includes(saleSupport) &&
            Object.values(diff.added[saleSupport]) !== undefined
        ) {
            const saleSupportAdded = Object.values(diff.added[saleSupport]);
            if (saleSupportAdded.includes(selectedSaleSupport) === true) {
                isSaleSupportKIOSKAdded = true;
            }
        }
    }

    const modifiedDisplayName = detailedDiff(
        oldData.designation,
        newData.designation
    );
    const modifiedURLLogo = detailedDiff(oldData.urlImage, newData.urlImage);

    if (
        isSaleSupportKIOSKAdded === false &&
        isSaleSupportKIOSKDeleted === false &&
        newData[saleSupport].includes("KIOSK") === true
    ) {
        return {
            modifiedDisplayName,
            modifiedURLLogo,
            isSaleSupportKIOSKAdded,
            isSaleSupportKIOSKDeleted,
            isSaleMethodHasSaleSupportKIOSK,
        };
    } else if (
        isSaleSupportKIOSKAdded === false &&
        isSaleSupportKIOSKDeleted === false &&
        newData[saleSupport].includes("KIOSK") === false
    ) {
        return {};
    } else {
        return {
            modifiedDisplayName,
            modifiedURLLogo,
            isSaleSupportKIOSKAdded,
            isSaleSupportKIOSKDeleted,
        };
    }
}
