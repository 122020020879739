/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { uuid } from "uuidv4";

import {
    setGlobalDataIsUpdated,
    setIsModalOpened,
    setOverLaps,
    store,
    setIsValidationNumToTake,
    setIsValidationPriceTheSpot,
    setIsValidationPriceToTake,
    setIsValidationPeriodToTake,
    setIsValidationNumDelivrey,
    setIsValidationNumTheSpot,
    setDuration,
    setKey,
} from "../store";
import {
    store as device,
    setActions,
    setIsActionsClicked,
    setIsEdited,
} from "../GeneralConfigC&C/City/store";
import { store as colorStore } from "@store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import ErrorToast from "@components/Common/ErrorTost";

import "./index.css";

type PropsType = {
    children: React.ReactNode;
    title: string;
    selectedRows: any;
};

export function ModalWrapper({
    children,
    title = "",
    selectedRows,
}: PropsType): JSX.Element {
    const { t } = useTranslation();
    let isShopClosed: boolean = false;
    const {
        isModalOpened,
        itemActivation,
        ItemSalesMethods,
        ItemPayementChoice,
        loyaltyAccount,
        options,
        orders,
        hourOfUse,
        isAddCity,
        isAddZone,
        isErreur,
        overLaps,
        optionComposite,
        isValidationNumTheSpot,
        isValidationNumToTake,
        isValidationPriceTheSpot,
        isValidationPriceToTake,
        isValidationPeriodToTake,
        isValidationNumDelivrey,
    } = useSnapshot(store);
    const { isActionsClicked, isEdited, modify } = useSnapshot(device);
    const { mainColor } = useSnapshot(colorStore);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const toggle = () => {
        if (isEdited === true) {
            setIsActionsClicked(!isActionsClicked);
            setActions(() => {
                setIsEdited(false);
                setIsModalOpened(!isModalOpened);
                setGlobalDataIsUpdated(true);
                setIsValidationNumToTake(false);
                setIsValidationPriceTheSpot(false);
                setIsValidationPriceToTake(false);
                setIsValidationPeriodToTake(false);
                setIsValidationNumDelivrey(false);
                setIsValidationNumTheSpot(false);
                setDuration("");
            });
        } else {
            setIsModalOpened(!isModalOpened);
            setGlobalDataIsUpdated(true);
            setIsValidationNumToTake(false);
            setIsValidationPriceTheSpot(false);
            setIsValidationPriceToTake(false);
            setIsValidationPeriodToTake(false);
            setIsValidationNumDelivrey(false);
            setIsValidationNumTheSpot(false);
            setDuration("");
        }
    };

    useEffect(() => {
        let overLap =
            overLaps !== undefined && JSON.parse(JSON.stringify(overLaps));
        JSON.parse(JSON.stringify(hourOfUse)).map(
            (element: any, key: number) => {
                if (
                    element.HeureDebut_Matin === "" &&
                    element.HeureFin_Matin !== ""
                ) {
                    // eslint-disable-next-line no-whitespace-before-property
                    overLap[key].inputDebut_Matin = true;
                } else if (
                    element.HeureDebut_Matin !== "" &&
                    element.HeureFin_Matin === ""
                ) {
                    // eslint-disable-next-line no-whitespace-before-property
                    overLap[key].inputFin_Matin = true;
                } else if (
                    element.HeureDebut_soir !== "" &&
                    element.HeureFin_soir === ""
                ) {
                    // eslint-disable-next-line no-whitespace-before-property
                    overLap[key].inputFin_Soir = true;
                } else if (
                    element.HeureDebut_soir === "" &&
                    element.HeureFin_soir !== ""
                ) {
                    // eslint-disable-next-line no-whitespace-before-property
                    overLap[key].inputDebut_Soir = true;
                } else {
                    overLap[key].inputDebut_Soir = false;
                    overLap[key].inputFin_Soir = false;
                    overLap[key].inputDebut_Matin = false;
                    overLap[key].inputFin_Matin = false;
                }
            }
        );
        setOverLaps(overLap);
    }, [hourOfUse, overLaps]);

    let schema: any = localStorage.getItem("schemaFranchise");

    async function ccSetup() {
        let localOptionComposite = {
            CMD_DIFFERE: optionComposite.CMD_DIFFERE,
            REGLEGRATUITE:
                optionComposite.REGLEGRATUITE !== ""
                    ? optionComposite.REGLEGRATUITE
                    : selectedRows[0]["composite Products"] !== undefined
                    ? selectedRows[0]["composite Products"].REGLEGRATUITE
                    : "",
        };

        let pattern: any = "";
        if (orders.localPattern !== "") {
            if (orders.localPattern.label === t("Technical problem")) {
                pattern = "PROBLEME TECHNIQUE";
            } else if (orders.localPattern.label === t("Out of stock")) {
                pattern = "RUPTURE DE STOCK";
            } else if (orders.localPattern.label === t("Annual closure")) {
                pattern = "FERMETURE ANNUELLE";
            } else {
                pattern = orders.localPattern.label;
            }
        } else if (orders.isPauseOrders) {
            selectedRows[0].motif_interompre !== undefined
                ? (pattern = selectedRows[0].motif_interompre)
                : (pattern = selectedRows[0].pattern);
        }

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/setup/c&c`;
        let etat: number = itemActivation[0].active === true ? 1 : 0;
        let savedData = {
            shopId: selectedRows[0].shopId,
            schemaFranchise: schema,
            etat_boutique: etat,
            orderactive: itemActivation[1].active,
            vente_livraison: ItemSalesMethods[2].active,
            vente_a_emporter: ItemSalesMethods[1].active,
            vente_surplace: ItemSalesMethods[0].active,
            payement_caisse: ItemPayementChoice[1].active,
            payement_cb: ItemPayementChoice[0].active,
            client_anonyme: options.activateButton,
            info_client_anonyme: options.mandatoryEmail,
            payement_fid: loyaltyAccount.isAuthorized,
            horraire_ouverture: hourOfUse,
            joignable_si_annulee: options.validatingOrder,
            parametre: localOptionComposite,
            interompre_commande: orders.isPauseOrders,
            interompre_indetermine: orders.interrompreIndeterliné,
            interompre_jusqua:
                orders.interompreJusqua === " Invalid date Invalid date"
                    ? ""
                    : orders.interompreJusqua,
            motif_interompre: pattern,
            timetogo: orders.timePrepartion,
            paramlivraison: orders.dataPrice,
        };
        setIsLoading(true);
        if (
            isValidationNumTheSpot ||
            isValidationNumToTake ||
            isValidationPriceTheSpot ||
            isValidationPriceToTake ||
            isValidationPeriodToTake ||
            isValidationNumDelivrey
        ) {
            setIsLoading(false);
        } else {
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(savedData),
                    }).then((result: any) => {
                        if (result.error === true) {
                            throw Error(result.message);
                        }

                        toast.success(`${t("''C&C'' setup successfully")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        });
                        setDuration("");
                        setKey(uuid());
                        setIsLoading(false);
                        setIsValidationNumToTake(false);
                        setIsValidationPriceTheSpot(false);
                        setIsValidationPriceToTake(false);
                        setIsValidationPeriodToTake(false);
                        setIsModalOpened(!isModalOpened);
                        setGlobalDataIsUpdated(true);
                    })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    function isClosed() {
        let isClosedShop: boolean = false;
        let hour: any[] = JSON.parse(JSON.stringify(hourOfUse)).filter(
            (element: any) =>
                (element.HeureDebut_Matin === "" &&
                    element.HeureFin_Matin !== "") ||
                (element.HeureDebut_Matin !== "" &&
                    element.HeureFin_Matin === "") ||
                (element.HeureDebut_soir === "" &&
                    element.HeureFin_soir !== "") ||
                (element.HeureDebut_soir !== "" && element.HeureFin_soir === "")
        );

        if (hour.length !== 0) {
            isClosedShop = true;
        } else {
            isClosedShop = false;
        }

        return isClosedShop;
    }
    isShopClosed = isClosed();
    function handleSetup() {
        if (
            ItemSalesMethods[0].active === false &&
            ItemSalesMethods[1].active === false &&
            ItemSalesMethods[2].active === false
        ) {
            ErrorToast(
                t("Attention, you must activate at least one mode of sale.")
            );
        } else if (
            ItemPayementChoice[0].active === false &&
            ItemPayementChoice[1].active === false
        ) {
            ErrorToast(
                t(
                    "Please note that you must activate at least one payment method."
                )
            );
        } else if (isShopClosed === true) {
            ErrorToast(
                t(
                    "Attention, no schedule has been entered, which means that the shop will be considered closed."
                )
            );
        } else if (
            orders?.delivery < 60 &&
            orders.onTheSpot < 60 &&
            orders.toTake < 60 &&
            !isErreur &&
            overLaps.every(
                (element: any) =>
                    element.HeureDebut_Matin === false &&
                    element.HeureFin_Matin === false &&
                    element.HeureDebut_soir === false &&
                    element.HeureFin_soir === false &&
                    element.inputDebut_Matin === false &&
                    element.inputFin_Matin === false &&
                    element.inputDebut_Soir === false &&
                    element.inputFin_Soir === false
            )
        ) {
            ccSetup();
        }
    }

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                toggle={toggle}
                isOpen={isModalOpened}
                className="modal-wrapper__clz"
            >
                <ModalHeader
                    toggle={toggle}
                    className="text-uppercase modal-wrapper-title__clz"
                >
                    {title}
                </ModalHeader>
                <ModalBody className="p-0">{children}</ModalBody>
                <ModalFooter>
                    {!isAddCity && !isAddZone ? (
                        <CustomMainColorButton
                            className="w-20"
                            rounded
                            color={mainColor}
                            onClick={() => {
                                handleSetup();
                            }}
                        >
                            {t("Save")}
                        </CustomMainColorButton>
                    ) : (
                        <CustomMainColorButton className="style-buttom__clz" />
                    )}
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
