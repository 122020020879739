import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { uuid } from "uuidv4";
import ErrorToast from "@components/Common/ErrorTost";

import store, {
    setFnDelete,
    setIsDeleteCheckClick,
    setActions,
    setIsActionsClicked,
    setIsEdited,
    setIsLoading,
    setKey,
} from "../store";
import { store as ModeOfInformation } from "../../../../components/VerticalLayout/store";

export function ActionColumnModeInformation({
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    selectedRows,
    setDataIsUpdated,
    setIsModalOpened,
    isModalOpened,
    isModeOfInformationArchived,
    setIsModeOfInformationArchived,
    setIsAddModeOfInformationClicked,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const {
        numberArchived,
        numberActive,
        isEdited,
        isActionsClicked,
        shopsModeInformationData,
        archivedModeInformationFranchise,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { franchiseID, oneShop, userID, operatorID, shopID } = useSnapshot(
        ModeOfInformation
    );
    const [error, setError] = React.useState<boolean>(false);
    const shopId = oneShop ? shopID : 0;
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    let dataAssociate: any[] = [];
    let dataArchive: any[] = [];

    if (!oneShop) {
        dataAssociate = AssociateModeOfInformation();
    }
    if (!oneShop) {
        dataAssociate = AssociateModeOfInformation();
        dataArchive = ArchiveModeOfInformation();
    } else {
        dataArchive = ArchiveModeOfInformationFranchise();
    }

    function ArchiveModeOfInformation() {
        let idsAssociated: any = [];

        if (shopsModeInformationData.data !== undefined) {
            shopsModeInformationData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }

        idsAssociated = [...new Set(idsAssociated)];

        return idsAssociated;
    }

    function ArchiveModeOfInformationFranchise() {
        let idsAssociated: any = [];

        if (archivedModeInformationFranchise !== undefined) {
            archivedModeInformationFranchise.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }

        idsAssociated = [...new Set(idsAssociated)];

        return idsAssociated;
    }
    async function activeModeOfInformation() {
        setIsLoading(true);
        toast.dismiss();
        let localData: any = [];

        selectedRows.forEach(async (el: any) => {
            if (el.Id !== undefined) {
                let dataModeOfSale = {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: [el.shopId],
                    franchiseId: franchiseID,
                    isShop: false,
                    data: {
                        [el.Id]: {
                            designation: el[t("Mode of information")],
                            idMI: el?.idMI,
                            supportOfSale: el[t("Sales support")],
                            salesSupportData: [el[t("Sales support")]],
                            displayName: el[t("Display name")],
                            modeOfSale: el[t("Sales method")],
                            tag: el.tag,
                            isArchived: false,
                            state: el.shopId === "0" ? true : el.state,
                            shopId: el.shopId,
                            stateShop: true,
                            advancedDisplayName: el.advancedDisplayName,
                        },
                    },
                };

                localData.push(dataModeOfSale);
            }
        });
        if (localData.length > 0) {
            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`,
                    await fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`,
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify(localData),
                        }
                    ).then((response) => response.json())
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setError(true);
                setIsLoading(false);
            }

            if (error === false) {
                toast.success(
                    `${t(
                        "Information mode has been successfully unarchived"
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setIsLoading(false);
                setKey(uuid());
            }
        }
    }
    function AssociateModeOfInformation() {
        let idsAssociated: any[] = [];
        if (shopsModeInformationData.data !== undefined) {
            shopsModeInformationData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function isModeOfInformationExist() {
        let isExist = false;
        selectedRows.forEach((el: any) => {
            if (dataAssociate.includes(el.Id)) {
                isExist = true;
            }
        });
        if (isExist) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
        } else {
            setIsDeleteCheckClick(true);
            setFnDelete(async () => {
                archiveModeOfInformation();
            });
        }
    }

    async function isInformationModeConsumed(shopId: string, id: string) {
        toast.dismiss();
        let isInformationModeUsed = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/consumedSettingGeneral?shopId=${shopId}&franchiseId=${franchiseID}&type=informationModes`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.data.some((el: any) => el.id === id)) {
                        isInformationModeUsed = true;
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isInformationModeUsed;
    }

    async function archiveModeOfInformation() {
        setLimit(3);
        setIsLoading(true);
        toast.dismiss();
        let localData: any = [];
        let isSomeConsumed = false;
        for (let el of selectedRows) {
            if (el.Id !== undefined) {
                let isConsumed: any = await isInformationModeConsumed(
                    el.shopId,
                    el.idMI
                );
                if (isConsumed === true) {
                    isSomeConsumed = true;
                } else {
                    let dataModeOfInformation = {
                        userId: userID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        shopId: [el.shopId],
                        franchiseId: franchiseID,
                        isShop: false,
                        data: {
                            [el.Id]: {
                                designation: el[t("Mode of information")],
                                idMI: el?.idMI,
                                supportOfSale: el[t("Sales support")],
                                salesSupportData: [el[t("Sales support")]],
                                displayName: el[t("Display name")],
                                modeOfSale: el[t("Sales method")],
                                tag: el.tag,
                                isArchived: true,
                                state: false,
                                shopId: el.shopId,
                                stateShop: true,
                                advancedDisplayName: el.advancedDisplayName,
                            },
                        },
                    };
                    localData.push(dataModeOfInformation);
                }
            }
        }
        if (localData.length > 0) {
            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`,
                    await fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`,
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify(localData),
                        }
                    ).then((response) => response.json())
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setError(true);
                setIsLoading(false);
            }
            if (error === false) {
                toast.success(
                    `${t(
                        "The Information mode has been successfully archived"
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setIsLoading(false);
                setKey(uuid());
            }
        }
        if (isSomeConsumed) {
            ErrorToast(
                t(
                    "Please note, you cannot archive an element used in a template"
                )
            );
            setIsLoading(false);
        }
    }

    async function isModeOfSaleArchived() {
        toast.dismiss();
        let isModeOfSaleArchived = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale_archived?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            })
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((elt: any) => {
                        selectedRows.forEach((element: any) => {
                            element[t("Sales method")].forEach((sale: any) => {
                                if (sale.label === elt["Mode of sale"]) {
                                    isModeOfSaleArchived = true;
                                }
                            });
                        });
                    });
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isModeOfSaleArchived;
    }

    return (
        <React.Fragment>
            <div>
                <div className="dropdown  cmn_drp_FRmenu">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsModeOfInformationArchived(false);
                            }}
                        >
                            <span className="ml-2  cmn_btn_FRlistActivated">
                                {t("Activated mode of information")}(s) (
                                {numberActive ? numberActive : 0})
                            </span>
                        </div>
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setIsModeOfInformationArchived(true);
                                        setIsAddModeOfInformationClicked(false);
                                    });
                                } else {
                                    setIsModeOfInformationArchived(true);
                                    setIsAddModeOfInformationClicked(false);
                                }
                            }}
                        >
                            <span className="ml-2  cmn_btn_FRlistArchived">
                                {t("Archived mode of information")}(s) (
                                {numberArchived ? numberArchived : 0})
                            </span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            className={` cmn_btn_FRhistory ${classnames(
                                " dropdown-item pl-1 pointer__clz",
                                {
                                    "not-allowed-icon__clz":
                                        selectedRows.length === 0,
                                }
                            )}`}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setIsAddModeOfInformationClicked(false);
                                        if (selectedRows.length <= 1) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else {
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                        }
                                    });
                                } else {
                                    if (selectedRows.length <= 1) {
                                        ErrorToast(
                                            `${t(
                                                "Attention, it is a multiple action. Please select at least two items"
                                            )!}`
                                        );
                                    } else {
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                    }
                                }
                            }}
                        >
                            <span className="ml-2">{t("Historical")}</span>
                        </div>
                        {modify === true ? (
                            <>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        " dropdown-item pl-1 pointer__clz cmn_btn_FRarchived cmn_btn_FRunarchive ",
                                        {
                                            "not-allowed-icon__clz":
                                                isLoading ||
                                                selectedRows.length === 0 ||
                                                (isModeOfInformationArchived &&
                                                    oneShop &&
                                                    selectedRows.some(
                                                        (element: any) =>
                                                            dataArchive.includes(
                                                                element.Id
                                                            )
                                                    )),
                                        }
                                    )}`}
                                    onClick={async () => {
                                        setIsLoading(true);
                                        let isArchived = await isModeOfSaleArchived();
                                        if (isEdited === true) {
                                            setIsLoading(false);
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                setIsAddModeOfInformationClicked(
                                                    false
                                                );
                                                if (selectedRows.length <= 1) {
                                                    ErrorToast(
                                                        `${t(
                                                            "Attention, it is a multiple action. Please select at least two items"
                                                        )!}`
                                                    );
                                                } else if (
                                                    isArchived === true &&
                                                    isModeOfInformationArchived
                                                ) {
                                                    ErrorToast(
                                                        `${t(
                                                            "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                                        )!}`
                                                    );
                                                } else {
                                                    isModeOfInformationArchived
                                                        ? selectedRows.some(
                                                              (element: any) =>
                                                                  dataArchive.includes(
                                                                      element.Id
                                                                  )
                                                          ) && !oneShop
                                                            ? setIsModalOpened(
                                                                  !isModalOpened
                                                              )
                                                            : activeModeOfInformation()
                                                        : isModeOfInformationExist();
                                                }
                                            });
                                        } else {
                                            if (selectedRows.length <= 1) {
                                                ErrorToast(
                                                    `${t(
                                                        "Attention, it is a multiple action. Please select at least two items"
                                                    )!}`
                                                );
                                                setIsLoading(false);
                                            } else if (
                                                isArchived === true &&
                                                isModeOfInformationArchived
                                            ) {
                                                ErrorToast(
                                                    `${t(
                                                        "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                                    )!}`
                                                );
                                                setIsLoading(false);
                                            } else {
                                                setIsLoading(false);
                                                isModeOfInformationArchived
                                                    ? selectedRows.some(
                                                          (element: any) =>
                                                              dataArchive.includes(
                                                                  element.Id
                                                              )
                                                      ) && !oneShop
                                                        ? setIsModalOpened(
                                                              !isModalOpened
                                                          )
                                                        : activeModeOfInformation()
                                                    : isModeOfInformationExist();
                                            }
                                        }
                                    }}
                                >
                                    <span
                                        className={`${classnames("ml-2", {})}`}
                                    >
                                        {isModeOfInformationArchived
                                            ? t("Unarchive")
                                            : t("Archiver")}
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
