import React from "react";
import { useTranslation } from "react-i18next";
import { ModalFooter } from "reactstrap";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { v4 as uuid } from "uuid";
import { mutate } from "swr";
import { toast } from "react-toastify";

import { store, setIsCreatedCoping } from "../store";
import { store as userStore } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type CardFooterCreatedCopingType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<boolean>>;
    shopId: string;
    dataProject: any;
    setSelectedRows: Function;
    designation: string;
    setInputDesignation: Function;
};

export default function CardFooterCreatedCoping({
    rowData,
    setSelectedRows,
    designation,
    setIsDataUpdated,
    setInputDesignation,
}: CardFooterCreatedCopingType) {
    const { t } = useTranslation();
    const { isCreatedCoping, dataTable } = useSnapshot(store);
    const { userID, franchiseID, operatorID, oneShop } = useSnapshot(userStore);
    const [
        selectedShopPrinterCodes,
        setSelectedShopPrinterCodes,
    ] = React.useState<any>();

    const uuidUser = localStorage.getItem("uuidUser");
    React.useEffect(() => {
        oneShop
            ? setSelectedShopPrinterCodes(
                  dataTable?.data?.map((el: any) => el.code) || []
              )
            : setSelectedShopPrinterCodes(
                  dataTable.data
                      .filter((el: any) => {
                          return rowData.shopId === el.shopId;
                      })
                      .map((element: any) => element.subRows)
                      .reduce(function (acc: any, curr: any) {
                          return acc.concat(curr);
                      }, [])
                      .map((el: any) => el.code)
              );
              // eslint-disable-next-line
    }, []);

    async function handleDuplicate() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/printer`;
        let dataPrint = {
            id: uuid(),
            designation: designation,
            mark: rowData[t("Mark")],
            note: rowData[t("Remark")],
            shopId: rowData.shopId,
            type: rowData.type,
            speed: rowData.speed,
            path: rowData.path,
            ip: rowData.ip,
            port: rowData.path,
            font_size: rowData[t("Font size")],
            edit_date: new Date(),
            add_date: "",
            ticket_width: rowData.ticket_width,
            port_series: rowData.port_series,
            isArchived: false,
            code:
                selectedShopPrinterCodes?.length > 0
                    ? Math.max(...selectedShopPrinterCodes) + 1
                    : 1,
            tickets: rowData.tickets,
            Kitchen_ticket: rowData.Kitchen_ticket,
            customer_ticket: rowData.customer_ticket,
            modelKitchenTicket: rowData.modelKitchenTicket,
            general: rowData.general,
            divers: rowData.divers,
        };

        let savedData = {
            userId: userID,
            shopId: rowData.shopId,
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: [dataPrint],
            duplicated: true,
        };

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then(() => {
                        toast.success(
                            `${t("The device is successfully duplicated.")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsDataUpdated(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <ModalFooter className="border-top-0">
            <CustomSecondaryColorButton
                outline
                variant="light"
                onClick={() => {
                    setIsCreatedCoping!(!{ isCreatedCoping });
                    setSelectedRows([]);
                }}
                rounded
            >
                {t("Cancel")}
            </CustomSecondaryColorButton>
            <CustomMainColorButton
                rounded
                className="mr-2"
                variant="primary"
                onClick={() => {
                    if (designation.trim() === "") {
                        setInputDesignation(true);
                    } else {
                        handleDuplicate();
                        setIsCreatedCoping(false);
                    }
                }}
            >
                {t("Validate")}
            </CustomMainColorButton>
        </ModalFooter>
    );
}
