import React from "react";
import SwiperCore, { Autoplay } from "swiper";
import { store } from "../../store";
import { useSnapshot } from "valtio";

import "swiper/swiper.min.css";
SwiperCore.use([Autoplay]);

export function ActivateStateScreenPreview(): JSX.Element {
    const { standbyScreenFile } = useSnapshot(store);
    const activeScreens: StandbyScreenItemType[] = standbyScreenFile.filter(
        (item: StandbyScreenItemType) => item.active
    );

    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{
                width: "470px",
                height: "700px",
            }}
        >
            {activeScreens.map((item: StandbyScreenItemType, key: number) => {
                return (
                    <div key={key}>
                        {item.type.includes("video") ? (
                            <video
                                autoPlay
                                muted
                                loop
                                style={{
                                    objectFit: "contain",
                                    width: "98%",
                                    height: "100%",
                                }}
                                src={item.content}
                            ></video>
                        ) : (
                            <img
                                alt="img"
                                style={{
                                    objectFit: "contain",
                                    width: "98%",
                                    height: "100%",
                                }}
                                src={item.content}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
}
