import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { uid } from "react-uid";
import { useSnapshot } from "valtio";

import {
    setImageKey,
    setProjectFiles,
    generalConfigStore,
    setIsBackgroundSummaryActive,
    setIsPageBackgroundSummaryActive,
} from "@store";

import { Uploader } from "@components/Uploader";

import "@pages/GeneralConfig/Design/index.css";

export function General(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template, files: images },
        imageKey,
        isBackgroundSummaryActive,
    } = useSnapshot(generalConfigStore);

    const background = (template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).summary.background;

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...images];
        const image = imagesArray.find((element) => element.id === id);
        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            imagesArray[imageIndex] = {
                ...image,
                content: image.defaultImage as string,
            };
        }

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }

    function handleIsBackgroundActiveSwitchOnClickEvent() {
        setIsBackgroundSummaryActive(!isBackgroundSummaryActive);
        setIsPageBackgroundSummaryActive(!isBackgroundSummaryActive);
    }

    return (
        <div
            className="mt-4 rounded border"
            style={{ width: "93%", height: "96%", borderColor: "#CECECE" }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("General")}
            </div>
            <div
                className="p-4 d-flex flex-column justify-content-between"
                style={{ gap: "20px" }}
            >
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                    }}
                >
                    <label>{t("Background")}</label>
                    <div className="d-flex flex-row align-items-center justify-content-start">
                        <StyledIconButton
                            title={t("Reset")}
                            className="m-0"
                            icon="RefreshIcon"
                            onClick={() => {
                                handleResetImagesOnClickEvent(
                                    background.id as string
                                );
                            }}
                            disabled={
                                isBackgroundSummaryActive === true
                                    ? false
                                    : true
                            }
                        >
                            <RefreshIcon height={15} width={15} />
                        </StyledIconButton>
                        <Uploader
                            key={imageKey}
                            uploadedFileTitle={
                                isBackgroundSummaryActive === true
                                    ? (background.content as string)
                                    : undefined
                            }
                            id={background.id}
                            disabled={
                                isBackgroundSummaryActive === true
                                    ? false
                                    : true
                            }
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isBackgroundSummaryActive}
                            onChange={
                                handleIsBackgroundActiveSwitchOnClickEvent
                            }
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
