import React from "react";
import styled from "styled-components";
import { useSnapshot } from "valtio";
import {
    EyeIcon,
    RefreshIcon,
    StyledIconButton,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { uid } from "react-uid";
import { t } from "i18next";
import ReactSwitch from "react-switch";
import { IoIosMenu } from "react-icons/io";
import swal from "@sweetalert/with-react";

import { Uploader } from "@components/Uploader";

import {
    generalConfigStore,
    setDeletedStandbyStateScreenItemId,
    setImageKey,
    setIsDeleteStandbyStateScreenItemModalOpened,
    setProjectFiles,
    setStandbyScreenFile,
    setStandbyStateScreenItems,
    setUploadedFiles,
} from "@store";

import { Table } from "./Table";
import { DeleteModal } from "./DeleteModal";
import { setIsLoading } from "../../../../store/project";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

const Styles = styled.div`
    padding: 1rem;
    height: 550px;
    table {
        border: 1px solid rgb(198, 198, 198);
        table-layout: fixed;
        border-spacing: 0;
        border-collapse: collapse;
        transition: background-color 0.5s ease;
        thead {
            border-bottom: 2px solid black;
            tr {
                :first-child {
                    color: black;
                    th {
                        :first-child {
                            padding-left: 16px;
                        }
                        height: 60px;
                        text-align: left;
                    }
                }
            }
        }
        th,
        td {
            margin: 10px;
            height: 30px;
            width: 30%;
            border-right: 0px;
            border-left: 0px;
            :first-child {
                width: 15%;
                input {
                    width: auto;
                    text-align: left;
                }
            }
            :last-child {
                width: 10%;
                text-align: right;
            }
            input {
                width: 35%;
                text-align: center;
                background-color: inherit;
                font-size: 1rem;
                padding: 5px;
                margin: 0;
                border-radius: 5px;
            }
        }
    }
`;

// eslint-disable-next-line
const UpDownArrow = ({ dragHandleProps, className }: any) => (
    <div
        {...dragHandleProps}
        className={className}
        aria-label="move"
        role="img"
    >
        <IoIosMenu
            style={{
                fill: "#848484",
                width: "50px",
                height: "50px",
            }}
        />
    </div>
);

export function StandbyStateScreenTable(): JSX.Element {
    const {
        project: {
            files: images,
            template: {
                content: { standbyStateScreen },
            },
        },
        uploadedFiles,
        standbyStateScreenItems,
        defaultStandbyStateScreenItems,
        isDeleteStandbyStateScreenItemModalOpened,
    } = useSnapshot(generalConfigStore);

    const uploadInputRef = React.useRef() as React.MutableRefObject<
        HTMLInputElement
    >;

    const activeScreens: StandbyScreenItemType[] = standbyStateScreenItems.filter(
        (item: StandbyScreenItemType) => item.active
    );

    const validUploadedTypes = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/webp",
        "video/mp4",
        "video/webm",
    ];

    // eslint-disable-next-line
    function OrderCell({ row, dragHandleProps, className }: any) {
        return (
            <div
                className="d-flex align-items-center position-relative"
                style={{ gap: "20px" }}
            >
                <div className="epb_icn_dragAndDropIconeSS">
                    <UpDownArrow
                        dragHandleProps={dragHandleProps}
                        className={className}
                    />
                </div>
                <span
                    style={{ font: "normal normal 600 15px/20px Segoe UI" }}
                    className="epb_txt_fileOrderSS"
                >
                    {row.index + 1}
                </span>
            </div>
        );
    }

    // eslint-disable-next-line
    function EditableNumberCell({ row, cell }: any) {
        const [value, setValue] = React.useState(cell.value);

        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(Math.abs(Number(e.target.value)));
        };

        const onBlur = () => {
            const localData = [...standbyStateScreenItems];
            localData[row.id] = {
                ...localData[row.id],
                timeout: Number(value),
            };

            setStandbyStateScreenItems(localData);
            setStandbyScreenFile(localData);
        };

        return (
            <input
            disabled={true}
                name="input"
                type="text"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                min={0}
                title="input"
                defaultValue={row.original.timeout}
                className="epb_inp_timeSS"
            />
        );
    }

    // eslint-disable-next-line
    function ActionsCell({ row }: any) {
        return (
            <div className="d-flex align-items-center justify-content-end position-relative">
                <ReactSwitch
                    disabled={
                        activeScreens.length === 1 && row.original.active
                            ? true
                            : false
                    }
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={26}
                    offColor="#f7b4b8"
                    offHandleColor="#E30613"
                    checked={row.original.active}
                    onChange={() => {
                        const localData = [...standbyStateScreenItems];
                        localData[row.id] = {
                            ...localData[row.id],
                            active: !row.original.active,
                        };

                        setStandbyStateScreenItems(localData);
                        setStandbyScreenFile(localData);
                    }}
                    onColor="#c2eddd"
                    onHandleColor="#34C38F"
                    width={55}
                    height={20}
                    className="epb_icn_activeDesactiveSS"
                />
                <StyledIconButton
                    icon="TrashIcon"
                    disabled={
                        activeScreens.length === 1 && row.original.active
                            ? true
                            : false
                    }
                    onClick={() => {
                        setDeletedStandbyStateScreenItemId(row.original.id);
                        setIsDeleteStandbyStateScreenItemModalOpened(
                            !isDeleteStandbyStateScreenItemModalOpened
                        );
                    }}
                    aria-label="delete"
                    className="m-0 epb_icn_archiveFileSS"
                >
                    <TrashIcon
                        style={{
                            fill: "red",
                        }}
                        height={20}
                        width={20}
                    />
                </StyledIconButton>
                <StyledIconButton
                                    rounded={true}
                                    className="m-0 bg-dark pme_btn_buttonVisualizeTemplate"
                    onClick={() => {
                        window.open(row.original.content, "_blank", "noreferrer");
                     
                    }}
                    >
                <EyeIcon
                            height={25}
                            width={20}
                            fill="white"
                        />

</StyledIconButton>
            </div>
        );
    }

    // eslint-disable-next-line
    function UploaderCell({ row }: any) {
        return (
            <div className="epb_icn_editFileSS">
                <Uploader
                    uploadedFileTitle={
                        row.original.content.split("/")[
                            row.original.content.split("/").length - 1
                        ]
                    }
                    id={row.original.id}
                    screenItemId={row.original.id}
                    screenItems={standbyStateScreenItems}
                    setScreenItems={setStandbyStateScreenItems}
                />
            </div>
        );
    }

    const columns = React.useMemo(
        () => [
            {
                Header: t("Order"),
                accessor: t("Order"),
                Cell: OrderCell,
            },
            {
                Header: t("Image or video"),
                accessor: "content",
                Cell: UploaderCell,
            },
            {
                Header: t("Display time (sec)"),
                accessor: "timeout",
                Cell: EditableNumberCell,
            },
            {
                Header: t("Actions"),
                accessor: t("Actions"),
                Cell: ActionsCell,
            },
        ],
        // eslint-disable-next-line
        [
            activeScreens,
            setStandbyStateScreenItems,
            standbyStateScreenItems,
            setIsDeleteStandbyStateScreenItemModalOpened,
            isDeleteStandbyStateScreenItemModalOpened,
        ]
    );

    function handleUploadImageOnClickEvent(): void {
        uploadInputRef?.current?.click();
    }

    async function handleUploadedDataOnChangeEvent(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const fileTarget = (event.target as HTMLInputElement)?.files;

        if (fileTarget !== null) {
            handleUploadingNewFileRequest(fileTarget[0]);
        }
    }

    async function handleUploadingNewFileRequest(file: File | undefined) {
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/kiosk/upload`;

        if (file !== undefined) {
            if (!validUploadedTypes.includes(file.type)) {
                return swal({
                    icon: "error",
                    content: <p>{t("File type is not valid")}!</p>,
                    buttons: false,
                    timer: 2000,
                });
            }

            const formData = new FormData();
            formData.append("file", file as File);

            setIsLoading(true);
            await fetch(apiUrl, {
                body: formData,
                method: "PUT",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error(data.message);
                    }

                    let newImages: ImageItemType[] = [...images];
                    newImages = [...newImages, data.data];
                    setProjectFiles(newImages);

                    const filesArray = [...uploadedFiles];
                    filesArray.push({
                        id: data.data.id as string,
                        uploadedFile: data.data,
                    });
                    setUploadedFiles(filesArray);

                    const localData: StandbyScreenItemType[] = [
                        ...standbyStateScreenItems,
                    ];
                    const searchedFileIndex: number = localData.indexOf(
                        // eslint-disable-next-line
                        localData.find(
                            (element) => element.id === data.data.id
                        )!
                    );
                    let data1 = [...localData];
                    if (searchedFileIndex !== -1) {
                        localData[searchedFileIndex] = {
                            ...localData[searchedFileIndex],
                            content: data.data.content,
                            timeout: data.data.type.includes("video")
                                ? data.data.timeout
                                : 3,
                            type: data.data.type,
                        };
                        data1[searchedFileIndex] = {
                            ...localData[searchedFileIndex],
                            content: `${URL.createObjectURL(file)}`,
                            timeout: data.data.type.includes("video")
                                ? data.data.timeout
                                : 3,
                            type: data.data.type,
                        };
                    } else {
                        localData.push({
                            id: data.data.id,
                            active: data.data.active,
                            content: data.data.content,
                            timeout: data.data.type.includes("video")
                                ? data.data.timeout
                                : 3,
                            type: data.data.type,
                        });
                        data1.push({
                            id: data.data.id,
                            active: data.data.active,
                            content: `${URL.createObjectURL(file)}`,
                            timeout: data.data.type.includes("video")
                                ? data.data.timeout
                                : 3,
                            type: data.data.type,
                        });
                    }
                    setStandbyStateScreenItems(localData);
                    setStandbyScreenFile(data1);
                    setIsLoading(false);
                    return swal({
                        icon: "success",
                        content: <p>{t(data.message)}!</p>,
                        buttons: false,
                        timer: 2000,
                    });
                })
                .catch((error) => {
                    setIsLoading(false);
                    return swal({
                        icon: "error",
                        content: <p>{t(error.message)}</p>,
                        buttons: false,
                        timer: 2000,
                    });
                });
        }
    }

    async function handleResetOnClickEvent(): Promise<void> {
        const imagesArray = [...images];
        (standbyStateScreen as StandbyStateScreenItemsType).items
            .map((item: any) => item.id)
            .forEach((id) => {
                const image = imagesArray.find((element) => element.id === id);

                if (image !== undefined) {
                    const imageIndex = imagesArray.indexOf(image);

                    imagesArray[imageIndex] = {
                        ...image,
                        content: imagesArray[imageIndex]
                            .defaultContent as string,
                    };
                }
            });
        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
        uploadInputRef.current.value = "";
    }

    return (
        <div className="d-flex flex-column cursor__clz" style={{ gap: "10px" }}>
            <span
                style={{
                    font: "normal normal 600 35px/47px Segoe UI",
                }}
            >
                {t("Standby state")}
            </span>
            <span
                style={{
                    font: "normal normal normal 15px/20px Nunito Sans",
                }}
            >
                {t(
                    "Insert the images/videos to be scrolled when activating the paused state."
                )}
            </span>

            <div className="d-flex justify-content-between align-items-center">
                <span
                    style={{
                        font: "normal normal normal 15px/20px Nunito Sans",
                    }}
                >
                    {t("Reading list")}
                </span>

                <div
                    className="d-flex align-items-center"
                    style={{
                        gap: "20px",
                    }}
                >
                    <StyledIconButton
                        title={t("Reset to default settings")}
                        outline={true}
                        variant="danger"
                        className="m-0 p-0"
                        icon="RefreshIcon"
                        onClick={() => {
                            setStandbyStateScreenItems(
                                defaultStandbyStateScreenItems
                            );
                            setStandbyScreenFile(
                                defaultStandbyStateScreenItems
                            );
                            handleResetOnClickEvent();
                        }}
                    >
                        <RefreshIcon
                            height={20}
                            width={20}
                            className="epb_icn_resetModificationsSS"
                        />
                    </StyledIconButton>

                    <div className="d-flex">
                        <input
                            accept="video/*,image/*"
                            multiple={false}
                            type="file"
                            id="fileUpload"
                            hidden={true}
                            ref={uploadInputRef}
                            onChange={handleUploadedDataOnChangeEvent}
                        />
                        <CustomMainColorButton
                            rounded={true}
                            variant="primary"
                            onClick={() => {
                                handleUploadImageOnClickEvent();
                            }}
                            className="epb_btn_addFileSS"
                        >
                            {t("Add")}
                        </CustomMainColorButton>
                    </div>
                </div>
            </div>

            <Styles>
                <Table columns={columns} />
            </Styles>
            <DeleteModal />
        </div>
    );
}
