import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";

import { store } from "../../../store";

import BellConcierge from "@components/Common/SvgIcons/BellConcierge";
import ModelNumber from "@components/Common/ModelNumber";

export function Model7() {
    const { t } = useTranslation();
    const { model, advertisingAnimation } = useSnapshot(store);
    const modalTemplate: any = JSON.parse(
        JSON.stringify(Object.values(model))
    )[0];
    let item =
        advertisingAnimation.activeStateScreenItems !== undefined &&
        advertisingAnimation.activeStateScreenItems.find(
            (element: any) => element.active === true
        );
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
            }}
        >
            <div
                style={{
                    background:
                        "rgb(255, 255, 255) none no-repeat scroll 0% 0% padding-box",
                    border: "1px solid rgb(230, 230, 230)",
                    color: "#212529 ",
                    height: "440px",
                    position: "relative",
                }}
                className="d-flex flex-column align-items-center justify-content-center"
            >
                <div
                    style={{ position: "absolute", top: "0px", width: "100%" }}
                >
                    <ModelNumber
                        text="N° 005"
                        colorPrimary={
                            modalTemplate === undefined
                                ? "#000"
                                : modalTemplate[0]["content"]
                        }
                        colorSecondary={
                            modalTemplate === undefined
                                ? "#E6E6E6"
                                : modalTemplate[1]["content"]
                        }
                        width="100%"
                    />
                </div>
                {item !== undefined && item.type !== undefined ? (
                    item.type.includes("video") ? (
                        <video
                            autoPlay
                            muted
                            loop
                            width="100%"
                            height="80%"
                            style={{
                                objectFit: "contain",
                            }}
                            src={item.content}
                        ></video>
                    ) : (
                        <img
                            src={item.content}
                            alt={item.content}
                            title="Model1"
                            width="100%"
                            height="80%"
                            style={{
                                objectFit: "contain",
                            }}
                        />
                    )
                ) : null}

                <StyledH2
                    className="d-flex ml-3 mb"
                    style={{ position: "absolute", bottom: "0px" }}
                >
                    {t("Please present your ticket at the cash desk")}
                </StyledH2>
            </div>
            <div
                style={{
                    backgroundColor:
                        modalTemplate === undefined
                            ? "#000"
                            : modalTemplate[0]["content"],
                }}
            >
                {[1, 2, 3, 4].reverse().map((elt: any) => {
                    return (
                        <div
                            className="d-flex mt-3"
                            style={{
                                gap: "2%",
                                marginTop: "5%",
                                marginRight: "2%",
                            }}
                        >
                            <BellConcierge
                                fill={
                                    modalTemplate === undefined
                                        ? "rgb(230, 230, 230)"
                                        : modalTemplate[1]["content"]
                                }
                                style={{ marginTop: "2%", width: "30% " }}
                            />
                            <ModelNumber
                                text={`N° 00${elt}`}
                                colorPrimary={
                                    modalTemplate === undefined
                                        ? "#000"
                                        : modalTemplate[0]["content"]
                                }
                                colorSecondary={
                                    modalTemplate === undefined
                                        ? "#E6E6E6"
                                        : modalTemplate[1]["content"]
                                }
                                marginTop="2%"
                                width="100%"
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
