import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { CheckboxIcon, CrossIcon, StyledButton } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { diff } from "deep-diff";
import classnames from "classnames";

import {
    computedStore,
    generalConfigStore,
    setChosenTemplate,
    setInitItemsFromGeneralSetting,
    setIsInformationModesEmpty,
    setIsModalOpened,
    setIsProjectModesModalOpened,
    setProject,
} from "@store";
import { store as useStore } from "@components/VerticalLayout/store";
import { store as mainColorStore } from "@store";
import {
    setIsLoading,
    setIsModalConfirmationConfigurationKiosk,
    setKioskProject,
    store,
} from "../../../store/project";
import { setKey, store as localStore } from "@pages/Project/store";

import { SwiperWrapper } from "../../FullscreenPreview/SwiperWrapper";
import { Modal as Modala } from "./Modal";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./index.css";
import { moveArrayElementToTheBegin } from "../../../helpers/general";

type PropsType = {
    children: React.ReactNode;
    title: string;
    rowData?: any;
    setIsDataUpdated: Function;
    isDataUpdated: boolean | number;
    setIsCreatedProject: Function;
    setSelectedRows: Function;
};

export function ModalWrapper({
    children,
    title = "",
    rowData,
    setIsDataUpdated,
    isDataUpdated,
    setIsCreatedProject,
    setSelectedRows,
}: PropsType): JSX.Element {
    const { t } = useTranslation();
    const { initialProject } = useSnapshot(localStore);
    const {
        userID,
        franchiseID,
        projectId,
        shopID,
        oneShop,
        operatorID,
    } = useSnapshot(useStore);
    const uuidUser = localStorage.getItem("uuidUser");

    const {
        isModalOpened,
        isWaysTabActive,
        project,
        isFieldRequiredEmpty,
    } = useSnapshot(generalConfigStore);
    const { isConsult, dataProjectFranchise } = useSnapshot(store);
    const { items } = useSnapshot(computedStore);
    const [isModalShown, setModalVisibility] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const { mainColor } = useSnapshot(mainColorStore);

    const toggle = () => {
        setIsModalOpened(!isModalOpened);
        setIsCreatedProject(false);
        setIsProjectModesModalOpened(false);
        setSelectedRows([]);
    };

    async function handleUpdateProjectButtonOnClickEvent() {
        let apiUrl: any;
        const kioskProject = JSON.parse(localStorage.getItem("project")!);
        setKioskProject(kioskProject);
        apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
        setIsLoading(true);

        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: userID,
                    franchiseId: franchiseID,
                    projectId:
                        Object.keys(rowData).length === 0
                            ? projectId
                            : rowData.projectId ?? rowData.id_project,
                    project_id: "PROJECT",
                    shopId:
                        Object.keys(rowData).length === 0
                            ? oneShop
                                ? shopID
                                : "0"
                            : rowData.shopId.toString(),
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: {
                        template: kioskProject.template,
                        files: kioskProject.files,
                    },
                }),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    setIsModalOpened(!isModalOpened);
                    if (!oneShop) {
                        toast.success(`${t("Template saved successfully")}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        });

                        if (
                            rowData !== undefined &&
                            Object.keys(rowData).length !== 0 &&
                            rowData.template.id !== "" &&
                            rowData?.subRows.length > 0 &&
                            (diff(
                                initialProject.template,
                                kioskProject.template
                            ) !== undefined ||
                                diff(
                                    initialProject.files,
                                    kioskProject.files
                                ) !== undefined)
                        ) {
                            setIsModalConfirmationConfigurationKiosk(true);
                        }
                    } else {
                        if (project.associates !== undefined) {
                            if (Object.keys(project.associates).length > 0) {
                                handleSaveTemplateOnClickEvent();
                            }
                        }
                    }
                    setIsLoading(false);
                    setKey(Math.floor(Math.random() * 10000));
                })

                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }
    async function handleUpdateProjectShopsButtonOnClickEvent() {
        setIsLoading(true);
        let apiUrl: any;
        const kioskProject = JSON.parse(localStorage.getItem("project")!);
        setKioskProject(kioskProject);
        apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: userID,
                    franchiseId: franchiseID,
                    project_id: "PROJECT",
                    projectId: kioskProject.projectId,
                    shopId: shopID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: {
                        template: kioskProject.template,
                        files: kioskProject.files,
                    },
                }),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }

                    toast.success(`${t("Template saved successfully")}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    });

                    if (
                        rowData !== undefined &&
                        Object.keys(rowData).length > 0 &&
                        rowData?.template.id !== "" &&
                        rowData?.subRows.length !== 0 &&
                        (diff(
                            initialProject.template,
                            kioskProject.template
                        ) !== undefined ||
                            diff(initialProject.files, kioskProject.files) !==
                                undefined)
                    ) {
                        setIsModalConfirmationConfigurationKiosk(true);
                    }
                    setIsLoading(false);
                    setKey(Math.floor(Math.random() * 10000));
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    const closeModal = () => {
        setModalVisibility(false);
    };
    const handleSaveTemplateOnClickEvent = async () => {
        for (let row of Object.keys(project.associates)) {
            let template: any;
            const getLanguagesUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/langue?franchiseId=${franchiseID}&shopId=${row}`
            );
            const languages = await getLanguagesUrl.json();
            const getSalesMethodUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${row}`
            );
            const saleMethods = await getSalesMethodUrl.json();

            if (
                saleMethods.filter(
                    (item: any) =>
                        item.active === true &&
                        item.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                ).length === 0 ||
                languages.filter((item: any) => item.active === true).length ===
                    0
            ) {
                toast.warning(
                    `${t(
                        `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                return;
            } else {
                const getReglementUrl: Response = await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/reglement?franchiseId=${franchiseID}&shopId=${row}`
                );
                const reglements = await getReglementUrl.json();

                const getInformationMethodUrl: Response = await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/information?franchiseId=${franchiseID}&shopId=${row}`
                );

                const informationModes = await getInformationMethodUrl.json();

                if (
                    informationModes.length === 0 ||
                    informationModes.filter(
                        // eslint-disable-next-line no-loop-func
                        (item: ProjectMainContentItemType) => {
                            return saleMethods.filter((saleMethod: any) => {
                                return (
                                    Object.keys(item.active).includes(
                                        saleMethod.name
                                    ) && saleMethod.active === true
                                );
                            });
                        }
                    ).length === 0
                ) {
                    setIsInformationModesEmpty(true);
                } else {
                    setIsInformationModesEmpty(false);
                }
                template = {
                    ...project.template,
                    content: {
                        ...project.template.content,
                        languages: {
                            ...project.template.content.languages,
                            items:
                                languages.filter(
                                    // eslint-disable-next-line no-loop-func
                                    (language: LanguagesItemType) =>
                                        language.active === true &&
                                        language.isDefault === false
                                ).length > 1
                                    ? moveArrayElementToTheBegin(
                                          languages.find(
                                              // eslint-disable-next-line no-loop-func
                                              (language: LanguagesItemType) =>
                                                  language.isDefault === true
                                          ),
                                          moveArrayElementToTheBegin(
                                              languages.find(
                                                  // eslint-disable-next-line no-loop-func
                                                  (
                                                      language: LanguagesItemType
                                                  ) =>
                                                      language.active ===
                                                          true &&
                                                      language.isDefault ===
                                                          false
                                              ),
                                              languages
                                          )
                                      )
                                    : languages,
                        },
                        salesMethods: {
                            items: saleMethods,
                        },
                        meansOfPayment: {
                            items: reglements,
                        },
                        informationModes: {
                            items: informationModes,
                        },
                    },
                };

                let files = project.files;
                setProject({
                    ...project,
                    template,
                    files,
                });
                setInitItemsFromGeneralSetting({
                    informationModes,
                    saleMethods,
                    reglements,
                    languages,
                });

                setChosenTemplate(template, files);
                let project1 = {
                    ...project,
                    template,
                };

                let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            userId: userID,
                            franchiseId: franchiseID,
                            projectId: project1.projectId,
                            project_id: "PROJECT",
                            shopId: row,
                            data: project1,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.error) {
                                throw Error(result.message);
                            }
                            setIsModalOpened(!isModalOpened);
                        })

                        .catch((error) => {
                            toast.error(`${t("There's an error")!}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });
                        })
                );
            }
        }
    };
    const handleSaveTemplateFromShopsToFranchiseOnClickEvent = async (
        shops: any
    ) => {
        let isEmpty = false;
        let isSuccess = false;
        setIsLoading(true);
        for (let shopId of shops) {
            const kioskProject = JSON.parse(localStorage.getItem("project")!);
            let project1: any;
            let template: any;
            if (shopId === "0") {
                const getLanguagesUrl: Response = await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/langue?franchiseId=${franchiseID}&shopId=${shopId}`
                );
                const languages = await getLanguagesUrl.json();
                const getSalesMethodUrl: Response = await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${shopId}`
                );
                const saleMethods = await getSalesMethodUrl.json();

                if (
                    saleMethods.filter(
                        (item: any) =>
                            item.active === true &&
                            item.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                    ).length === 0 ||
                    languages.filter((item: any) => item.active === true)
                        .length === 0
                ) {
                    isEmpty = true;
                    return;
                } else {
                    const getReglementUrl: Response = await fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/consomation/reglement?franchiseId=${franchiseID}&shopId=${shopId}`
                    );
                    const reglements = await getReglementUrl.json();

                    const getInformationMethodUrl: Response = await fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/consomation/information?franchiseId=${franchiseID}&shopId=${shopId}`
                    );

                    const getLogoUrl: Response = await fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/consomation/logo?franchiseId=${franchiseID}&shopId=${shopId}`
                    );

                    const informationModes = await getInformationMethodUrl.json();

                    const logo = await getLogoUrl.json();

                    if (
                        informationModes.length === 0 ||
                        informationModes.filter(
                            // eslint-disable-next-line no-loop-func
                            (item: ProjectMainContentItemType) => {
                                return saleMethods.filter((saleMethod: any) => {
                                    return (
                                        Object.keys(item.active).includes(
                                            saleMethod.name
                                        ) && saleMethod.active === true
                                    );
                                });
                            }
                        ).length === 0
                    ) {
                        setIsInformationModesEmpty(true);
                    } else {
                        setIsInformationModesEmpty(false);
                    }
                    template = {
                        ...project.template,
                        content: {
                            ...project.template.content,
                            languages: {
                                ...project.template.content.languages,
                                items:
                                    languages.filter(
                                        // eslint-disable-next-line no-loop-func
                                        (language: LanguagesItemType) =>
                                            language.active === true &&
                                            language.isDefault === false
                                    ).length > 1
                                        ? moveArrayElementToTheBegin(
                                              languages.find(
                                                  // eslint-disable-next-line no-loop-func
                                                  (
                                                      language: LanguagesItemType
                                                  ) =>
                                                      language.isDefault ===
                                                      true
                                              ),

                                              moveArrayElementToTheBegin(
                                                  languages.find(
                                                      // eslint-disable-next-line no-loop-func
                                                      (
                                                          language: LanguagesItemType
                                                      ) =>
                                                          language.active ===
                                                              true &&
                                                          language.isDefault ===
                                                              false
                                                  ),
                                                  languages
                                              )
                                          )
                                        : languages,
                            },
                            salesMethods: {
                                items: saleMethods,
                            },
                            meansOfPayment: {
                                items: reglements,
                            },
                            informationModes: {
                                items: informationModes,
                            },
                            generalDesign: {
                                ...project.template.content.generalDesign,
                                logo,
                            },
                        },
                    };

                    let files = project.files;
                    setProject({
                        ...project,
                        template,
                        files,
                    });
                    setInitItemsFromGeneralSetting({
                        informationModes,
                        saleMethods,
                        reglements,
                        languages,
                        logo,
                    });

                    setChosenTemplate(template, files);
                    project1 = {
                        ...project,
                        template,
                    };
                }
            }
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: userID,
                        franchiseId: franchiseID,
                        projectId:
                            Object.keys(rowData).length === 0
                                ? projectId
                                : shopId === "0"
                                ? project1.projectId
                                : kioskProject.projectId,
                        project_id: "PROJECT",
                        shopId: shopId,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        data: {
                            template:
                                shopId === "0"
                                    ? project1.template
                                    : kioskProject.template,
                            files:
                                shopId === "0"
                                    ? project1.files
                                    : kioskProject.files,
                        },
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    // eslint-disable-next-line no-loop-func
                    .then((result) => {
                        if (result.error) {
                            throw Error(result.message);
                        }
                        if (
                            !(
                                rowData !== undefined &&
                                Object.keys(rowData).length > 0 &&
                                rowData.template.id !== "" &&
                                rowData?.subRows.length > 0 &&
                                (diff(
                                    rowData.template,
                                    kioskProject.template
                                ) !== undefined ||
                                    diff(rowData.files, kioskProject.files) !==
                                        undefined)
                            )
                        ) {
                            setIsDataUpdated(!isDataUpdated);
                        }
                        isSuccess = true;
                    })

                    .catch((error) => {
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        }
        setIsLoading(false);
        if (isEmpty)
            toast.warning(
                `${t(
                    `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                )!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        if (isSuccess)
            toast.success(`${t("Template saved successfully")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
    };

    async function getDataFromGeneralSetting(
        shopId: string,
        templateProject: any
    ) {
        let template: any;
        const getLanguagesUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/langue?franchiseId=${franchiseID}&shopId=${shopId}`
        );
        const languages = await getLanguagesUrl.json();
        const getSalesMethodUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${shopId}`
        );
        const saleMethods = await getSalesMethodUrl.json();
        if (
            saleMethods.filter(
                (item: any) =>
                    item.active === true &&
                    item.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
            ).length === 0 ||
            languages.filter((item: any) => item.active === true).length === 0
        ) {
            return;
        } else {
            const getReglementUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/reglement?franchiseId=${franchiseID}&shopId=${shopId}`
            );
            const reglements = await getReglementUrl.json();

            const getInformationMethodUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/information?franchiseId=${franchiseID}&shopId=${shopId}`
            );

            const informationModes = await getInformationMethodUrl.json();

            JSON.parse(
                JSON.stringify(templateProject.content.salesMethods)
            ).items.forEach((element: any) => {
                saleMethods.forEach((el: any) => {
                    if (el.id === element.id) {
                        el.active = element.active;
                        el.languages = element.languages;
                    }
                });
            });
            JSON.parse(
                JSON.stringify(templateProject.content.languages)
            ).items.forEach((element: any) => {
                languages.forEach((el: any) => {
                    if (el.id === element.id) {
                        el.active = element.active;
                    }
                });
            });

            JSON.parse(
                JSON.stringify(templateProject.content.informationModes)
            ).items.forEach((element: any) => {
                informationModes.forEach((el: any) => {
                    if (el.id === element.id) {
                        el.active = element.active;
                        el.languages = element.languages;
                    }
                });
            });
            JSON.parse(
                JSON.stringify(templateProject.content.meansOfPayment)
            ).items.forEach((element: any) => {
                reglements.forEach((el: any) => {
                    if (el.id === element.id) {
                        el.active = element.active;
                        el.languages = element.languages;
                    }
                });
            });
            if (
                informationModes.length === 0 ||
                informationModes.filter((item: ProjectMainContentItemType) => {
                    return saleMethods.filter((saleMethod: any) => {
                        return (
                            Object.keys(item.active).includes(
                                saleMethod.name
                            ) && saleMethod.active === true
                        );
                    });
                }).length === 0
            ) {
                setIsInformationModesEmpty(true);
            } else {
                setIsInformationModesEmpty(false);
            }

            template = {
                ...templateProject,
                content: {
                    ...templateProject.content,
                    languages: {
                        ...templateProject.content.languages,
                        items:
                            languages.filter(
                                (language: LanguagesItemType) =>
                                    language.active === true &&
                                    language.isDefault === false
                            ).length > 1
                                ? moveArrayElementToTheBegin(
                                      languages.find(
                                          (language: LanguagesItemType) =>
                                              language.isDefault === true
                                      ),
                                      moveArrayElementToTheBegin(
                                          languages.find(
                                              (language: LanguagesItemType) =>
                                                  language.active === true &&
                                                  language.isDefault === false
                                          ),
                                          languages
                                      )
                                  )
                                : languages,
                    },
                    salesMethods: {
                        items: saleMethods,
                    },
                    meansOfPayment: {
                        items: reglements,
                    },
                    informationModes: {
                        items: informationModes,
                    },
                },
            };
            return template;
        }
    }
    async function handleValidate() {
        setIsLoading(true);
        let localData: any = [];
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
        for (let el of rowData.subRows) {
            let shopProject: any = await getDataFromGeneralSetting(
                el.id_boutique,
                project.template
            );
            let savedData: any = {
                userId: userID,
                franchiseId: franchiseID,
                projectId: project.projectId,
                project_id: "PROJECT",
                shopId: el.id_boutique,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: {
                    template: shopProject,
                    files: project.files,
                },
            };
            localData.push(
                fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                }).then((response) => response.json())
            );
        }

        handleSaveProjectsButtonOnClickEvent(localData, apiUrl);
    }

    async function handleSaveProjectsButtonOnClickEvent(
        localData: any,
        apiUrl: any
    ) {
        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then(async (result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        setIsLoading(false);
                    }
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                toggle={toggle}
                isOpen={isModalOpened}
                className="modal-wrapper__clz"
            >
                <ModalHeader
                    toggle={toggle}
                    className="text-uppercase cursor__clz"
                >
                    <span className="pme_txt_popUpTitle"> {title}</span>
                </ModalHeader>
                <ModalBody className="p-0" style={{ overflowY: "unset" }}>
                    {children}
                </ModalBody>
                <ModalFooter>
                    {isWaysTabActive ? (
                        <StyledButton
                            rounded
                            className="m-2"
                            outline
                            variant={mainColor}
                            disabled={Object.values(items.ways).length === 0}
                            onClick={() => setModalVisibility(true)}
                        >
                            {t("See")}
                        </StyledButton>
                    ) : null}
                    {isConsult ? (
                        <CustomMainColorButton
                            className="w-20"
                            rounded
                            variant="primary"
                            onClick={() => {
                                setIsModalOpened(false);
                                setIsProjectModesModalOpened(false);
                                setSelectedRows([]);
                            }}
                        >
                            {t("Close")}
                        </CustomMainColorButton>
                    ) : (
                        <CustomMainColorButton
                            disabled={
                                project.template.id === "" ||
                                isFieldRequiredEmpty === true ||
                                isDisabled
                                    ? true
                                    : false
                            }
                            className={`${classnames(
                                "w-20 cmn_btn_SaveButton",
                                {
                                    "not-allowed-icon__clz": isDisabled,
                                }
                            )}`}
                            rounded
                            variant="primary"
                            onClick={() => {
                                setIsDisabled(true);
                                if (
                                    (oneShop &&
                                        (rowData === undefined ||
                                            Object.keys(rowData).length ===
                                                0)) ||
                                    (oneShop &&
                                        rowData !== undefined &&
                                        Object.keys(rowData).length !== 0 &&
                                        rowData?.template.id === "")
                                ) {
                                    let shops = [
                                        ...dataProjectFranchise
                                            .find(
                                                (el: any) =>
                                                    el.projectId === projectId
                                            )
                                            .subRows.map(
                                                (elt: any) => elt.shopId
                                            ),
                                        "0",
                                    ];
                                    handleSaveTemplateFromShopsToFranchiseOnClickEvent(
                                        shops
                                    );
                                } else if (
                                    oneShop &&
                                    rowData !== undefined &&
                                    Object.keys(rowData).length !== 0 &&
                                    rowData?.template.id !== ""
                                ) {
                                    handleUpdateProjectShopsButtonOnClickEvent();
                                } else if (
                                    !oneShop &&
                                    rowData !== undefined &&
                                    Object.keys(rowData).length !== 0 &&
                                    rowData?.template.id === "" &&
                                    rowData.subRows.length !== 0
                                ) {
                                    handleUpdateProjectButtonOnClickEvent();
                                    handleValidate();
                                } else {
                                    handleUpdateProjectButtonOnClickEvent();
                                }
                                setIsProjectModesModalOpened(false);
                                setIsModalOpened(false);
                                setIsCreatedProject(false);
                            }}
                        >
                            {t("Save")}
                        </CustomMainColorButton>
                    )}
                </ModalFooter>
            </Modal>
            {isModalShown && (
                <Modala close={closeModal}>
                    <div
                        style={{
                            height: "940px",
                            display: "grid",
                            gridTemplateRows: "0.001fr 0.999fr",
                            marginTop: "-185px",
                            marginLeft: "-280px",
                            borderRadius: "9px",
                            background:
                                "linear-gradient(227.9deg, #1F1726 0%, #120E16 100%)",
                            boxShadow:
                                "-5px -5px 11px #221A2B, 5px 5px 11px #16101C",
                            position: "fixed",
                            zIndex: 500000,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "start",
                                justifyContent: "end",
                                padding: "10px",
                            }}
                        >
                            <CrossIcon
                                fill="white"
                                height={10}
                                width={10}
                                onClick={() => {
                                    closeModal();
                                }}
                            />
                        </div>

                        <SwiperWrapper />
                    </div>
                </Modala>
            )}
        </React.Fragment>
    );
}
