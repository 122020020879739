import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { cache } from "swr";
import { useTranslation } from "react-i18next/";
import {
    HeaderWrapper,
    MenuBurgerIcon,
    SettingsIcon,
    StyledH1,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import classNames from "classnames";
import jwt_decode from "jwt-decode";
import { Tooltip } from "@mui/material";

import {
    setIsMobile,
    setCollapsed,
    setIsMobileBurgerButtonClicked,
    store,
} from "./store";
import { store as colorStore } from "@store";
import {
    store as appStore,
    setIsActionsClicked,
    setActions,
    setValidationAction,
} from "@store";
import {
    store as saleMode,
    setIsEdited as setIsEditedModeOfSale,
    setIsActionsClicked as setIsActionsClickedModeOfSale,
    setActions as setActionsModeOfSale,
} from "@pages/SettingGeneral/ModeOfSale/store";
import {
    store as langue,
    setIsEdited as setIsEditedLanguage,
    setIsActionsClicked as setIsActionsClickedLanguage,
    setActions as setActionsLanguage,
} from "@pages/SettingGeneral/Language/store";
import {
    storeVatRate,
    setIsEdited as setIsEditedVatRate,
    setIsActionsClicked as setIsActionsClickedVatRate,
    setActions as setActionsVatRate,
} from "@pages/SettingGeneral/VatRate/store";
import {
    store as saleSupport,
    setIsEdited as setIsEditedSaleSupport,
    setIsActionsClicked as setIsActionsClickedSaleSupport,
    setActions as setActionsSaleSupport,
} from "@pages/SettingGeneral/SaleSupport/store";
import {
    store as modeOfInformation,
    setIsEdited as setIsEditedModeOfInformation,
    setIsActionsClicked as setIsActionsClickedModeOfInformation,
    setActions as setActionsModeOfInformation,
} from "@pages/SettingGeneral/ModeOfInformation/store";
import {
    store as paymentMethod,
    setIsEdited as setIsEditedPaymentMethod,
    setIsActionsClicked as setIsActionsClickedPaymentMethod,
    setActions as setActionsPaymentMethod,
} from "@pages/SettingGeneral/PaymentMethod/store";
import {
    store as logo,
    setIsEdited as setIsEditedLogo,
    setIsActionsClicked as setIsActionsClickedLogo,
    setActions as setActionsLogo,
} from "@pages/SettingGeneral/Logo/store";
import {
    store as devise,
    setIsEdited as setIsEditedDevise,
    setIsActionsClicked as setIsActionsClickedDevise,
    setActions as setActionsDevise,
} from "@pages/SettingGeneral/Devise/store";
import {
    store as fidelity,
    setIsEdited as setIsEditedFidelity,
    setIsActionsClicked as setIsActionsClickedFidelity,
    setActions as setActionsFidelity,
} from "@pages/SettingGeneral/Fidelity/store";
import {
    store as posEditor,
    setIsEdited as setIsEditedPosEditor,
    setIsActionsClicked as setIsActionsClickedPosEditor,
    setActions as setActionsPosEditor,
} from "@pages/SettingGeneral/PosEditor/store";
import {
    store as projectStore,
    setIsEdited as setIsEditedProject,
    setIsActionsClicked as setIsActionsClickedProject,
    setActions as setActionsProject,
} from "@pages/Project/store";
import {
    store as applicationsStore,
    setIsEdited as setIsEditedApplications,
    setIsActionsClicked as setIsActionsClickedApplications,
    setActions as setActionsApplications,
} from "@pages/Applications/store";
import {
    store as printer,
    setIsEdited as setIsEditedPrinter,
    setIsActionsClicked as setIsActionsClickedPrinter,
    setActions as setActionsPrinter,
} from "@pages/Peripherals/Printers/store";
import {
    store as monetic,
    setIsEdited as setIsEditedMonetic,
    setIsActionsClicked as setIsActionsClickedMonetic,
    setActions as setActionsMonetic,
} from "@pages/Peripherals/Monetics/store";
import {
    store as cashManagement,
    setIsEdited as setIsEditedCashManagement,
    setIsActionsClicked as setIsActionsClickedCashManagement,
    setActions as setActionsCashManagement,
} from "@pages/Peripherals/CashManagement/store";

import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import LanguageDropdown from "@components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import { HomeSvgIcon, ShopIconHeader } from "../Common/SvgIcons";
import ProfileMenu from "./ProfileMenu";

import "./layout.css";

export default function Header(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { mainColor } = useSnapshot(colorStore);

    const {
        collapsed,
        isMobileBurgerButtonClicked,
        oneShop,
        isShopUser,
        isMobile,
    } = useSnapshot(store);
    const { isEdited } = useSnapshot(appStore);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const {
        isEdited: modeOfSaleIsEdited,
        isActionsClicked: modeOfSaleIsActionsClicked,
    } = useSnapshot(saleMode);
    const {
        isEdited: langueIsEdited,
        isActionsClicked: langueIsActionsClicked,
    } = useSnapshot(langue);
    const {
        isEdited: vatRateIsEdited,
        isActionsClicked: vatRateIsActionsClicked,
    } = useSnapshot(storeVatRate);
    const {
        isEdited: saleSupportIsEdited,
        isActionsClicked: saleSupportIsActionsClicked,
    } = useSnapshot(saleSupport);
    const {
        isEdited: modeOfInformationIsEdited,
        isActionsClicked: modeOfInformationIsActionsClicked,
    } = useSnapshot(modeOfInformation);
    const {
        isEdited: paymentMethodIsEdited,
        isActionsClicked: paymentMethodIsActionsClicked,
    } = useSnapshot(paymentMethod);
    const {
        isEdited: logoIsEdited,
        isActionsClicked: logoIsActionsClicked,
    } = useSnapshot(logo);
    const {
        isEdited: deviseIsEdited,
        isActionsClicked: deviseIsActionsClicked,
    } = useSnapshot(devise);
    const {
        isEdited: fidelityIsEdited,
        isActionsClicked: fidelityIsActionsClicked,
    } = useSnapshot(fidelity);
    const {
        isEdited: posEditorIsEdited,
        isActionsClicked: posEditorIsActionsClicked,
    } = useSnapshot(posEditor);
    const {
        isEdited: projectIsEdited,
        isActionsClicked: projectIsActionsClicked,
    } = useSnapshot(projectStore);
    const {
        isEdited: applicationsIsEdited,
        isActionsClicked: applicationsIsActionsClicked,
    } = useSnapshot(applicationsStore);
    const {
        isEdited: printerIsEdited,
        isActionsClicked: printerIsActionsClicked,
    } = useSnapshot(printer);
    const {
        isEdited: moneticIsEdited,
        isActionsClicked: moneticIsActionsClicked,
    } = useSnapshot(monetic);
    const {
        isEdited: cashManagementIsEdited,
        isActionsClicked: cashManagementIsActionsClicked,
    } = useSnapshot(cashManagement);
    const [menu, setMenu] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>("");
    const [userFirstName, setUserFirstName] = React.useState<any>("");
    const [email, setEmail] = React.useState<any>("");
    const [userEmail, setUserEmail] = React.useState<any>("");
    const [userLastName, setUserLastName] = React.useState<any>("");

    const tokenUserTmp: any = localStorage.getItem("access_token_id");
    const location = useLocation();

    let matchCompteRoute = "/compte" === location.pathname;
    let matchTestAutoRoute = "/test-auto" === location.pathname;

    let testing = false;

    if (matchCompteRoute === true || matchTestAutoRoute === true) {
        testing = true;
    }

    function logout() {
        cache.clear();
        localStorage.clear();
        navigate("/login");
    }

    const cryptDecryptDataUser = React.useCallback(
        async (wordToDecrypt: string, stateSetter: typeof Function) => {
            return await fetch(
                `${process.env.REACT_APP_ACCESS_API_URL}/decryptWord`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: `wordToDecrypted=${wordToDecrypt}`,
                    method: "POST",
                }
            )
                .then((result) => result.text())
                .then((response: any) => {
                    stateSetter(response);
                    return response;
                })
                .catch((error) => {
                    stateSetter("");
                    return "";
                });
        },
        []
    );
   
    function handleOnChangeEvent() {
        if (modeOfSaleIsEdited === true) {
            setIsActionsClickedModeOfSale(!modeOfSaleIsActionsClicked);
            setActionsModeOfSale(() => {
                setIsEditedModeOfSale(false);
                if (isMobile === true) {
                    setCollapsed(true);
                    setIsMobileBurgerButtonClicked(
                        !isMobileBurgerButtonClicked
                    );
                } else setCollapsed(!collapsed);
            });
        } else if (langueIsEdited === true) {
            setIsActionsClickedLanguage(!langueIsActionsClicked);
            setActionsLanguage(() => {
                setIsEditedLanguage(false);
                if (isMobile === true) {
                    setCollapsed(true);
                    setIsMobileBurgerButtonClicked(
                        !isMobileBurgerButtonClicked
                    );
                } else setCollapsed(!collapsed);
            });
        } else if (vatRateIsEdited === true) {
            setIsActionsClickedVatRate(!vatRateIsActionsClicked);
            setActionsVatRate(() => {
                setIsEditedVatRate(false);
                if (isMobile === true) {
                    setCollapsed(true);
                    setIsMobileBurgerButtonClicked(
                        !isMobileBurgerButtonClicked
                    );
                } else setCollapsed(!collapsed);
            });
        } else if (saleSupportIsEdited === true) {
            setIsActionsClickedSaleSupport(!saleSupportIsActionsClicked);
            setActionsSaleSupport(() => {
                setIsEditedSaleSupport(false);
                if (isMobile === true) {
                    setCollapsed(true);
                    setIsMobileBurgerButtonClicked(
                        !isMobileBurgerButtonClicked
                    );
                } else setCollapsed(!collapsed);
            });
        } else if (modeOfInformationIsEdited === true) {
            setIsActionsClickedModeOfInformation(
                !modeOfInformationIsActionsClicked
            );
            setActionsModeOfInformation(() => {
                setIsEditedModeOfInformation(false);
                if (isMobile === true) {
                    setCollapsed(true);
                    setIsMobileBurgerButtonClicked(
                        !isMobileBurgerButtonClicked
                    );
                } else setCollapsed(!collapsed);
            });
        } else if (paymentMethodIsEdited === true) {
            setIsActionsClickedPaymentMethod(!paymentMethodIsActionsClicked);
            setActionsPaymentMethod(() => {
                setIsEditedPaymentMethod(false);
                if (isMobile === true) {
                    setCollapsed(true);
                    setIsMobileBurgerButtonClicked(
                        !isMobileBurgerButtonClicked
                    );
                } else setCollapsed(!collapsed);
            });
        } else if (logoIsEdited === true) {
            setIsActionsClickedLogo(!logoIsActionsClicked);
            setActionsLogo(() => {
                setIsEditedLogo(false);
                if (isMobile === true) {
                    setCollapsed(true);
                    setIsMobileBurgerButtonClicked(
                        !isMobileBurgerButtonClicked
                    );
                } else setCollapsed(!collapsed);
            });
        } else if (deviseIsEdited === true) {
            setIsActionsClickedDevise(!deviseIsActionsClicked);
            setActionsDevise(() => {
                setIsEditedDevise(false);
                if (isMobile === true) {
                    setCollapsed(true);
                    setIsMobileBurgerButtonClicked(
                        !isMobileBurgerButtonClicked
                    );
                } else setCollapsed(!collapsed);
            });
        } else if (fidelityIsEdited === true) {
            setIsActionsClickedFidelity(!fidelityIsActionsClicked);
            setActionsFidelity(() => {
                setIsEditedFidelity(false);
                if (isMobile === true) {
                    setCollapsed(true);
                    setIsMobileBurgerButtonClicked(
                        !isMobileBurgerButtonClicked
                    );
                } else setCollapsed(!collapsed);
            });
        } else if (posEditorIsEdited === true) {
            setIsActionsClickedPosEditor(!posEditorIsActionsClicked);
            setActionsPosEditor(() => {
                setIsEditedPosEditor(false);
                if (isMobile === true) {
                    setCollapsed(true);
                    setIsMobileBurgerButtonClicked(
                        !isMobileBurgerButtonClicked
                    );
                } else setCollapsed(!collapsed);
            });
        } else if (projectIsEdited === true) {
            setIsActionsClickedProject(!projectIsActionsClicked);
            setActionsProject(() => {
                setIsEditedProject(false);
                if (isMobile === true) {
                    setCollapsed(true);
                    setIsMobileBurgerButtonClicked(
                        !isMobileBurgerButtonClicked
                    );
                } else setCollapsed(!collapsed);
            });
        } else if (applicationsIsEdited === true) {
            setIsActionsClickedApplications(!applicationsIsActionsClicked);
            setActionsApplications(() => {
                setIsEditedApplications(false);
                if (isMobile === true) {
                    setCollapsed(true);
                    setIsMobileBurgerButtonClicked(
                        !isMobileBurgerButtonClicked
                    );
                } else setCollapsed(!collapsed);
            });
        } else if (printerIsEdited === true) {
            setIsActionsClickedPrinter(!printerIsActionsClicked);
            setActionsPrinter(() => {
                setIsEditedPrinter(false);
                if (isMobile === true) {
                    setCollapsed(true);
                    setIsMobileBurgerButtonClicked(
                        !isMobileBurgerButtonClicked
                    );
                } else setCollapsed(!collapsed);
            });
        } else if (moneticIsEdited === true) {
            setIsActionsClickedMonetic(!moneticIsActionsClicked);
            setActionsMonetic(() => {
                setIsEditedMonetic(false);
                if (isMobile === true) {
                    setCollapsed(true);
                    setIsMobileBurgerButtonClicked(
                        !isMobileBurgerButtonClicked
                    );
                } else setCollapsed(!collapsed);
            });
        } else if (cashManagementIsEdited === true) {
            setIsActionsClickedCashManagement(!cashManagementIsActionsClicked);
            setActionsCashManagement(() => {
                setIsEditedCashManagement(false);
                if (isMobile === true) {
                    setCollapsed(true);
                    setIsMobileBurgerButtonClicked(
                        !isMobileBurgerButtonClicked
                    );
                } else setCollapsed(!collapsed);
            });
        } else {
            if (isMobile === true) {
                setCollapsed(true);
                setIsMobileBurgerButtonClicked(!isMobileBurgerButtonClicked);
            } else setCollapsed(!collapsed);
        }
    }

    React.useEffect(() => {
        if (window.innerWidth < 767) {
            setIsMobile(true);
        }

        const handleResize = () => {
            if (window.innerWidth < 767) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    React.useEffect(() => {
        if (isMobile === true) {
            setCollapsed(true);
        }
    }, [isMobile, collapsed, isMobileBurgerButtonClicked]);

    React.useEffect(() => {}, [oneShop]);

    React.useEffect(() => {
        if (tokenUserTmp != null) {
            let dataUserConnected: any = jwt_decode(tokenUserTmp);
            setUserFirstName(async () => {
                const firstName = await cryptDecryptDataUser(
                    dataUserConnected.sub.firstname,
                    setUserFirstName as typeof Function
                );
                return firstName;
            });
            setUserEmail(async () => {
                const email = await cryptDecryptDataUser(
                    dataUserConnected.sub.email,
                    setUserEmail as typeof Function
                );
                return email;
            });
            setUserLastName(async () => {
                const lastName = await cryptDecryptDataUser(
                    dataUserConnected.sub.name,
                    setUserLastName as typeof Function
                );
                return lastName;
            });
        } else {
            setUserFirstName(async () => {
                const firstNameLocalStorage: any = localStorage.getItem(
                    "firstName"
                );
                const firstName = await cryptDecryptDataUser(
                    firstNameLocalStorage,
                    setUserFirstName as typeof Function
                );

                return firstName;
            });
            setUserLastName(async () => {
                const lastNameLocalStorage: any =
                    localStorage.getItem("lastName") ?? "test";
                const lastName = await cryptDecryptDataUser(
                    lastNameLocalStorage,
                    setUserLastName as typeof Function
                );

                return lastName;
            });

            setUserEmail(async () => {
                const emailLocalStorage: any = localStorage.getItem("email");
                const email = await cryptDecryptDataUser(
                    emailLocalStorage,
                    setUserEmail as typeof Function
                );

                return email;
            });
        }
    }, [tokenUserTmp, cryptDecryptDataUser]);

    React.useEffect(() => {
        if (userFirstName !== "" && userLastName !== "") {
            const fullName = `${userFirstName} ${userLastName}`;
            setName(fullName);
        } else if (localStorage.getItem("authUser")) {
            if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                const obj = JSON.parse(localStorage.getItem("authUser")!);
                setName(
                    `${
                        obj.firstname
                            ? `${obj.firstname} ${obj.lastname}`
                            : obj.username
                    }`
                );
            }
        }
        if (userEmail !== "" && typeof userEmail !== "object") {
            setEmail(userEmail);
        }
    }, [userEmail, userFirstName, userLastName]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(true);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    return (
        <HeaderWrapper className="header_wrapper__clz">
            <div className="navbar-header">
                <div className="d-flex align-items-center">
                    {collapsed ? (
                        <StyledH1
                            style={{ backgroundColor: mainColor }}
                            className="navbar-brand-box text-white d-flex justify-content-center brand__clz mb-0  styled_h1__clz"
                        >
                            <SettingsIcon
                                className=""
                                height={25}
                                width={25}
                                fill="white"
                            />
                        </StyledH1>
                    ) : (
                        <StyledH1
                            style={{ backgroundColor: mainColor }}
                            className="navbar-brand-box navbar-brand-box__clz text-white d-flex justify-content-center brand__clz mb-0 p-2 sid_txt_nameOfTheProject"
                        >
                            SETTINGS
                        </StyledH1>
                    )}

                    <div
                        className={classNames({
                            "d-none": testing,
                        })}
                    >
                        <div
                            className={classNames(
                                "d-flex justify-content-center align-items-center",
                                {
                                    "ml-2": collapsed === true,
                                    "ml-3": collapsed === false,
                                    "d-none": testing,
                                }
                            )}
                            style={{ columnGap: 10, cursor: "pointer" }}
                        >
                            <MenuBurgerIcon
                                fill={mainColor}
                                height={25}
                                width={25}
                                onClick={handleOnChangeEvent}
                                className="sid_btn_openClose"
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center">
                    <LanguageDropdown />

                    <Tooltip title="">
                        <div>
                            <StyledIconButton
                                className="m-2 border-none__clz"
                                icon="ShopIcon"
                                onClick={(e: any) => {
                                    window.location.replace(
                                        `${process.env.REACT_APP_PORTAIL_URL}`
                                    );
                                }}
                            >
                                <HomeSvgIcon
                                    onClick={(e: any) => {
                                        window.location.replace(
                                            `${process.env.REACT_APP_PORTAIL_URL}`
                                        );
                                    }}
                                    height={25}
                                    width={25}
                                />
                            </StyledIconButton>
                        </div>
                    </Tooltip>
                    <Tooltip
                        className={classNames({ "d-none": isShopUser })}
                        title={(oneShop
                            ? t("Single store display")
                            : t("Multi-store display")
                        ).toString()}
                    >
                        <div>
                            <StyledIconButton
                                className="m-2 border-none__clz"
                                icon="ShopIcon"
                                onClick={(e: any) => {
                                    navigate("/compte");
                                }}
                            >
                                {oneShop ? (
                                    <ShopIconHeader height={25} width={25} />
                                ) : (
                                    <ShopIconHeader height={25} width={25} />
                                )}
                            </StyledIconButton>
                        </div>
                    </Tooltip>

                    <ProfileMenu
                        logout={logout}
                        menu={menu}
                        setMenu={setMenu}
                        name={name}
                        email={email}
                    />
                </div>
            </div>
        </HeaderWrapper>
    );
}
