import React from "react";
import styled from "styled-components";
import { useSnapshot } from "valtio";
import {
    RefreshIcon,
    StyledIconButton,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { uid } from "react-uid";
import { t } from "i18next";
import ReactSwitch from "react-switch";
import { IoIosMenu } from "react-icons/io";
import swal from "@sweetalert/with-react";
import { mutate } from "swr";

import { Uploader } from "@components/Uploader";

import {
    generalConfigStore,
    setActiveStateScreenItems,
    setDeletedActiveStateScreenItemId,
    setImageKey,
    setIsDeleteActiveStateScreenItemModalOpened,
    setProjectFiles,
    setActiveStandbyScreenFile,
    setUploadedFiles,
} from "@store";

import { Table } from "./Table";
import { DeleteModal } from "./DeleteModal";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { setIsLoading } from "../../../../../../../store/project";

const Styles = styled.div`
    padding: 0.5rem;
    height: 420px;
    table {
        table-layout: fixed;
        border-spacing: 0;
        border-collapse: collapse;
        transition: background-color 0.5s ease;
        thead {
            border-bottom: 2px solid black;
            tr {
                :first-child {
                    color: black;
                    th {
                        :first-child {
                            text-align: left;
                        }
                        text-align: left;
                    }
                }
            }
        }
        th,
        td {
            margin: 10px;
            padding: 0.5rem;
            height: 30px;
            width: 30%;
            border-right: 0px;
            border-left: 0px;
            :first-child {
                width: 15%;
                input {
                    width: auto;
                    text-align: left;
                }
            }
            :last-child {
                width: 10%;
                text-align: right;
            }
            input {
                width: 35%;
                text-align: center;
                background-color: inherit;
                font-size: 1rem;
                padding: 5px;
                margin: 0;
                border-radius: 5px;
            }
        }
    }
`;

// eslint-disable-next-line
const UpDownArrow = ({ dragHandleProps, className }: any) => (
    <div
        {...dragHandleProps}
        className={className}
        aria-label="move"
        role="img"
    >
        <IoIosMenu
            style={{
                fill: "#848484",
                width: "50px",
                height: "50px",
            }}
        />
    </div>
);

export function ActiveStateScreenTable(): JSX.Element {
    const {
        project: {
            template: { pages },
            files: images,
        },
        uploadedFiles,
        activeStateScreenItems,
        defaultActiveStateScreenItems,
        isDeleteActiveStateScreenItemModalOpened,
    } = useSnapshot(generalConfigStore);

    const uploadInputRef = React.useRef() as React.MutableRefObject<
        HTMLInputElement
    >;

    const validUploadedTypes = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "video/mp4",
        "video/webm",
    ];

    const activeScreens = activeStateScreenItems.filter(
        (item: StandbyScreenItemType) => item.active
    );

    // eslint-disable-next-line
    function OrderCell({ row, dragHandleProps, className }: any) {
        return (
            <div
                className="d-flex align-items-center position-relative"
                style={{ gap: "20px" }}
            >
                <UpDownArrow
                    dragHandleProps={dragHandleProps}
                    className={className}
                />

                <span style={{ font: "normal normal 600 20px Segoe UI" }}>
                    {row.index + 1}
                </span>
            </div>
        );
    }

    // eslint-disable-next-line
    function EditableNumberCell({ row, cell }: any) {
        const [value, setValue] = React.useState(cell.value);

        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(Math.abs(Number(e.target.value)));
        };

        const onBlur = () => {
            const localData = [...activeStateScreenItems];
            localData[row.id] = {
                ...localData[row.id],
                timeout: Number(value),
            };

            setActiveStateScreenItems(localData);
            setActiveStandbyScreenFile(localData);
        };

        return (
            <input
                name="input"
                title="input"
                type="number"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                defaultValue={row.original.timeout}
            />
        );
    }

    // eslint-disable-next-line
    function ActionsCell({ row }: any) {
        return (
            <div className="d-flex align-items-center justify-content-end position-relative">
                <ReactSwitch
                    disabled={
                        activeScreens.length === 1 && row.original.active
                            ? true
                            : false
                    }
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={26}
                    offColor="#f7b4b8"
                    offHandleColor="#E30613"
                    checked={row.original.active}
                    onChange={() => {
                        const localData = [...activeStateScreenItems];
                        localData[row.id] = {
                            ...localData[row.id],
                            active: !row.original.active,
                        };

                        setActiveStateScreenItems(localData);
                        setActiveStandbyScreenFile(localData);
                    }}
                    onColor="#c2eddd"
                    onHandleColor="#34C38F"
                    width={55}
                    height={20}
                />
                <StyledIconButton
                    icon="TrashIcon"
                    disabled={
                        activeScreens.length === 1 && row.original.active
                            ? true
                            : false
                    }
                    onClick={() => {
                        setDeletedActiveStateScreenItemId(row.original.id);
                        setIsDeleteActiveStateScreenItemModalOpened(
                            !isDeleteActiveStateScreenItemModalOpened
                        );
                    }}
                    aria-label="delete"
                >
                    <TrashIcon
                        style={{
                            fill: "red",
                        }}
                        height={20}
                        width={20}
                    />
                </StyledIconButton>
            </div>
        );
    }

    // eslint-disable-next-line
    function UploaderCell({ row }: any) {
        return (
            <Uploader
                uploadedFileTitle={
                    row.original.content.split("/")[
                        row.original.content.split("/").length - 1
                    ]
                }
                id={row.original.id}
                screenItemId={row.original.id}
                screenItems={activeStateScreenItems}
                setScreenItems={setActiveStateScreenItems}
            />
        );
    }

    const columns = React.useMemo(
        () => [
            {
                Header: t("Order"),
                accessor: t("Order"),
                Cell: OrderCell,
            },
            {
                Header: t("Image or video"),
                accessor: "content",
                Cell: UploaderCell,
            },
            {
                Header: t("Display time (sec)"),
                accessor: "timeout",
                Cell: EditableNumberCell,
            },
            {
                Header: t("Actions"),
                accessor: t("Actions"),
                Cell: ActionsCell,
            },
        ],
        // eslint-disable-next-line
        [
            activeScreens,
            setActiveStateScreenItems,
            activeStateScreenItems,
            setIsDeleteActiveStateScreenItemModalOpened,
            isDeleteActiveStateScreenItemModalOpened,
        ]
    );

    function handleUploadImageOnClickEvent(): void {
        uploadInputRef?.current?.click();
    }

    async function handleUploadedDataOnChangeEvent(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        // eslint-disable-next-line
        const fileTarget = (event.target as HTMLInputElement)?.files;

        if (fileTarget !== null) {
            handleUploadingNewFileRequest((fileTarget as FileList)[0]);
        }
    }

    async function handleUploadingNewFileRequest(file: File | undefined) {
        const apiUrl = `${process.env.REACT_APP_API_URL}/kiosk/upload`;
        if (file !== undefined) {
            if (!validUploadedTypes.includes(file.type)) {
                return swal({
                    icon: "error",
                    content: <p>{t("File type is not valid")}!</p>,
                    buttons: false,
                    timer: 2000,
                });
            }
            const data = new FormData();
            data.append("file", file as File);
            setIsLoading(true);
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    body: data,
                    method: "PUT",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error(data.message);
                        }

                        let newImages: ImageItemType[] = [...images];
                        newImages = [...newImages, data.data];
                        setProjectFiles(newImages);

                        const filesArray = [...uploadedFiles];
                        filesArray.push({
                            id: data.data.id as string,
                            uploadedFile: data.data,
                        });
                        setUploadedFiles(filesArray);

                        const localData: StandbyScreenItemType[] = [
                            ...activeStateScreenItems,
                        ];

                        let data1: StandbyScreenItemType[] = [
                            ...activeStateScreenItems,
                        ];

                        const searchedFileIndex = localData.indexOf(
                            // eslint-disable-next-line
                            localData.find(
                                (element) => element.id === data.data.id
                            )!
                        );

                        if (searchedFileIndex !== -1) {
                            localData[searchedFileIndex] = {
                                ...localData[searchedFileIndex],
                                content: data.data.content,
                                timeout: data.data.type.includes("video")
                                    ? data.data.timeout
                                    : 3,
                                type: data.data.type,
                            };
                            data1[searchedFileIndex] = {
                                ...data1[searchedFileIndex],
                                content: `${URL.createObjectURL(file)}`,
                                timeout: data.data.type.includes("video")
                                    ? data.data.timeout
                                    : 3,
                                type: data.data.type,
                            };
                        } else {
                            localData.push({
                                id: data.data.id,
                                active: data.data.active,
                                content: data.data.content,
                                timeout: data.data.type.includes("video")
                                    ? data.data.timeout
                                    : 3,
                                type: data.data.type,
                            });
                            data1.push({
                                id: data.data.id,
                                active: data.data.active,
                                content: `${URL.createObjectURL(file)}`,
                                timeout: data.data.type.includes("video")
                                    ? data.data.timeout
                                    : 3,
                                type: data.data.type,
                            });
                        }
                        setActiveStateScreenItems(localData);
                        setActiveStandbyScreenFile(data1);
                        setIsLoading(false);
                        return swal({
                            icon: "success",
                            content: <p>{t(data.message)}!</p>,
                            buttons: false,
                            timer: 2000,
                        });
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        return swal({
                            icon: "error",
                            content: <p>{t(error.message)}</p>,
                            buttons: false,
                            timer: 2000,
                        });
                    })
            );
        }
    }

    async function handleResetOnClickEvent(): Promise<void> {
        const imagesArray = [...images];
        (pages.ways.standbyScreen as StandbyScreenPageType).items
            .map((item: any) => item.id)
            .forEach((id) => {
                const image = imagesArray.find((element) => element.id === id);

                if (image !== undefined) {
                    const imageIndex = imagesArray.indexOf(image);

                    imagesArray[imageIndex] = {
                        ...image,
                        content: imagesArray[imageIndex]
                            .defaultContent as string,
                    };
                }
            });
        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
        uploadInputRef.current.value = "";
    }

    return (
        <div
            className="d-flex flex-column cursor__clz rounded border"
            style={{ gap: "10px", borderColor: "#CECECE" }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("General")}
            </div>

            <div className="px-2 d-flex justify-content-between align-items-center">
                <span
                    style={{
                        font: "normal normal normal 15px/20px Nunito Sans",
                    }}
                >
                    {t("Reading list")}
                </span>

                <div
                    className="d-flex align-items-center"
                    style={{
                        gap: "10px",
                    }}
                >
                    <StyledIconButton
                        title={t("Reset to default settings")}
                        outline={true}
                        variant="danger"
                        className="m-0 p-0"
                        icon="RefreshIcon"
                        onClick={() => {
                            setActiveStateScreenItems(
                                defaultActiveStateScreenItems
                            );
                            setActiveStandbyScreenFile(
                                defaultActiveStateScreenItems
                            );
                            handleResetOnClickEvent();
                        }}
                    >
                        <RefreshIcon height={20} width={20} />
                    </StyledIconButton>

                    <div className="d-flex">
                        <input
                            accept="video/*,image/*"
                            multiple={false}
                            type="file"
                            id="fileUpload"
                            hidden={true}
                            ref={uploadInputRef}
                            onChange={handleUploadedDataOnChangeEvent}
                        />
                        <CustomMainColorButton
                            rounded={true}
                            variant="primary"
                            onClick={() => {
                                handleUploadImageOnClickEvent();
                            }}
                        >
                            {t("Add")}
                        </CustomMainColorButton>
                    </div>
                </div>
            </div>

            <Styles>
                <Table columns={columns} />
            </Styles>
            <DeleteModal />
        </div>
    );
}
