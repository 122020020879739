import React from "react";
import CustomReactTable from "@components/Common/CustomReactTable";
import { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import "../index.css";

export default function CategoryReadyStateList({
    newCategoryAssignment,
    newCategoryAssignmentGlobalFilter,
    setNewCategoryAssignmentGlobalFilter,
    rowSelection1,
    setRowSelection1,
}:any) {
    const { t } = useTranslation();

    const Columns: ColumnDef<any>[] = React.useMemo(() => {
        return [
            {
                accessorKey: "title",
                header: () => (
                    <span style={{ width: "327px" }}>
                        {t("List of category(ies) to be set to ready state")}
                    </span>
                ),
            },
        ];
    }, [t]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "3fr 1fr 3fr",
                }}
                className="mt-1"
            >
                <div
                    style={{
                        background:
                            "rgb(255, 255, 255) none no-repeat scroll 0% 0% padding-box",
                        border: "1px solid rgb(230, 230, 230)",
                        color: "#212529 ",
                        height: "600px",
                    }}
                >
                    <CustomReactTable
                        tableColumns={Columns}
                        data={newCategoryAssignment}
                        globalFilter={newCategoryAssignmentGlobalFilter}
                        setGlobalFilter={setNewCategoryAssignmentGlobalFilter}
                        customRowSelection={rowSelection1}
                        setRowSelection={setRowSelection1}
                        isRowSelectionEnabled
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
