import React from "react";
import { StyledH1, StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";

import { setBeeper } from "../store";

export function Beeper({
    beeperCall,
    setBeeperCall,
    reminder,
    setReminder,
    display,
    setDisplay,
}: any) {
    const { t } = useTranslation();

    React.useEffect(() => {
        setBeeper({
            beeperCall: beeperCall,
            reminder: reminder,
            display: display,
        });
    }, [beeperCall, reminder, display]);

    return (
        <React.Fragment>
            <StyledH1 className="ml-3 mt-4 mb-4">{t("Beeper")}</StyledH1>
            <div className="border-footer__clz p-2" />
            <StyledH2 className="ml-3 mt-4 mb-3">
                {t("Functionality of the 'Beeper call' button")}
            </StyledH2>
            <div className="d-flex  align-items-center justify-content-between mt-3">
                <div className="ml-5 pl-5" style={{ width: "30%" }}>
                    {t("Beeper call")}
                </div>
                <div className="float-right kds_icn_pagerCall mr-5">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={beeperCall}
                        onChange={() => {
                            setBeeperCall(!beeperCall);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between mt-3">
                <div className="ml-5 pl-5" style={{ width: "30%" }}>
                    {t("Reminder on customer display screen (ORB)")}
                </div>
                <div className="float-right kds_icn_reminderOnScreenCustomerDisplayOrb mr-5">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={reminder}
                        onChange={() => {
                            setReminder(!reminder);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between mt-3">
                <div className="ml-5 pl-5" style={{ width: "30%" }}>
                    {t("Manual display of orders on the customer screen (ORB)")}
                </div>
                <div className="float-right kds_icn_manualDisplayControlsOnScreen mr-5">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={display}
                        onChange={() => {
                            setDisplay(!display);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            <div className="ml-5 pl-5" style={{ fontSize: "12px" }}>
                {t(
                    "For proper functioning, please activate the same functionality on ORB"
                )}
            </div>
        </React.Fragment>
    );
}
