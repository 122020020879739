import React, { useMemo } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    setActiveSubTab,
    setDefaultLanguage,
    setIsWaysTabActive,
    generalConfigStore,
} from "@store";

import { Options } from "@pages/GeneralConfig/Options";
import { SalesMethods } from "@pages/GeneralConfig/SalesMethods";
import { InformationModes } from "@pages/GeneralConfig/InformationModes";
import { TemplateSelection } from "@pages/GeneralConfig/TemplateSelection";
import { CustomerAccount } from "@pages/GeneralConfig/CustomerAccount";
import { MeansOfPayment } from "@pages/GeneralConfig/MeansOfPayment";
import { VariousTabs } from "@pages/GeneralConfig/Various/VariousTabs";
import { Languages } from "@pages/GeneralConfig/Languages";
import { AdvertisingScreen } from "@pages/GeneralConfig/AdvertisingScreen";

import "./index.css";

type TabsMenuType = {
    title: string;
    content: React.ReactNode;
    subMenu?: {
        title: string;
        content: React.ReactNode;
    }[];
};

export function ModalContentWrapperConfigure() {
    const { t } = useTranslation();

    const {
        isWaysTabActive,
        chosenTemplate,
        project: { template },
        activeSubTab,
        isCustomerAccountActive,
    } = useSnapshot(generalConfigStore);

    const [activeTab, setActiveTab] = React.useState<number>(0);

    const [isSubMenuCollapsed, setIsSubMenuCollapsed] = React.useState<boolean>(
        false
    );

    const tabs: TabsMenuType[] = useMemo(() => {
        return [
            {
                title: t("Template Selection"),
                content: <TemplateSelection />,
            },
            {
                title: t("Configuration"),
                content: "",
                subMenu: [
                    {
                        title: t("Options"),
                        content: <Options />,
                    },
                    {
                        title: t("Standby screen"),
                        content: <AdvertisingScreen />,
                    },
                    {
                        title: t("Sale Mode"),
                        content: <SalesMethods />,
                    },
                    {
                        title: t("Means of payment"),
                        content: <MeansOfPayment />,
                    },
                    {
                        title: t("Information mode"),
                        content: <InformationModes />,
                    },
                    {
                        title: t("Loyalty account"),
                        content: <CustomerAccount />,
                    },
                    {
                        title: t("Languages"),
                        content: <Languages />,
                    },
                    {
                        title: t("Various"),
                        content: <VariousTabs />,
                    },
                ],
            },
        ];
    }, [t]);

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        if (isSubMenuCollapsed === true) {
            setIsSubMenuCollapsed(false);
        }
    }

    function toggleSubNav(tab: number) {
        if (activeSubTab !== tab) {
            setActiveSubTab(tab);
        }
    }

    React.useEffect(() => {
        setIsWaysTabActive(
            tabs.findIndex((item) => {
                return item.title === t("Ways");
            }) === activeTab
        );
    }, [tabs, activeTab, t]);

    React.useEffect(() => {
        setDefaultLanguage(
            (template.content.languages as LanguagesType).items.filter(
                (language) => language.isDefault === true
            )[0]
        );
    }, [template.content.languages]);

    return (
        <div
            className="h-100"
            style={{ display: "grid", gridTemplateColumns: "220px 1fr" }}
        >
            <Nav
                tabs
                className="d-flex flex-column"
                style={{ backgroundColor: "#323333" }}
            >
                {tabs.map((element, index) => {
                    return (
                        <NavItem key={index} className="pb-1">
                            <NavLink
                                disabled={chosenTemplate.id === ""}
                                className={classNames(
                                    "border-0 menu-wrapper-nav-link__clz",
                                    {
                                        "active menu-wrapper-items-nav-link-active__clz":
                                            activeTab === index,
                                    }
                                )}
                                onClick={() => {
                                    toggleNav(index);

                                    if (element.title === t("Configuration")) {
                                        setIsSubMenuCollapsed(
                                            !isSubMenuCollapsed
                                        );
                                    }
                                }}
                            >
                                <span className="px-2">{element.title}</span>
                            </NavLink>
                            {element.title === t("Configuration") &&
                            isSubMenuCollapsed
                                ? element.subMenu?.map(
                                      (
                                          item: {
                                              title: string;
                                              content: React.ReactNode;
                                          },
                                          subMenuIndex: number
                                      ): JSX.Element => (
                                          <NavLink
                                              disabled={
                                                  item.title ===
                                                      t("Loyalty account") &&
                                                  !isCustomerAccountActive
                                              }
                                              key={subMenuIndex}
                                              className={classNames(
                                                  "border-0 text-nowrap pl-5 menu-wrapper-nav-link__clz",
                                                  {
                                                      "active sub-menu-wrapper-items-nav-link-active__clz":
                                                          activeSubTab ===
                                                          subMenuIndex,
                                                  }
                                              )}
                                              onClick={() => {
                                                  toggleSubNav(subMenuIndex);
                                              }}
                                          >
                                              <span className="px-2">
                                                  {item.title}
                                              </span>
                                          </NavLink>
                                      )
                                  )
                                : null}
                        </NavItem>
                    );
                })}
            </Nav>

            <TabContent
                activeTab={activeTab}
                className={classNames({
                    "px-5": !isWaysTabActive && activeTab !== 3,
                })}
                style={{
                    overflowY: isWaysTabActive ? "hidden" : "auto",
                }}
            >
                {tabs.map((element, index) => {
                    return element.subMenu !== undefined &&
                        isSubMenuCollapsed ? (
                        element.subMenu?.map(({ content }, subMenuIndex) => {
                            return (
                                <TabPane
                                    tabId={index}
                                    className={classNames({
                                        "d-none": subMenuIndex !== activeSubTab,
                                    })}
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        overflow: "hidden",
                                    }}
                                    key={subMenuIndex}
                                >
                                    {content}
                                </TabPane>
                            );
                        })
                    ) : (
                        <TabPane
                            tabId={index}
                            key={index}
                            className={classNames({
                                "design-active-tab__clz": activeTab === 3,
                            })}
                        >
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
}
