import { t } from "i18next";
import { generalConfigStore } from "@store";
import { useSnapshot } from "valtio";
import React from "react";
import { DynamicSvgComponent } from "@components/Common/DynamicSvgComponent";
import {
    NO_THANKS_BUTTON_ROLE,
    VALIDATE_BUTTON_ROLE,
} from "../../../../../../constants";

import { getImageContentById } from "@helpers/general";

export default function Footer() {
    const {
        selectedActionsButtons2Language,
        project: {
            files: images,
            template: {
                content,
                pages: {
                    ways: {
                        orderTaking: { actionsButtons },
                    },
                    subSteps,
                },
            },
        },
    } = useSnapshot(generalConfigStore);

    const customerBenefitOption = subSteps["customerBenefit"].options;

    const isNothankButtonActive = customerBenefitOption.nothanksButton;

    const validateButton = content.actionsButtons.items.filter((item) => {
        return item.role === VALIDATE_BUTTON_ROLE;
    })[0];

    const validateButtonImageContent = getImageContentById(
        validateButton.id,
        images,
        selectedActionsButtons2Language
    );
    const validateButtonImageSrc =
        validateButtonImageContent?.includes("http") ||
        validateButtonImageContent?.includes("https")
            ? validateButtonImageContent
            : `./images/${validateButtonImageContent}`;

    const noThanksButton = actionsButtons.items.filter((item) => {
        return item.active === true && item.role === NO_THANKS_BUTTON_ROLE;
    })[0];

    const noThanksButtonImageContent = getImageContentById(
        noThanksButton.id,
        images,
        selectedActionsButtons2Language
    );

    const noThanksButtonImageSrc =
        noThanksButtonImageContent?.includes("http") ||
        noThanksButtonImageContent?.includes("https")
            ? noThanksButtonImageContent
            : `./images/${noThanksButtonImageContent}`;

    return (
        <div
            className="d-flex w-100 position-absolute centered-items"
            style={{ bottom: "2rem", maxWidth: "27rem", gap: "2rem" }}
        >
            {isNothankButtonActive ? (
                noThanksButtonImageSrc.includes("png") ||
                noThanksButtonImageSrc.includes("webp") ||
                noThanksButtonImageSrc.includes("jpg") ? (
                    <img
                        alt="actionButton"
                        src={noThanksButtonImageSrc}
                        style={{
                            maxWidth: "8rem",
                            maxHeight: "20%",
                        }}
                    />
                ) : (
                    <DynamicSvgComponent
                        nameSvg={noThanksButtonImageContent}
                        width={"8rem"}
                        height={"20%"}
                        selectedLanguage={selectedActionsButtons2Language}
                    />
                )
            ) : null}

            {validateButtonImageSrc.includes("png") ||
            validateButtonImageSrc.includes("webp") ||
            validateButtonImageSrc.includes("jpg") ? (
                <img
                    alt="actionButton"
                    src={validateButtonImageSrc}
                    style={{
                        maxWidth: "8rem",
                        maxHeight: "20%",
                    }}
                />
            ) : (
                <DynamicSvgComponent
                    nameSvg={validateButtonImageContent}
                    width={"8rem"}
                    height={"20%"}
                    selectedLanguage={selectedActionsButtons2Language}
                />
            )}
        </div>
    );
}
