import React from "react";

import { useTranslation } from "react-i18next";
import { StyledModal, CheckboxIcon, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { generalConfigStore, setItemsDisable } from "@store";

type ConfirmationActivationDeactivationMessageModalType = {
    setIsModalOpened: Function;
    isModalOpened: boolean;
    e: any;
    setDataIsUpdated: Function;
    modeOfSaleState: boolean;
};

export function ConfirmationActivationDeactivationMessageModal({
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    e,
    modeOfSaleState,
}: ConfirmationActivationDeactivationMessageModalType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const { itemsDisable } = useSnapshot(generalConfigStore);
    const uuidUser = localStorage.getItem("uuidUser");

    async function handleStateChange() {
        let id = e.selectedRow.Id;
        let dataConfig = [
            {
                userId: userID,
                shopId: [e.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: {
                    [id]: {
                        designation: e.selectedRow["Mode of sale"],
                        tag: e.selectedRow.tag,
                        display_name: e.selectedRow.display_name,
                        support_vente: e.selectedRow.support_vente,
                        isArchived: false,
                        isFavorite: e.selectedRow.isFavorite,
                        state: !modeOfSaleState,
                        shopId: e.selectedRow.shopId,
                        advancedDisplayName: e.selectedRow.advancedDisplayName,
                    },
                },
            },
        ];
        const isStateChange = true;
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ dataConfig, isStateChange }),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }

                    toast.success(
                        !modeOfSaleState
                            ? `${t(
                                  "Sales method has been successfully activated"
                              )}`
                            : `${t(
                                  "Sales method has been successfully deactivated"
                              )}`,

                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setDataIsUpdated!(true);
                    if (!modeOfSaleState === false) {
                        const item = [
                            ...JSON.parse(JSON.stringify(itemsDisable)),
                            {
                                id: e.selectedRow.Id,
                                state: !modeOfSaleState,
                                shopId: e.selectedRow.shopId,
                            },
                        ];
                        setItemsDisable(item);

                        localStorage.setItem(
                            "itemsDisable",
                            JSON.stringify(item)
                        );
                    } else if (!modeOfSaleState === true) {
                        const item = itemsDisable.filter(
                            (item: any) => !(item.id === e.selectedRow.Id && item.shopId === e.selectedRow.shopId)
                        );
                        setItemsDisable(item);
                        localStorage.setItem(
                            "itemsDisable",
                            JSON.stringify(item)
                        );
                    }
                })
                .catch((error: any) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                    setDataIsUpdated!(true);
                }}
            >
                <span
                    className="text-uppercase"
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {t("Alert")}
                </span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {!modeOfSaleState
                        ? t(
                              "Would you like to confirm the activation of the selected sales mode?"
                          )
                        : t(
                              "Caution, the sales mode may be used by other modules.\n Would you like to confirm the deactivation of the selected sales mode?"
                          )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                    className="cmn_btn_cancelAlert"
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    className="cmn_btn_validateAlert mr-2"
                    onClick={() => {
                        handleStateChange();
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
