import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import React from "react";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import { ToastContainer } from "react-toastify";

import { store as useStore } from "@components/VerticalLayout/store";
import {
    setIsEdited,
    setIsModalDissociateOpened,
    setModify,
    setNumberActive,
    setNumberArchived,
    setSelectedApplicationKds,
    store,
} from "./store";

import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import HistoryModal from "./HistoryModal";
import { ConfirmationModalArchived } from "./ConfirmationModalArchived";
import DeviceDissociateModal from "../DeviceDissociateModal";
import { CustomIcons } from "./CustomIcons";
import { CustomSideFilter } from "./CustomSideFilter";
import { AddProject } from "./AddProject";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { DropDownWrapper } from "./DropDownWrapper";
import ModalAssociateStore from "./ModalAssociateStore";
import { ConfirmationDissociateProject } from "./ConfirmationDissociateProject";
import { ConfirmationModalActived } from "./ConfirmationModalActived";
import { ConfirmationModalCopied } from "./ConfirmationModalCopied";
import { getPathAuthorization } from "@components/VerticalLayout";
import { useLocation } from "react-router-dom";
import { ModalWrapper } from "./ModalWrapper";
import { ModalContentWrapper } from "./ModalContentWrapper";
import ModalConfirmationConfigurationKds from "./ModalConfirmationConfigurationKds";

export default function KdsProjectSetup() {
    const { t } = useTranslation();
    const location = useLocation();
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isAddProject, setIsAddProject] = React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);

    const [isProjectArchived, setIsProjectArchived] = React.useState<boolean>(
        false
    );
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [data, setData] = React.useState<any>({});
    const [typeDisplay, setTypeDisplay] = React.useState<string>("notArchived");
    const [selectedRows, setSelectedRows] = React.useState<any>({});
    const [isDataUpdated, setIsDataUpdated] = React.useState<boolean | number>(
        false
    );
    const [
        isModalConfirmationConfigurationKds,
        setIsModalConfirmationConfigurationKds,
    ] = React.useState<boolean>(false);
    const [
        isDeviceDissociateModalOpened,
        setIsDeviceDissociateModalOpened,
    ] = React.useState<boolean>(false);
    const [
        isConfirmationModalArchived,
        setIsConfirmationModalArchived,
    ] = React.useState<boolean>(false);

    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [
        isConfirmationModalActived,
        setIsConfirmationModalActived,
    ] = React.useState<boolean>(false);
    const [
        isConfirmationModalCopied,
        setIsConfirmationModalCopied,
    ] = React.useState<boolean>(false);
    const [
        isAddNewApplicationButtonClicked,
        setIsAddNewApplicationButtonClicked,
    ] = React.useState(false);

    const appId = "PROJECT-KDS";
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(useStore);

    const { isModalAssociateStore, isModalDissociateOpened, key } = useSnapshot(
        store
    );
    const [shopId, setShopId] = React.useState<string>(oneShop ? shopID : "0");
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [dataIdStore, setDataIdStore] = React.useState<any>([]);
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const uuidUser = localStorage.getItem("uuidUser");
    const url = oneShop
        ? `${process.env.REACT_APP_API_V2_URL}/settings/application/kds/project?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&appId=${appId}&typeDisplay=${typeDisplay}`
        : `${process.env.REACT_APP_API_V2_URL}/settings/application/kds/project?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&appId=${appId}&typeDisplay=${typeDisplay}`;

    const urlApi = `${process.env.REACT_APP_ACCESS_API_URL}/accounts/${uidEntity}/${uuidUser}`;
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setDataIsUpdated={setIsDataUpdated}
                    selectedRows={selectedRows}
                    isAddProject={isAddProject}
                    setIsAddProject={setIsAddProject}
                    isProjectArchived={isProjectArchived}
                    setShopId={setShopId}
                    setLocalFilterActive={setLocalFilterActive}
                    setSelectedRows={setSelectedRows}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    dataIdStore={dataIdStore}
                />
            ),
            disableFilter: false,
        },
        {
            indexOFColumn: 1,
            columnName: t("Designation"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={e.selectedRow[t("Designation")]}
                    />
                );
            },
        },
        {
            indexOFColumn: 4,
            columnName: t("Status"),
            customJsx: (e: any) => {
                return e.selectedRow.key_project !== undefined &&
                    e.selectedRow[t("Status")] === "En attente" ? (
                    !isProjectArchived ? (
                        <h4 style={{ paddingTop: "8px" }}>
                            <span
                                className="badge badge-pill badge-danger"
                                style={{
                                    paddingTop: "8px",
                                    paddingBottom: "9px",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    width: "116px",
                                }}
                            >
                                {t("Waiting")}
                            </span>
                        </h4>
                    ) : (
                        <h4 style={{ paddingTop: "8px" }}>
                            <span
                                className="badge-secondary_clz"
                                style={{
                                    paddingTop: "8px",
                                    paddingBottom: "9px",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    width: "116px",
                                    color: "black",
                                }}
                            >
                                {t("Archived")}
                            </span>
                        </h4>
                    )
                ) : null;
            },
        },
    ];
    React.useEffect(() => {
        if (filterActive) setIsAddProject(false);
    }, [filterActive]);

    React.useEffect(() => {
        let array: any = [];

        Object.values(selectedRows).forEach((element: any) => {
            if (element.projectId !== undefined) {
                array.push({ [element.shopId]: element.projectId });
            }
        });
        setSelectedApplicationKds(array);
    }, [selectedRows, shopID, oneShop, data, isProjectArchived]);

    React.useEffect(() => {
        setNumberActive(
            data.othersData !== undefined ? data.othersData.activedProjects : 0
        );
        setNumberArchived(
            data.othersData !== undefined ? data.othersData.archivedProjects : 0
        );
    }, [data]);
    React.useEffect(() => {
        if (i18nextLng === "fr" || i18nextLng === "en") {
            setIsDataUpdated(true);
        }
    }, [i18nextLng]);

    React.useEffect(() => {
        fetch(urlApi)
            .then((value) => value.json())
            .then((result) => {
                setDataIdStore(result.data);
            });
    }, [urlApi]);

    setModify(getPathAuthorization(location.pathname).Modifie);
    return (
        <React.Fragment>
            <PageTitle title={t("Applications")} />

            <div className="page-content__clz">
                <StyledBreadcrumb
                    items={[
                        {
                            item: "Settings".toUpperCase(),
                            link: `${process.env.REACT_APP_PORTAIL_URL}`,
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: oneShop
                                ? "/setting/customer-account"
                                : "/compte",
                        },
                        {
                            item: t("Application").toUpperCase(),
                            link: "applications",
                        },
                        {
                            item: t("KDS").toUpperCase(),
                            link: "kds",
                        },
                        {
                            item: t(
                                typeDisplay !== "notArchived"
                                    ? t("Archived project").toUpperCase()
                                    : t("Application Setup").toUpperCase()
                            ).toUpperCase(),
                            link: "kds-project-setting",
                        },
                    ]}
                />
                <MessageInfo
                    message={t(
                        "The KDS (Kitchen Display Systems) can be configured in this section, which allows them to be adapted to the specific needs of the store."
                    )}
                />

                <div
                    style={{
                        display: "grid",
                        minHeight: oneShop ? "650px" : "600px",
                        gridTemplateColumns: isAddProject ? "2fr 1fr " : "auto",
                        gridColumnGap: "4px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        url={url}
                        key={key}
                        name="Item_table"
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        setData={setData}
                        canExpand
                        canSort
                        customSelect
                        customJsxSideFilterButton={
                            <CustomSideFilter
                                isAddProject={isAddProject}
                                setIsAddProject={setIsAddProject}
                                setLocalFilterActive={setLocalFilterActive}
                                setDataIsUpdated={setIsDataUpdated}
                                selectedRows={selectedRows}
                                isProjectArchived={isProjectArchived}
                                setIsProjectArchived={setIsProjectArchived}
                                setIsConfirmationModalActived={
                                    setIsConfirmationModalActived
                                }
                                shopId={shopId}
                                setIsInInitializationModalOpened={
                                    setIsInInitializationModalOpened
                                }
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                setIsConfirmationModalArchived={
                                    setIsConfirmationModalArchived
                                }
                                setIsModalDissociateOpened={
                                    setIsModalDissociateOpened
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                setTypeDisplay={setTypeDisplay}
                            />
                        }
                        canResize
                        canSelect
                        elevationTable={1}
                        setSelectedRows={setSelectedRows}
                        showGlobalFilter
                        showFilter
                        canMovedCheckboxLeftOnExpand
                        actionColumn={(e: any) => {
                            return (
                                <DropDownWrapper
                                    element={e}
                                    setSelectedRows={setSelectedRows}
                                    isProjectArchived={isProjectArchived}
                                    setIsConfirmationModalArchived={
                                        setIsConfirmationModalArchived
                                    }
                                    setIsConfirmationModalActived={
                                        setIsConfirmationModalActived
                                    }
                                    setIsConfirmationModalCopied={
                                        setIsConfirmationModalCopied
                                    }
                                    setIsModalOpened={setIsModalOpened}
                                    dataIdStore={dataIdStore}
                                />
                            );
                        }}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        filterActive={filterActive}
                        setLocalFilterActive={setLocalFilterActive}
                        setDataIsUpdated={setIsDataUpdated}
                        dataIsUpdated={isDataUpdated}
                        minHeight="500px"
                        maxHeight="500px"
                    />
                    {isAddProject ? (
                        <AddProject
                            isAddProject={isAddProject}
                            setIsAddProject={setIsAddProject}
                            setDataIsUpdated={setIsDataUpdated}
                            isAddNewApplicationButtonClicked={
                                isAddNewApplicationButtonClicked
                            }
                            data={data}
                            selectedRows={selectedRows}
                            shopId={shopId}
                        />
                    ) : null}
                    {isInInitializationModalOpened === true ? (
                        <ConfirmationInitializationMessage
                            isModalOpened={isInInitializationModalOpened}
                            setIsModalOpened={setIsInInitializationModalOpened}
                            setIsClicked={setIsAddNewApplicationButtonClicked}
                            isClicked={isAddNewApplicationButtonClicked}
                            setIsEdited={setIsEdited}
                        />
                    ) : null}
                    {isHistoryModalOpened ? (
                        <HistoryModal
                            ids={selectedRows}
                            setIsModalOpened={setIsHistoryModalOpened}
                            isModalOpened={isHistoryModalOpened}
                            setIsDataUpdated={setIsDataUpdated}
                        />
                    ) : null}
                    {isConfirmationModalArchived ? (
                        <ConfirmationModalArchived
                            setIsConfirmationModalArchived={
                                setIsConfirmationModalArchived
                            }
                            isConfirmationModalArchived={
                                isConfirmationModalArchived
                            }
                            setIsDataUpdated={setIsDataUpdated}
                            selectedRows={selectedRows}
                        />
                    ) : null}
                    {isConfirmationModalActived ? (
                        <ConfirmationModalActived
                            setIsConfirmationModalActived={
                                setIsConfirmationModalActived
                            }
                            isConfirmationModalActived={
                                isConfirmationModalActived
                            }
                            setIsDataUpdated={setIsDataUpdated}
                            selectedRows={selectedRows}
                        />
                    ) : null}

                    <DeviceDissociateModal
                        selectedRows={selectedRows}
                        isDataUpdated={isDataUpdated}
                        setDataIsUpdated={setIsDataUpdated}
                        setIsDeviceDissociateModalOpened={
                            setIsDeviceDissociateModalOpened
                        }
                        isDeviceDissociateModalOpened={
                            isDeviceDissociateModalOpened
                        }
                    />
                    {isModalAssociateStore ? (
                        <ModalAssociateStore
                            rowData={selectedRows}
                            setIsDataUpdated={setIsDataUpdated}
                        />
                    ) : null}
                    {isModalDissociateOpened ? (
                        <ConfirmationDissociateProject
                            isModalDissociateOpened={isModalDissociateOpened}
                            setIsModalDissociateOpened={
                                setIsModalDissociateOpened
                            }
                            setIsDataUpdated={setIsDataUpdated}
                            selectedRows={selectedRows}
                        />
                    ) : null}
                    {isConfirmationModalCopied ? (
                        <ConfirmationModalCopied
                            setIsConfirmationModalCopied={
                                setIsConfirmationModalCopied
                            }
                            isConfirmationModalCopied={
                                isConfirmationModalCopied
                            }
                            setIsDataUpdated={setIsDataUpdated}
                            selectedRows={selectedRows}
                        />
                    ) : null}
                    {isModalOpened ? (
                        <ModalWrapper
                            isConfigurationModalOpened={isModalOpened}
                            setIsConfigurationModalOpened={setIsModalOpened}
                            rowData={selectedRows}
                            setIsDataUpdated={setIsDataUpdated}
                            setIsModalConfirmationConfigurationKds={
                                setIsModalConfirmationConfigurationKds
                            }
                        >
                            <ModalContentWrapper rowData={selectedRows} />
                        </ModalWrapper>
                    ) : null}
                    {isModalConfirmationConfigurationKds ? (
                        <ModalConfirmationConfigurationKds
                            setIsDataUpdated={setIsDataUpdated}
                            isDataUpdated={isDataUpdated}
                            setIsModalConfirmationConfigurationKds={
                                setIsModalConfirmationConfigurationKds
                            }
                            isModalConfirmationConfigurationKds={
                                isModalConfirmationConfigurationKds
                            }
                            rowData={selectedRows}
                        />
                    ) : null}
                    <ToastContainer limit={1} />
                </div>
            </div>
        </React.Fragment>
    );
}
