import React from "react";
import { useTranslation } from "react-i18next";
import { StyledH2, StyledLabel, StyledTextInput } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";
import { AvForm } from "availity-reactstrap-validation";
import moment from "moment";
import classnames from "classnames";

import { store, setIsCreatedCoping } from "../store";

import { capitalize } from "@helpers/general";
import CardFooterCreatedCoping from "./CardFooterCreatedCoping";

type ModalCreatedCopingType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<boolean>>;
    shopId: string;
    dataProject: any;
    setSelectedRows: Function;
};

export default function ModalCreatedCoping({
    rowData,
    setIsDataUpdated,
    shopId,
    dataProject,
    setSelectedRows,
}: ModalCreatedCopingType) {
    const { t } = useTranslation();
    const { isCreatedCoping } = useSnapshot(store);

    const [designation, setDesignation] = React.useState(
        `${rowData[t("Printer name")]}_${t("Copy")}_${moment().format(
            "DD/MM/YY - HH:mm:ss"
        )}`
    );
    const [inputDesignation, setInputDesignation] = React.useState(false);

    return (
        <React.Fragment>
            <Modal centered isOpen={isCreatedCoping}>
                <ModalHeader
                    toggle={() => {
                        setIsCreatedCoping!(!{ isCreatedCoping });
                        setSelectedRows([]);
                    }}
                >
                    <StyledH2>{capitalize(t("Printer duplication"))}</StyledH2>
                </ModalHeader>
                <ModalBody
                    style={{ font: "normal normal 400 18px/30px Segoe UI" }}
                >
                    {t("Please enter the name of the new printer to create")}.
                    <AvForm>
                        <StyledLabel className="mt-3" htmlFor="example-input">
                            {t("Printer designation")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            id="remark"
                            name="remark"
                            onChange={(e: any) => {
                                setInputDesignation(false);
                                setDesignation(e.target.value);
                                if (e?.target?.value?.trim() === "") {
                                    setInputDesignation(true);
                                }
                            }}
                            value={designation}
                            className={classnames({
                                input__clz: inputDesignation,
                            })}
                        />
                        {inputDesignation ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a designation")}
                            </div>
                        ) : null}
                    </AvForm>
                </ModalBody>
                <CardFooterCreatedCoping
                    rowData={rowData}
                    setIsDataUpdated={setIsDataUpdated}
                    shopId={shopId}
                    dataProject={dataProject}
                    setSelectedRows={setSelectedRows}
                    designation={designation}
                    setInputDesignation={setInputDesignation}
                />
            </Modal>
        </React.Fragment>
    );
}
