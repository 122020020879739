import { ref } from "valtio";
import moment from "moment";
import { computedStore, store } from ".";
import { UID_ENTER_BUTTON, UID_SCAN_BUTTON } from "@constants/index";

export function setPageUserAccountItems(
    items: HeaderLanguageItemsType,
    pageName: string
): void {
    ((store.project.template.pages.ways[pageName] as
        | PageType
        | OrderTakingPageType
        | PrinterOptionsPageType).header
        .userAccount as UserAccountType).languages = items;
}

export function setPageInformationMessageItems(
    items: HeaderLanguageItemsType,
    pageName: string
): void {
    (store.project.template.pages.ways[
        pageName
    ] as PageType).header.informationMessage.languages = items;
}

export function setPaymentPageInformationMessageItems(
    items: HeaderLanguageItemsType,
    pageName: string
): void {
    (store.project.template.pages.ways[
        "payment"
    ] as PaymentPageType).regulationMode[
        pageName
    ].header.informationMessage.languages = items;
}

export function setIsPageInformationMessageActive(
    isInformationMessageActive: boolean,
    pageName: string
): void {
    (store.project.template.pages.ways[
        pageName
    ] as PageType).header.informationMessage.active = isInformationMessageActive;
}

export function setPaymentIsPageInformationMessageActive(
    isInformationMessageActive: boolean,
    pageName: string
): void {
    (store.project.template.pages.ways[
        "payment"
    ] as PaymentPageType).regulationMode[
        pageName
    ].header.informationMessage.active = isInformationMessageActive;
}

export function setIsPageUserAccountActive(
    isUserAccountActive: boolean,
    pageName: string
): void {
    ((store.project.template.pages.ways[pageName] as PageType).header
        .userAccount as UserAccountType).active = isUserAccountActive;
}

export function setIsPageAccountActive(isAccountActive: boolean): void {
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).design.items.filter(
        (design) => design.name === "Customer account"
    )[0].active = isAccountActive;
}

export function setIsPageAllergenActive(isAllergenActive: boolean): void {
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).design.items.filter(
        (design) => design.name === "Allergen"
    )[0].active = isAllergenActive;
}

export function setIsPageHeaderActive(
    isHeaderActive: boolean,
    pageName: string
): void {
    (store.project.template.pages.ways[pageName] as
        | PageType
        | OrderTakingPageType).header.active = isHeaderActive;
}

export function setPaymentIsPageHeaderActive(
    isHeaderActive: boolean,
    pageName: string
): void {
    (store.project.template.pages.ways[
        "payment"
    ] as PaymentPageType).regulationMode[
        pageName
    ].header.active = isHeaderActive;
}

export function setIsPageLogoActive(
    isLogoActive: boolean,
    pageName: string
): void {
    (store.project.template.pages.ways[pageName] as
        | PageType
        | PaymentPageType).topBanner.logo.active = isLogoActive;
}

export function setIsPageTopBannerActive(
    isTopBannerActive: boolean,
    pageName: string
): void {
    (store.project.template.pages.ways[pageName] as
        | PageType
        | PaymentPageType).topBanner.active = isTopBannerActive;
}

export function setIsPageProductAccessIconActive(
    active: boolean,
    pageName: string
): void {
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).orderTakingProduct.productAccessIcon.active = active;
}

export function setIsPagePromoCodeActive(active: boolean): void {
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).summary.promoCodeActive = active;
}

export function setIsShopCartDetailsActive(active: boolean): void {
    store.isShopCartDetailsActive = active;
}

export function setIsPageCommentArticleActive(active: boolean): void {
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).orderTakingProduct.isCommentArticleActive = active;
}

export function setIsPageTagsActive(active: boolean, pageName: string): void {
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).orderTakingProduct.tags = active;
}
export function setIsPageFreeCommentActive(active: boolean): void {
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).orderTakingProduct.isFreeCommentActive = active;
}
export function setIsPageCommentRecoveryActive(active: boolean): void {
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).orderTakingProduct.isCommentRecoveryActive = active;
}

export function setIsPageSelectedCategoryTitleActive(
    active: boolean,
    pageName: string
): void {
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).orderTakingProduct.selectedCategoryTitle = active;
}

export function setIsPageActionsButtonsActive(
    isActionsButtonsActive: boolean,
    pageName: string
): void {
    (store.project.template.pages.ways[
        pageName
    ] as PageType).actionsButtons.active = isActionsButtonsActive;
}

export function setPageActionsButtonsItems(
    actionsButtonsItems: ActionsButtonsDesignItemType[],
    pageName: string
): void {
    const updatedArray = (store.project.template.pages.ways[pageName] as
        | PageType
        | OrderTakingPageType
        | PrinterOptionsPageType).actionsButtons.items.map(
        (pageActionButton) => {
            const element = actionsButtonsItems.find(
                (item) => item.id === pageActionButton.id
            );

            if (element !== undefined) {
                return element;
            }

            return pageActionButton;
        }
    );
    (store.project.template.pages.ways[
        pageName
    ] as PageType).actionsButtons.items = updatedArray;
}

export function setIsUploadRequestInProgress(progress: boolean): void {
    store.isUploadRequestInProgress = progress;
}
export function setIsPageSkipped(
    pageName: string,
    isPageSkipped: boolean
): void {
    if (
        (store.project.template.pages.ways[pageName] as PageType) !== undefined
    ) {
        (store.project.template.pages.ways[
            pageName
        ] as PageType).skipped = isPageSkipped;
    } else {
        (store.project.template.pages.elements[
            pageName
        ] as PageType).skipped = isPageSkipped;
    }
}

export function setProjectMainContentItems(
    name: string,
    items: ProjectMainContentItemType[]
): void {
    ((store.project.template.content as ProjectContentType)[
        name
    ] as ProjectContentItemType).items = items;
}

export function setConfigSalesMethods(
    salesMethods: ProjectMainContentItemType[]
): void {
    (store.project.template.content
        .salesMethods as ProjectContentItemType).items = salesMethods;
}

export function setOrderInformationModeBySaleMethod(
    saleMethod: any,
    items: any
): void {
    const itemBySelectedSalesMethod = (store.project.template.content
        .salesMethods as ProjectContentItemType).items.find(
        (sale: any) => sale.name === saleMethod
    );
    if (
        itemBySelectedSalesMethod !== undefined &&
        itemBySelectedSalesMethod.informationModes !== undefined
    ) {
        itemBySelectedSalesMethod.informationModes.items = items;
    }
}
export function setConfigInformationModes(
    informationModes: ProjectMainContentItemType[]
): void {
    const updatedArray = (store.project.template.content
        .informationModes as ProjectContentItemType).items.map((globalItem) => {
        const element = informationModes.find(
            (item) => item.id === globalItem.id
        );

        if (element !== undefined) {
            return element;
        }

        return globalItem;
    });
    (store.project.template.content
        .informationModes as ProjectContentItemType).items = updatedArray;
}

export function setConfigRegulationsModes(
    regulationsModes: ProjectMainContentItemType[]
): void {
    (store.project.template.content
        .meansOfPayment as ProjectContentItemType).items = regulationsModes;
}

export function setChosenTemplate(
    chosenTemplate: NewTemplateType,
    files: ImageItemType[]
): void {
    // @see https://developer.mozilla.org/en-US/docs/Glossary/Deep_copy
    store.project.template = JSON.parse(JSON.stringify(chosenTemplate));
    store.project.files = files;
    store.chosenTemplate = chosenTemplate;
}

export function setVisualizedTemplate(
    visualizedTemplate: NewTemplateType
): void {
    store.visualizedTemplate = visualizedTemplate;
}

export function setIsModalOpened(isModalOpened: boolean): void {
    store.isModalOpened = isModalOpened;
}

export function setItems(items: ItemsPagesType): void {
    computedStore.items = JSON.parse(JSON.stringify(items));
    store.project.template.pages = JSON.parse(JSON.stringify(items));
}

export function setIsWaysTabActive(isWaysTabActive: boolean): void {
    store.isWaysTabActive = isWaysTabActive;
}

export function setConfigOptions(options: OptionsItemType[]): void {
    (store.project.template.content.options as OptionsType).items = options;
}
export function setConfigOptionsCustomerAccount(active: boolean): void {
    (store.project.template.content.options as OptionsType).items.filter(
        (item) => item.title === "Customer account"
    )[0].active = active;
}
export function setTimeOutOptions(timeout: number): void {
    store.project.template.timeout = timeout;
}

export function setAuthenticationModesOptions(
    authenticationModes: ProjectMainContentItemType[]
): void {
    // eslint-disable-next-line
    (store.project.template.content
        .authenticationModes as ProjectContentItemType).items = authenticationModes;
}

export function setAccountCreationMethodsOptions(
    accountCreationMethods: CustomerAccountItemType[]
): void {
    // eslint-disable-next-line
    (store.project.template.content.options as OptionsType).items.filter(
        (item: OptionsItemType) => item.title === "Customer account"
    )[0].accountCreationMethods!.items = accountCreationMethods;
}

export function setIsAccountCreationMethodsOptionActive(
    isAccountCreationMethodsOptionActive: boolean
): void {
    // eslint-disable-next-line
    (store.project.template.content.options as OptionsType).items.filter(
        (item: OptionsItemType) => item.title === "Customer account"
    )[0].accountCreationMethods!.active = isAccountCreationMethodsOptionActive;
}

export function setCustomerAccountOptionsItems(
    customerAccountOptions: CustomerAccountItemType[]
): void {
    // eslint-disable-next-line
    (store.project.template.content.options as OptionsType).items.filter(
        (item: OptionsItemType) => item.title === "Customer account"
    )[0].customerAccountOptions! = customerAccountOptions;
}

export function setConfigLanguages(languages: LanguagesItemType[]): void {
    (store.project.template.content
        .languages as LanguagesType).items = languages;
}

export function setRegulationModesItems(
    items: ProjectMainContentItemType[]
): void {
    (store.project.template.content
        .meansOfPayment as ProjectContentItemType).items = items;
}

export function setChosenTransition(chosenTransition: TransitionType): void {
    store.chosenTransition = chosenTransition;
}

export function setGeneralChosenTransition(
    generalChosenTransition: string
): void {
    store.generalChosenTransition = generalChosenTransition;
}

export function setIsAnimationIn(isAnimationIn: boolean): void {
    store.isAnimationIn = isAnimationIn;
}

export function setDefaultLanguage(language: LanguagesItemType): void {
    store.defaultLanguage = language;
}

export function setDesignButtonsItems(
    items: ActionsButtonsDesignItemType[]
): void {
    (store.project.template.content
        .actionsButtons as ActionsButtonsDesignType).items = items;
    items.forEach((item) => {
        Object.values(store.project.template.pages.ways as ItemsType).forEach(
            (page) => {
                if ((page as PageType).actionsButtons !== undefined) {
                    const element = (page as PageType).actionsButtons.items.find(
                        (element) => element.id === item.id
                    );
                    if (element !== undefined) {
                        const index = (page as PageType).actionsButtons.items.indexOf(
                            element
                        );
                        setPageActionsButtonItems(
                            page.name,
                            index,
                            item.languages
                        );
                    }
                }
            }
        );
    });
}

export function setOrderTakingActionsButtonsItems(
    items: ActionsButtonsDesignItemType[]
): void {
    ((store.project.template.pages.ways.orderTaking as OrderTakingPageType)
        .actionsButtons as ActionsButtonsDesignType).items = items;
}
export function setOrderTakingDesignButtonsItems(
    items: ActionsButtonsDesignItemType[]
): void {
    ((store.project.template.pages.ways.orderTaking as OrderTakingPageType)
        .design as ActionsButtonsDesignType).items = items;
}

export function setPageActionsButtonItems(
    pageName: string,
    actionsButtonsItemIndex: number,
    languages: ActionsButtonsDesignLanguageType
): void {
    (store.project.template.pages.ways[
        pageName
    ] as PageType).actionsButtons.items[
        actionsButtonsItemIndex
    ].languages = languages;
}

export function setLogo(logo: GeneralDesignLogoType): void {
    (store.project.template.content
        .generalDesign as GeneralDesignType).logo = logo;
}

export function setPRM(prm: GeneralDesignPrmType): void {
    (store.project.template.content
        .generalDesign as GeneralDesignType).prm = prm;
}

export function setGeneralDesignFonts(fonts: GeneralDesignFontType[]): void {
    (store.project.template.content
        .generalDesign as GeneralDesignType).fonts = fonts;
}

export function setGeneralDesignColor(colors: ColorsContentType[]): void {
    (store.project.template.content
        .generalDesign as GeneralDesignType).colors = colors;
}

export function setIsSalesMethodsSkipped(isSalesMethodsSkipped: boolean): void {
    (store.project.template.pages.ways
        .salesMethods as PageType).skipped = isSalesMethodsSkipped;
}

export function setIsInformationModesSkipped(
    isInformationModesSkipped: boolean,
    salesModeValue: string
): void {
    if (
        (store.project.template.pages.ways.informationModes as PageType) !==
        undefined
    ) {
        ((store.project.template.pages.ways.informationModes as PageType)
            .skipped as ItemContentType)[
            salesModeValue
        ] = isInformationModesSkipped;
    } else {
        ((store.project.template.pages.elements.informationModes as PageType)
            .skipped as ItemContentType)[
            salesModeValue
        ] = isInformationModesSkipped;
    }
}

export function setIsRegulationModesSkipped(
    isRegulationModesSkipped: boolean
): void {
    if (
        (store.project.template.pages.ways.meansOfPayment as PageType) !==
        undefined
    ) {
        (store.project.template.pages.ways
            .meansOfPayment as PageType).skipped = isRegulationModesSkipped;
    } else {
        (store.project.template.pages.elements
            .meansOfPayment as PageType).skipped = isRegulationModesSkipped;
    }
}

export function setIsAuthenticationModeSkipped(
    isAuthenticationModesSkipped: boolean
): void {
    if (
        (store.project.template.pages.ways.authenticationModes as PageType) !==
        undefined
    ) {
        // eslint-disable-next-line
        (store.project.template.pages.ways
            .authenticationModes as PageType).skipped = isAuthenticationModesSkipped;
    } else {
        (store.project.template.pages.elements
            .authenticationModes as PageType).skipped = isAuthenticationModesSkipped;
    }
}

export function setIsAccountCreationSkipped(
    isAccountCreationSkipped: boolean
): void {
    // eslint-disable-next-line
    (store.project.template.content
        .options as OptionsType).items[0].accountCreationMethods!.skipped = isAccountCreationSkipped;
}

export function setActiveSubTab(activeSubTab: number): void {
    store.activeSubTab = activeSubTab;
}

export function setUserLanguage(userLanguage: string): void {
    store.userLanguage = userLanguage;
}

export function setProjectName(name: string): void {
    store.project.name = name;
}

export function setProjectDesignation(designation: string): void {
    store.project.designation = designation;
}

export function setProjectNote(note: string): void {
    store.project.note = note;
}

export function setProject(project: ProjectType): void {
    store.project = project;
}

export function setSelectedActionsButtonsLanguage(
    selectedActionsButtonsLanguage: string
): void {
    store.selectedActionsButtonsLanguage = selectedActionsButtonsLanguage;
}

export function setSelectedActionsButtons1Language(
    selectedActionsButtonsLanguage: string
): void {
    store.selectedActionsButtons1Language = selectedActionsButtonsLanguage;
}

export function setSelectedActionsButtons2Language(
    selectedActionsButtonsLanguage: string
): void {
    store.selectedActionsButtons2Language = selectedActionsButtonsLanguage;
}

export function setSelectedOptionsLanguage(
    selectedOptionsLanguage: string
): void {
    store.selectedOptionsLanguage = selectedOptionsLanguage;
}

export function setGeneralTransition(generalTransition: TransitionType): void {
    store.generalTransition = generalTransition;
}

export function setConfigStandbyScreen(
    standbyScreenItems: StandbyScreenItemType[]
): void {
    (store.project.template.pages.ways
        .standbyScreen as StandbyScreenPageType).items = standbyScreenItems;
}

export function setCreateAddItemStandbyScreen(
    isCreateItemStandbyScreenClicked: boolean
): void {
    store.isCreateItemStandbyScreenClicked = isCreateItemStandbyScreenClicked;
}

export function setInitialStepTimeout(initialStepTimeout: number): void {
    store.project.template.initialStepTimeout = initialStepTimeout;
}

export function setSelectedStandbyScreenItemId(
    selectedStandbyScreenItemId: string
): void {
    store.selectedStandbyScreenItemId = selectedStandbyScreenItemId;
}

export function setIsModesModalOpened(isModesModalOpened: boolean): void {
    store.isModesModalOpened = isModesModalOpened;
}

export function setIsCustomerAccountActive(
    isCustomerAccountActive: boolean
): void {
    store.isCustomerAccountActive = isCustomerAccountActive;
}

export function setIsCustomerAccountOrderTakingActive(
    isCustomerAccountOrderTakingActive: boolean
): void {
    store.isCustomerAccountOrderTakingActive = isCustomerAccountOrderTakingActive;
}
export function setKeyboard(keyBoard: string): void {
    // eslint-disable-next-line
    store.project.template.keyboard! = keyBoard;
}

export function setSelectedSaleModeValue(selectedSaleModeValue: string): void {
    store.selectedSaleModeValue = selectedSaleModeValue;
}

export function setIsPageIconActive(
    isIconActive: boolean,
    pageName: string
): void {
    ((store.project.template.pages.subSteps[pageName] as PageType).header
        .icon as IconType).active = isIconActive;
}

export function setSubStepPageActionsButtonsItems(
    actionsButtonsItems: ActionsButtonsDesignItemType[],
    pageName: string
): void {
    const updatedArray = (store.project.template.pages.subSteps[
        pageName
    ] as PageType).actionsButtons.items.map((pageActionButton) => {
        const element = actionsButtonsItems.find(
            (item) => item.id === pageActionButton.id
        );

        if (element !== undefined) {
            return element;
        }

        return pageActionButton;
    });
    (store.project.template.pages.subSteps[
        pageName
    ] as PageType).actionsButtons.items = updatedArray;
}

export function setIsSubStepPageLogoActive(
    isLogoActive: boolean,
    pageName: string
): void {
    (store.project.template.pages.subSteps[
        pageName
    ] as PageType).topBanner.logo.active = isLogoActive;
}

export function setIsSubStepPageTopBannerActive(
    isTopBannerActive: boolean,
    pageName: string
): void {
    (store.project.template.pages.subSteps[
        pageName
    ] as PageType).topBanner.active = isTopBannerActive;
}

export function setIsSubStepPageInformationMessageActive(
    isInformationMessageActive: boolean,
    pageName: string
): void {
    (store.project.template.pages.subSteps[
        pageName
    ] as PageType).header.informationMessage.active = isInformationMessageActive;
}

export function setIsSubStepPageHeaderActive(
    isSubStepHeaderActive: boolean,
    pageName: string
): void {
    (store.project.template.pages.subSteps[
        pageName
    ] as PageType).header.active = isSubStepHeaderActive;
}

export function setSubStepPageInformationMessageItems(
    items: HeaderLanguageItemsType,
    pageName: string
): void {
    (store.project.template.pages.subSteps[
        pageName
    ] as PageType).header.informationMessage.languages = items;
}

export function setIsDesignConfigOptionsActive(
    isConfigOptionsActive: boolean
): void {
    (store.project.template.content
        .options as OptionsType).active = isConfigOptionsActive;
}

export function setIsFooterTicketMessageActive(
    footerTicketMessage: boolean,
    pageName: string
): void {
    // eslint-disable-next-line
    (store.project.template.pages.ways[
        pageName
    ] as PageType).footer!.ticketNumberMessage!.active = footerTicketMessage;
}

export function setIsDesignFooterFinalMessageActive(
    footerFinalMessage: boolean,
    pageName: string
): void {
    // eslint-disable-next-line
    (store.project.template.pages.ways[
        pageName
    ] as PageType).footer!.finalMessage.active = footerFinalMessage;
}

export function setFooterTicketMessageItems(
    items: HeaderLanguageItemsType,
    pageName: string
): void {
    (((store.project.template.pages.ways[pageName] as PageType)
        .footer as FooterType)
        .ticketNumberMessage as UserAccountType).languages = items;
}

export function setFooterFinalMessageItems(
    items: HeaderLanguageItemsType,
    pageName: string
): void {
    (((store.project.template.pages.ways[pageName] as PageType)
        .footer as FooterType)
        .finalMessage as UserAccountType).languages = items;
}

export function setDesignFinalMessage(
    item: string,
    selectedRegulationModes: string,
    selectedInformationValue: string,
    selectedLanguage: string
): void {
    const filteredFinalMessage = (store.project.template.content
        .finalMessage as FinalMessageContentType).items.filter(
        (item: FinalMessageItemType) => {
            return item.title === selectedRegulationModes;
        }
    );

    filteredFinalMessage[0][selectedInformationValue].languages[
        selectedLanguage
    ].content = item;
}

export function setIsSpecificTransitionChosen(
    isSpecificTransitionChosen: boolean
): void {
    store.isSpecificTransitionChosen = isSpecificTransitionChosen;
}

export function setPaymentConfig(
    paymentMainContent: PaymentMainContentItemType[]
): void {
    (store.project.template.content
        .payment as PaymentContentItemType).items = paymentMainContent;
}

export function setIsPageBackgroundActive(
    isBackgroundActive: boolean,
    pageName: string
): void {
    (store.project.template.pages.ways[pageName] as
        | PaymentPageType
        | PageType
        | OrderTakingPageType
        | PrinterOptionsPageType).background.active = isBackgroundActive;
}

export function setIsPageBackgroundSummaryActive(
    isBackgroundActive: boolean
): void {
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).summary.background.active = isBackgroundActive;
}

export function setIsPaymentPageActionsButtonsActive(
    isActionsButtonsActive: boolean,
    pageName: string
): void {
    (store.project.template.pages.ways[
        "payment"
    ] as PaymentPageType).regulationMode[
        pageName
    ].actionsButtons.active = isActionsButtonsActive;
}

export function setPaymentPageActionsButtonsItems(
    actionsButtonsItems: ActionsButtonsDesignItemType[],
    pageName: string
): void {
    const updatedArray = (store.project.template.pages.ways[
        "payment"
    ] as PaymentPageType).regulationMode[pageName].actionsButtons.items.map(
        (pageActionButton) => {
            const element = actionsButtonsItems.find(
                (item) => item.id === pageActionButton.id
            );

            if (element !== undefined) {
                return element;
            }

            return pageActionButton;
        }
    );
    (store.project.template.pages.ways[
        "payment"
    ] as PaymentPageType).regulationMode[
        pageName
    ].actionsButtons.items = updatedArray;
}

export function setPaymentPageHeaderTPA(TPAPaymentHeader: NewHeaderType): void {
    (store.project.template.pages.subSteps[
        "TPAValina"
    ] as TPAValinaPageType).header = TPAPaymentHeader;
}

export function setPaymentPageActionButtonTPA(
    TPAPaymentActionButton: ActionsButtonsDesignItemType[]
): void {
    (store.project.template.pages.subSteps[
        "TPAValina"
    ] as TPAValinaPageType).actionsButtons.items = TPAPaymentActionButton;
}

export function setProjectFinalMessageItems(
    items: FinalMessageItemType[]
): void {
    (store.project.template.content
        .finalMessage as FinalMessageContentType).items = items;
}

export function setIsOrderTakingTopBannerActive(
    isOrderTakingTopBannerActive: boolean
): void {
    store.isOrderTakingTopBannerActive = isOrderTakingTopBannerActive;
}

export function setIsOrderTakingTagsActive(
    isOrderTakingTagsActive: boolean
): void {
    store.isOrderTakingTagsActive = isOrderTakingTagsActive;
}

export function setIsOrderTakingPromoCodeActive(
    isOrderTakingPromoCodeActive: boolean
): void {
    store.isOrderTakingPromoCodeActive = isOrderTakingPromoCodeActive;
}

export function setProjectTPAValinaModalContent(
    projectTPAValinaModalContent: TPAValinaPageType
): void {
    store.project.template.content.TPAValina = projectTPAValinaModalContent;
}

export function setProjectPaymentContent(
    projectPaymentContent: PaymentContentItemType
): void {
    store.project.template.content.payment = projectPaymentContent;
}

export function setIsResetOneImageButtonClicked(
    isResetOneImageButtonClicked: boolean
): void {
    store.isResetOneImageButtonClicked = isResetOneImageButtonClicked;
}

export function setProjectPaymentInstructionItem(
    localItems: PaymentInstructionsType,
    paymentSelectedRegulationModeValue: string,
    selectedLanguage: string
): void {
    // eslint-disable-next-line
    (store.project.template.content
        .payment as PaymentContentItemType).items.filter((regulationMode) => {
        return regulationMode.shortName === paymentSelectedRegulationModeValue;
    })[0]!.languages[selectedLanguage].paymentInstructions = localItems;
}

export function setProjectFiles(images: ImageItemType[]): void {
    store.project.files = images;
}

export function setUploadedFiles(uploadedFiles: UploadedFilesType[]): void {
    store.uploadedFiles = uploadedFiles.map(ref);
}

export function setImageKey(imageKey: string): void {
    store.imageKey = imageKey;
}
export function setOrderTakingBackground(background: BackgroundType): void {
    ((store.project.template.pages.ways["orderTaking"] as OrderTakingPageType)
        .background as BackgroundType) = background;
}

export function setIsAllergenActive(isAllergenActive: boolean): void {
    store.isAllergenActive = isAllergenActive;
}
export function setIsAllergenOrderTakingActive(
    isAllergenOrderTakingActive: boolean
): void {
    store.isAllergenOrderTakingActive = isAllergenOrderTakingActive;
}
export function setIsOrderTakingCategoryTitleActive(
    isOrderTakingCategoryTitleActive: boolean
): void {
    store.isOrderTakingCategoryTitleActive = isOrderTakingCategoryTitleActive;
}
export function setIsOrderTakingSelectedCategoryTitleActive(
    isOrderTakingSelectedCategoryTitleActive: boolean
): void {
    store.isOrderTakingSelectedCategoryTitleActive = isOrderTakingSelectedCategoryTitleActive;
}
export function setIsOrderTakingProductInformationActive(
    isOrderTakingProductInformationActive: boolean
): void {
    store.isOrderTakingProductInformationActive = isOrderTakingProductInformationActive;
}

export function setActiveStateScreenItems(
    activeStateScreenItems: StandbyScreenItemType[]
): void {
    store.activeStateScreenItems = activeStateScreenItems;
    Object.keys(store.project.template.pages.ways).includes("standbyScreen")
        ? ((store.project.template.pages.ways
              .standbyScreen as StandbyScreenPageType).items = activeStateScreenItems)
        : ((store.project.template.pages.elements
              .standbyScreen as StandbyScreenPageType).items = activeStateScreenItems);
}

export function setDefaultActiveStateScreenItems(
    defaultActiveStateScreenItems: StandbyScreenItemType[]
): void {
    store.defaultActiveStateScreenItems = defaultActiveStateScreenItems;
}

export function setIsDeleteActiveStateScreenItemModalOpened(
    isDeleteActiveStateScreenItemModalOpened: boolean
): void {
    store.isDeleteActiveStateScreenItemModalOpened = isDeleteActiveStateScreenItemModalOpened;
}

export function setStandbyStateScreenItems(
    standbyStateScreenItems: StandbyScreenItemType[]
): void {
    store.standbyStateScreenItems = standbyStateScreenItems;
    (store.project.template.content
        .standbyStateScreen as StandbyStateScreenItemsType).items = standbyStateScreenItems;
}

export function setDefaultStandbyStateScreenItems(
    defaultStandbyStateScreenItems: StandbyScreenItemType[]
): void {
    store.defaultStandbyStateScreenItems = defaultStandbyStateScreenItems;
}

export function setIsDeleteStandbyStateScreenItemModalOpened(
    isDeleteStandbyStateScreenItemModalOpened: boolean
): void {
    store.isDeleteStandbyStateScreenItemModalOpened = isDeleteStandbyStateScreenItemModalOpened;
}

export function setActiveAdvertisingScreenTab(
    activeAdvertisingScreenTab: number
): void {
    store.activeAdvertisingScreenTab = activeAdvertisingScreenTab;
}

export function setDeletedActiveStateScreenItemId(
    deletedActiveStateScreenItemId: string
): void {
    store.deletedActiveStateScreenItemId = deletedActiveStateScreenItemId;
}

export function setDeletedStandbyStateScreenItemId(
    deletedStandbyStateScreenItemId: string
): void {
    store.deletedStandbyStateScreenItemId = deletedStandbyStateScreenItemId;
}

export function setSecondColor(secondColor: string): void {
    store.secondColor = secondColor;
}

export function setOrderTakingOptionsItems(options: OptionsItemType[]): void {
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).options.items = options;
}

export function setOrderTakingSummaryOptionsItems(
    options: OptionsItemType[]
): void {
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).summary.options.items = options;
}

export function setIsOrderTakingCaloryActive(
    isOrderTakingCaloryActive: boolean
): void {
    store.isOrderTakingCaloryActive = isOrderTakingCaloryActive;
}
export function setSelectedCategoryActive(
    selectedCategoryActive: boolean
): void {
    store.selectedCategoryActive = selectedCategoryActive;
}

export function setPageShopCardMessage(
    items: ShopCardMessageLanguageType,
    pageName: string
): void {
    (store.project.template.pages.ways[
        pageName
    ] as OrderTakingPageType).options.shopCardMessage.languages = items;
}

export function setPageCompositeProductShopCardMessage(
    items: ShopCardMessageLanguageType,
    pageName: string
): void {
    (store.project.template.pages.ways[
        pageName
    ] as OrderTakingPageType).compositeProduct.shopCardMessage.languages = items;
}

export function setIsConfigActive(isConfigActive: boolean): void {
    store.isConfigActive = isConfigActive;
}

export function setSelectSubStepOrderTaking(
    selectSubStepOrderTaking: string
): void {
    store.selectSubStepOrderTaking = selectSubStepOrderTaking;
}

export function setIsProjectSaveSuccessfully(
    isProjectSaveSuccessfully: boolean
): void {
    store.isProjectSaveSuccessfully = isProjectSaveSuccessfully;
}

export function setPageOrderTakingSummary(items: any): void {
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).summary = items;
}

export function setPageOrderTaking(items: any): void {
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).orderTakingProduct = items;
}

export function setPageOrderTakingCompositeProduct(items: any): void {
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).compositeProduct = items;
}

export function setIsBackgroundSummaryActive(
    isBackgroundSummaryActive: boolean
): void {
    store.isBackgroundSummaryActive = isBackgroundSummaryActive;
}

export function setIsPRMActive(isPRMActive: boolean): void {
    store.isPRMActive = isPRMActive;
}
export function setIsHelpActive(isHelpActive: boolean): void {
    store.isHelpActive = isHelpActive;
}
export function setIsCaloryActive(isCaloryActive: boolean): void {
    store.isCaloryActive = isCaloryActive;
}

export function setIsStateChanged(isStateChanged: boolean): void {
    store.isStateChanged = isStateChanged;
}

export function setIsInformationModesEmpty(
    isInformationModesEmpty: boolean
): void {
    store.isInformationModesEmpty = isInformationModesEmpty;
}

export function setInitItemsFromGeneralSetting(initItemsFromGeneralSetting: {
    informationModes: ProjectMainContentItemType[];
    saleMethods: ProjectMainContentItemType[];
    reglements: ProjectMainContentItemType[];
    languages: LanguagesItemType[];
    logo: { name: string; content: string; id: string };
}): void {
    store.initItemsFromGeneralSetting = initItemsFromGeneralSetting;
}

export function setDefaultWays(defaultWays: ItemsPagesType): void {
    store.defaultWays = defaultWays;
}

export function setIsConfigured(isConfigured: boolean): void {
    store.isConfigured = isConfigured;
}

export function setInitStoreVariables(): void {
    store.project = {
        projectId: 0,
        name: "",
        associates: {},
        isArchived: false,
        Status: "en attente",
        modified_at: `le ${moment().format("DD/MM/YY à hh:mm a")}`,
        copy_status: false,
        hour: `${moment().format("DD/MM/YY - hh:mm a")}`,
        template: {
            id: "",
            name: "",
            coverImage: "",
            pages: {
                elements: {},
                ways: {},
                subSteps: {},
            },
            content: {
                salesMethods: { items: [] },
                meansOfPayment: { items: [] },
                informationModes: { items: [] },
                options: { active: true, items: [] },
                actionsButtons: {
                    active: true,
                    items: [
                        {
                            id: "",
                            name: "",
                            active: true,
                            languages: {
                                fr: {
                                    content: "",
                                },
                                en: {
                                    content: "",
                                },
                            },
                            role: 1,
                        },
                    ],
                },
                languages: {
                    active: true,
                    items: [
                        {
                            id: "",
                            flag: "",
                            name: "",
                            title: "",
                            language: "",
                            active: true,
                            isDefault: false,
                        },
                    ],
                },
                generalDesign: {
                    logo: {
                        id: "",
                        name: "",
                        content: "l",
                    },
                    prm: {
                        id: "",
                        name: "",
                        content: "l",
                        defaultContent: "",
                        type: "",
                        timeout: 0,
                    },
                    colors: [],
                    fonts: [],
                },
            },
            timeout: 15,
            initialStepTimeout: 10,
            keyboard: "azerty",
            autoLaunch: true,
        },
        files: [
            {
                name: "",
                id: "",
                content: "",
                type: "",
                timeout: 0,
                key: "",
                subName: "",
                defaultImage: "",
            },
        ],
        note: "",
        designation: "",
        hoursOfUse: [],
        shopId: "0",
    };
    store.chosenTemplate = {
        id: "",
        name: "",
        coverImage: "",
        pages: {
            elements: {},
            ways: {},
            subSteps: {},
        },
        content: {
            salesMethods: { items: [] },
            meansOfPayment: { items: [] },
            informationModes: { items: [] },
            options: { active: true, items: [] },
            actionsButtons: {
                active: true,
                items: [
                    {
                        id: "",
                        name: "",
                        active: true,
                        languages: {
                            fr: {
                                content: "",
                            },
                            en: {
                                content: "",
                            },
                        },
                        role: 1,
                    },
                ],
            },
            languages: {
                active: true,
                items: [
                    {
                        id: "",
                        flag: "",
                        name: "",
                        title: "",
                        language: "",
                        active: true,
                        isDefault: false,
                    },
                ],
            },
            generalDesign: {
                logo: {
                    id: "",
                    name: "",
                    content: "l",
                },
                prm: {
                    id: "",
                    name: "",
                    content: "l",
                    defaultContent: "",
                    type: "",
                    timeout: 0,
                },
                colors: [],
                fonts: [],
            },
        },
        timeout: 20,
        initialStepTimeout: 10,
        keyboard: "azerty",
        autoLaunch: true,
    };
    store.visualizedTemplate = {
        id: "",
        name: "",
        coverImage: "",
        pages: {
            elements: {},
            ways: {},
            subSteps: {},
        },
        content: {
            salesMethods: { items: [] },
            meansOfPayment: { items: [] },
            informationModes: { items: [] },
            options: { active: true, items: [] },
            actionsButtons: {
                active: true,
                items: [
                    {
                        id: "",
                        name: "",
                        active: true,
                        languages: {
                            fr: {
                                content: "",
                            },
                            en: {
                                content: "",
                            },
                        },
                        role: 1,
                    },
                ],
            },
            languages: {
                active: true,
                items: [
                    {
                        id: "",
                        flag: "",
                        name: "",
                        title: "",
                        language: "",
                        active: true,
                        isDefault: false,
                    },
                ],
            },
            generalDesign: {
                logo: {
                    id: "",
                    name: "",
                    content: "l",
                },
                prm: {
                    id: "",
                    name: "",
                    content: "l",
                    defaultContent: "",
                    type: "",
                    timeout: 0,
                },
                colors: [],
                fonts: [],
            },
        },
        timeout: 15,
        initialStepTimeout: 10,
        keyboard: "azerty",
        autoLaunch: true,
    };
    store.isModalOpened = false;
    store.activeSubTab = 0;
    store.isWaysTabActive = false;

    store.chosenTransition = { prettyName: "", timeout: 0, className: "" };
    store.generalTransition = {
        prettyName: "Fade",
        timeout: 1000,
        className: "fade",
    };
    store.isAnimationIn = false;
    store.defaultLanguage = {
        id: "",
        flag: "",
        name: "",
        title: "",
        language: "",
        active: false,
        isDefault: false,
    };
    store.userLanguage = "fr";
    store.selectedActionsButtonsLanguage = "fr";
    store.isCreateItemStandbyScreenClicked = false;
    store.selectedStandbyScreenItemId = "";
    store.isModesModalOpened = false;
    store.isCustomerAccountActive = false;
    store.selectedSaleModeValue = "";
    store.isSpecificTransitionChosen = false;
    store.isOrderTakingTopBannerActive = false;
    store.isOrderTakingTagsActive = false;
    store.isResetOneImageButtonClicked = false;
    store.isResetModalOpened = false;
    store.uploadedFiles = [];
    store.imageKey = "";
    store.generalChosenTransition = "";
    store.isAllergenActive = false;
    store.isOrderTakingCategoryTitleActive = false;
    store.isOrderTakingProductInformationActive = false;
    store.activeStateScreenItems = [];
    store.defaultActiveStateScreenItems = [];
    store.isDeleteActiveStateScreenItemModalOpened = false;
    store.standbyStateScreenItems = [];
    store.defaultStandbyStateScreenItems = [];
    store.isDeleteStandbyStateScreenItemModalOpened = false;
    store.activeAdvertisingScreenTab = 0;
    store.deletedActiveStateScreenItemId = "";
    store.deletedStandbyStateScreenItemId = "";
    store.secondColor = "";
    store.selectedCategoryActive = false;
    store.isConfigActive = false;
    store.selectSubStepOrderTaking = "";
    store.isProjectSaveSuccessfully = false;
    computedStore.items = store.project.template.pages;
    store.initItemsFromGeneralSetting = {
        informationModes: [],
        saleMethods: [],
        reglements: [],
        languages: [],
        logo: { name: "", content: "", id: "" },
    };
    store.defaultWays = {};
    store.isOrderTakingCaloryActive = false;
    store.isStateChanged = false;
    store.selectedOptionsLanguage = "fr";
    store.isInformationModesEmpty = false;
    store.isConfigured = false;
}

export function setChangeMachineTimeOut(timeout: number): void {
    (store.project.template.pages.ways
        .payment as PaymentPageType).regulationMode.changeMachine.timeout = timeout;
}

export function setIsAutoLaunchActivated(autoLaunch: boolean): void {
    store.project.template.autoLaunch = autoLaunch;
}

export function setActiveStandbyScreenFile(
    activeStandbyScreenFile: StandbyScreenItemType[]
) {
    store.activeStandbyScreenFile = activeStandbyScreenFile;
}

export function setStandbyScreenFile(
    standbyScreenFile: StandbyScreenItemType[]
) {
    store.standbyScreenFile = standbyScreenFile;
}
export function setIsCommentArticleActive(
    isCommentArticleActive: boolean
): void {
    store.isCommentArticleActive = isCommentArticleActive;
}
export function setIsFreeCommentActive(isFreeCommentActive: boolean): void {
    store.isFreeCommentActive = isFreeCommentActive;
}
export function setIsCommentRecoveryActive(
    isCommentRecoveryActive: boolean
): void {
    store.isCommentRecoveryActive = isCommentRecoveryActive;
}
export function setIsPaperlessTicketActive(
    isPaperlessTicketActive: boolean
): void {
    store.isPaperlessTicketActive = isPaperlessTicketActive;
    if (
        (store.project.template.pages.ways
            .printerOptions as PrinterOptionsPageType) !== undefined
    ) {
        (store.project.template.pages.ways[
            "printerOptions"
        ] as PrinterOptionsPageType).isPaperlessTicketActive = isPaperlessTicketActive;
    } else {
        (store.project.template.pages.elements[
            "printerOptions"
        ] as PrinterOptionsPageType).isPaperlessTicketActive = isPaperlessTicketActive;
    }
}

export function setIsEmailActive(isEmailActive: boolean): void {
    store.isEmailActive = isEmailActive;
    if (
        (store.project.template.pages.ways
            .printerOptions as PrinterOptionsPageType) !== undefined
    ) {
        ((store.project.template.pages.ways[
            "printerOptions"
        ] as PrinterOptionsPageType)
            .printerOptionsConfig as PrinterOptionsConfigType).isEmailActive = isEmailActive;
    } else {
        ((store.project.template.pages.elements[
            "printerOptions"
        ] as PrinterOptionsPageType)
            .printerOptionsConfig as PrinterOptionsConfigType).isEmailActive = isEmailActive;
    }
}
export function setIsConfirmationEmailActive(
    isConfirmationEmailActive: boolean
): void {
    store.isConfirmationEmailActive = isConfirmationEmailActive;

    if (
        (store.project.template.pages.ways
            .printerOptions as PrinterOptionsPageType) !== undefined
    ) {
        ((store.project.template.pages.ways[
            "printerOptions"
        ] as PrinterOptionsPageType)
            .printerOptionsConfig as PrinterOptionsConfigType).isConfirmationEmailActive = isConfirmationEmailActive;
    } else {
        ((store.project.template.pages.elements[
            "printerOptions"
        ] as PrinterOptionsPageType)
            .printerOptionsConfig as PrinterOptionsConfigType).isConfirmationEmailActive = isConfirmationEmailActive;
    }
}
export function setIsUnauthenticatedEmailActive(
    isUnauthenticatedEmailActive: boolean
): void {
    store.isUnauthenticatedEmailActive = isUnauthenticatedEmailActive;
    if (
        (store.project.template.pages.ways
            .printerOptions as PrinterOptionsPageType) !== undefined
    ) {
        ((store.project.template.pages.ways[
            "printerOptions"
        ] as PrinterOptionsPageType)
            .printerOptionsConfig as PrinterOptionsConfigType).isUnauthenticatedEmailActive = isUnauthenticatedEmailActive;
    } else {
        ((store.project.template.pages.elements[
            "printerOptions"
        ] as PrinterOptionsPageType)
            .printerOptionsConfig as PrinterOptionsConfigType).isUnauthenticatedEmailActive = isUnauthenticatedEmailActive;
    }
}

export function setFunctionIsPaperlessChecked(
    functionIsPaperlessChecked: Function
): void {
    store.functionIsPaperlessChecked = functionIsPaperlessChecked;
}
export function setInformationPrinterOptionsWithEmailItems(
    informationPrinterOptionsWithEmailItems: PrinterOptionsInformationMessageContentType
): void {
    (((store.project.template.pages.ways[
        "printerOptions"
    ] as PrinterOptionsPageType).header as PrinterOptionsNewHeaderType)
        .informationMessage as PrinterOptionsInformationMessageType).withemail = informationPrinterOptionsWithEmailItems;
}
export function setInformationPrinterOptionsWithOutEmailItems(
    informationPrinterOptionsWithOutEmailItems: PrinterOptionsInformationMessageContentType
): void {
    (((store.project.template.pages.ways[
        "printerOptions"
    ] as PrinterOptionsPageType).header as PrinterOptionsNewHeaderType)
        .informationMessage as PrinterOptionsInformationMessageType).withoutemail = informationPrinterOptionsWithOutEmailItems;
}
export function setInformationPrinterOptionsEntryEmailItems(
    informationPrinterOptionsEntryEmailItems: PrinterOptionsInformationMessageContentType
): void {
    ((((store.project.template.pages.subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType)
        .header as PrinterOptionsConfirmationEmailNewHeaderType)
        .informationMessage as PrinterOptionsConfirmationEmailInformationMessageType).entryemail = informationPrinterOptionsEntryEmailItems;
}
export function setInformationPrinterOptionsConfirmationEmailItems(
    informationPrinterOptionsConfirmationEmailItems: PrinterOptionsInformationMessageContentType
): void {
    ((((store.project.template.pages.subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType)
        .header as PrinterOptionsConfirmationEmailNewHeaderType)
        .informationMessage as PrinterOptionsConfirmationEmailInformationMessageType).confirmationemail = informationPrinterOptionsConfirmationEmailItems;
}
export function setItemsPrinterOptions(
    itemsPrinterOptions: printerOptionsItemsType
): void {
    (store.project.template.pages.ways[
        "printerOptions"
    ] as PrinterOptionsPageType).items = itemsPrinterOptions;
}
export function setIsBackgroundPrinterOptionsConfirmationEmailActive(
    isBackgroundPrinterOptionsConfirmationEmailActive: boolean
): void {
    ((store.project.template.pages.subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).background.active = isBackgroundPrinterOptionsConfirmationEmailActive;
}
export function setIsLogoPrinterOptionsConfirmationEmailActive(
    isLogoPrinterOptionsConfirmationEmailActive: boolean
): void {
    ((store.project.template.pages.subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).topBanner.logo.active = isLogoPrinterOptionsConfirmationEmailActive;
}
export function setIsTopBannerPrinterOptionsConfirmationEmailActive(
    isTopBannerPrinterOptionsConfirmationEmailActive: boolean
): void {
    ((store.project.template.pages.subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).topBanner.active = isTopBannerPrinterOptionsConfirmationEmailActive;
}
export function setIsActionButtonsPrinterOptionsActive(
    isActionButtonsPrinterOptionsActive: boolean
): void {
    (store.project.template.pages.ways[
        "printerOptions"
    ] as PrinterOptionsPageType).topBanner.active = isActionButtonsPrinterOptionsActive;
}
export function setActionsButtonsPrinterOptionsItems(
    actionsButtonsPrinterOptionsItems: ActionsButtonsDesignItemType[]
): void {
    (((store.project.template.pages.subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType)
        .actionsButtons as ActionsButtonsDesignType).items = actionsButtonsPrinterOptionsItems;
}
export function setFiles(files: ImageItemType[]) {
    store.files = files;
}

export function setDesignCategories(designCategories: string | number): void {
    store.designCategories = designCategories;
}

export function setIsFooterTicketNumberActive(
    footerTicketNumberActive: boolean
): void {
    ((store.project.template.pages.ways["finalMessage"] as PageType)
        .footer as FooterType).isTicketNumberActive = footerTicketNumberActive;
}

export function setIsNutriscoreActive(active: boolean): void {
    store.isNutriscoreActive = active;
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).orderTakingProduct.isNutriscoreActive = active;
    if (active === false) {
        store.isNutriscoreInCompositeProductActive = active;
        (store.project.template.pages.ways[
            "orderTaking"
        ] as OrderTakingPageType).compositeProduct.isNutriscoreInCompositeProductActive = active;
        store.isNutriscoreOnTheProductActive = active;
        (store.project.template.pages.ways[
            "orderTaking"
        ] as OrderTakingPageType).orderTakingProduct.isNutriscoreOnTheProductActive = active;
        store.isNutriscoreInTheInformationPopUpActive = active;
        (store.project.template.pages.ways[
            "orderTaking"
        ] as OrderTakingPageType).orderTakingProduct.isNutriscoreInTheInformationPopUpActive = active;
    }
}
export function setIsNutriscoreInCompositeProductActive(active: boolean): void {
    store.isNutriscoreInCompositeProductActive = active;
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).compositeProduct.isNutriscoreInCompositeProductActive = active;
}

export function setIsNutriscoreOnTheProductActive(active: boolean): void {
    store.isNutriscoreOnTheProductActive = active;
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).orderTakingProduct.isNutriscoreOnTheProductActive = active;
}

export function setIsNutriscoreInTheInformationPopUpActive(
    active: boolean
): void {
    store.isNutriscoreInTheInformationPopUpActive = active;
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).orderTakingProduct.isNutriscoreInTheInformationPopUpActive = active;
}

export function setIsBorderCardProductActive(active: boolean): void {
    store.isBorderCardProductActive = active;
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).orderTakingProduct.isBorderCardProductActive = active;
}

export function setIsFieldRequiredEmpty(isFieldRequiredEmpty: boolean) {
    store.isFieldRequiredEmpty = isFieldRequiredEmpty;
}
export function setIsCoverImageActive(active: boolean): void {
    store.isCoverImageActive = active;
    (store.project.template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).orderTakingProduct.isCoverImageActive = active;
}

export function setIsTCPOSAvailable(isTCPOSAvailable: boolean): void {
    store.isTCPOSAvailable = isTCPOSAvailable;
}
export function setItemsDisable(itemsDisable: any) {
    store.itemsDisable = itemsDisable;
}

export function setAccountFidelity(item: PageType): void {
    (store.project.template.pages.subSteps[
        "accountFidelite"
    ] as PageType) = item;
}
export function setIsEnterOrScanPromoCode(isEnterOrScanPromoCode: boolean) {
    ((((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .promoCode as RewardType).active = isEnterOrScanPromoCode;
}
export function setIsGift(isGift: boolean) {
    ((((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .gifts as RewardType).active = isGift;
}
export function setIsRewards(isRewards: boolean) {
    ((((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .rewards as RewardType).active = isRewards;
}
export function setIsPaymentWithLoyaltyBalance(
    isPaymentWithLoyaltyBalance: boolean
) {
    (((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).paymentWithLoyaltyBalance = isPaymentWithLoyaltyBalance;
}
export function setIsCumulativeAdvantage(isCumulativeAdvantage: boolean) {
    (((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).cumulativeAdvantage = isCumulativeAdvantage;
}
export function setIsAccountCreation(isAccountCreation: boolean) {
    (((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).accountCreation = isAccountCreation;
}
export function setIsAutoLaunchOnOrderTaking(
    isAutoLaunchOnOrderTaking: boolean
) {
    (((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).autoLaunchOnOrderTaking = isAutoLaunchOnOrderTaking;
}
export function setIsAutoLaunchOnPayButton(isAutoLaunchOnPayButton: boolean) {
    (((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).autoLaunchOnPayButton = isAutoLaunchOnPayButton;
}
export function setIsHeaderInformationMessage(
    isHeaderInformationMessage: boolean
) {
    (((((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .customerBenefitHeader as CustomerBenefitHeaderType)
        .informationMessage as CustomerBalanceType).active = isHeaderInformationMessage;
}
export function setIsCustomerBalance(isCustomerBalance: boolean) {
    (((((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .customerBenefitHeader as CustomerBenefitHeaderType)
        .customerBalance as CustomerBalanceType).active = isCustomerBalance;
}
export function setIsBenefitsCustomerAccount(
    isBenefitsCustomerAccount: boolean
) {
    ((((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .customerBenefitHeader as CustomerBenefitHeaderType).customerAccountAdvantages = isBenefitsCustomerAccount;
}
export function setIsProfileClient(isProfileClient: boolean) {
    ((((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .customerBenefitHeader as CustomerBenefitHeaderType).customerProfile = isProfileClient;
}
export function setHeaderBenefitsClientInformationMessage(
    headerBenefitsClientInformationMessage: RefactoredLanguageType
) {
    ((((((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .customerBenefitHeader as CustomerBenefitHeaderType)
        .informationMessage as CustomerBalanceType)
        .languages as RefactoredLanguageType) = headerBenefitsClientInformationMessage;
}
export function setHeaderCustomerBalanceMessage(
    headerColoreaderCustomerBalanceMessage: RefactoredLanguageType
) {
    ((((((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .customerBenefitHeader as CustomerBenefitHeaderType)
        .customerBalance as CustomerBalanceType)
        .languages as RefactoredLanguageType) = headerColoreaderCustomerBalanceMessage;
}
export function setIsActiveNoThanksButton(isActiveNoThanksButton: boolean) {
    (((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).nothanksButton = isActiveNoThanksButton;
}
export function setMessagePromoCode(messagePromoCode: RefactoredLanguageType) {
    (((((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).promoCode as RewardType)
        .languages as RefactoredLanguageType) = messagePromoCode;
}
export function setMessageGifts(messageGifts: RefactoredLanguageType) {
    (((((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).gifts as RewardType)
        .languages as RefactoredLanguageType) = messageGifts;
}
export function setMessageRewards(messageRewards: RefactoredLanguageType) {
    (((((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).rewards as RewardType)
        .languages as RefactoredLanguageType) = messageRewards;
}
export function setIsActiveEnterButton(isActiveEnterButton: boolean) {
    (((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .actionsButtons as CustomerBenefitActionsButtonsType)[
        UID_ENTER_BUTTON
    ].active = isActiveEnterButton;
}
export function setIsActiveScanButton(isActiveScanButton: boolean) {
    (((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .actionsButtons as CustomerBenefitActionsButtonsType)[
        UID_SCAN_BUTTON
    ].active = isActiveScanButton;
}
export function setPictureScan(pictureScan: RefactoredLanguageType) {
    (((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .actionsButtons as CustomerBenefitActionsButtonsType)[
        UID_SCAN_BUTTON
    ].languages = pictureScan;
}
export function setPictureButton(
    uidButton: string,
    pictureButton: RefactoredLanguageType
) {
    (((store.project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .actionsButtons as CustomerBenefitActionsButtonsType)[
        uidButton
    ].languages = pictureButton;
}
