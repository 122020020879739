import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import {
    setLocalShop,
    setNumberActive,
    setNumberArchived,
    store as generalSettingStore,
    setIdsLocalShop,
    setLanguagesData,
    setArchivedLanguageFranchise,
    setIsAssociatedLangues,
    setLanguagesFranchise,
    setDataArchive,
    setArchivedLanguageOfFranchise,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setValidationAction,
    setKey,
} from "../store";

import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";

import { ConfirmationActiveLanguage } from "./ConfirmationActiveLanguage";
import { CustomIcons } from "./CustomIcons";
import { LanguageForm } from "./LanguageForm";
import { DisplayName } from "../DisplayName";
import { CustomSideFilter } from "./CustomSideFilter";
import { History } from "../History";
import { ConfirmationAssociatedMessage } from "../ConfirmationAssociatedMessage";
import { ConfirmationArchiveLanguage } from "../ConfirmationArchiveLanguage";
import { ConfirmationAssociateModifiedMessage } from "./ConfirmationAssociateModifiedMessage";

import { ConfirmationActivationDeactivationMessageModal } from "@components/Common/ConfirmationActivationDeactivationMessageModal";
import { store as StoreConfirmationActivationDeactivation } from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "../index.css";

export function Languages({
    setIsArchivedLanguagesClicked,
    isArchivedLanguagesClicked,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(store);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const didMountRef = React.useRef(false);
    const { activationDeactivationMessageModalOpened } = useSnapshot(
        StoreConfirmationActivationDeactivation
    );
    const [isConsult, setIsConsult] = React.useState<boolean>(false);
    const {
        isAssociatedLangues,
        isValidateArchiveOpened,
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
        key,
    } = useSnapshot(generalSettingStore);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [isAssociateModified, setIsAssociateModified] = React.useState<
        boolean
    >(false);
    const [
        isAddNewLanguageButtonClicked,
        setIsAddNewLanguageButtonClicked,
    ] = useState(false);
    const [rowData, setRowData] = useState<any>({});
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);
    let dataAssociate: any[] = [];

    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [Language, setLanguage] = useState<any[]>([]);
    const [shopId, setShopId] = React.useState<number | string>(
        oneShop ? shopID : 0
    );
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [dataTable, setDataTable] = React.useState<any>({});
    const [isAddLanguageClicked, setIsAddLanguageClicked] = React.useState<
        boolean
    >(false);
    const [selectedElement, setSelectedElement] = React.useState<any>({});
    const [dataState, setDataState] = useState<any>({});
    const urlArchive = `${process.env.REACT_APP_API_V2_URL}/settings/general/archived_language?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;
    const urlActive = `${process.env.REACT_APP_API_V2_URL}/settings/general/language?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Language"),
            customJsx: (e: any) => <span>{e.selectedRow[t("Language")]}</span>,
            filterName: t("Language"),
            canFilter: true,
        },
        {
            indexOFColumn: 1,
            columnName: t("Display name"),
            customJsx: (e: any) => <DisplayName e={e} />,
            filterName: "display_name",
            canFilter: true,
        },
        {
            indexOFColumn: 3,
            columnName: t("Country"),
            customJsx: (e: any) => <GetCountry e={e} />,
            filterName: t("Country"),
            canFilter: true,
        },
        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setShopId={setShopId}
                    setRowData={setRowData}
                    setLanguage={setLanguage}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    setSelectedRows={setSelectedRows}
                    dataIsUpdated={dataIsUpdated}
                    isArchivedLanguagesClicked={isArchivedLanguagesClicked}
                    isAddLanguageClicked={isAddLanguageClicked}
                    setIsAddLanguageClicked={setIsAddLanguageClicked}
                    setIsAssociateModified={setIsAssociateModified}
                    dataAssociate={dataAssociate}
                    setSelectedElement={setSelectedElement}
                    setIsConsult={setIsConsult}
                    setDataState={setDataState}
                    setLocalFilterActive={setLocalFilterActive}
                />
            ),
        },
    ];

    function GetCountry(e: any) {
        return (
            <React.Fragment>
                {e.e.selectedRow?.subRows !== undefined
                    ? e.e.selectedRow.country
                    : e.e.selectedRow[t("Country")]}
            </React.Fragment>
        );
    }

    const ActiveArchiveCount = () => {
        if (dataTable.othersData !== undefined) {
            if (dataTable.othersData.numberActive !== undefined) {
                setNumberActive(dataTable.othersData.numberActive);
                setNumberArchived(dataTable.othersData.numberArchive);
            } else {
                setNumberActive(0);
                setNumberArchived(0);
            }
        }
    };

    React.useEffect(() => {
        ActiveArchiveCount();
        // eslint-disable-next-line
    }, [dataTable]);

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];
        selectedRows.forEach((el: any) => {
            localShop.push(el.shopId);
            idsLocalShop.push(el.Id);
        });
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        setLanguagesData(dataTable.data !== undefined ? dataTable.data : []);
        setLanguagesFranchise(
            dataTable.othersData !== undefined &&
                dataTable.othersData.languagesOfFranchise !== undefined
                ? dataTable.othersData.languagesOfFranchise
                : []
        );
        setArchivedLanguageFranchise(
            dataTable.othersData !== undefined &&
                dataTable.othersData.archivedLanguageFranchise !== undefined
                ? dataTable.othersData.archivedLanguageFranchise
                : []
        );
        setDataArchive(
            dataTable.othersData !== undefined &&
                dataTable.othersData.dataArchive !== undefined
                ? dataTable.othersData.dataArchive
                : []
        );
        setArchivedLanguageOfFranchise(
            dataTable.othersData !== undefined &&
                dataTable.othersData.dataOfFranchiseArchive !== undefined
                ? dataTable.othersData.dataOfFranchiseArchive
                : []
        );
    }, [selectedRows, dataTable]);

    React.useEffect(() => {
        if (filterActive) setIsAddLanguageClicked(false);
    }, [filterActive]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    React.useEffect(() => {
        setIsAddLanguageClicked(false);
        if (didMountRef.current) {
            setDataIsUpdated(true);
        } else {
            didMountRef.current = true;
        }
        setKey(Math.floor(Math.random() * 10000));
    }, [i18nextLng]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: isAddLanguageClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
                key={key}
            >
                <DynamicTable
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    canMovedCheckboxLeftOnExpand
                    url={isArchivedLanguagesClicked ? urlArchive : urlActive}
                    setData={setDataTable}
                    canSelect
                    customSelect
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <CustomSideFilter
                            setLanguage={setLanguage}
                            setShopId={setShopId}
                            selectedRows={selectedRows}
                            Language={Language}
                            setIsInInitializationModalOpened={
                                setIsInInitializationModalOpened
                            }
                            setDataIsUpdated={setDataIsUpdated}
                            dataIsUpdated={dataIsUpdated}
                            isHistoryModalOpened={isHistoryModalOpened}
                            setIsHistoryModalOpened={setIsHistoryModalOpened}
                            setLocalFilterActive={setLocalFilterActive}
                            setIsModalOpened={setIsModalOpened}
                            isModalOpened={isModalOpened}
                            setIsArchivedLanguagesClicked={
                                setIsArchivedLanguagesClicked
                            }
                            isArchivedLanguagesClicked={
                                isArchivedLanguagesClicked
                            }
                            isAddLanguageClicked={isAddLanguageClicked}
                            setIsAddLanguageClicked={setIsAddLanguageClicked}
                        />
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="language"
                />
                {isAddLanguageClicked ? (
                    <LanguageForm
                        setIsAddLanguageClicked={setIsAddLanguageClicked}
                        isAddLanguageClicked={isAddLanguageClicked}
                        setDataIsUpdated={setDataIsUpdated}
                        rowData={Language}
                        isAddNewLanguageButtonClicked={
                            isAddNewLanguageButtonClicked
                        }
                        setIsAddNewLanguageButtonClicked={
                            setIsAddNewLanguageButtonClicked
                        }
                        setDataLanguage={setLanguage}
                        setLocalFilterActive={setLocalFilterActive}
                        dataIsUpdated={dataIsUpdated}
                        setIsConsult={setIsConsult}
                        isConsult={isConsult}
                        dataState={dataState}
                    />
                ) : null}
            </div>
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewLanguageButtonClicked}
                    isClicked={isAddNewLanguageButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isHistoryModalOpened === true ? (
                <History
                    ids={selectedRows}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    rowData={rowData}
                    setDataShopIsUpdated={setDataIsUpdated}
                />
            ) : null}
            <ConfirmationActiveLanguage
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
                setDataIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
                selectedRows={selectedRows}
                isOneShop={true}
            />
            {isAssociatedLangues && oneShop ? (
                <ConfirmationAssociatedMessage
                    setRowData={setLanguage}
                    setDataIsUpdated={setDataIsUpdated}
                    isModalOpened={isAssociatedLangues}
                    setIsModalOpened={setIsAssociatedLangues}
                />
            ) : null}
            {isValidateArchiveOpened ? (
                <ConfirmationArchiveLanguage
                    selectedRows={selectedRows}
                    setLimit={setLimit}
                />
            ) : null}
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isAssociateModified ? (
                <ConfirmationAssociateModifiedMessage
                    isModalOpened={isAssociateModified}
                    setIsModalOpened={setIsAssociateModified}
                    e={selectedElement}
                    languageState={rowData.isActive}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                />
            ) : null}
            {activationDeactivationMessageModalOpened && oneShop && (
                <ConfirmationActivationDeactivationMessageModal />
            )}
        </React.Fragment>
    );
}
