import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { Card } from "reactstrap";
import { uid } from "react-uid";

import {
    generalConfigStore,
    designStore,
    setProjectFiles,
    setIsResetOneImageButtonClicked,
    setFiles,
} from "@store";

import { getImageContentById } from "@helpers/general";

import { Uploader } from "@components/Uploader";

export function UsualModeWrapper(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template, files },
        imageKey,
        isResetOneImageButtonClicked,
        selectedOptionsLanguage,
    } = useSnapshot(generalConfigStore);
    const { selectedLanguage } = useSnapshot(designStore);

    const usualMode = (template.content.options as OptionsType).items.filter(
        (item: any) => item.title === "Usual"
    )[0];

    const [cardImageKey, setCardImageKey] = React.useState<string>("");

    const imageContent = getImageContentById(
        usualMode.id,
        files,
        selectedLanguage
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...files];
        const image = imagesArray.find(
            (element) => element.name === "Usual" && element.id === id
        );

        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            const localContent = image.content as ImageItemLanguagesType;

            const modifiedLanguage = Object.keys(localContent).reduce(
                (prev: any, key: any) => {
                    prev[key] = {
                        ...localContent[key],
                        path: localContent[key].defaultImage as string,
                    };

                    return prev;
                },
                {}
            );
            imagesArray[imageIndex] = {
                ...image,
                content: {
                    ...modifiedLanguage,
                } as ImageItemLanguagesType,
            };
        }
        setFiles(imagesArray);
        setProjectFiles(imagesArray);
        setCardImageKey(uid(imagesArray));
    }

    return (
        <div className="d-flex flex-column cursor__clz" style={{ gap: "10px" }}>
            <Card
                style={{
                    width: "270px",
                    height: "230px",
                    borderRadius: "20px",
                    display: "grid",
                    gridTemplateRows: "0.1fr 0.8fr auto",
                    gap: "10px",
                }}
            >
                <div className="d-flex justify-content-end">
                    <StyledIconButton
                        title={t("Reset to default image")}
                        outline={true}
                        variant="danger"
                        className="m-0 p-0 "
                        icon="RefreshIcon"
                        style={{ height: "20%", width: "20px" }}
                        onClick={() => {
                            handleResetImagesOnClickEvent(usualMode.id);
                            setIsResetOneImageButtonClicked(true);
                        }}
                    >
                        <RefreshIcon height={20} width={20} />
                    </StyledIconButton>
                </div>
                <div className="d-flex justify-content-center align-items-end">
                    <img
                        alt="img"
                        src={imageSrc}
                        style={{
                            width: "100px",
                            height: "100px",
                        }}
                    />
                </div>

                <div className="d-flex justify-content-center align-items-center h-100 cmn_icn_uploadPhoto">
                    <StyledIconButton
                        style={{
                            width: "50px",
                            height: "50px",
                            boxShadow: "0px 3px 6px #00000029",
                        }}
                        className="m-0"
                    >
                        <Uploader
                            id={usualMode.id}
                            key={
                                isResetOneImageButtonClicked === false
                                    ? imageKey
                                    : cardImageKey
                            }
                            activeLanguage={selectedOptionsLanguage}
                        />
                    </StyledIconButton>
                </div>
            </Card>

            <div className="d-flex justify-content-center align-items-center">
                <span
                    style={{
                        font: "normal normal normal 15px/20px Nunito Sans",
                        color: "black",
                    }}
                >
                    {t(usualMode.title)}
                </span>
            </div>
        </div>
    );
}
