import React from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import { StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import classnames from "classnames";

import { store } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type DisplayNameType = {
    selectedRows: any[];
    isHistoryModalOpened: boolean;
    setIsHistoryModalOpened: Function;
    setDataShopIsUpdated: Function;
    displayMode: string;
};

export function ApplicationHistory({
    selectedRows,
    isHistoryModalOpened,
    setIsHistoryModalOpened,
    setDataShopIsUpdated,
    displayMode,
}: DisplayNameType) {
    const { t } = useTranslation();
    const { userID, franchiseID, oneShop, shopID } = useSnapshot(store);

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [ids, setIds] = React.useState<any>({});
    const [data, setData] = React.useState<any>({});
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const url = `${
        process.env.REACT_APP_API_V2_URL
    }/settings/general/application/history?userId=${userID}&franchiseId=${franchiseID}&applications=${JSON.stringify(
        ids
    )}`;

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    const getArrayOfIds = () => {
        let ids: any = {};
        let arrayApplications: any = [];
        oneShop
            ? selectedRows.forEach((element: any) => {
                  if (
                      (displayMode === t("View by device") &&
                          element.subRows === undefined) ||
                      (displayMode !== t("View by device") &&
                          element.subRows !== undefined)
                  ) {
                      arrayApplications.push(element.appId);
                  }
              })
            : selectedRows.forEach((element: any) => {
                  if (
                      (element[t("Shop")] === undefined ||
                          element[t("Shop")] === "") &&
                      ((element.Application !== "" &&
                          displayMode !== t("View by device")) ||
                          displayMode === t("View by device"))
                  ) {
                      if (Object.keys(ids).includes(element.shopId)) {
                          ids[element.shopId] = [
                              ...ids[element.shopId],
                              element.appId,
                          ];
                      } else ids[element.shopId] = [element.appId];
                  }
              });
        setIds(oneShop ? { [shopID]: arrayApplications } : ids);
    };

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Before modification"),
            customJsx: (e: any) => {
                return <div>{e.selectedRow.beforeModification}</div>;
            },
        },
        {
            indexOFColumn: 3,
            columnName: t("After modification"),
            customJsx: (e: any) => {
                return <div>{e.selectedRow.afterModification}</div>;
            },
        },
        {
            indexOFColumn: 4,
            columnName: t("Operator"),
            customJsx: (e: any) => {
                return <div>{e.selectedRow.Operator}</div>;
            },
        },
        {
            indexOFColumn: 5,
            columnName: t("Date and hour"),
            customJsx: (e: any) => {
                return <div>{e.selectedRow["Date and hour"]}</div>;
            },
        },
    ];

    React.useEffect(() => {
        getArrayOfIds();
        // eslint-disable-next-line
    }, []);
    
    return (
        <StyledModal
            toggle={true}
            isOpen={isHistoryModalOpened!}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsHistoryModalOpened(!isHistoryModalOpened);
                    setDataShopIsUpdated(true);
                }}
            >
                <StyledH2 className="text-uppercase">{t("History")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <div
                    className={`${classnames("title__clz", {
                        "not-allowed-icon__clz":
                            Object.keys(ids).length > 1 ||
                            Object.values(ids).some((el: any) => el.length > 1),
                    })}`}
                >
                    {t("Application")}:
                    {Object.keys(ids).length > 1 ||
                    Object.values(ids).some((el: any) => el.length > 1)
                        ? null
                        : data.data !== undefined &&
                          data.data[0]["Application"]}
                </div>
                <DynamicTable
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    url={url}
                    canResize
                    actionColumn={ActionColumn}
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    setDataIsUpdated={setDataIsUpdated}
                    setData={setData}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight="500px"
                    name="history"
                    arrayOfCustomColumns={arrayOfCustomColumns}
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomMainColorButton
                    variant="primary"
                    onClick={() => {
                        setIsHistoryModalOpened(!isHistoryModalOpened);
                        setDataShopIsUpdated(true);
                    }}
                    rounded
                    className="lan_btn_HSclose"
                >
                    {t("Close")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
