import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutate } from "swr";
import {
    CrossIcon,
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { Modal } from "reactstrap";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "../../../components/Common/CustomCardStyled";

import { store as fidelity, setIsConsultModalOpened } from "./store";
import { store } from "@components/VerticalLayout/store";
import FidelityAuresKonnect from "./FidelityFranchise/FidelityAuresKonnect";
import { capitalize } from "../../../helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { numberMatchWord } from "@constants/index";
import FidelitySynapsy from "./FidelityFranchise/FidelitySynapsy";
import FidelityZerosix from "./FidelityFranchise/FidelityZerosix";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type FidelityFormType = {
    selectedShopsRows: any;
    setGlobalDataIsUpdated: Function;
};

export default function InformationFidelity({
    selectedShopsRows,
    setGlobalDataIsUpdated,
}: FidelityFormType) {
    const { t } = useTranslation();
    const { isConsultModalOpened } = useSnapshot(fidelity);
    const [designation, setDesignation] = React.useState<string>("");
    const [choiceFidelity, setChoiceFidelity] = React.useState<string>("");
    const [numberFidelityPoints, setNumberFidelityPoints] = React.useState<
        string
    >("");
    const [password, setPassword] = React.useState<string>("");
    const [remark, setRemark] = React.useState<string>("");
    const [login, setLogin] = React.useState<string>("");
    const [moneyCorrespondence, setMoneyCorrespondence] = React.useState<
        string
    >("");
    //eslint-disable-next-line
    const [isEdited, setIsEdited] = React.useState<boolean>(false);
    const [authenticationMode, setAuthenticationMode] = React.useState<any>([]);
    const [sortedVisibility, setSortedVisibility] = React.useState<any>([{}]);
    const [inputUser, setInputUser] = React.useState<boolean>(false);
    const [inputDashboardLink, setInputDashboardLink] = React.useState<boolean>(
        false
    );
    const [user, setUser] = React.useState<string>("");
    const [dashboardLink, setDashboardLink] = React.useState<string>("");
    const [apiKey, setApiKey] = React.useState<string>("");
    const [inputApiKey, setInputApiKey] = React.useState<boolean>(false);
    const [
        inputAuthenticationMode,
        setInputAuthenticationMode,
    ] = React.useState<boolean>(false);
    const [inputLogin, setInputLogin] = React.useState<boolean>(false);
    const [inputPassword, setInputPassword] = React.useState<boolean>(false);
    const [auresKonnectGroup, setAuresKonnectGroup] = React.useState<string>(
        ""
    );
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const [inputGroup, setInputGroup] = React.useState<boolean>(false);
    const [isCentralizationActive, setIsCentralizationActive] = React.useState<
        boolean
    >(false);

    const [isVisibiliteActive, setIsVisibiliteActive] = React.useState<boolean>(
        false
    );
    const [
        inputNumberFidelityPoints,
        setInputNumberFidelityPoints,
    ] = React.useState<boolean>(false);
    const authenticationModeData: string[] = [
        t("Loyalty card"),
        t("Phone number"),
        t("Email and password"),
    ];

    const [
        isFidelityNumberInValid,
        setIsFidelityNumberInValid,
    ] = React.useState<boolean>(false);

    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const uuidUser = localStorage.getItem("uuidUser");

    async function editFidelity() {
        toast.dismiss();
        const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
        let dataConfig = {
            userId: userID,
            shopId: [selectedShopsRows[0].shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [selectedShopsRows[0]?.Id]: {
                    designation: designation,
                    type: choiceFidelity,
                    number_fidelity_points: numberFidelityPoints,
                    money_correspondence: moneyCorrespondence,
                    authentication_mode: authenticationMode.join(","),
                    sortedVisibility: sortedVisibility,
                    Login: login,
                    password: password,
                    note: remark,
                    isActive: selectedShopsRows[0]?.isActive,
                    isArchived: false,
                    shopId: selectedShopsRows[0].shopId,
                    dashboardLink: dashboardLink,
                    user: user,
                    apiKey: apiKey,
                    auresKonnectGroup: auresKonnectGroup,
                    isCentralizationActive: isCentralizationActive,
                    isVisibiliteActive: isVisibiliteActive,
                    fidelityChoiceIndex:
                        selectedShopsRows[0]?.fidelityChoiceIndex,
                },
            },
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };

        if (isEdited === false) {
            setIsConsultModalOpened(false);
            setGlobalDataIsUpdated!(true);
        } else {
            try {
                mutate(
                    apiUrlUpdate,
                    await fetch(apiUrlUpdate, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The fidelity setting has been successfully modified"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );

                            setIsEdited(false);
                            setIsConsultModalOpened(false);
                            setGlobalDataIsUpdated!(true);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    function handleValidate() {
        if (designation === "") {
            setInputDesignation(true);
        }
        if (login === "") {
            setInputLogin(true);
        }
        if (password === "") {
            setInputPassword(true);
        }
        if (user === "") {
            setInputUser(true);
        }
        if (numberFidelityPoints === "") {
            setInputNumberFidelityPoints(true);
        }
        if (dashboardLink === "") {
            setInputDashboardLink(true);
        }
        if (apiKey === "") {
            setInputApiKey(true);
        }
        if (auresKonnectGroup === "") {
            setInputGroup(true);
        }
        // if (authenticationMode.length === 0) {
        setInputAuthenticationMode(true);
        // }

        if (selectedShopsRows[0]?.Id !== undefined) {
            if (
                designation !== "" &&
                choiceFidelity !== "" &&
                !isFidelityNumberInValid
            ) {
                if (
                    (choiceFidelity.startsWith(t("Fidelity Aures Konnect")) &&
                        auresKonnectGroup !== "" &&
                        // authenticationMode.length !== 0 &&
                        numberFidelityPoints.match(numberMatchWord)) ||
                    (choiceFidelity === t("Fidelity Synapsy") &&
                        login !== "" &&
                        password !== "") ||
                    (choiceFidelity === t("Fidelity Zerosix") &&
                        user !== "" &&
                        password !== "" &&
                        dashboardLink !== "" &&
                        apiKey !== "")
                ) {
                    editFidelity();
                }
            }
        }
    }
    
    useEffect(() => {
        if (selectedShopsRows[0]?.Id !== undefined) {
            setDesignation(selectedShopsRows[0][t("Display name")]);
            setChoiceFidelity(
                selectedShopsRows[0][t("Loyalty")]
                    ? selectedShopsRows[0][t("Loyalty")]
                    : ""
            );
            setNumberFidelityPoints(
                selectedShopsRows[0]?.number_fidelity_points
            );
            setMoneyCorrespondence(selectedShopsRows[0]?.moneyCorrespondence);
            setAuthenticationMode(
                selectedShopsRows[0]?.authentication_mode !== undefined
                    ? selectedShopsRows[0]?.authentication_mode.split(",")
                    : []
            );
            setSortedVisibility(
                selectedShopsRows[0]?.sortedVisibility !== undefined
                    ? selectedShopsRows[0]?.sortedVisibility
                    : []
            );
            setRemark(selectedShopsRows[0][t("Remark")]);
            setLogin(selectedShopsRows[0]?.Login);
            setPassword(selectedShopsRows[0][t("Password")]);
            setUser(selectedShopsRows[0].user);
            setAuresKonnectGroup(selectedShopsRows[0][t("Group")]);
            setDashboardLink(selectedShopsRows[0]["Dashboard Link"]);
            setApiKey(selectedShopsRows[0]["API key"]);
            setIsCentralizationActive(
                selectedShopsRows[0][t("isCentralizationActive")]
            );
            setIsVisibiliteActive(
                selectedShopsRows[0][t("isVisibiliteActive")]
            );
        }
    }, [selectedShopsRows, t]);
    useEffect(() => {
        if ((numberFidelityPoints as any) % 100 !== 0) {
            setIsFidelityNumberInValid(true);
        } else {
            setIsFidelityNumberInValid(false);
        }
    }, [numberFidelityPoints]);

    return (
        <React.Fragment>
            <Modal
                centered
                isOpen={isConsultModalOpened}
                className="information_fidelity_modal__clz mt-5"
            >
                <AvForm className="card information_fidelity__clz">
                    <CustomCardHeader className="d-flex align-items-center justify-content-between">
                        <h5 className="pt-3">
                            {capitalize(t("Fidelity setting"))}
                        </h5>
                        <CrossIcon
                            className="icon-close-notification__clz"
                            height={13}
                            width={13}
                            onClick={() => {
                                setIsConsultModalOpened!(!isConsultModalOpened);
                                setGlobalDataIsUpdated!(true);
                            }}
                        />
                    </CustomCardHeader>
                    <CustomCardBody
                        className="pl-3 pr-5"
                        style={{
                            maxHeight: "500px",
                            overflowY: " scroll",
                        }}
                    >
                        <AvGroup style={{ display: "none" }}>
                            <StyledLabel
                                className="required__clz mt-3 "
                                htmlFor="name"
                            >
                                {t("Display name")}
                            </StyledLabel>
                            <StyledTextInput
                                id="designation"
                                name="designation"
                                value={designation}
                                onChange={(e: any) => {
                                    setDesignation(e.target.value.trim());
                                    setIsEdited(true);
                                    setInputDesignation(false);
                                }}
                                className={classnames("not-allowed-icon__clz", {
                                    "not-allowed-icon__clz":
                                        isCentralizationActive &&
                                        isConsultModalOpened,
                                    input__clz: inputDesignation,
                                })}
                            />
                            {inputDesignation ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please enter a display name")}
                                </div>
                            ) : null}
                        </AvGroup>
                        <AvGroup>
                            <StyledLabel
                                className="required__clz mt-3"
                                htmlFor="choiceFidelity"
                            >
                                {t("Loyalty")}
                            </StyledLabel>

                            <StyledSelectInput
                                value={
                                    choiceFidelity === ""
                                        ? null
                                        : {
                                              label: choiceFidelity,
                                              value: choiceFidelity,
                                          }
                                }
                                className="not-allowed-fidelity__clz"
                                style={{ opactiy: "0.8" }}
                                onChange={(e: any) => {}}
                                options={[
                                    {
                                        label: t("Fidelity Aures Konnect"),
                                        value: t("Fidelity Aures Konnect"),
                                    },
                                    {
                                        label: t("Fidelity Synapsy"),
                                        value: t("Fidelity Synapsy"),
                                    },
                                ]}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: `${t(
                                            "Please select a fidelity choice"
                                        )!}`,
                                    },
                                }}
                                placeholder={t("Select")}
                                name="mark"
                                noOptionsMessage={() => t("No options")}
                            />
                        </AvGroup>

                         {selectedShopsRows[0].fidelityChoiceIndex === 1 ? (
                        <FidelityAuresKonnect
                            numberFidelityPoints={numberFidelityPoints}
                            setNumberFidelityPoints={setNumberFidelityPoints}
                            moneyCorrespondence={moneyCorrespondence}
                            setMoneyCorrespondence={setMoneyCorrespondence}
                            authenticationMode={authenticationMode}
                            setAuthenticationMode={setAuthenticationMode}
                            authenticationModeData={authenticationModeData}
                            sortedVisibility={sortedVisibility}
                            setSortedVisibility={setSortedVisibility}
                            inputAuthenticationMode={inputAuthenticationMode}
                            setInputAuthenticationMode={
                                setInputAuthenticationMode
                            }
                            setIsEdited={setIsEdited}
                            auresKonnectGroup={auresKonnectGroup}
                            setAuresKonnectGroup={setAuresKonnectGroup}
                            inputGroup={inputGroup}
                            setInputGroup={setInputGroup}
                            isCentralizationActive={isCentralizationActive}
                            setIsCentralizationActive={
                                setIsCentralizationActive
                            }
                            isVisibiliteActive={isVisibiliteActive}
                            setIsVisibiliteActive={setIsVisibiliteActive}
                            setChoiceFidelity={setChoiceFidelity}
                            choiceFidelity={choiceFidelity}
                            designation={designation}
                            setDesignation={setDesignation}
                            isFidelityNumberInValid={isFidelityNumberInValid}
                            inputNumberFidelityPoints={
                                inputNumberFidelityPoints
                            }
                            setInputNumberFidelityPoints={
                                setInputNumberFidelityPoints
                            }
                            isTest={true}
                        />
                     ) : null}
                        {selectedShopsRows[0].fidelityChoiceIndex === 2 ? (
                            <FidelitySynapsy
                                password={password}
                                setPassword={setPassword}
                                login={login}
                                setLogin={setLogin}
                                inputLogin={inputLogin}
                                setInputLogin={setInputLogin}
                                inputPassword={inputPassword}
                                setInputPassword={setInputPassword}
                                setIsEdited={setIsEdited}
                            />
                        ) : null}
                        {selectedShopsRows[0].fidelityChoiceIndex === 3 ? (
                            <FidelityZerosix
                                password={password}
                                setPassword={setPassword}
                                user={user}
                                setUser={setUser}
                                inputUser={inputUser}
                                setInputUser={setInputUser}
                                inputPassword={inputPassword}
                                setInputPassword={setInputPassword}
                                setIsEdited={setIsEdited}
                                dashboardLink={dashboardLink}
                                setDashboardLink={setDashboardLink}
                                setApiKey={setApiKey}
                                apiKey={apiKey}
                                inputDashboardLink={inputDashboardLink}
                                inputApiKey={inputApiKey}
                                setInputDashboardLink={setInputDashboardLink}
                                setInputApiKey={setInputApiKey}
                            />
                        ) : null}
                       {selectedShopsRows[0].fidelityChoiceIndex === 1?null: <AvGroup>
                            <StyledLabel className="mt-3" htmlFor="name">
                                {t("Remark")}
                            </StyledLabel>
                            <StyledTextInput
                                autocomplete="off"
                                id="remark"
                                name="remark"
                                placeholder={t("Write")}
                                type="text"
                                onChange={(e: any) => {
                                    setRemark(e.target.value);
                                    setIsEdited(true);
                                }}
                                className={classnames("", {
                                    "not-allowed-icon__clz":
                                        isCentralizationActive &&
                                        isConsultModalOpened,
                                })}
                                value={remark}
                            />
                        </AvGroup>}
                    </CustomCardBody>
                    <CustomCardFooter className="pt-1 pb-2 mt-4">
                        <CustomSecondaryColorButton
                            outline
                            rounded
                            className="mr-2 fed_btn_FRcancel cmn_btn_cancel"
                            variant="light"
                            onClick={() => {
                                setIsConsultModalOpened(false);
                                setGlobalDataIsUpdated!(true);
                            }}
                        >
                            <span> {t("Cancel")}</span>
                        </CustomSecondaryColorButton>
                        <CustomMainColorButton
                            variant="primary"
                            className={`cmn_btn_validate  ${classnames(
                                {
                                    "not-allowed-icon__clz":
                                        isCentralizationActive === true,
                                }
                            )}`}
                            onClick={() => {
                                handleValidate();
                            }}
                            rounded
                        >
                            {t("Validate")}
                        </CustomMainColorButton>
                    </CustomCardFooter>
                </AvForm>
            </Modal>
        </React.Fragment>
    );
}
